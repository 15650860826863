
import React, { Component, useState, useRef } from 'react';
import { Link } from 'react-router';
import axios, { post }  from 'axios';
import browserHistory from 'react-router/lib/browserHistory';
import { connect } from 'react-redux';

import Modal2 from '../components/modal2.jsx'
import $ from 'jquery';
import parse from 'html-react-parser';
import Karteikarte from './karteikarte.jsx'
import {getPronom, getKonjugationsTable, getSpracheLang, getOS} from '../components/generalfunctions'
import 'material-icons/iconfont/material-icons.css';
import {Modal} from 'react-bootstrap'
import YoutubeVideo from '../components/youtubevideo.jsx';
import Beschriftung from '../components/beschriftung.jsx'
import getImageSize from 'image-size-from-url';
import CameraPanel from '../components/camerapanel.jsx';



class EditUnterrichtsModul extends Component  {


    


    constructor(props)  {
        super(props);
        this.state=(
          {              
                unterrichtsmodul:null,
                unterrichtsmodule:[],
                unterrichtsmodulteile:[],
                activeKopierenModul:null,
                addModulteilText:'',
                selectedmodulteil:{
                    id:0,
                    unterrichtsmodule_id:props.params.unterrichtsmodulid,
                    autor_id:0,
                    frage:'',
                    antwort1:'',
                    antwort2:'',
                    antwort3:'',
                    antwort4:'',
                    antwort5:'',
                    mussfrageschriftlichbeantworten:0,
                    aktiv:1,
                    notizen:'',
                    zusatzinfos:'',
                    frage_videobild_url:'',
                    wahroderfalsch:0,
                    antwort1_richtig:1,
                    antwort2_richtig:0,
                    antwort3_richtig:0,
                    antwort4_richtig:0,
                    antwort5_richtig:0,
                    anzahl_antworten:4,  
                    konjug_sprache:'',
                    konjug_zeitform:'',
                    konjug_ich:'',
                    konjug_du:'',
                    konjug_ersiees:'',
                    konjug_wir:'',
                    konjug_ihr:'',
                    konjug_sie:'',
                    ist_konjugation:0,
                    konjug_verb:'' ,    
                    ist_youtube_video:0,
                    nur_audio_abspielen:0,
                    youtube_video_id:'',
                    youtube_video_startzeit:0,
                    youtube_video_laufzeit:0,
                    antwort_videobild_url:'',
                    ist_vokabelaudio:0,
                    ist_bildmitbeschriftung:0,
                    beschriftung_1:'',
                    beschriftung_2:'',
                    beschriftung_3:'',
                    beschriftung_4:'',
                    beschriftung_5:'',
                    beschriftung_6:'',
                    beschriftung_7:'',
                    beschriftung_8:'',
                    beschriftung_9:'',
                    beschriftung_10:'',
                    beschriftung_1_pfeil:1,
                    beschriftung_2_pfeil:1,
                    beschriftung_3_pfeil:1,
                    beschriftung_4_pfeil:1,
                    beschriftung_5_pfeil:1,
                    beschriftung_6_pfeil:1,
                    beschriftung_7_pfeil:1,
                    beschriftung_8_pfeil:1,
                    beschriftung_9_pfeil:1,
                    beschriftung_10_pfeil:1,
                    beschriftung_1_x:0.0,
                    beschriftung_1_y:0.0,
                    beschriftung_2_x:60.0,
                    beschriftung_2_y:0.0,
                    beschriftung_3_x:120.0,
                    beschriftung_3_y:0.0,
                    beschriftung_4_x:160.0,
                    beschriftung_4_y:0.0,
                    beschriftung_5_x:200.0,
                    beschriftung_5_y:0.0,
                    beschriftung_6_x:240.0,
                    beschriftung_6_y:0.0,
                    beschriftung_7_x:280.0,
                    beschriftung_7_y:0.0,
                    beschriftung_8_x:320.0,
                    beschriftung_8_y:0.0,
                    beschriftung_9_x:360.0,
                    beschriftung_9_y:0.0,
                    beschriftung_10_x:400.0,
                    beschriftung_10_y:0.0,
                    beschriftung_antwort_nummern:0,   
                    antwort1_imageurl:'',  
                    antwort2_imageurl:'',  
                    antwort3_imageurl:'',  
                    antwort4_imageurl:'',  
                    antwort5_imageurl:'',

                
                },
                benutzereinstellungen:[], 
                antwortModus:1,
                lastAntwortModus:1,
                istneuesModulteil:true,
                insertNewAtOne:false,
                insertAndPreview:false,
                selectedUnterrichtsmodulTeileID:0,
                translationLanguages:[
                    {
                        key:'deen',
                        label:'Englisch'
                    },
                    {
                        key:'defr',
                        label:'Französisch'
                    },
                    {
                        key:'dees',
                        label:'Spanisch'
                    },
                    {
                        key:'dept',
                        label:'Portugiesisch'
                    },
                    {
                        key:'detr',
                        label:'Türkisch'
                    },
                    {
                        key:'arde',
                        label:'Arabisch'
                    },
                    {
                        key:'deit',
                        label:'Italienisch'
                    },
                    {
                        key:'dehr',
                        label:'Kroatisch'
                    },
                    {
                        key:'deru',
                        label:'Russisch'
                    },
                    {
                        key:'depl',
                        label:'Polnisch'
                    },
                    {
                        key:'desl',
                        label:'Slowenisch'
                    },
                    {
                        key:'defi',
                        label:'Finnisch'
                    },
                    {
                        key:'dero',
                        label:'Rumänisch'
                    },           
                    {
                        key:'desk',
                        label:'Slowakisch'
                    },           
                    {
                        key:'deja',
                        label:'Japanisch'
                    },           
                    {
                        key:'csde',
                        label:'Tschechisch'
                    },           
                    {
                        key:'dehu',
                        label:'Ungarisch'
                    },           
                    {
                        key:'deno',
                        label:'Norwegisch'
                    },           
                    {
                        key:'desv',
                        label:'Schwedisch'
                    },            
                    {
                        key:'dade',
                        label:'Dänisch'
                    },           
                    {
                        key:'denl',
                        label:'Niederländisch'
                    },          
                    {
                        key:'delb',
                        label:'Elbisch'
                    },   

                ],
                selectedTranslationLanguage: {
                    key:'deen',
                    label:'Englisch'
                }, 
                translationHits:[],
                translationHits2:[],
                alertMessage:'',
                file: null,
                previewModulteil: null,
                karteikartenModus:'Frage',
                konjug_zeitformen_de:[                    
                    "INDIKATIV - Präsens (ich spreche)",
                    "INDIKATIV - Präteritum (ich sprach)",
                    "INDIKATIV - Futur I (ich werde sprechen)",
                    "INDIKATIV - Perfekt (ich habe gesprochen)",
                    "INDIKATIV - Plusquamperfekt (ich hatte gesprochen)",
                    "INDIKATIV - Futur II (ich werde gesprochen haben)",                    
                    "KONJUNKTIV I - Präsens (du sprechest, er spreche)",
                    "KONJUNKTIV I - Futur I (du werdest sprechen)",
                    "KONJUNKTIV I - Perfekt (er/sie/es habe gesprochen)",
                    "KONJUNKTIV I - Futur II (ich werde gesprochen haben)",
                    "KONJUNKTIV II - Präteritum (ich spräche, du sprächest)",
                    "KONJUNKTIV II - Futur I (du würdest sprechen)",
                    "KONJUNKTIV II - Plusquamperfekt (ich hätte gesprochen)",
                    "KONJUNKTIV II - Futur II (ich würde gesprochen haben)",
                    "Imperativ Präsens",  
                ],

                konjug_zeitformen_en:[
                    "Present (I speak)",
                    "Preterite (I spoke)",
                    "Present continuous (I am speaking)",
                    "Present perfect (I have spoken) ",
                    "Future (I will speak)",
                    "Future perfect (You will have spoken)",
                    "Past continous (I was speaking)",
                    "Past perfect (I had spoken)",
                    "Future continuous (I will be speaking)",
                    "Present perfect continuous (I have been speaking)",
                    "Past perfect continuous (I had been speaking)",
                    "Future perfect continuous (I will have been speaking)",
                ],

                konjug_zeitformen_fr:[
                    "INDICATIF - Présent (je parle)",
                    "INDICATIF - Imparfait (je parlais)",
                    "INDICATIF - Futur (je parlerai)",
                    "INDICATIF - Passé simple (je parlai)",
                    "INDICATIF - Passé composé (j'ai parlé)",
                    "INDICATIF - Plus-que-parfait (j'avais parlé)",
                    "INDICATIF - Passé antérieur (j'eus parlé)",
                    "INDICATIF - Futur antérieur (j'aurai parlé))",
                    "SUBJONCTIF - Présent (que je parle)",
                    "SUBJONCTIF - Imparfait (que je parlasse)",
                    "SUBJONCTIF - Plus-que-parfait (que j'eusse parlé)",
                    "SUBJONCTIF - Passé (que j'aie parlé)",
                    "CONDITIONNEL - Présent (je parlerais)",
                    "CONDITIONNEL - Passé première forme (j'aurais parlé)",
                    "CONDITIONNEL - Passé deuxième forme (j'eusse parlé)",
                    "IMPÉRATIF - Présent (parle)",
                    "IMPÉRATIF - Passé (aie parlé)",
                ],

                konjug_zeitformen_es:[
                    "INDICATIVO - presente (hablo)",
                    "INDICATIVO - imperfecto (hablaba)",
                    "INDICATIVO - indefinido (hablé)",
                    "INDICATIVO - perfecto compuesto (he hablado)",
                    "INDICATIVO - pluscuamperfecto (había hablado)",
                    "INDICATIVO - pretérito anterior (hube hablado)",
                    "INDICATIVO - futuro simple(hablaré)",
                    "SUBJUNTIVO - presente (yo hable)",
                    "SUBJUNTIVO - imperfecto (yo hablara)",
                    "SUBJUNTIVO - imperfecto (2) (yo hablase)",
                    "SUBJUNTIVO - pretérito perfecto (yo haya hablado)",
                    "SUBJUNTIVO - pluscuamperfecto (yo hubiera hablado)",
                    "SUBJUNTIVO - pluscuamperfecto (2) (yo hubiese hablado)",
                    "SUBJUNTIVO - futuro simple (yo hablare)",
                    "SUBJUNTIVO - futuro compuesto (hubiere hablado)",
                    "CONDICIONAL - simple(hablaría)",
                    "CONDICIONAL - compuesto (habría hablado)",
                    "IMPERATIVO - (habla tu)",
                    "IMPERATIVO - negativo (no hables)",
                ],

                konjug_zeitformen_it:[
                    "INDICATIVO - Presente (parlo)",
                    "INDICATIVO - Imperfetto (parlavo)",
                    "INDICATIVO - Passato remoto (parlai)",
                    "INDICATIVO - Futuro semplice (parlerò)",
                    "INDICATIVO - Passato prossimo (ho parlato)",
                    "INDICATIVO - Trapassato prossimo (avevo parlato)",
                    "INDICATIVO - Trapassato remoto (ebbi parlato)",
                    "INDICATIVO - Futuro anteriore (avrò parlato)",
                    "CONGIUNTIVO - Presente (che parli)",
                    "CONGIUNTIVO - Passato (che abbia parlato)",
                    "CONGIUNTIVO - Imperfetto (che parlassi)",
                    "CONGIUNTIVO - Trapassato (che avessi parlato)",
                    "CONDIZIONALE - Presente (parlerei)",
                    "CONDIZIONALE - Passato (avrei parlato)",
                    "IMPERATIVO PRESENTE - (parla) ",
                ],
                konjug_zeitformen_pt:[
                    "INDICATIVO - Presente (falo)",
                    "INDICATIVO - Pretérito Perfeito (falei)",
                    "INDICATIVO - Pretérito Imperfeito (falava)",
                    "INDICATIVO - Pretérito Mais-que-Perfeito (falara)",
                    "INDICATIVO - Pretérito Perfeito Composto (tenho falado)",
                    "INDICATIVO - Pretérito Mais-que-Perfeito Composto (tinha falado)",
                    "INDICATIVO - Pretérito Mais-que-Perfeito Anterior (tivera falado)",
                    "INDICATIVO - Futuro do Presente Simples (falarei)",
                    "INDICATIVO - Futuro do Presente Composto (terei falado)",
                    "CONJUNTIVO / SUBJUNTIVO - Presente (que fale)",
                    "CONJUNTIVO / SUBJUNTIVO - Pretérito Perfeito (tenha falado)",
                    "CONJUNTIVO / SUBJUNTIVO - Pretérito Imperfeito (se falasse)",
                    "CONJUNTIVO / SUBJUNTIVO - Pretérito Mais-que-Perfeito Composto (tivesse falado)",
                    "CONJUNTIVO / SUBJUNTIVO - Futuro (quando falar)",
                    "CONJUNTIVO / SUBJUNTIVO - Futuro Composto (tiver falado)",
                    "CONDICIONAL - Futuro do Pretérito Simples (falaria)",
                    "CONDICIONAL - Futuro do Pretérito Composto (teria falado)",
                    "IMPERATIVO - (fala)",
                    "IMPERATIVO NEGATIVO - (não fales)",
                ],
                eingabeHilfeListe:[],
                markierenStatus:false,
                selectedObjectIDs:[],
                karteikarte: null,
                uploaderlaubnis: false,
                mp3SuchSprache:'Englisch',
                filenameSkizze:'',
                editing:false,
                canRefresh:true,
                dataUri: null

                
          }
    
        ) 
        this.unterrichtsmodulteileRow = this.unterrichtsmodulteileRow.bind(this)  
        this.getmodalNeuesModulTeil = this.getmodalNeuesModulTeil.bind(this) 
        this.saveModulTeil = this.saveModulTeil.bind(this)
        this.changeFrage = this.changeFrage.bind(this)
        this.changeURL = this.changeURL.bind(this)
        this.changeAntwortURL = this.changeAntwortURL.bind(this)
        this.changeYoutubeVideoID = this.changeYoutubeVideoID.bind(this)
        this.changeAntwort = this.changeAntwort.bind(this)
        this.addNeuesModul = this.addNeuesModul.bind(this)
        this.addNeuesModu2 = this.addNeuesModul2.bind(this)
        this.checkAntwortModus = this.checkAntwortModus.bind(this)
        this.divTranslationButton = this.divTranslationButton.bind(this)
        this.editModulteil = this.editModulteil.bind(this)
        this.saveAndNext = this.saveAndNext.bind(this)
        this.saveAndPreview = this.saveAndPreview.bind(this)
        this.save = this.save.bind(this)
        this.deleteunterrichtsmodulteil = this.deleteunterrichtsmodulteil.bind(this)
        this.showDeleteDialog = this.showDeleteDialog.bind(this)
        this.previewModul = this.previewModul.bind(this)
        this.previewModulReadOnly = this.previewModulReadOnly.bind(this)
        this.canEditModul = this.canEditModul.bind(this)
        this.setWahroderfalsch = this.setWahroderfalsch.bind(this)
        this.changeKorrektur = this.changeKorrektur.bind(this)
        this.changeNurNummernEingeben = this.changeNurNummernEingeben.bind(this)
        this.getHauspostadresse = this.getHauspostadresse.bind(this)
        this.sendTranslationRequest = this.sendTranslationRequest.bind(this)
        this.alertMessage = this.alertMessage.bind(this)
        this.alertMessageDialog = this.alertMessageDialog.bind(this)
        this.translationLanguagesRow = this.translationLanguagesRow.bind(this)
        this.getBenutzereinstellung = this.getBenutzereinstellung.bind(this)
        this.saveLanguage = this.saveLanguage.bind(this)
        this.saveKonjugationLanguage = this.saveKonjugationLanguage.bind(this)
        this.fileUploadButton = this.fileUploadButton.bind(this)    
        this.onFileUploadChange = this.onFileUploadChange.bind(this)
        this.fileUpload = this.fileUpload.bind(this)
        this.setAnzahlAntworten = this.setAnzahlAntworten.bind(this)
        this.changeRichtigeAntwortNummer = this.changeRichtigeAntwortNummer.bind(this)
        this.vorschauKarteikarte = this.vorschauKarteikarte.bind(this)
        this.showPreviewDialog = this.showPreviewDialog.bind(this)
        this.onClickKarteikarte = this.onClickKarteikarte.bind(this)
        this.getUnterrichtsmodulTeile = this.getUnterrichtsmodulTeile.bind(this)
        this.changeKonjugSprache = this.changeKonjugSprache.bind(this)
        this.changeKonjugZeitform = this.changeKonjugZeitform.bind(this)
        this.zeitformRow=this.zeitformRow.bind(this)
        this.changeKonjug = this.changeKonjug.bind(this)
        this.changeKonjugVerb = this.changeKonjugVerb.bind(this)
        this.modalEingabeHilfeDialog = this.modalEingabeHilfeDialog.bind(this)
        this.kopierenDialog = this.kopierenDialog.bind(this)
        this.getUnterrichtsmodule = this.getUnterrichtsmodule.bind(this)
        this.markieren = this.markieren.bind(this)
        this.clickDataset = this.clickDataset.bind(this)
        this.kopieren = this.kopieren.bind(this)     
        this.changeIstYoutubeVideo = this.changeIstYoutubeVideo.bind(this)
        this.changeNurAudioAbspielen = this.changeNurAudioAbspielen.bind(this)
        this.changeVideoStartZeit = this.changeVideoStartZeit.bind(this)
        this.changeVideoLaufZeit = this.changeVideoLaufZeit.bind(this)
        this.cancelPreview = this.cancelPreview.bind(this)
        this.modalYoutubeDialog = this.modalYoutubeDialog.bind(this)
        this.getUploadErlaubnis = this.getUploadErlaubnis.bind(this)
        this.checkNews = this.checkNews.bind(this)
        this.suchemp3 = this.suchemp3.bind(this)
        this.setBeschriftung = this.setBeschriftung.bind(this)
        this.getSkizzenBeschriftungsTabelle = this.getSkizzenBeschriftungsTabelle.bind(this)
        this.changePfeilRichtung = this.changePfeilRichtung.bind(this)
        this.onStopDrag = this. onStopDrag.bind(this)
        this.cameraDialog = this.cameraDialog.bind(this)
        this.handleTakePhoto = this.handleTakePhoto.bind(this)
        this.uploadImage = this.uploadImage.bind(this)
        this.changeAntwortImageUrl = this.changeAntwortImageUrl.bind(this)

        setInterval(this.checkNews, 30 * 1000)


        
      }  

      checkNews(){
        if (this.state.editing || this.state.canRefresh===false) return
        
        this.getUnterrichtsmodulTeile()
        this.getUploadErlaubnis()
      }

      handleTakePhoto(dataUri){
        console.log(dataUri)
        this.setState({dataUri, })
      }

      cameraDialog(){

           
        const body=(
           
            <div>
               <CameraPanel
                    onTakePhoto={(dataUri)=>this.handleTakePhoto(dataUri)}
                    dataUri={this.state.dataUri}
                    />
            </div>
        )

        const okButtonVisible = this.state.dataUri !==null

        let leftButton1 = null
        let okButton = false
        if (this.state.dataUri !==null){
            leftButton1=<button className="btn btn-primary" onClick={()=>this.setState({dataUri:null})}>Neues Foto aufnehmen</button>
            
        }

        const _this = this

        function close(){
            const selectedmodulteil = _this.state.selectedmodulteil
            selectedmodulteil.frage_videobild_url = ''
            _this.setState({selectedmodulteil})

        }

        
      

        return (
            <Modal2
                modalid="modalcameraDialog"
                buttonid="modalcameraDialogButton"
                body={body}
                title="Foto aufnehmen"
                okButtonTitle="Foto verwenden"             
                okButtonClick={close }
                okButtonVisible={okButtonVisible}
                cancelButton={true}
                cancelButtonClick={()=>this.setState({dataUri:null})}
                leftButton1={leftButton1}
            />
        )

      }

      uploadImage(filename){

     
            const _this = this
            var blob =  new Blob(["i am a blob"]);
            var reader = new FileReader();
            // this function is triggered once a call to readAsDataURL returns
            reader.onload = function(){
                var fd = new FormData();            
                fd.append('anmeldecode', _this.props.activeUser.anmeldecode);
                fd.append('filename', filename)
                fd.append('data', _this.state.dataUri);
                $.ajax({
                    type: 'POST',
                    url: 'https://schoolflashcards.eu/_php/upload2.php',
                    data: fd,
                    processData: false,
                    contentType: false
                }).done(function(result) {
                    // print the output from the upload.php script
                    console.log("Muschiresult", result);
                });
            };      
            // trigger the read from the reader...
            reader.readAsDataURL(blob);


      }


      getUploadErlaubnis(){
        if (this.props.activeUser === undefined) return
        if (this.props.activeUser.ist_schueler===1){
            const sql = `SELECT COUNT(*) FROM sfc_schueler_upload_erlaubnisse WHERE schueler_id=${this.props.activeUser.id} AND erlaubt_bis > NOW()`
            const array={sql}
            axios.post('/api/execcommonquery/integer', array)
            .then(
                result=>{
                    let uploaderlaubnis = false
                    if (result.data > 0){
                        uploaderlaubnis = true                        
                    }
                    this.setState({uploaderlaubnis})
                }
            )
        }

    }


      modalYoutubeDialog() {

        let karteikarte = null
        let title = null

        if (this.state.karteikarte !==null){
            karteikarte=this.state.karteikarte
            title=karteikarte.frage
        }




        let body=(

            <YoutubeVideo
            karteikarte={karteikarte}
          />
        )

       

        return (
            <Modal2
                modalid="modalYoutubeDialog"
                buttonid="modalYoutubeDialogButton"
                body={body}
                title={title}
                okButtonTitle="OK"
                okButtonClick={()=>this.setState({karteikarte:null})}
                cancelButton={true}
                cancelButtonClick={()=>this.setState({karteikarte:null})}
            />
        )
    }

     


      kopierenDialog(){

        const _this = this


        function modulRow(modul){
            return(
                <a key={modul.id} className="dropdown-item" onClick={()=>_this.setState({activeKopierenModul:modul})} href="#">{modul.bezeichnung}</a>
            )
        }

        let title="(nicht gewählt)"
        if (this.state.activeKopierenModul !==null){
            title = this.state.activeKopierenModul.bezeichnung
        }

        let dropDown=(
            <div className="dropdown">
                <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {title}
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    {this.state.unterrichtsmodule.map(modulRow)}
                </div>
            </div>
        )

        let body=(
            <div>
                <p>Ausgewählte Karteikarten werden in folgenden Stapel kopiert:</p>
                {dropDown}

            </div>
        )

        return(
            
            <Modal2
            modalid="modalKopierenDialog"
            buttonid="modalKopierenDialogButton"
            title="Karteikarten kopieren"
            body={body}
            okButtonClick={this.kopieren}
            okButtonTitle="Zum ausgewählten Stapel kopieren"
            cancelButton={true}
            okButtonenabled={this.state.activeKopierenModul !==null}
        />
        )

      }


      
      kopieren(){

        const _this = this
        let unterrichtsmodulteile=[]

        this.state.selectedObjectIDs.forEach(
            function(id){
                _this.state.unterrichtsmodulteile.forEach(
                    function(modulteil){
                        if (modulteil.id===id){
                            unterrichtsmodulteile.push(modulteil)
                        }
                    }
                )
            }
        )

        let array={
            unterrichtsmodule_id: this.state.activeKopierenModul.id,
            unterrichtsModulTeilList:unterrichtsmodulteile
        }


       

        axios.post('/api/unterrichtsmodulteile/kopieren/' ,array )
        .then(
            result=>{
            }
        )

        this.setState({activeKopierenModul:null})

      }


      clickDataset(e) {

        let selectedObjectIDs = this.state.selectedObjectIDs;

        const id = Number(e.target.value);
        if (selectedObjectIDs.indexOf(id) === -1) {
            selectedObjectIDs.push(id);
        }
        else {
            selectedObjectIDs.splice(selectedObjectIDs.indexOf(id), 1)
        }

        this.setState({ selectedObjectIDs })
    }

      markieren(e) {

        let selectedObjectIDs = []
        if (e.target.name === "allemarkieren") {
            this.state.unterrichtsmodulteile.forEach(function (modulteil) {
                selectedObjectIDs.push(modulteil.id);
            }
            );
        }
        this.setState({ selectedObjectIDs })
    }

    

      getBenutzereinstellung(tag) {

        let benutzerEinstellung = null

        this.state.benutzereinstellungen.forEach(
            function (be) {

                if (be.tag === tag) {
                    benutzerEinstellung = be
                }
            }
        )

        return benutzerEinstellung
    }


    getHauspostadresse(){
        let eigeneAdresse= this.props.activeUser.hauspost_adresse
        if (this.props.activeUser.ist_schueler===1){
                eigeneAdresse= this.props.activeSchueler.hauspost_adresse
        }
        return eigeneAdresse
    }


      previewModul(){       

        let backto = 'edit'
        if (this.props.params.from==="briefkasten")backto="briefkasten"
        if (this.props.params.from==="globalemodule")backto="globalemodule"
   
        browserHistory.push('/previewunterrichtsmodul/' + this.props.params.unterrichtsmodulid + '/' + backto)
       
      }


      previewModulReadOnly(){
          browserHistory.push('/previewunterrichtsmodul/' + this.props.params.unterrichtsmodulid + '/readonly')
      }

      deleteunterrichtsmodulteil(id){
            const _this = this
            this.state.unterrichtsmodulteile.forEach(
                function(teil){
                    if (teil.id===_this.state.selectedUnterrichtsmodulTeileID){
                        axios.post('/api/unterrichtsmodulteile/delete', teil)
                        .then(
                            result=>{
                                _this.setState({unterrichtsmodulteile: result.data})
                            }
                        )
                    }
                }
            ) 
      }

      
      addNeuesModul(){

        this.setState({
            antwortModus:1,
            lastAntwortModus:1,
            file:null,
            canRefresh:true,
            editing:true,
            selectedmodulteil:{
                id:0,
                unterrichtsmodule_id:this.props.params.unterrichtsmodulid,
                autor_id:0,
                frage:'',
                antwort1:'',
                antwort2:'',
                antwort3:'',
                antwort4:'',
                antwort5:'',
                mussfrageschriftlichbeantworten:0,
                aktiv:1,
                notizen:'',
                zusatzinfos:'',
                frage_videobild_url:'',
                wahroderfalsch:0,
                antwort1_richtig:1,
                antwort2_richtig:0,
                antwort3_richtig:0,
                antwort4_richtig:0,
                antwort5_richtig:0,
                anzahl_antworten:4, 
                konjug_sprache:this.getBenutzereinstellung("lastKonjugationLanguage").strvalue,
                konjug_zeitform:'',
                konjug_ich:'',
                konjug_du:'',
                konjug_ersiees:'',
                konjug_wir:'',
                konjug_ihr:'',
                konjug_sie:'',
                ist_konjugation:0,
                konjug_verb:'' ,      
                ist_youtube_video:0,
                nur_audio_abspielen:0,
                youtube_video_id:'',
                youtube_video_startzeit:0,
                youtube_video_laufzeit:0,
                antwort_videobild_url:'',
                ist_vokabelaudio:0,
                ist_bildmitbeschriftung:0,
                beschriftung_1:'',
                beschriftung_2:'',
                beschriftung_3:'',
                beschriftung_4:'',
                beschriftung_5:'',
                beschriftung_6:'',
                beschriftung_7:'',
                beschriftung_8:'',
                beschriftung_9:'',
                beschriftung_10:'',
                beschriftung_1_pfeil:1,
                beschriftung_2_pfeil:1,
                beschriftung_3_pfeil:1,
                beschriftung_4_pfeil:1,
                beschriftung_5_pfeil:1,
                beschriftung_6_pfeil:1,
                beschriftung_7_pfeil:1,
                beschriftung_8_pfeil:1,
                beschriftung_9_pfeil:1,
                beschriftung_10_pfeil:1,
                beschriftung_1_x:0.0,
                beschriftung_1_y:0.0,
                beschriftung_2_x:60.0,
                beschriftung_2_y:0.0,
                beschriftung_3_x:120.0,
                beschriftung_3_y:0.0,
                beschriftung_4_x:160.0,
                beschriftung_4_y:0.0,
                beschriftung_5_x:200.0,
                beschriftung_5_y:0.0,
                beschriftung_6_x:240.0,
                beschriftung_6_y:0.0,
                beschriftung_7_x:280.0,
                beschriftung_7_y:0.0,
                beschriftung_8_x:320.0,
                beschriftung_8_y:0.0,
                beschriftung_9_x:360.0,
                beschriftung_9_y:0.0,
                beschriftung_10_x:400.0,
                beschriftung_10_y:0.0,
                beschriftung_antwort_nummern:0,   
                antwort1_imageurl:'',  
                antwort2_imageurl:'',  
                antwort3_imageurl:'',  
                antwort4_imageurl:'',  
                antwort5_imageurl:''


            }
        } ,
        function(){
            $('#neuesModulTeilButton').click()
        }
        )
        
      }


      addNeuesModul2(){

        this.setState({
            antwortModus:1,
            lastAntwortModus:1,
            file:null,
            selectedmodulteil:{
                id:0,
                unterrichtsmodule_id:this.props.params.unterrichtsmodulid,
                autor_id:0,
                frage:'',
                antwort1:'',
                antwort2:'',
                antwort3:'',
                antwort4:'',
                antwort5:'',
                mussfrageschriftlichbeantworten:0,
                aktiv:1,
                notizen:'',
                zusatzinfos:'',
                frage_videobild_url:'',
                wahroderfalsch:0,
                antwort1_richtig:1,
                antwort2_richtig:0,
                antwort3_richtig:0,
                antwort4_richtig:0,
                antwort5_richtig:0,
                anzahl_antworten:5, 
                konjug_sprache:this.getBenutzereinstellung("lastKonjugationLanguage").strvalue,
                konjug_zeitform:'',
                konjug_ich:'',
                konjug_du:'',
                konjug_ersiees:'',
                konjug_wir:'',
                konjug_ihr:'',
                konjug_sie:'',
                ist_konjugation:0,
                konjug_verb:'' ,     
                ist_youtube_video:0,
                nur_audio_abspielen:0,
                youtube_video_id:'',
                youtube_video_startzeit:0,
                youtube_video_laufzeit:0,
                antwort_videobild_url:'',
                ist_vokabelaudio:0,
                ist_bildmitbeschriftung:0,
                beschriftung_1:'',
                beschriftung_2:'',
                beschriftung_3:'',
                beschriftung_4:'',
                beschriftung_5:'',
                beschriftung_6:'',
                beschriftung_7:'',
                beschriftung_8:'',
                beschriftung_9:'',
                beschriftung_10:'',
                beschriftung_1_pfeil:1,
                beschriftung_2_pfeil:1,
                beschriftung_3_pfeil:1,
                beschriftung_4_pfeil:1,
                beschriftung_5_pfeil:1,
                beschriftung_6_pfeil:1,
                beschriftung_7_pfeil:1,
                beschriftung_8_pfeil:1,
                beschriftung_9_pfeil:1,
                beschriftung_10_pfeil:1,
                beschriftung_1_x:0.0,
                beschriftung_1_y:0.0,
                beschriftung_2_x:60.0,
                beschriftung_2_y:0.0,
                beschriftung_3_x:120.0,
                beschriftung_3_y:0.0,
                beschriftung_4_x:160.0,
                beschriftung_4_y:0.0,
                beschriftung_5_x:200.0,
                beschriftung_5_y:0.0,
                beschriftung_6_x:240.0,
                beschriftung_6_y:0.0,
                beschriftung_7_x:280.0,
                beschriftung_7_y:0.0,
                beschriftung_8_x:320.0,
                beschriftung_8_y:0.0,
                beschriftung_9_x:360.0,
                beschriftung_9_y:0.0,
                beschriftung_10_x:400.0,
                beschriftung_10_y:0.0,
                beschriftung_antwort_nummern:0,   
                antwort1_imageurl:'',  
                antwort2_imageurl:'',  
                antwort3_imageurl:'',  
                antwort4_imageurl:'',  
                antwort5_imageurl:''


            }
        } 
        )
        
      }


      save(){
          this.setState({insertNewAtOne:false},
            function(){     
                    
                this.saveModulTeil();
            })
          
         
      }

      saveAndNext(){
          this.setState({insertNewAtOne:true},
            function(){
                this.saveModulTeil();
            })
      }

      saveModulTeil(){

        let selectedmodulteil= this.state.selectedmodulteil

    

        if (selectedmodulteil.autor_id ===0)
            selectedmodulteil.autor_id = this.props.activeUser.id      
        
        selectedmodulteil.antwort1 = selectedmodulteil.antwort1.trim()
        selectedmodulteil.antwort2 = selectedmodulteil.antwort2.trim()
        selectedmodulteil.antwort3 = selectedmodulteil.antwort3.trim()
        selectedmodulteil.antwort4 = selectedmodulteil.antwort4.trim()
        if (selectedmodulteil.antwort5)
        selectedmodulteil.antwort5 = selectedmodulteil.antwort5.trim()

        let mussfrageschriftlichbeantworten = 0
        if (this.state.antwortModus===2)mussfrageschriftlichbeantworten=1
        selectedmodulteil.mussfrageschriftlichbeantworten=mussfrageschriftlichbeantworten

        if (this.state.antwortModus< 3){
            selectedmodulteil.antwort2=''
            selectedmodulteil.antwort3=''
            selectedmodulteil.antwort4=''
            selectedmodulteil.antwort5=''
        }

        selectedmodulteil.multiplechoice=0
        if (this.state.antwortModus == 3) {
            selectedmodulteil.multiplechoice=1
            
            if (selectedmodulteil.anzahl_antworten===2){
                selectedmodulteil.antwort3=''
                selectedmodulteil.antwort4=''
                selectedmodulteil.antwort5=''
            }
            if (selectedmodulteil.anzahl_antworten===3){
                selectedmodulteil.antwort4=''
                selectedmodulteil.antwort5=''
            }
            if (selectedmodulteil.anzahl_antworten===4){
                selectedmodulteil.antwort5=''
            }
        }
        
        selectedmodulteil.ist_konjugation= 0;
        if (this.state.antwortModus===5){
            selectedmodulteil.ist_konjugation= 1;
        }
        selectedmodulteil.ist_vokabelaudio = 0
        if (this.state.antwortModus===6){
            selectedmodulteil.ist_vokabelaudio= 1;
        }
        selectedmodulteil.ist_bildmitbeschriftung = 0
        if (this.state.antwortModus===7){
            selectedmodulteil.ist_bildmitbeschriftung =1
        }


        let wahroderfalsch = -1
        if (this.state.antwortModus===4){
            wahroderfalsch = selectedmodulteil.wahroderfalsch
            selectedmodulteil.antwort1=''
        }
        selectedmodulteil.wahroderfalsch = wahroderfalsch   

        this.saveKonjugationLanguage(selectedmodulteil.konjug_sprache)
       
        //Jetzt posten
        const filename = `${Date.now()}.${this.props.activeUser.anmeldecode}`
        if (this.state.file !==null ) {
            this.fileUpload(this.state.file, filename)          
            selectedmodulteil.frage_videobild_url=`https://schoolflashcards.eu/_upload/${filename}`
            console.log(selectedmodulteil.frage_videobild_url)
        }

        if (this.state.dataUri !== null) {            
            this.uploadImage(filename)            
            selectedmodulteil.frage_videobild_url=`https://schoolflashcards.eu/_upload/${filename}`
            console.log(selectedmodulteil.frage_videobild_url)
            
        }


        if (this.state.insertAndPreview){
            this.setState({previewModulteil:selectedmodulteil, karteikartenModus:'Frage', insertAndPreview:false,},
             function(){
                $('#vorschauKarteikarteDialogButton').click()
            }) 

            return
        }

       

        this.setState({selectedmodulteil, editing:false, canRefresh:true,},
            function() { 
                axios.post('/api/unterrichtsmodulteile', this.state.selectedmodulteil)
                .then(
                    result=>{

                        if (this.state.insertNewAtOne===false){
                            this.setState({unterrichtsmodulteile: result.data}) 
                        }
                        else{
                            this.setState({unterrichtsmodulteile: result.data},
                                function(){
                                    this.addNeuesModul2()
                                })
                        }
                        
                    }
                )
            }
           )      
           
      }

      changeYoutubeVideoID(e){
        let selectedmodulteil= this.state.selectedmodulteil
        selectedmodulteil.youtube_video_id=e.target.value
        this.setState({selectedmodulteil})
    }

    changeURL(e){
        let selectedmodulteil= this.state.selectedmodulteil
        selectedmodulteil.frage_videobild_url=e.target.value
        this.setState({selectedmodulteil, dataUri:null})
    }


    changeAntwortURL(e){
        let selectedmodulteil= this.state.selectedmodulteil
        selectedmodulteil.antwort_videobild_url=e.target.value
        this.setState({selectedmodulteil})
    }

    controlSonderzeichen(value){

        let result = value


        if (value.indexOf("eeee")>-1)  {result =   value.replace("eeee", "€")}

        if (value.indexOf("cccc")>-1)  {result =   value.replace("cccc", "ç")}
        if (value.indexOf("CCCC")>-1)  {result =   value.replace("CCCC", "Ç")}


        if (value.indexOf("nnnn")>-1)  {result =   value.replace("nnnn", "ñ")}
        if (value.indexOf("ssss")>-1)  {result =   value.replace("ssss", "ß")}
        if (value.indexOf("????")>-1)  {result =   value.replace("????", "¿")}
        if (value.indexOf("!!!!")>-1)  {result =   value.replace("!!!!", "¡")}
        if (value.indexOf("<<<<")>-1)  {result =   value.replace("<<<<", "«")}
        if (value.indexOf(">>>>")>-1)  {result =   value.replace(">>>>", "»")}
        if (value.indexOf("''''")>-1)  {result =   value.replace("''''", '"')}
        
        return result

    }

      changeFrage(e){
          let selectedmodulteil= this.state.selectedmodulteil
          const value = this.controlSonderzeichen(e.target.value)
          selectedmodulteil.frage=value
          this.setState({selectedmodulteil})
      }
      changeAntwort(e,num){
          let selectedmodulteil= this.state.selectedmodulteil
          const value = this.controlSonderzeichen(e.target.value)
          if (num===1)selectedmodulteil.antwort1=value
          if (num===2)selectedmodulteil.antwort2=value
          if (num===3)selectedmodulteil.antwort3=value
          if (num===4)selectedmodulteil.antwort4=value    
          if (num===5)selectedmodulteil.antwort5=value         
          this.setState({selectedmodulteil})
      }


      changeAntwortImageUrl(e,num){

          let selectedmodulteil= this.state.selectedmodulteil
          const value = e.target.value
          if (num===1)selectedmodulteil.antwort1_imageurl=value
          if (num===2)selectedmodulteil.antwort2_imageurl=value
          if (num===3)selectedmodulteil.antwort3_imageurl=value
          if (num===4)selectedmodulteil.antwort4_imageurl=value    
          if (num===5)selectedmodulteil.antwort5_imageurl=value         
          this.setState({selectedmodulteil})
      }

      checkAntwortModus(e){
          let antwortModus = 1
          let lastAntwortModus = this.state.lastAntwortModus
          if (e.target.value==="option2")antwortModus = 2
          if (e.target.value==="option3")  antwortModus = 3
          if (e.target.value==="option4")antwortModus = 4
          if (e.target.value==="option5")antwortModus = 5
          lastAntwortModus=antwortModus
          this.setState({antwortModus, lastAntwortModus})
      }

      setWahroderfalsch(e){
          let selectedmodulteil = this.state.selectedmodulteil
          let wahroderfalsch = 0
          if (e.target.checked) wahroderfalsch = 1
          selectedmodulteil.wahroderfalsch =wahroderfalsch
          this.setState({selectedmodulteil})
      }




        modalTranslationDialog() {
            let trs=[]

            this.state.translationHits.forEach(
                function(hit){

                    if (hit.roms !==undefined){
                        hit.roms.forEach(
                            function(rom){
                                if (rom.arabs !==undefined){
                                    rom.arabs.forEach(
                                        function(arab){
                                            if(arab.translations !==undefined){
                                                arab.translations.forEach(
                                                    function(translation){
                                                        trs.push(<tr><td width="50%">{parse(translation.source.replace("class", "className"))}</td><td width="50%">{parse(translation.target)}</td></tr>)  
                                                    }
                                                )
                                            }

                                        }
                                    )
                                }
                               //                   
                            }
                        )                        
                    }
                    else{
                        trs.push(<tr><td width="50%">{parse(hit.source.replace("class", "className"))}</td><td width="50%">{parse(hit.target)}</td></tr>)
                    }


                    
                }
            )


            const body=(
                <div className="table-responsive">
                    <table className="table table-striped">
                        <tbody>
                            {trs}
                        </tbody>
                    </table>
                </div>
            )

            return (
                <Modal2
                    modalid="modalTranslationDialog"
                    buttonid="modalTranslationDialogButton"
                    title="Übersetzung"
                    body={body}
                    okButtonClick={this.ordnerLoeschen}
                    okButtonTitle="Ordner löschen und Stapel behalten"
                    cancelButton={true}
                />
            )
        }


    alertMessage(message) {

        this.setState({ alertMessage: message }, function () { $('#alertMessageDialogButton').click() })

    }

    alertMessageDialog() {

        return (
            <Modal2
                modalid="alertMessageDialog"
                buttonid="alertMessageDialogButton"
                title="Schoolflashcards"
                body={this.state.alertMessage}
                okButtonTitle="OK"
                okButtonenabled={true} />
        )
    }




      sendTranslationRequest(place){

        let sourcetext=this.state.selectedmodulteil.frage
        if (place==="antwort")sourcetext=this.state.selectedmodulteil.antwort1

        const array={
            sourcetext,
            languagekey:this.state.selectedTranslationLanguage.key
        }

        axios.post('/api/ponsrequest', array)
        .then(
            result =>{
          

                if (result.data==="" || result.data==="Not found" || result.data===undefined || result.data===null){
                     this.alertMessage('Keine Übersetzungsergebnisse gefunden!')
                     return
                }            
                    
                if (result.data.length > 0  && result.data[0].hits !==undefined){
                     this.setState({translationHits:result.data[0].hits}, function (){$('#modalTranslationDialogButton').click()})
                    
                }

                if (result.data.indexOf("<!DOCTYPE html>") !==-1){
                    this.alertMessage(parse(result.data))
                }
            }
        )

      }

      saveLanguage(language){

        this.setState({selectedTranslationLanguage:language})
        let benutzereinstellung = this.getBenutzereinstellung("lastTranslationLanguageKey")
        benutzereinstellung.strvalue = language.key
        axios.post('/api/benutzereinstellungen', benutzereinstellung)
      }

      saveKonjugationLanguage(language){

        let benutzereinstellung = this.getBenutzereinstellung("lastKonjugationLanguage")
        benutzereinstellung.strvalue = language
        axios.post('/api/benutzereinstellungen', benutzereinstellung)
      }

      translationLanguagesRow(language){

        return(
            <a className="dropdown-item" href="#" onClick={()=>this.saveLanguage(language)} >{language.label}</a>
        )

      }

      divTranslationButton(place){

          if (place==="frage"){
              if (this.state.selectedmodulteil.frage ==="") return null
          }
          if (place==="antwort"){
              if (this.state.selectedmodulteil.antwort1 ==="") return null
          }

        

        return(
            <>

                <div className="btn-group dropdown">
                    <button className="btn btn-info" onClick={()=>this.sendTranslationRequest(place)}>Übersetzen</button>
                    <button type="button" className="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {this.state.selectedTranslationLanguage.label}  <span className="sr-only">Toggle Dropdown</span>
                    </button>
                    <div className="dropdown-menu">
                        {this.state.translationLanguages.map(this.translationLanguagesRow)}
                    
                    </div>
                </div>
            </>
        )
      }

     /*    onFileUploadChange(e){    
          
        if(e.target.files[0].size > 358400){
            this.alertMessage('Maximale Dateigrösse: 350kB')
            return
         };

        let reader = new FileReader()
        reader.readAsDataURL(e.target.files[0])
        reader.onload = (eg)=>{  
            let selectedmodulteil = this.state.selectedmodulteil;   
            selectedmodulteil.bild = eg.target.result
            selectedmodulteil.frage_videobild_url=''
            this.setState({selectedmodulteil})
        }    
    }*/


    onFileUploadChange(e){

        let selectedmodulteil = this.state.selectedmodulteil;   

            const jahr=new Date().getFullYear()
            if (this.props.activeSchule.lizenz_jahr < jahr){
                this.alertMessage(parse("<div>Keine Schul-Lizenz für das Jahr <b>" + jahr  + "</b> zum Upload von eigenen Dateien vorhanden. Sie können unter 'Verwaltung - Schuldaten bearbeiten' eine Lizenz bestellen. Bitte kontaktieren Sie Ihren Schuladministrator oder schreiben Sie eine Mail an <i>info@schoolflashcards.eu</i><p>Sie können hier alternativ die URL eines Bildes über Copy & Paste einfügen!</p></div>" ))
                return
            }

            var tmppath = URL.createObjectURL(e.target.files[0])
            selectedmodulteil.frage_videobild_url=tmppath        
            this.setState({file:e.target.files[0], selectedmodulteil, dataUri:null})   

          /* if(e.target.files[0] !==undefined && e.target.files[0].size > 358400){
                
                this.alertMessage('Maximale Dateigrösse: 350kB')    
                selectedmodulteil.frage_videobild_url = ''
                this.setState({file:null, selectedmodulteil, dataUri:null})   
            }
            else{

                
                
                var tmppath = URL.createObjectURL(e.target.files[0])
                selectedmodulteil.frage_videobild_url=tmppath        
                this.setState({file:e.target.files[0], selectedmodulteil, dataUri:null})   
                    
            }*/

      }


      fileUpload(file, filename){     
      
        const url = 'https://schoolflashcards.eu/_php/upload.php';
        const form_data = new FormData();

            form_data.append('file', file);
            form_data.append('filename', filename);

            axios.post(url, form_data)
            .then(
                result=>{     
                    let selectedmodulteil = this.state.selectedmodulteil;     
                    selectedmodulteil.frage_videobild_url=result.data
                    this.setState({selectedmodulteil, file:null})  
                    const sql=`UPDATE sfc_unterrichtsmodulteile SET frage_videobild_url='${result.data}' WHERE id=${selectedmodulteil.id}`
                    const array={sql}
                    axios.post('/api/execcommonquery', array)
                }
            )
      }

      fileUploadButton(){     
          
       /* if (this.props.activeUser !== null && this.props.activeUser.ist_schueler===1) {
            if (this.state.uploaderlaubnis===false) return null
        }*/

        let imgCamera = (
            <div><button className="btn btn-default" onClick={()=>this.setState({dataUri: null}, ()=> $('#modalcameraDialogButton').click())}><i className="material-icons">photo_camera</i></button></div>
        )
        const os = getOS()
        if (os === "iOS" || os==='Android' || os==='BlackBerry' ){
            imgCamera = null
          /*  imgCamera=(
                <div>
                 <input  id="opencamerabutton"  type="file"  onChange={this.onFileUploadChange}  style={{visibility:'hidden'}}  accept="image/*" capture /> 
                 <button className="btn btn-default" onClick={()=>$('#opencamerabutton').click()}><i className="material-icons">photo_camera</i></button>
                </div>
            )*/
        }

        return(
            <div style={{display:'flex'}}>
                <div>
                    <label className="btn btn-info"  htmlFor="selectFileButton">Datei wählen</label>
                    <input type="file" accept="image/*"  id="selectFileButton" style={{visibility:'hidden'}} onChange={this.onFileUploadChange}/>
                                      
                </div>
                {imgCamera}
              
            </div>
        )      

      }

      changeVideoStartZeit(e){
        let selectedmodulteil= this.state.selectedmodulteil;
        selectedmodulteil.youtube_video_startzeit = e.target.value
        this.setState({selectedmodulteil})
      }

      changeVideoLaufZeit(e){
        let selectedmodulteil= this.state.selectedmodulteil;
        selectedmodulteil.youtube_video_laufzeit = e.target.value
        this.setState({selectedmodulteil})
      }
      

      changeNurAudioAbspielen(e){
        let selectedmodulteil= this.state.selectedmodulteil;
        let value = 0;
        if (e.target.checked) value = 1
        selectedmodulteil.nur_audio_abspielen = value
       this.setState({selectedmodulteil})
      }

      changeIstYoutubeVideo(e){
        let selectedmodulteil= this.state.selectedmodulteil;
        let value = 0;
        if (e.target.checked) value = 1
        selectedmodulteil.ist_youtube_video = value
       this.setState({selectedmodulteil})
      }

      setAnzahlAntworten(anzahl){
          let selectedmodulteil= this.state.selectedmodulteil;
          selectedmodulteil.anzahl_antworten = anzahl
          this.setState({selectedmodulteil,antwortModus:3,lastAntwortModus:3})
      }

      changeRichtigeAntwortNummer(e, num){
        let selectedmodulteil= this.state.selectedmodulteil;
        let value = 0;
        if (e.target.checked) value = 1
        if (num===1) selectedmodulteil.antwort1_richtig = value
        if (num===2) selectedmodulteil.antwort2_richtig = value
        if (num===3) selectedmodulteil.antwort3_richtig = value
        if (num===4) selectedmodulteil.antwort4_richtig = value
        if (num===5) selectedmodulteil.antwort5_richtig = value
        this.setState({selectedmodulteil})

      }

      changeKonjugVerb(e){
        let selectedmodulteil = this.state.selectedmodulteil
        selectedmodulteil.konjug_verb = e.target.value
        this.setState({selectedmodulteil})

    }

      changeKonjugSprache(sprache){
          let selectedmodulteil = this.state.selectedmodulteil
          selectedmodulteil.konjug_sprache = sprache
          selectedmodulteil.konjug_zeitform=""
          this.setState({selectedmodulteil})
          this.saveKonjugationLanguage(sprache)

      }

      changeKonjugZeitform(zeitform){

        let selectedmodulteil = this.state.selectedmodulteil
        selectedmodulteil.konjug_zeitform = zeitform
        this.setState({selectedmodulteil})
      }

      changeKonjug(e, pron){

        let selectedmodulteil = this.state.selectedmodulteil
        if (pron==="ich") selectedmodulteil.konjug_ich = e.target.value
        if (pron==="du") selectedmodulteil.konjug_du = e.target.value
        if (pron==="ersiees") selectedmodulteil.konjug_ersiees = e.target.value
        if (pron==="wir") selectedmodulteil.konjug_wir = e.target.value
        if (pron==="ihr") selectedmodulteil.konjug_ihr = e.target.value
        if (pron==="sie") selectedmodulteil.konjug_sie = e.target.value
        this.setState({selectedmodulteil})

      }

   

      getKarteikartenContent(){

        let zusaetzlicheAntwortenEdits = []
        let textAntwort1 = 'Antwort'
        let checkboxesWahrOderFalsch = null

     

        let eingabehilfeButton = <div style={{textAlign:'right'}}><a href="#" className="text-primary" onClick={()=>$('#modalEingabeHilfeDialogButton').click()}>Eingabe von Sonderzeichen...</a></div> 


      
        if (this.state.antwortModus===3){

               for (let i=2;i<=this.state.selectedmodulteil.anzahl_antworten;i++){
                   let id = `inputantwort${i}`
                   let placeholder = `Antwort ${i}`
                   let value = this.state.selectedmodulteil.antwort2
                   if (i===3) value = this.state.selectedmodulteil.antwort3
                   if (i===4) value = this.state.selectedmodulteil.antwort4
                   if (i===5) value = this.state.selectedmodulteil.antwort5

                   let imageurl = this.state.selectedmodulteil.antwort1_imageurl
                   if (i===2) imageurl = this.state.selectedmodulteil.antwort2_imageurl
                   if (i===3) imageurl = this.state.selectedmodulteil.antwort3_imageurl
                   if (i===4) imageurl = this.state.selectedmodulteil.antwort4_imageurl
                   if (i===5) imageurl = this.state.selectedmodulteil.antwort5_imageurl

                   let image=null
                   if (imageurl !==null){
                    image=<img src={imageurl} height ="50" />
                   }


                   let checked = this.state.selectedmodulteil.antwort2_richtig===1
                   if (i===3){
                    checked = this.state.selectedmodulteil.antwort3_richtig===1
                   }
                   if (i===4){
                    checked = this.state.selectedmodulteil.antwort4_richtig===1
                   }
                   if (i===5){
                    checked = this.state.selectedmodulteil.antwort5_richtig===1
                   }
                  
                    zusaetzlicheAntwortenEdits.push(    
                        <> 
                            <hr/>
                            <div className="form-group">
                                <label htmlFor="inputantwort2">Antwort {i} </label>
                                <textarea  autoCapitalize="off" onChange={(e)=>this.changeAntwort(e,i)} className="form-control" id={id} placeholder={placeholder} rows="4" value={value} />
                            </div>
                            <div className="togglebutton">
                                <label>
                                    <input type="checkbox" onChange={(e)=>this.changeRichtigeAntwortNummer(e,i)} checked={checked} />
                                    <span className="toggle"></span>
                                    Antwort {i} ist richtig
                                </label>
                            </div>                    
                            {image}
                            <input type="text" autoCapitalize="off"  className="form-control" onChange={(e)=>this.changeAntwortImageUrl(e,i)} value={imageurl} placeholder="Bildadresse"/>
                        </>                    
                       
                    )
               }

            textAntwort1 = 'Antwort 1'

        }

        let checkAntwort1 = null
        let translationButton = this.divTranslationButton("antwort")
        if (this.state.antwortModus===3){
            checkAntwort1=(

                <div className="togglebutton">
                <label>
                    <input type="checkbox" onChange={(e)=>this.changeRichtigeAntwortNummer(e,1)} checked={this.state.selectedmodulteil.antwort1_richtig===1} />
                    <span className="toggle"></span>
                    Antwort 1 ist richtig
                </label>
            </div>
            )
            translationButton = null
                
            
        }

        let antwort1image = null
        if (this.state.selectedmodulteil.antwort1_imageurl !== ""){

            antwort1image = <img src={this.state.selectedmodulteil.antwort1_imageurl } height="60" />

        }


        let divAntwort1 = (
            <>                
                <div className="form-group">
                    <label htmlFor="inputantwort1">{textAntwort1}</label>
                    <textarea  autoCapitalize="off" onChange={(e)=>this.changeAntwort(e,1)} className="form-control" id="inputantwort1" rows="3" placeholder={textAntwort1} value={this.state.selectedmodulteil.antwort1} />
                    {checkAntwort1}
                    {translationButton}
                </div>
                {antwort1image}
                <input type="text" autoCapitalize="off"  className="form-control" onChange={(e)=>this.changeAntwortImageUrl(e,1)} value={this.state.selectedmodulteil.antwort1_imageurl} placeholder="Bildadresse"/>
            </>
            )
    

        
        if (this.state.antwortModus===4){
            divAntwort1 = null
            checkboxesWahrOderFalsch =(
               
                    <div className="togglebutton">
                        <label>
                            <input type="checkbox" onChange={this.setWahroderfalsch} checked={this.state.selectedmodulteil.wahroderfalsch===1}/>
                            <span className="toggle"></span>Die Behauptung ist wahr.
                        </label>
                    </div>
                
            )
        }

        const dropdownAntwortAnzahl =(
            <>
                 <button className="btn btn-secondary dropdown-toggle" style={{marginTop:'0px'}} type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {this.state.selectedmodulteil.anzahl_antworten}
                </button> 
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a className="dropdown-item" href="#" onClick={()=>this.setAnzahlAntworten(2)}>2 Antworten</a>
                    <a className="dropdown-item" href="#" onClick={()=>this.setAnzahlAntworten(3)}>3 Antworten</a>
                    <a className="dropdown-item" href="#" onClick={()=>this.setAnzahlAntworten(4)}>4 Antworten</a>
                    <a className="dropdown-item" href="#" onClick={()=>this.setAnzahlAntworten(5)}>5 Antworten</a>
                </div>
            </>
        )

       let imagesource = this.state.selectedmodulteil.frage_videobild_url

       if (this.state.dataUri !== null) imagesource = this.state.dataUri 

       let divYoutubeDetails=null
       if (this.state.selectedmodulteil.ist_youtube_video===1){
            divYoutubeDetails=(
                <div className="table" style={{fontSize:'14px'}}>
                    <table className="table">
                        <tbody>
                        <tr>
                            <td width="200"><input type="text" style={{marginLeft:'15px'}} autoCapitalize="off" onChange={this.changeYoutubeVideoID} placeholder="Video-ID" className="form-control" value={this.state.selectedmodulteil.youtube_video_id} /> 
                            </td>                       
                            <td  style={{fontSize:'12px'}}>
                                <div className="togglebutton">
                                    <label><input type="checkbox" onChange={(e)=>this.changeNurAudioAbspielen(e)}  checked={this.state.selectedmodulteil.nur_audio_abspielen===1} /><span className="toggle" ></span>Nur Audio abspielen</label>                      
                                </div>  
                            </td>
                            <td width="100" className="text-center" style={{fontSize:'12px'}}>Start-Zeit in Sek.</td>
                            <td>  <input className="form-control" value={this.state.selectedmodulteil.youtube_video_startzeit} type="number" min={0} onChange={(e)=>this.changeVideoStartZeit(e)}     /></td>
                            <td width="100" className="text-center" style={{fontSize:'12px'}}>Laufzeit in Sek.</td>
                            <td>  <input className="form-control" value={this.state.selectedmodulteil.youtube_video_laufzeit} type="number" min={0} onChange={(e)=>this.changeVideoLaufZeit(e)}     /> <small>(0 = ganzes Video)</small></td>
                        </tr></tbody>
                    </table>
                </div>

            )
       }

       let inputURLFrageRow = (
                        <div className="form-group">
                            <label htmlFor="inputfrage2">URL zu Foto (z.b. https://....jpg) </label>
                            <input type="text"  autoCapitalize="off" onChange={this.changeURL} className="form-control" id="inputfrage2"  value={this.state.selectedmodulteil.frage_videobild_url} />                             
                            <div className="fileinput-new thumbnail img-raised">
                                <img width="200" className="img-fluid" alt="" src={imagesource}/>                                
                            </div> 
                        
                        </div>
                      

       )   
       if (this.state.antwortModus===5) inputURLFrageRow = null

      

       let frageRow=(
                    <div>
                        <h3 className="card-title" >Frage (Vorderseite der Karte)</h3>
                        <div className="form-group">
                            <label htmlFor="inputfrage">Frage oder Behauptung eingeben (HTML-Code oder z.B. YouTube-Embedded-Code möglich)</label>
                            <textarea  autoCapitalize="off"  onChange={this.changeFrage} className="form-control" id="inputfrage" rows="5" value={this.state.selectedmodulteil.frage}></textarea>
                        </div>
                        {this.fileUploadButton()} 
                        {inputURLFrageRow}
                        {this.divTranslationButton("frage")}

                            <hr/>
                                <div style={{marginTop:'10px'}}>
                                    <div className="form-group">
                                        <div className="togglebutton">
                                        <label><input type="checkbox" className="form-control" onChange={(e)=>this.changeIstYoutubeVideo(e)}  checked={this.state.selectedmodulteil.ist_youtube_video===1} /><span className="toggle" ></span>YouTube-Video einbinden</label>
                                    </div>                                                                               
                                </div> 
                                {divYoutubeDetails}
                            <hr/>
                        </div>


                    </div>
       )

       let antwortImageURLRow = (
            <>
                <hr/>
                <div className="form-group">
                    <label htmlFor="inputantwort2">URL zu Foto (z.b. https://....jpg) </label>
                    <input type="text"  autoCapitalize="off" onChange={this.changeAntwortURL} className="form-control" id="inputantwort2"  value={this.state.selectedmodulteil.antwort_videobild_url} />                             
                    <div className="fileinput-new thumbnail img-raised">
                        <img width="200" className="img-fluid" alt="" src={this.state.selectedmodulteil.antwort_videobild_url}/>                                
                    </div> 
                </div>
            </>
       )

       if (this.state.antwortModus===5) antwortImageURLRow = null
      
       let titledrowpdown = "Frage- und Antwortkarte"

       let radioButtons=(
            <div>
                <h3 className="card-title">Antwort (Rückseite der Karte)</h3>    
                
                    <div className="form-check form-check-radio">
                        <label className="form-check-label">
                            <input onChange={this.checkAntwortModus} className="form-check-input" type="radio" name="exampleRadios1" id="exampleRadios1" value="option1" checked={this.state.antwortModus===1}/>
                            Die Antwort muss mündlich formuliert und eigenverantwortlich überprüft werden
                            <span className="circle">
                                <span className="check"></span>
                            </span>
                        </label>
                    </div>           

                    <div className="form-check form-check-radio">
                        <label className="form-check-label">
                            <input onChange={this.checkAntwortModus} className="form-check-input" type="radio" name="exampleRadios2" id="exampleRadios2" value="option2" checked={this.state.antwortModus===2}/>
                            Die Antwort muss schriftlich formuliert werden und wird vom Programm überprüft
                            <span className="circle">
                                <span className="check"></span>
                            </span>
                        </label>
                    </div>

                    <div className="form-check form-check-radio">
                        <label className="form-check-label">
                            <input onChange={this.checkAntwortModus} className="form-check-input" type="radio" name="exampleRadios3" id="exampleRadios3" value="option3"  checked={this.state.antwortModus===3} />
                            <div style={{marginTop:'-14px'}}>Es kann aus {dropdownAntwortAnzahl} Antworten eine oder mehrere richtige Antwort/en gewählt werden</div>
                            <span className="circle">
                                <span className="check"></span>
                            </span>
                        </label>
                    </div>


                    <div className="form-check form-check-radio">
                        <label className="form-check-label">
                            <input onChange={this.checkAntwortModus} className="form-check-input" type="radio" name="exampleRadios4" id="exampleRadios4" value="option4"  checked={this.state.antwortModus===4} />
                            Die Frage wird als eine Behauptung formuliert, und es muss ausgewählt werden, ob die Behauptung richtig oder falsch ist
                            <span className="circle">
                                <span className="check"></span>
                            </span>
                        </label>
                    </div>
            </div>
       )

       let konjugationsTable = null
       if (this.state.antwortModus===5){  
            eingabehilfeButton = null                    

            let sprache=getSpracheLang(this.getBenutzereinstellung("lastKonjugationLanguage").strvalue)

            let dropdownSprachen=(
                <div className="dropdown">
                    <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {sprache}
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a className="dropdown-item" href="#" onClick={()=>this.changeKonjugSprache("de")}>Deutsch</a>
                        <a className="dropdown-item" href="#" onClick={()=>this.changeKonjugSprache("en")}>Englisch</a>
                        <a className="dropdown-item" href="#" onClick={()=>this.changeKonjugSprache("fr")}>Französisch</a>
                        <a className="dropdown-item" href="#" onClick={()=>this.changeKonjugSprache("es")}>Spanisch</a>
                        <a className="dropdown-item" href="#" onClick={()=>this.changeKonjugSprache("it")}>Italienisch</a>
                        <a className="dropdown-item" href="#" onClick={()=>this.changeKonjugSprache("pt")}>Portugiesisch</a>
                    </div>
                </div>
            )

            let titleZeitformen ="(Zeitform nicht gewählt)"
            if (this.state.selectedmodulteil.konjug_zeitform !==null && this.state.selectedmodulteil.konjug_zeitform !==""){
                titleZeitformen = this.state.selectedmodulteil.konjug_zeitform 
            }

            let zeitformMapping = this.state.konjug_zeitformen_de.map(this.zeitformRow)
            if (this.state.selectedmodulteil.konjug_sprache==="en") zeitformMapping= this.state.konjug_zeitformen_en.map(this.zeitformRow)
            if (this.state.selectedmodulteil.konjug_sprache==="fr") zeitformMapping= this.state.konjug_zeitformen_fr.map(this.zeitformRow)
            if (this.state.selectedmodulteil.konjug_sprache==="es") zeitformMapping= this.state.konjug_zeitformen_es.map(this.zeitformRow)
            if (this.state.selectedmodulteil.konjug_sprache==="it") zeitformMapping= this.state.konjug_zeitformen_it.map(this.zeitformRow)
            if (this.state.selectedmodulteil.konjug_sprache==="pt") zeitformMapping= this.state.konjug_zeitformen_pt.map(this.zeitformRow)


            let dropdownZeitformen=(
                <div className="dropdown">
                    <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {titleZeitformen}
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                        {zeitformMapping}
                    </div>
                </div>
               
            )

            
            let ich_form=getPronom('ich', this.state.selectedmodulteil.konjug_sprache)
            let du_form=getPronom('du', this.state.selectedmodulteil.konjug_sprache)
            let ersiees_form=getPronom('ersiees', this.state.selectedmodulteil.konjug_sprache)
            let wir_form=getPronom('wir', this.state.selectedmodulteil.konjug_sprache)
            let ihr_form=getPronom('ihr', this.state.selectedmodulteil.konjug_sprache)
            let sie_form=getPronom('sie', this.state.selectedmodulteil.konjug_sprache)

            konjugationsTable = (
                <>
                   <div style={{display:'flex'}}>{dropdownSprachen} {dropdownZeitformen} </div>
                   <div className="form-group">
                        <input type="email" className="form-control" id="exampleInputVerb" aria-describedby="emailHelp" placeholder="Verb" value={this.state.selectedmodulteil.konjug_verb} onChange={this.changeKonjugVerb} />
                        <small id="emailHelp" className="form-text text-muted">Zu konjugierendes Verb</small>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <tbody>
                                <tr>
                                    <td style={{backgroundColor:'#e7e7e7'}}>{ich_form}</td><td></td><td><input type="text" autoCapitalize="off" className="form-control" value={this.state.selectedmodulteil.konjug_ich} onChange={(e)=>this.changeKonjug(e,"ich")} /> </td>
                                </tr>
                                <tr>
                                    <td style={{backgroundColor:'#e7e7e7'}}>{du_form}</td><td></td><td><input type="text" autoCapitalize="off" className="form-control" value={this.state.selectedmodulteil.konjug_du} onChange={(e)=>this.changeKonjug(e,"du")} /></td>
                                </tr>
                                <tr>
                                    <td style={{backgroundColor:'#e7e7e7'}}>{ersiees_form}</td><td></td><td><input type="text" autoCapitalize="off" className="form-control" value={this.state.selectedmodulteil.konjug_ersiees} onChange={(e)=>this.changeKonjug(e,"ersiees")} /></td>
                                </tr>
                                <tr>
                                    <td style={{backgroundColor:'#e7e7e7'}}>{wir_form}</td><td></td><td><input type="text" autoCapitalize="off" className="form-control" value={this.state.selectedmodulteil.konjug_wir} onChange={(e)=>this.changeKonjug(e,"wir")} /></td>
                                </tr>
                                <tr>
                                    <td style={{backgroundColor:'#e7e7e7'}}>{ihr_form}</td><td></td><td><input type="text" autoCapitalize="off" className="form-control" value={this.state.selectedmodulteil.konjug_ihr} onChange={(e)=>this.changeKonjug(e,"ihr")} /></td>
                                </tr>
                                <tr>
                                    <td style={{backgroundColor:'#e7e7e7'}}>{sie_form}</td><td></td><td><input type="text" autoCapitalize="off" className="form-control" value={this.state.selectedmodulteil.konjug_sie} onChange={(e)=>this.changeKonjug(e, "sie")} /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </>
            )

            frageRow = null
            divAntwort1 = null     
            titledrowpdown = "Vokabelkarte - Konjugation"
            radioButtons = null


       }

       //Vokabel + Audio
       if (this.state.antwortModus===6){
            titledrowpdown = "Vokabelkarte - Hören und schreiben"     
            eingabehilfeButton = null         
            divAntwort1 = null  
            radioButtons = null
            checkboxesWahrOderFalsch = null
            konjugationsTable = null
            zusaetzlicheAntwortenEdits = null
            antwortImageURLRow= null
            const titelMp3SuchSprache = this.state.mp3SuchSprache

            const dropDownMp3SuchSprache=(

                <div className="dropdown">
                    <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton6" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {titelMp3SuchSprache}
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton6">
                        <a className="dropdown-item" href="#" onClick={()=>this.setState({mp3SuchSprache:"Englisch"})}>Englisch</a>
                        <a className="dropdown-item" href="#" onClick={()=>this.setState({mp3SuchSprache:"Französisch"})}>Französisch</a>
                        <a className="dropdown-item" href="#" onClick={()=>this.setState({mp3SuchSprache:"Spanisch"})}>Spanisch</a>
                        <a className="dropdown-item" href="#" onClick={()=>this.setState({mp3SuchSprache:"Portugiesisch"})}>Portugiesisch</a>
                        <a className="dropdown-item" href="#" onClick={()=>this.setState({mp3SuchSprache:"Deutsch"})}>Deutsch</a>
                    </div>
                </div>

            )

            let link = <a href="https://howtopronounce.com" target="_blank">howtopronounce.com</a>

            let iframe = null
            if (this.state.selectedmodulteil.frage_videobild_url !=="" && this.state.selectedmodulteil.frage_videobild_url !=="keine mp3 auf howtopronounce.com gefunden"){
                iframe = <iframe src={this.state.selectedmodulteil.frage_videobild_url}></iframe>
            }

            let sucheButton =  <button className="btn btn-primary btn-sm" onClick={this.suchemp3}>mp3 finden</button>
            if (this.state.selectedmodulteil.antwort1 ==="")sucheButton =  <button className="btn btn-primary btn-sm" disabled>mp3 finden</button>

            frageRow = (
                <>          
                <div className="form-group">
                    <label htmlFor="inputantwort1">Vokabel der Audio-mp3 eingeben</label>
                    <input type="text"  autoCapitalize="off" onChange={(e)=>this.changeAntwort(e,1)} className="form-control" id="inputantwort1" placeholder="Vokabel" value={this.state.selectedmodulteil.antwort1} />
                    <div style={{display:'flex'}}>{dropDownMp3SuchSprache} {sucheButton}</div>
                </div>
                 {iframe}
                    <div className="form-group">
                        <label htmlFor="inputfrage2">URL zu Audio-mp3 auf {link} eingeben) </label>
                        <input type="text"  autoCapitalize="off" onChange={this.changeURL} className="form-control" id="inputfrage2"  value={this.state.selectedmodulteil.frage_videobild_url} />     
                    </div>
                </>

            )
       }

       //Skizze beschriften
       if (this.state.antwortModus===7){
            titledrowpdown = "Bild/Skizze beschriften"      
            eingabehilfeButton = null                    
            divAntwort1 = null  
            radioButtons = null
            checkboxesWahrOderFalsch = null
            konjugationsTable = null
            zusaetzlicheAntwortenEdits = null
            antwortImageURLRow= null
       }

       let dropdownFrage=(
           <>
                <div className="dropdown">
                    <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton3" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {titledrowpdown}
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                        <a className="dropdown-item" href="#" onClick={()=>this.setState({antwortModus:this.state.lastAntwortModus})}>Frage- und Antwortkarte</a>
                        <a className="dropdown-item" href="#" onClick={()=>this.setState({antwortModus:5})}>Vokabelkarte - Konjugation</a>
                        <a className="dropdown-item" href="#" onClick={()=>this.setState({antwortModus:6})}>Vokabelkarte - Hören und schreiben</a>
                        <a className="dropdown-item" href="#" onClick={()=>this.setState({antwortModus:7})}>Bild/Karte beschriften</a>
                    </div>
                </div>
                <hr/>
            </>
       )

       if (this.state.antwortModus===7){
            return(
                <> 
                    
                     {dropdownFrage}  
                   <h3>Bild/Skizze beschriften</h3>
                    {this.getSkizzenBeschriftungsTabelle()}   
                        
                    {this.fileUploadButton()} 
    
                    <div className="form-group">
                        <label htmlFor="inputfrage2">URL zu Foto (z.b. https://....jpg) </label>
                        <input type="text"  autoCapitalize="off" onChange={this.changeURL} className="form-control" id="inputfrage2"  value={this.state.selectedmodulteil.frage_videobild_url} />   
                    </div>  

                    <div className="form-group">
                        <div className="togglebutton">
                            <label><input type="checkbox" className="form-control" onChange={(e)=>this.changeNurNummernEingeben(e)}  checked={this.state.selectedmodulteil.beschriftung_antwort_nummern===1} /><span className="toggle" ></span>Bei der Antwort nur die Nummern eingeben</label>
                        </div>          
                    </div>  
                    <div className="form-group">
                            <label htmlFor="inputfrage">Frage oder Aufgabe eingeben (HTML-Code möglich)</label>
                            <textarea  autoCapitalize="off"  onChange={this.changeFrage} className="form-control" id="inputfrage" rows="2" value={this.state.selectedmodulteil.frage}></textarea>
                        </div>              
                    <hr/> 

                </>

            )
       }
     


        return(
            <>
                {eingabehilfeButton}
                {dropdownFrage}
                {frageRow}
                {radioButtons}                       
                {divAntwort1}
                {checkboxesWahrOderFalsch}
                {konjugationsTable}
                {zusaetzlicheAntwortenEdits}
                {antwortImageURLRow}
                <hr/>  
            </>
        )
      }

      ondragover(e){
        
        e.preventDefault()
      }

      ondrop(e){
        
      }




    getSkizzenBeschriftungsTabelle(){

        const _selectedmodulteil = this.state.selectedmodulteil

        function getBeschriftung(index){
            if (index ===0) return _selectedmodulteil.beschriftung_1
            if (index ===1) return _selectedmodulteil.beschriftung_2
            if (index ===2) return _selectedmodulteil.beschriftung_3
            if (index ===3) return _selectedmodulteil.beschriftung_4
            if (index ===4) return _selectedmodulteil.beschriftung_5
            if (index ===5) return _selectedmodulteil.beschriftung_6
            if (index ===6) return _selectedmodulteil.beschriftung_7
            if (index ===7) return _selectedmodulteil.beschriftung_8
            if (index ===8) return _selectedmodulteil.beschriftung_9
            if (index ===9) return _selectedmodulteil.beschriftung_10    
        }
        function getPfeilrichtung(index){
            if (index ===0) return _selectedmodulteil.beschriftung_1_pfeil
            if (index ===1) return _selectedmodulteil.beschriftung_2_pfeil
            if (index ===2) return _selectedmodulteil.beschriftung_3_pfeil
            if (index ===3) return _selectedmodulteil.beschriftung_4_pfeil
            if (index ===4) return _selectedmodulteil.beschriftung_5_pfeil
            if (index ==5) return _selectedmodulteil.beschriftung_6_pfeil
            if (index ===6) return _selectedmodulteil.beschriftung_7_pfeil
            if (index ===7) return _selectedmodulteil.beschriftung_8_pfeil
            if (index ===8) return _selectedmodulteil.beschriftung_9_pfeil
            if (index ===9) return _selectedmodulteil.beschriftung_10_pfeil  

            return 1
        }
        function getCanShow(index){
            if (index===0)return _selectedmodulteil.beschriftung_1 !==""
            if (index===1)return _selectedmodulteil.beschriftung_2 !==""
            if (index===2)return _selectedmodulteil.beschriftung_3 !==""
            if (index===3)return _selectedmodulteil.beschriftung_4 !==""
            if (index===4)return _selectedmodulteil.beschriftung_5 !==""
            if (index===5)return _selectedmodulteil.beschriftung_6 !==""
            if (index===6)return _selectedmodulteil.beschriftung_7 !==""
            if (index===7)return _selectedmodulteil.beschriftung_8 !==""
            if (index===8)return _selectedmodulteil.beschriftung_9 !==""
            if (index===9)return _selectedmodulteil.beschriftung_10 !==""
        }   
        
        function getBeschriftungPositon(index){
      
            let x = 0
            let y = 0

            if (index===0){x = _selectedmodulteil.beschriftung_1_x; y = _selectedmodulteil.beschriftung_1_y}
            if (index===1){x = _selectedmodulteil.beschriftung_2_x; y = _selectedmodulteil.beschriftung_2_y}
            if (index===2){x = _selectedmodulteil.beschriftung_3_x; y = _selectedmodulteil.beschriftung_3_y}
            if (index===3){x = _selectedmodulteil.beschriftung_4_x; y = _selectedmodulteil.beschriftung_4_y}
            if (index===4){x = _selectedmodulteil.beschriftung_5_x; y = _selectedmodulteil.beschriftung_5_y}
            if (index===5){x = _selectedmodulteil.beschriftung_6_x; y = _selectedmodulteil.beschriftung_6_y}
            if (index===6){x = _selectedmodulteil.beschriftung_7_x; y = _selectedmodulteil.beschriftung_7_y}
            if (index===7){x = _selectedmodulteil.beschriftung_8_x; y = _selectedmodulteil.beschriftung_8_y}
            if (index===8){x = _selectedmodulteil.beschriftung_9_x; y = _selectedmodulteil.beschriftung_9_y}
            if (index===9){x = _selectedmodulteil.beschriftung_10_x; y = _selectedmodulteil.beschriftung_10_y}

            return {
                x,
                y
            }
        }     

        let trBeschriftungsInputs = []
        let beschriftungen = []        
        for (var i=0;i<10;i++){    

            const key=`beschriftung${i}`

            beschriftungen.push(
                   <Beschriftung 
                        key={key}
                        pfeil={getPfeilrichtung(i)}
                        nummer={i}  
                        left={getBeschriftungPositon(i).x}
                        top={getBeschriftungPositon(i).y }
                        onStopDrag={this.onStopDrag} 
                        canShow={getCanShow(i)} 
                    /> 
            )

            const index = i

            const imageSource = `/assets/img/beschriftung_${i+1}_${getPfeilrichtung(i)}.png`       
            let button = (             
                 <a href="#"  onClick={()=>this.changePfeilRichtung(index, getPfeilrichtung(index))}><img width="52" src={imageSource} draggable={false} /></a>
              
            )
            
            if (getBeschriftung(i)===""){
                button = null
            }

           

            const placeholder = `Beschriftung ${i+1}`
            const name=`edit${i}`
            trBeschriftungsInputs.push(
                <tr key={`trbeschriftung${i}`}>
                    <td width={15}>{i+1}=</td>
                    <td><input className="form-control" name={name} type="text" placeholder={placeholder} onChange={this.setBeschriftung} value={getBeschriftung(i)} /></td>
                    <td style={{backgroundColor:'#eeeeee'}}  width={40}>{button}</td>
                </tr>
            )
        }    
        let beschriftungenTable=(
            <table className="table" >
                <tbody>                        
                        {trBeschriftungsInputs}
                </tbody>
            </table>
                
        )

        let iSource = this.state.selectedmodulteil.frage_videobild_url
        if (this.state.dataUri !== null)iSource = this.state.dataUri

        let image=(
            <img draggable={false} src={iSource} width={700} />
        )
     
     
        if (this.state.selectedmodulteil.frage_videobild_url===""){
            beschriftungenTable = null
            image = null
            beschriftungen = null
        }    

           
       

        return(
            <>
            <div  style={{position:'relative', width:'700px',}}>
                {beschriftungen}
                {image}
            </div>  
                {beschriftungenTable}
             </>   
        )

    }


    

    onStopDrag(nummer,position){

        let selectedmodulteil = this.state.selectedmodulteil               
        if (nummer===0) {
            selectedmodulteil.beschriftung_1_x = position.x;
            selectedmodulteil.beschriftung_1_y = position.y;
        }
        if (nummer===1) {
            selectedmodulteil.beschriftung_2_x = position.x;
            selectedmodulteil.beschriftung_2_y = position.y;
        }
        if (nummer===2) {
            selectedmodulteil.beschriftung_3_x = position.x;
            selectedmodulteil.beschriftung_3_y = position.y;
        }
        if (nummer===3) {
            selectedmodulteil.beschriftung_4_x = position.x;
            selectedmodulteil.beschriftung_4_y = position.y;
        }
        if (nummer===4) {
            selectedmodulteil.beschriftung_5_x = position.x;
            selectedmodulteil.beschriftung_5_y = position.y;
        }
        if (nummer===5) {
            selectedmodulteil.beschriftung_6_x = position.x;
            selectedmodulteil.beschriftung_6_y = position.y;
        }
        if (nummer===6) {
            selectedmodulteil.beschriftung_7_x = position.x;
            selectedmodulteil.beschriftung_7_y = position.y;
        }
        if (nummer===7) {
            selectedmodulteil.beschriftung_8_x = position.x;
            selectedmodulteil.beschriftung_8_y = position.y;
        }
        if (nummer===8) {
            selectedmodulteil.beschriftung_9_x = position.x;
            selectedmodulteil.beschriftung_9_y = position.y;
        }
        if (nummer===9) {
            selectedmodulteil.beschriftung_10_x = position.x;
            selectedmodulteil.beschriftung_10_y = position.y;
        }
      

     this.setState({selectedmodulteil})

           
    }

    changePfeilRichtung(nummer,pfeil){


        let neuerpfeil = pfeil + 1
        if (pfeil ===9) neuerpfeil=1
        let selectedmodulteil = this.state.selectedmodulteil      
        if (nummer ==0) selectedmodulteil.beschriftung_1_pfeil=neuerpfeil;  
        if (nummer ==1) selectedmodulteil.beschriftung_2_pfeil=neuerpfeil;  
        if (nummer ==2) selectedmodulteil.beschriftung_3_pfeil=neuerpfeil;    
        if (nummer ==3) selectedmodulteil.beschriftung_4_pfeil=neuerpfeil;   
        if (nummer ==4) selectedmodulteil.beschriftung_5_pfeil=neuerpfeil;  
        if (nummer ==5) selectedmodulteil.beschriftung_6_pfeil=neuerpfeil;  
        if (nummer ==6) selectedmodulteil.beschriftung_7_pfeil=neuerpfeil;  
        if (nummer ==7) selectedmodulteil.beschriftung_8_pfeil=neuerpfeil;  
        if (nummer ==8) selectedmodulteil.beschriftung_9_pfeil=neuerpfeil;  
        if (nummer ==9) selectedmodulteil.beschriftung_10_pfeil =neuerpfeil;    
        
        
      //  axios.post('/api/unterrichtsmodulteile',selectedmodulteil )
        this.setState({selectedmodulteil})


     }

     


    setBeschriftung(e){
        
        let nummer 
        for (let i=0;i<10;i++){
                if (e.target.name===`edit${i}`){
                    nummer = i
                }
        }

        let selectedmodulteil = this.state.selectedmodulteil       
        if (nummer ==0) selectedmodulteil.beschriftung_1=e.target.value;  
        if (nummer ==1) selectedmodulteil.beschriftung_2=e.target.value;  
        if (nummer ==2) selectedmodulteil.beschriftung_3=e.target.value;  
        if (nummer ==3) selectedmodulteil.beschriftung_4=e.target.value;  
        if (nummer ==4) selectedmodulteil.beschriftung_5=e.target.value;  
        if (nummer ==5) selectedmodulteil.beschriftung_6=e.target.value;  
        if (nummer ==6) selectedmodulteil.beschriftung_7=e.target.value;  
        if (nummer ==7) selectedmodulteil.beschriftung_8=e.target.value;  
        if (nummer ==8) selectedmodulteil.beschriftung_9=e.target.value;  
        if (nummer ==9) selectedmodulteil.beschriftung_10 =e.target.value;       

        if (e.target.value===""){           
            if (nummer ==0) {selectedmodulteil.beschriftung_1_x= 0; selectedmodulteil.beschriftung_1_y = 0}
            if (nummer ==1) {selectedmodulteil.beschriftung_2_x= 60; selectedmodulteil.beschriftung_2_y = 0}
            if (nummer ==2) {selectedmodulteil.beschriftung_3_x= 120; selectedmodulteil.beschriftung_3_y = 0}
            if (nummer ==3) {selectedmodulteil.beschriftung_4_x= 160; selectedmodulteil.beschriftung_4_y = 0}
            if (nummer ==4) {selectedmodulteil.beschriftung_5_x= 200; selectedmodulteil.beschriftung_5_y = 0}
            if (nummer ==5) {selectedmodulteil.beschriftung_6_x= 240; selectedmodulteil.beschriftung_6_y = 0}
            if (nummer ==6) {selectedmodulteil.beschriftung_7_x= 280; selectedmodulteil.beschriftung_7_y = 0}
            if (nummer ==7) {selectedmodulteil.beschriftung_8_x= 320; selectedmodulteil.beschriftung_8_y = 0}
            if (nummer ==8) {selectedmodulteil.beschriftung_9_x= 360; selectedmodulteil.beschriftung_9_y = 0}
            if (nummer ==9) {selectedmodulteil.beschriftung_10_x= 400; selectedmodulteil.beschriftung_1_y = 0}
        }

       

        this.setState({selectedmodulteil})
    }

    suchemp3(){
        let wort = this.state.selectedmodulteil.antwort1.replaceAll(" ", "-")
        wort = wort.replaceAll("'", '-')

        let language = "englisch"
        if (this.state.mp3SuchSprache==="Deutsch")language = "german"
        if (this.state.mp3SuchSprache==="Französisch")language = "french"
        if (this.state.mp3SuchSprache==="Spanisch")language = "spanish"
        if (this.state.mp3SuchSprache==="Portugiesisch")language = "portuguese"

        axios.get('/api/unterrichtsmodulteile/howtopronounce/'+ language + '/'  + wort)
        .then(
            result=>{
                let selectedmodulteil = this.state.selectedmodulteil
                selectedmodulteil.frage_videobild_url = result.data    
                this.setState({selectedmodulteil})
            }
        )

    }


      zeitformRow(zeitform){

        return(
            <a className="dropdown-item" href="#" onClick={()=>this.changeKonjugZeitform(zeitform)}>{zeitform}</a>
        )

      }


      getmodalNeuesModulTeil(){

       


        let title = this.state.addModulteilText
        let okButtonTitle = 'Speichern und schliessen'

        if (this.state.istneuesModulteil===false){
            title = "Stapel bearbeiten"
        }


        if (this.state.selectedmodulteil===null) return null

        let okButtonenabled = (this.state.selectedmodulteil.frage !=='' && this.state.selectedmodulteil.antwort1.trim() !=='')

        if (this.state.antwortModus===3){
          
            if (this.state.selectedmodulteil.anzahl_antworten===2) okButtonenabled = (this.state.selectedmodulteil.frage !=='' && this.state.selectedmodulteil.antwort1.trim() !=='' && this.state.selectedmodulteil.antwort2.trim() !==''  && (this.state.selectedmodulteil.antwort1_richtig===1 || this.state.selectedmodulteil.antwort2_richtig===1) )
            if (this.state.selectedmodulteil.anzahl_antworten===3) okButtonenabled = (this.state.selectedmodulteil.frage !=='' && this.state.selectedmodulteil.antwort1.trim() !==''&& this.state.selectedmodulteil.antwort2.trim() !=='' && this.state.selectedmodulteil.antwort3.trim() !=='' && (this.state.selectedmodulteil.antwort1_richtig===1 || this.state.selectedmodulteil.antwort2_richtig===1 || this.state.selectedmodulteil.antwort3_richtig===1))
            if (this.state.selectedmodulteil.anzahl_antworten===4) okButtonenabled = (this.state.selectedmodulteil.frage !=='' &&  this.state.selectedmodulteil.antwort1.trim() !=='' && this.state.selectedmodulteil.antwort2.trim() !=='' && this.state.selectedmodulteil.antwort3.trim() !=='' && this.state.selectedmodulteil.antwort4.trim() !=='' && (this.state.selectedmodulteil.antwort1_richtig===1 || this.state.selectedmodulteil.antwort2_richtig===1 || this.state.selectedmodulteil.antwort3_richtig===1 || this.state.selectedmodulteil.antwort4_richtig===1))
            if (this.state.selectedmodulteil.anzahl_antworten===5) okButtonenabled = (this.state.selectedmodulteil.frage !=='' &&  this.state.selectedmodulteil.antwort1.trim() !=='' && this.state.selectedmodulteil.antwort2.trim() !=='' && this.state.selectedmodulteil.antwort3.trim() !=='' && this.state.selectedmodulteil.antwort4.trim() !=='' && this.state.selectedmodulteil.antwort5.trim() && (this.state.selectedmodulteil.antwort1_richtig===1 || this.state.selectedmodulteil.antwort2_richtig===1 || this.state.selectedmodulteil.antwort3_richtig===1 || this.state.selectedmodulteil.antwort4_richtig===1 || this.state.selectedmodulteil.antwort5_richtig===1))
                
       
        }


        if (this.state.antwortModus ===4){
            okButtonenabled = this.state.selectedmodulteil.frage !==''
        } 

        if (this.state.antwortModus===5){
            okButtonenabled=(this.state.selectedmodulteil.konjug_sprache !=="" && this.state.selectedmodulteil.konjug_zeitform !=="" && this.state.selectedmodulteil.konjug_verb !==""  && (this.state.selectedmodulteil.konjug_ich!=="" || this.state.selectedmodulteil.konjug_du!=="" || this.state.selectedmodulteil.konjug_ersiees!=="" || this.state.selectedmodulteil.konjug_wir!=="" || this.state.selectedmodulteil.konjug_ihr!=="" || this.state.selectedmodulteil.konjug_sie!=="") )
        }  
        
            // Wenn BVokabel-Audio
        if (this.state.antwortModus===6){
            okButtonenabled = (this.state.selectedmodulteil.frage_videobild_url !=='' && this.state.selectedmodulteil.antwort1.trim() !=='')    
        }    
   

        if (this.state.selectedmodulteil.ist_youtube_video===1){
            okButtonenabled = okButtonenabled && this.state.selectedmodulteil.youtube_video_id !==""
        }


        if (this.state.antwortModus===7){
            okButtonenabled =this.state.selectedmodulteil.frage_videobild_url !==""
        }
  

        let leftButton1 = <button  className="btn btn-primary" onClick={this.saveAndPreview}><i className="material-icons"> visibility</i> Vorschau</button>
        if (okButtonenabled===false){
            leftButton1 = <button  className="btn btn-primary" disabled><i className="material-icons"> visibility</i> Vorschau</button>
        }

        let body = this.getKarteikartenContent()

        return(

            <div>
                <Modal2
                    modalid="neuesModulTeil"
                    buttonid="neuesModulTeilButton"     
                    body={body}
                    title={title}
                    okButtonTitle={okButtonTitle}
                    okButtonClick={this.save}
                    okButtonenabled={okButtonenabled}
                    cancelButton={true} 
                    returnDisabled={true}  
                    applyButtonClick={()=>this.saveAndNext()}  
                    appyButtonTitle="Speichern und neue Karte"
                    leftButton1={leftButton1}

                />  
            </div>
        )

      }

      saveAndPreview(){

          this.setState({insertAndPreview:true},
            function(){
                this.saveModulTeil();
            })

      }

    

      modalDelete(){

        
        let title ="Karteikarte löschen"
        let text = 'Klicken Sie auf "Löschen", um die Karteikarte unwiderruflich zu löschen'
        
        const body=(
            <div>
               <h4>{text}</h4> 
            </div>
        )
        return(

            <div>
                <Modal2 modalid="loescheModulTeil"
                buttonid="loescheModulTeilButton"
                body={body}
                title={title}
                okButtonTitle="Löschen"
                okButtonClick={this.deleteunterrichtsmodulteil}
                cancelButton={true} 
                />  
            </div>
        )
      }

      showDeleteDialog(e){

        this.setState({selectedUnterrichtsmodulTeileID:parseInt(e.target.id,10)},
        function(){
            $('#loescheModulTeilButton').click()
        })


          
      }

      editModulteil(e){
          const _this = this
          this.state.unterrichtsmodulteile.forEach(
              function(teil){
                  if (teil.id===parseInt(e.target.id,10)){
                      let antwortModus = 1;
                      if (teil.mussfrageschriftlichbeantworten===1) antwortModus=2
                      if (teil.multiplechoice===1) antwortModus=3
                      if (teil.wahroderfalsch > -1 )antwortModus = 4
                      if (teil.ist_konjugation===1) antwortModus = 5
                      if (teil.ist_vokabelaudio===1)antwortModus = 6
                      if (teil.ist_bildmitbeschriftung!==null && teil.ist_bildmitbeschriftung===1)antwortModus = 7;

                      if (antwortModus===7){
                        _this.forceUpdate()
                      }

                      _this.setState({selectedmodulteil:teil,istneuesModulteil:false, antwortModus,editing:true, canRefresh:true},
                        function() { 
                             $('#neuesModulTeilButton').click()
                            
                        } )                     
                  }
              }
          )

      }


      canEditModul(){

     
        let result = true
        /*    if( this.state.unterrichtsmodul.anzahlkopien>0 && this.state.unterrichtsmodul.eigentuemer_hausadresse===this.getHauspostadresse()){
                result = false
            }*/
        return result

      }

      changeNurNummernEingeben(e){
        const selectedmodulteil = this.state.selectedmodulteil
        selectedmodulteil.beschriftung_antwort_nummern = 0
        if (e.target.checked){
            selectedmodulteil.beschriftung_antwort_nummern = 1        
        }
        this.setState({selectedmodulteil})

      }


    changeKorrektur(e, modulteil){    
        
        const jahr=new Date().getFullYear()
        if (this.props.activeSchule.lizenz_jahr < jahr){
            this.alertMessage(parse("<div>Keine Schul-Lizenz für das Jahr <b>" + jahr  + "</b> für die Korrigierfunktionen vorhanden. Sie können unter 'Schuldaten bearbeiten' eine Lizenz bestellen. Bitte kontaktieren Sie Ihren Schuladministrator oder schreiben Sie eine Mail an <i>info@schoolflashcards.eu</i></div>" ))
            return
        }
          
        modulteil.korrekturmarkierung = 0;
        if(e.target.checked) {
            modulteil.korrekturmarkierung = 1;
        }  
        

        axios.post('/api/unterrichtsmodulteile', modulteil)
        .then(
            result=>{
                this.setState({unterrichtsmodulteile:result.data})

                const stapelablage={
                    unterrichtsmodule_id:modulteil.unterrichtsmodule_id,
                    stapel_eigentuemer_id:this.state.unterrichtsmodul.eigentuemer_id,
                    stapel_absender_id:this.props.activeUser.id,
                    status:'Warten auf Verbesserung'
                }  
                axios.post('/api/stapelablage', stapelablage)
            }
        )  
        
    }
      
    unterrichtsmodulteileRow(modulteil){

        let antwort = ""

        if (modulteil.antwort1===null)modulteil.antwort1=""

        try{
            antwort = parse(modulteil.antwort1)
        }
        catch(err){
            antwort = modulteil.antwort1 + "FEHLER IM HTML-CODE!!!"
        }


        let zusatzschriftlich=null
       
        let frage= modulteil.frage
    
        if (modulteil.mussfrageschriftlichbeantworten===1){
            zusatzschriftlich = <i className="text-size-07rem">(Antwort erfolgt schriftlich)</i>
        }
    
        let wahrfalschzusatz = null
        if (modulteil.wahroderfalsch>-1){
            wahrfalschzusatz = <b>wahr</b>
            if (modulteil.wahroderfalsch===0)  wahrfalschzusatz = <b>falsch</b>
            frage = `Wahr oder falsch: ${modulteil.frage} `
        }
    
        let width=100
     
         let zusatzVideoBildURL = null
         if (modulteil.frage_videobild_url !=="" && modulteil.frage_videobild_url !==null) zusatzVideoBildURL =<div><img width={width} className="mg-fluid  img-thumbnail" alt="" src={modulteil.frage_videobild_url}/></div>        
        
        let td_switchKorrektur = null;
    
        if (this.state.unterrichtsmodul.eigentuemer_id === this.props.activeUser.id || this.props.params.from==="korrigieren"){    
            td_switchKorrektur=(
                <div className="togglebutton">
                    <label>
                        <input type="checkbox" onChange={(e)=>this.changeKorrektur(e, modulteil)}  checked={modulteil.korrekturmarkierung===1} />
                            <span className="toggle"></span>
                            korrigieren
                    </label>
                </div>
            ) 
        }       
        
        
            
        if (modulteil.multiplechoice ===1){

       
            let antwort1 = parse(modulteil.antwort1)
            if (modulteil.antwort1_richtig===1)antwort1=<b>{antwort1}</b>

            let antwort2 = parse(modulteil.antwort2)
            if (modulteil.antwort2_richtig===1)antwort2=<b>{antwort2}</b>

            let antwort3 =null
            let antwort4 =null
            let antwort5 =null

            if (modulteil.anzahl_antworten>2){
                antwort3 = parse(modulteil.antwort3)
                if (modulteil.antwort3_richtig===1)antwort3=<b>{antwort3}</b>
            }
            if (modulteil.anzahl_antworten>3){
                antwort4 = parse(modulteil.antwort4)
                if (modulteil.antwort4_richtig===1)antwort4=<b>{antwort4}</b>
            }   
            if (modulteil.anzahl_antworten>4){
                antwort5 = parse(modulteil.antwort5)
                if (modulteil.antwort5_richtig===1)antwort5=<b>{antwort5}</b>
            }         
    
            antwort = <i>Multiple choice ({antwort1}, {antwort2}, {antwort3}, {antwort4}, {antwort5})</i>
        }
    
      
        let btnEdit =<button id={modulteil.id} className="btn btn-info btn-fab btn-fab-mini btn-round noprint" onClick={this.editModulteil} ><i id={modulteil.id} className="material-icons">edit</i></button>
        let btnDelete = <button id={modulteil.id} className="btn btn-danger btn-fab btn-fab-mini btn-round noprint" onClick={this.showDeleteDialog} ><i id={modulteil.id} className="material-icons">close</i></button>
        let btnPreview = <button id={modulteil.id} className="btn btn-primary btn-fab btn-fab-mini btn-round noprint" onClick={this.showPreviewDialog} ><i id={modulteil.id} className="material-icons">visibility</i></button>

        if( this.canEditModul()===false){
            btnEdit=null
            btnDelete = null
        }

      
    
       let td_korrekturmarkierung = null
       if (this.state.unterrichtsmodul.ist_in_stapelablage>0 &&  modulteil.korrekturmarkierung===1  && this.props.activeUser!==null ){    
         
            if (this.props.activeUser.id === this.state.unterrichtsmodul.eigentuemer_id)
            td_korrekturmarkierung = (
                <div style={{color:'#ff0000', fontSize:'21p'}}><i>Bitte zu den Aufgaben wechseln und diesen Stapel verbessern!</i></div>
            )
       }    
    
       let zusatzkonjugation = null


       if (frage !==null && frage !==""){
            frage=parse(frage)
       }

       if (modulteil.ist_konjugation ===1){
            antwort=null
            wahrfalschzusatz=null
            zusatzschriftlich=null
            frage=<p>{getSpracheLang(modulteil.konjug_sprache)}<br/>{modulteil.konjug_zeitform}<br/> Konjugiere das Verb <b>{modulteil.konjug_verb}</b></p>
            zusatzkonjugation = getKonjugationsTable(modulteil)
       }

       let divmarkieren = null
       
       if (this.state.markierenStatus){
            divmarkieren=(
                    <td><div className="togglebutton">
                        <label>
                            <input type="checkbox" value={modulteil.id}  checked={this.state.selectedObjectIDs.indexOf(modulteil.id)>-1} onClick={this.clickDataset}  />
                            <span className="toggle"></span>
                        </label>
                    </div>
                    </td>
            )
       }

       if (this.props.params.from==="briefkasten" || this.props.params.from==="globalemodule"){
            td_korrekturmarkierung=null
            td_switchKorrektur=null
            btnEdit=null
            btnDelete = null
       }

       let td_autor = <td>{modulteil.autor}</td>
       if (this.props.params.from==="globalemodule")td_autor=null

       if (this.state.unterrichtsmodul.wird_gemeinsam_bearbeitet > 0 && modulteil.autor_id !== this.props.activeUser.id && this.props.activeUser.ist_schueler===1){
        
           td_switchKorrektur = null
           btnDelete = null
           btnEdit = null

       }

       let antwortimage = null
       if (modulteil.antwort_videobild_url !== "" && modulteil.antwort_videobild_url !==null){
        antwortimage = <img width="100" src={modulteil.antwort_videobild_url } />
       }

       let youTubePlayerButton = null 
       if (modulteil.ist_youtube_video===1){
           youTubePlayerButton = <button className="btn btn-info btn-fab btn-fab-mini btn-round" onClick={()=>this.setState({karteikarte:modulteil}, function(){$('#modalYoutubeDialogButton').click()} )}><i className="material-icons">play_circle</i></button>                            
       } 

       if (modulteil.ist_vokabelaudio===1){
        frage = <iframe src={modulteil.frage_videobild_url}></iframe>
        antwort = modulteil.antwort1
       }
    
       if (modulteil.ist_bildmitbeschriftung===1){
       
        if (modulteil.frage==="")frage = "Bild/Karte beschriften"
        let woerter=[]
        woerter.push(modulteil.beschriftung_1)
        if (modulteil.beschriftung_2 !=="")  woerter.push(", " + modulteil.beschriftung_2)
        if (modulteil.beschriftung_3 !=="")  woerter.push(", " + modulteil.beschriftung_3)
        if (modulteil.beschriftung_4 !=="")  woerter.push(", " + modulteil.beschriftung_4)
        if (modulteil.beschriftung_5 !=="")  woerter.push(", " + modulteil.beschriftung_5)
        if (modulteil.beschriftung_6 !=="")  woerter.push(", " + modulteil.beschriftung_6)
        if (modulteil.beschriftung_7 !=="")  woerter.push(", " + modulteil.beschriftung_7)
        if (modulteil.beschriftung_8 !=="")  woerter.push(", " + modulteil.beschriftung_8)
        if (modulteil.beschriftung_9 !=="")  woerter.push(", " + modulteil.beschriftung_9)
        if (modulteil.beschriftung_10 !=="")  woerter.push(", " + modulteil.beschriftung_10)
        antwort = woerter
       }
    
      
        return(
            <tr key={modulteil.id}>
                {divmarkieren}
                <td>{frage} {zusatzVideoBildURL} {youTubePlayerButton}</td>
                <td>{antwort} {wahrfalschzusatz} {zusatzschriftlich} {zusatzkonjugation} {antwortimage}</td>
                <td className="text-center">{td_korrekturmarkierung}</td>
                <td className="text-center">{td_switchKorrektur}</td>
                <td className="text-center">{btnPreview}</td>
                <td className="text-center">{btnEdit}</td>
                <td className="text-center">{btnDelete}</td>
                {td_autor}
                
            </tr>
        )
       
      }

  
    

      getUnterrichtsmodulTeile(){
        axios.get('/api/unterrichtsmodulteile/' + this.props.params.unterrichtsmodulid) 
          .then(
              result=>{
                  this.setState({unterrichtsmodulteile: result.data})
              }
          )
      }

      getUnterrichtsmodule() {
        if (this.props.activeUser===undefined || this.props.activeUser===null) return
        axios.get('/api/unterrichtsmodule/' + this.props.activeUser.id)
            .then(
                result => {
                    if (result.data !== null  && result.data.unterrichtsmodule !==null) {
                        this.setState({unterrichtsmodule:result.data.unterrichtsmodule})
                    }
                }
            )
    }

      componentWillMount(){

        if (this.props.activeSchule !==null)

          axios.get('/api/unterrichtsmodule/byid/' + this.props.params.unterrichtsmodulid)          
          .then(
              result=>{                
                axios.get('/api/unterrichtsmodulteile/' + result.data.id)
                .then(
                    result2=>{                       
                  let backlink=''
                        if (this.props.params.from==="user")backlink='/user/flashcards'
                        if (this.props.params.from==="preview")backlink='/previewunterrichtsmodul/' + this.props.params.unterrichtsmodulid + '/edit'
                        

                        let addModulteilText = 'Karteikarte +'
                        
                        this.setState({
                            unterrichtsmodul: result.data, 
                            backlink,
                            unterrichtsmodulteile: result2.data,
                            addModulteilText})
                           
                        
                    }
                )                
              }             
          )
          const _this = this
          if (this.props.activeUser !== undefined)
          axios.get('/api/benutzereinstellungen/' + this.props.activeUser.id)
          .then(
              result=>{
                  if (result.data !==null){
                        this.setState({benutzereinstellungen: result.data}, function(){
                          let benutzereinstellung = this.getBenutzereinstellung("lastTranslationLanguageKey")                          
                          if (benutzereinstellung !==null){
                              this.state.translationLanguages.forEach(
                                  function(language){
                                      if (language.key==benutzereinstellung.strvalue){
                                          _this.setState({selectedTranslationLanguage:language})
                                      }

                                  }
                              )
                          }
                      })
                  }
              }
          )

          axios.get('/api/hilfe/eingabehilfe')
          .then(
              result=>{
                  if (result.data !==null) this.setState({eingabeHilfeListe:result.data})
              }
          )

          this.getUnterrichtsmodule()

          
      }


    showPreviewDialog(e){

          const _this = this
          this.state.unterrichtsmodulteile.forEach(
              function(teil){
                  if (teil.id===Number(e.target.id)){
                      _this.setState({previewModulteil:teil, karteikartenModus:'Frage',canRefresh:false},
                        function() { 
                             $('#vorschauKarteikarteDialogButton').click()
                        } )                     
                  }
              }
          )

      }

      cancelPreview(){
        //  this.setState({showKarteikarte:false})
        $('#vorschauKarteikarteDialogButton').click()
      }


    onClickKarteikarte(){
        let karteikartenModus=this.state.karteikartenModus
        if (karteikartenModus==='Frage'){
            karteikartenModus='Antwort'
        }
        else{
            karteikartenModus='Frage'
        }
       
        this.setState({karteikartenModus})
      
    }

      vorschauKarteikarte(){

          if (this.state.previewModulteil===null) return 

          let modulteile = []
          modulteile.push(this.state.previewModulteil)

        

         const body=(

            
             
            <Karteikarte 
                title={this.state.unterrichtsmodul.bezeichnung}  
                unterrichtsmodulteile={modulteile} 
                unterrichtsmodul={this.state.unterrichtsmodul}
                karteikartenIndex={0}
                onClickKarteikarte={this.onClickKarteikarte}
                karteikartenModus={this.state.karteikartenModus}
                benutzer_id={this.props.activeUser.id}
                quiz={false}
                previewMode={true}
                cancelPreview={this.cancelPreview}
            />
         )

       /*    return(
            <div style={{width:'600px'}}>

                <button className="invisible" id="vorschauKarteikarteDialogButton" onClick={()=>this.setState({showKarteikarte:true})}>
                    Launch modal
                </button>

                <Modal show={this.state.showKarteikarte} onHide={()=>this.setState({showKarteikarte:false})} size="lg">
                <Modal.Header closeButton>                   
                </Modal.Header>
                <Modal.Body>
                    <div>{body} </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary noprint" onClick={()=>this.setState({showKarteikarte:false})}>Schliessen</button>
                </Modal.Footer>
                </Modal>
            </div>

         )*/

        return(
              <Modal2
                modalid="vorschauKarteikarteDialog" 
                buttonid="vorschauKarteikarteDialogButton" 
                body={body}
                okButtonTitle="Schliessen"
                okButtonClick={this.closePreview}
                okButtonenabled={true}
                cancelButton={false}  
              />
          )
      }




      modalEingabeHilfeDialog(){

      

        function eingabeHilfeRow (eingabehilfe){        
     
            return(
                <tr key={eingabehilfe.id}>
                    <td style={{backgroundColor:'#e7e7e7'}}>{eingabehilfe.zeichenklein}</td><td>{eingabehilfe.eingabeklein}</td><td style={{backgroundColor:'#e7e7e7'}}>{eingabehilfe.zeichengross}</td><td>{eingabehilfe.eingabegross}</td>
                </tr>
            )            
        }

        const body=(

            <div className="table-responsive">
                <table className="table table-striped">
                    <tbody>
                        {this.state.eingabeHilfeListe.map(eingabeHilfeRow)}
                    </tbody>
                </table>
            </div>
        )


        return(
            <Modal2

              modalid="modalEingabeHilfeDialog" 
              buttonid="modalEingabeHilfeDialogButton" 
              body={body}
              okButtonenabled={true}
              okButtonTitle="Schliessen"


            />
        )

      }

     



      render() {

        let modalNeuesModulTeil = null

        let modalDelete = this.modalDelete()     

      

        

        let closeLink =  <Link to={this.state.backlink} className="btn btn-danger">Schliessen</Link>
        if (this.props.params.from==="korrigieren"){
            closeLink =  <Link to="/user/korrigieren" className="btn btn-danger">Schliessen</Link>           
        }
        if (this.props.params.from==="monitoring"){
            closeLink =  <Link to="/user/monitoring" className="btn btn-danger">Schliessen</Link>           
        }
        if (this.props.params.from==="briefkasten"){
            closeLink =  <Link to="/user/briefkasten" className="btn btn-danger">Schliessen</Link>           
        }
        if (this.props.params.from==="globalemodule"){
            closeLink =  <Link to="/globalemodule" className="btn btn-danger">Schliessen</Link>           
        }

        let title = <div></div>
        let info=null
        let author = null
        if (this.state.unterrichtsmodul !==null){
            author = this.state.unterrichtsmodul.eigentuemer

            if (this.state.unterrichtsmodul.ist_veroeffentlicht===1 && this.props.params.from==="globalemodule"){
                author = this.state.unterrichtsmodul.public_autor_pseudonym
            }


            title = this.state.unterrichtsmodul.bezeichnung
            modalNeuesModulTeil = this.getmodalNeuesModulTeil();
            if (this.canEditModul()===false) info=<div>Sie können das Modul nicht verändern, da Sie es schon an andere Benutzer verschickt haben.</div>
        }

        let modulart = null
       
        let karteikartenliste = null     
        let buttonPlus = <button onClick={ this.addNeuesModul} className="btn btn-primary">{this.state.addModulteilText}</button>
        let buttonPreview = <button onClick={this.previewModul} className="btn btn-info"><i className="material-icons">visibility</i> Vorschau </button>

        if (this.props.params.from==="globalemodule")buttonPlus = null

        let th_markieren = null
        let markierenCassName = "btn btn-primary"
        let markierenTitle = "Markieren"
        let kopierenButton = null;
        if (this.state.markierenStatus) {
             markierenCassName="btn btn-danger"
             markierenTitle = "Markieren abbrechen"
             th_markieren=(
                <th>
                    <button className="btn btn-default btn-sm btn-round"  name="allemarkieren" onClick={this.markieren}>alle ja</button> <button className="btn btn-default btn-sm btn-round" name="alledemarkieren" onClick={this.markieren}>alle nein</button> 
                </th>
            )

            kopierenButton =<button className="btn btn-primary" disabled >Kopieren...</button>

            if (this.state.selectedObjectIDs.length>0){
                kopierenButton =<button className="btn btn-primary" onClick={()=>$('#modalKopierenDialogButton').click()} >Kopieren...</button>

            }
        }

        const buttonBearbeiten = <button className={markierenCassName} onClick={()=>this.setState({markierenStatus:!this.state.markierenStatus})}>{markierenTitle}</button>   
        const _this = this 
        const buttonAktualisieren = <button className="btn btn-default" onClick={()=>this.setState({canRefresh:true}, function(){_this.checkNews()})}>Aktualisieren</button>    
        
        let th_autor = <th>Autor/in</th>
        if (this.props.params.from==="globalemodule")th_autor=null

        if (this.state.unterrichtsmodulteile.length>0){
            karteikartenliste = (
                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                {th_markieren}
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                {th_autor}
                                
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.unterrichtsmodulteile.map(this.unterrichtsmodulteileRow)}
                        </tbody>
                    </table>
                </div>
            )
        }

        let titleUnterrichtsmodulteile = <h4>{this.state.unterrichtsmodulteile.length} Karteikarten</h4>

        let div2ndButtons = null
        if (this.state.unterrichtsmodulteile.length>5){
            div2ndButtons = <div>{buttonPlus} {buttonPreview} {buttonBearbeiten} {kopierenButton} {buttonAktualisieren}</div>
        }

        if (this.props.params.from==="briefkasten"){
            buttonPlus = null            
        }

      
        
  

        return (        
       
            <div  style={{height:'100%', padding:'50px',backgroundColor:'white'}}>
                        <div className="noprint">{closeLink}</div>
                        <div className="line noprint"/>
                        <div>Autor/in: <big>{author}</big></div>
                        <h4>{modulart}</h4>
                        <h2>{title}</h2>
                        <h4>{info}</h4>
                        {titleUnterrichtsmodulteile}                    
                        <div>{buttonPlus} {buttonPreview} {buttonBearbeiten} {kopierenButton} {buttonAktualisieren}</div>
                        <div className="line noprint"/>
                        {karteikartenliste}                               
                        <div className="line noprint"/>   
                        {div2ndButtons}               
                        
                        <div className="noprint">{closeLink}  </div>

                  {modalNeuesModulTeil} {modalDelete} {this.modalTranslationDialog()} {this.alertMessageDialog()} {this.vorschauKarteikarte()}   {this.modalEingabeHilfeDialog()} {this.kopierenDialog()} {this.modalYoutubeDialog()} {this.cameraDialog()}
            </div>
      
       
        )
    }

}


function mapStateToProps ({ posts }) {
    return {
      activeUser: posts.userData,
      activeSchueler: posts.pupilsData,
      activeSchule:posts.schule
    };
  };
  export default connect(mapStateToProps) (EditUnterrichtsModul);
  
  
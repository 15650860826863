import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import $ from 'jquery';
import { shuffleArray, is_Html, getSpracheLang, getPronom, clearString } from '../components/generalfunctions'
import Modal from '../components/modal.jsx'
import YoutubeVideo from '../components/youtubevideo.jsx';
import parse from 'html-react-parser';
import {getAnzahlRichtigeAntworten} from './karteikartenutils.jsx'
import Beschriftung from '../components/beschriftung.jsx'


const RadioButtons =(props) => {   

   
    let radioDivs = []   
    


    props.antwortArray.forEach(
        function(antwort){

            let div= <div><button className="btn btn-default btn-sm btn-round" style={{marginBottom:"25px",minWidth:'400px' }} onClick={()=>props.clickAnsweroption(antwort, props.karteikarte)} ><div style={{fontSize:"1.1rem"}}>{antwort.antwort}</div></button></div>
          
            if (getAnzahlRichtigeAntworten(props.karteikarte)>1){

                 div=(      
                                   
                    <div className="togglebutton">
                        <label style={{fontSize:'1.3rem', color:'black'}}>
                            <input type="checkbox" onChange={(e)=>props.checkAntwort(e, antwort.number)}/>
                            <span className="toggle"></span>
                            {antwort.antwort}
                        </label>  
                    </div>   
                 )
             }

            radioDivs.push(                                    
                    div    
            )
        }
    )
    let info = null
    if (getAnzahlRichtigeAntworten(props.karteikarte)>1)info= <p style={{color:'red'}}><b>Falls du dich verklickt hast, must du <b>jeden einzelnen Radiobutton erneut anklicken(!)</b> und in den richtigen Checkzustand versetzen.</b></p>

    if (getAnzahlRichtigeAntworten(props.karteikarte)>1){
        radioDivs = (
            <div style={{display:'flex',justifyContent: 'space-around', marginTop:'50px'}}>
                {radioDivs}
            </div>
        )
    }

    return(
        <div style={{width:'100%'}}>
            {info}
                {radioDivs}
        </div>  
    )

}

class Wettbewerb extends React.Component  {

    constructor(props) {
        super(props);

        var  integerList = [0,1,2,3,4,5,6,7,8,9]
        shuffleArray(integerList)
        
        var  mappingIntegerList = [1,2,3,4,5,6,7,8,9,10]
        shuffleArray(mappingIntegerList)

        this.state = {
            wettbewerbMitSchuelern:null,     
            schueler_in_wettbewerben:[],      
            anzahlrichtige:0, 
            gestartet:0,
            karteikarten:[],
            karteikartenIndex:0,
            antwort:'',
            alertMessage:'',
            wettbewerb: null,
            abschlussliste:[],
            wettkampfGestartet: false,
            unterrichtsModul:null,
            antwortArray: undefined,
            antwort1_richtig:false,
            antwort2_richtig:false,
            antwort3_richtig:false,
            antwort4_richtig:false,
            antwort5_richtig:false,
            selectedmodulteil:{
                id:0,
                unterrichtsmodule_id:props.params.unterrichtsmodulid,
                autor_id:0,
                frage:'',
                antwort1:'',
                antwort2:'',
                antwort3:'',
                antwort4:'',
                antwort5:'',
                mussfrageschriftlichbeantworten:0,
                aktiv:1,
                notizen:'',
                zusatzinfos:'',
                frage_videobild_url:'',
                wahroderfalsch:0,
                antwort1_richtig:1,
                antwort2_richtig:0,
                antwort3_richtig:0,
                antwort4_richtig:0,
                antwort5_richtig:0,
                anzahl_antworten:5,  
                konjug_sprache:'',
                konjug_zeitform:'',
                konjug_ich:'',
                konjug_du:'',
                konjug_ersiees:'',
                konjug_wir:'',
                konjug_ihr:'',
                konjug_sie:'',
                ist_konjugation:0,
                konjug_verb:'' ,    
                ist_youtube_video:0,
                nur_audio_abspielen:0,
                youtube_video_id:'',
                youtube_video_startzeit:0,
                youtube_video_laufzeit:0,
                antwort_videobild_url:'',
                ist_vokabelaudio:0,
                ist_bildmitbeschriftung:0,
                beschriftung_1:'',
                beschriftung_2:'',
                beschriftung_3:'',
                beschriftung_4:'',
                beschriftung_5:'',
                beschriftung_6:'',
                beschriftung_7:'',
                beschriftung_8:'',
                beschriftung_9:'',
                beschriftung_10:'',
                beschriftung_1_pfeil:1,
                beschriftung_2_pfeil:1,
                beschriftung_3_pfeil:1,
                beschriftung_4_pfeil:1,
                beschriftung_5_pfeil:1,
                beschriftung_6_pfeil:1,
                beschriftung_7_pfeil:1,
                beschriftung_8_pfeil:1,
                beschriftung_9_pfeil:1,
                beschriftung_10_pfeil:1,
                beschriftung_1_x:0.0,
                beschriftung_1_y:0.0,
                beschriftung_2_x:60.0,
                beschriftung_2_y:0.0,
                beschriftung_3_x:120.0,
                beschriftung_3_y:0.0,
                beschriftung_4_x:160.0,
                beschriftung_4_y:0.0,
                beschriftung_5_x:200.0,
                beschriftung_5_y:0.0,
                beschriftung_6_x:240.0,
                beschriftung_6_y:0.0,
                beschriftung_7_x:280.0,
                beschriftung_7_y:0.0,
                beschriftung_8_x:320.0,
                beschriftung_8_y:0.0,
                beschriftung_9_x:360.0,
                beschriftung_9_y:0.0,
                beschriftung_10_x:400.0,
                beschriftung_10_y:0.0,
                beschriftung_antwort_nummern:0

            
            },
            beschriftung1:'',      
            beschriftung2:'',      
            beschriftung3:'',      
            beschriftung4:'',      
            beschriftung5:'',      
            beschriftung6:'',      
            beschriftung7:'',      
            beschriftung8:'',      
            beschriftung9:'',      
            beschriftung10:'',      
            beschriftung1Nummer:'',      
            beschriftung2Nummer:'',      
            beschriftung3Nummer:'',      
            beschriftung4Nummer:'',      
            beschriftung5Nummer:'',      
            beschriftung6Nummer:'',      
            beschriftung7Nummer:'',      
            beschriftung8Nummer:'',      
            beschriftung9Nummer:'',      
            beschriftung10Nummer:'',     
            integerList ,
            mappingIntegerList,
            
        }

        this.getWettbewerb = this.getWettbewerb.bind(this)
        this.starteWettbewerb = this.starteWettbewerb.bind(this)
        this.losGehts = this.losGehts.bind(this)
        this.getKarteikarte = this.getKarteikarte.bind(this)
        this.modalKarteikarte = this.modalKarteikarte.bind(this)
        this.clickOK = this.clickOK.bind(this)
        this.alertMessage = this.alertMessage.bind(this)
        this.alertMessageDialog = this.alertMessageDialog.bind(this)
        this.saveAntwort = this.saveAntwort.bind(this)
        this.rennTabelle = this.rennTabelle.bind(this)
        this.getButtonsOrCheckGroup = this.getButtonsOrCheckGroup.bind(this)
        this.getAntwortArray = this.getAntwortArray.bind(this)
        this.clickAnsweroption = this.clickAnsweroption.bind(this)
        this.getKarteikarteAntwort = this.getKarteikarteAntwort.bind(this)
        this.checkAntwort = this.checkAntwort.bind(this)
        this.setWettbewerbState = this.setWettbewerbState.bind(this)
        this.richtigeAntwortenPruefen = this.richtigeAntwortenPruefen.bind(this)
        this.getKonjugationstabelle = this.getKonjugationstabelle.bind(this)
        this.changeKonjug = this.changeKonjug.bind(this)
        this.konjugationPruefen = this.konjugationPruefen.bind(this)
        this.changeAntwort2 = this.changeAntwort2.bind(this)
        this.getSkizzenBeschriftungsTabelle = this.getSkizzenBeschriftungsTabelle.bind(this)
        this.checkBeschriftungen = this.checkBeschriftungen.bind(this)

        setInterval(this.getWettbewerb, 500)

       
        
    }

    checkBeschriftungen(){

        let ohneFehler = true

        let fehler = 0;

        if (this.state.karteikarte.beschriftung_antwort_nummern ===0){

            if (this.state.karteikarte.beschriftung_1 !== "" && this.state.beschriftung1.trim() !== this.state.karteikarte.beschriftung_1.trim() ){
                fehler = fehler + 1
            }
            if (this.state.karteikarte.beschriftung_2 !== "" && this.state.beschriftung2.trim() !== this.state.karteikarte.beschriftung_2.trim()){
                fehler = fehler + 1
            }
            if (this.state.karteikarte.beschriftung_3 !== "" && this.state.beschriftung3.trim() !== this.state.karteikarte.beschriftung_3.trim()){
                fehler = fehler + 1
            }
            if (this.state.karteikarte.beschriftung_4 !== "" && this.state.beschriftung4.trim() !== this.state.karteikarte.beschriftung_4.trim()){
                fehler = fehler + 1
            }
            if (this.state.karteikarte.beschriftung_5 !== "" && this.state.beschriftung5.trim() !== this.state.karteikarte.beschriftung_5.trim()){
                fehler = fehler + 1
            }
            if (this.state.karteikarte.beschriftung_6 !== "" && this.state.beschriftung6.trim() !== this.state.karteikarte.beschriftung_6.trim()){
                fehler = fehler + 1
            }
            if (this.state.karteikarte.beschriftung_7 !== "" && this.state.beschriftung7.trim() !== this.state.karteikarte.beschriftung_7.trim()){
                fehler = fehler + 1
            }
            if (this.state.karteikarte.beschriftung_8 !== "" && this.state.beschriftung8.trim() !== this.state.karteikarte.beschriftung_8.trim()){
                fehler = fehler + 1
            }
            if (this.state.karteikarte.beschriftung_9 !== "" && this.state.beschriftung9.trim() !== this.state.karteikarte.beschriftung_9.trim()){
                fehler = fehler + 1
            }
            if (this.state.karteikarte.beschriftung_10 !== "" && this.state.beschriftung1.trim() !== this.state.karteikarte.beschriftung_10.trim()){
                fehler = fehler + 1
            }
        }
        else{

            if (this.state.karteikarte.beschriftung_1 !== "" && this.state.beschriftung1Nummer !== this.state.mappingIntegerList[0].toString()){
                fehler = fehler + 1
            }
            if (this.state.karteikarte.beschriftung_2 !== "" && this.state.beschriftung2Nummer !== this.state.mappingIntegerList[1].toString()){
                fehler = fehler + 1
            }
            if (this.state.karteikarte.beschriftung_3 !== "" && this.state.beschriftung3Nummer !== this.state.mappingIntegerList[2].toString()){
                fehler = fehler + 1
            }
            if (this.state.karteikarte.beschriftung_4 !== "" && this.state.beschriftung4Nummer !== this.state.mappingIntegerList[3].toString()){
                fehler = fehler + 1
            }
            if (this.state.karteikarte.beschriftung_5 !== "" && this.state.beschriftung5Nummer !== this.state.mappingIntegerList[4].toString()){
                fehler = fehler + 1
            }
            if (this.state.karteikarte.beschriftung_6 !== "" && this.state.beschriftung6Nummer !== this.state.mappingIntegerList[5].toString()){
                fehler = fehler + 1
            }
            if (this.state.karteikarte.beschriftung_7 !== "" && this.state.beschriftung7Nummer !== this.state.mappingIntegerList[6].toString()){
                fehler = fehler + 1
            }
            if (this.state.karteikarte.beschriftung_8 !== "" && this.state.beschriftung8Nummer !== this.state.mappingIntegerList[7].toString()){
                fehler = fehler + 1
            }
            if (this.state.karteikarte.beschriftung_9 !== "" && this.state.beschriftung9Nummer !== this.state.mappingIntegerList[8].toString()){
                fehler = fehler + 1
            }
            if (this.state.karteikarte.beschriftung_10 !== "" && this.state.beschriftung10Nummer !== this.state.mappingIntegerList[9].toString()){
                fehler = fehler + 1
            }


        }

        let richtig = false

        if (fehler === 0){
            richtig = true
        }
        if (richtig){                     
            this.saveAntwort(this.state.karteikartenIndex)   
        }

        this.setWettbewerbState(richtig)   

    }

    getSkizzenBeschriftungsTabelle(){

        const _this = this

        function setBeschriftung(e,index){
            if (index ===0) _this.setState({beschriftung1: e.target.value})
            if (index ===1) _this.setState({beschriftung2: e.target.value})
            if (index ===2) _this.setState({beschriftung3: e.target.value})
            if (index ===3) _this.setState({beschriftung4: e.target.value})
            if (index ===4) _this.setState({beschriftung5: e.target.value})
            if (index ===5) _this.setState({beschriftung6: e.target.value})
            if (index ===6) _this.setState({beschriftung7: e.target.value})
            if (index ===7) _this.setState({beschriftung8: e.target.value})
            if (index ===8) _this.setState({beschriftung9: e.target.value})
            if (index ===9) _this.setState({beschriftung10: e.target.value})

        }


        function setBeschriftungNummer(e,index){
            if (index ===0) _this.setState({beschriftung1Nummer: e.target.value})
            if (index ===1) _this.setState({beschriftung2Nummer: e.target.value})
            if (index ===2) _this.setState({beschriftung3Nummer: e.target.value})
            if (index ===3) _this.setState({beschriftung4Nummer: e.target.value})
            if (index ===4) _this.setState({beschriftung5Nummer: e.target.value})
            if (index ===5) _this.setState({beschriftung6Nummer: e.target.value})
            if (index ===6) _this.setState({beschriftung7Nummer: e.target.value})
            if (index ===7) _this.setState({beschriftung8Nummer: e.target.value})
            if (index ===8) _this.setState({beschriftung9Nummer: e.target.value})
            if (index ===9) _this.setState({beschriftung10Nummer: e.target.value})

        }

        function getBeschriftung(index){
            if (index ===0) return _this.state.beschriftung1
            if (index ===1) return _this.state.beschriftung2
            if (index ===2) return _this.state.beschriftung3
            if (index ===3) return _this.state.beschriftung4
            if (index ===4) return _this.state.beschriftung5
            if (index ===5) return _this.state.beschriftung6
            if (index ===6) return _this.state.beschriftung7
            if (index ===7) return _this.state.beschriftung8
            if (index ===8) return _this.state.beschriftung9
            if (index ===9) return _this.state.beschriftung10    
        }
        function getBeschriftungNummer(index){
            if (index ===0) return _this.state.beschriftung1Nummer
            if (index ===1) return _this.state.beschriftung2Nummer
            if (index ===2) return _this.state.beschriftung3Nummer
            if (index ===3) return _this.state.beschriftung4Nummer
            if (index ===4) return _this.state.beschriftung5Nummer
            if (index ===5) return _this.state.beschriftung6Nummer
            if (index ===6) return _this.state.beschriftung7Nummer
            if (index ===7) return _this.state.beschriftung8Nummer
            if (index ===8) return _this.state.beschriftung9Nummer
            if (index ===9) return _this.state.beschriftung10Nummer   
        }
        function getPfeilrichtung(index){
            if (index ===0) return _this.state.karteikarte.beschriftung_1_pfeil
            if (index ===1) return _this.state.karteikarte.beschriftung_2_pfeil
            if (index ===2) return _this.state.karteikarte.beschriftung_3_pfeil
            if (index ===3) return _this.state.karteikarte.beschriftung_4_pfeil
            if (index ===4) return _this.state.karteikarte.beschriftung_5_pfeil
            if (index ==5) return _this.state.karteikarte.beschriftung_6_pfeil
            if (index ===6) return _this.state.karteikarte.beschriftung_7_pfeil
            if (index ===7) return _this.state.karteikarte.beschriftung_8_pfeil
            if (index ===8) return _this.state.karteikarte.beschriftung_9_pfeil
            if (index ===9) return _this.state.karteikarte.beschriftung_10_pfeil  

            return 1
        }
        function getCanShow(index){
            if (index===0)return _this.state.karteikarte.beschriftung_1 !==""
            if (index===1)return _this.state.karteikarte.beschriftung_2 !==""
            if (index===2)return _this.state.karteikarte.beschriftung_3 !==""
            if (index===3)return _this.state.karteikarte.beschriftung_4 !==""
            if (index===4)return _this.state.karteikarte.beschriftung_5 !==""
            if (index===5)return _this.state.karteikarte.beschriftung_6 !==""
            if (index===6)return _this.state.karteikarte.beschriftung_7 !==""
            if (index===7)return _this.state.karteikarte.beschriftung_8 !==""
            if (index===8)return _this.state.karteikarte.beschriftung_9 !==""
            if (index===9)return _this.state.karteikarte.beschriftung_10 !==""
        }   
        
        function getBeschriftungPositon(index){
      
            let x = 0
            let y = 0

            if (index===0){x = _this.state.karteikarte.beschriftung_1_x; y = _this.state.karteikarte.beschriftung_1_y}
            if (index===1){x = _this.state.karteikarte.beschriftung_2_x; y = _this.state.karteikarte.beschriftung_2_y}
            if (index===2){x = _this.state.karteikarte.beschriftung_3_x; y = _this.state.karteikarte.beschriftung_3_y}
            if (index===3){x = _this.state.karteikarte.beschriftung_4_x; y = _this.state.karteikarte.beschriftung_4_y}
            if (index===4){x = _this.state.karteikarte.beschriftung_5_x; y = _this.state.karteikarte.beschriftung_5_y}
            if (index===5){x = _this.state.karteikarte.beschriftung_6_x; y = _this.state.karteikarte.beschriftung_6_y}
            if (index===6){x = _this.state.karteikarte.beschriftung_7_x; y = _this.state.karteikarte.beschriftung_7_y}
            if (index===7){x = _this.state.karteikarte.beschriftung_8_x; y = _this.state.karteikarte.beschriftung_8_y}
            if (index===8){x = _this.state.karteikarte.beschriftung_9_x; y = _this.state.karteikarte.beschriftung_9_y}
            if (index===9){x = _this.state.karteikarte.beschriftung_10_x; y = _this.state.karteikarte.beschriftung_10_y}

            return {
                x,
                y
            }
        } 
        
        
        function getKartenBeschriftung(index){
            if (index ===0) return _this.state.karteikarte.beschriftung_1
            if (index ===1) return _this.state.karteikarte.beschriftung_2
            if (index ===2) return _this.state.karteikarte.beschriftung_3
            if (index ===3) return _this.state.karteikarte.beschriftung_4
            if (index ===4) return _this.state.karteikarte.beschriftung_5
            if (index ===5) return _this.state.karteikarte.beschriftung_6
            if (index ===6) return _this.state.karteikarte.beschriftung_7
            if (index ===7) return _this.state.karteikarte.beschriftung_8
            if (index ===8) return _this.state.karteikarte.beschriftung_9
            if (index ===9) return _this.state.karteikarte.beschriftung_10    
        }

        let trBeschriftungsInputs = []
        let beschriftungen = []    

        let offset=0

        let div = document.getElementById("titelBildbeschriftung")
        if (div !==null){
            offset = div.style.offsetHeight
        }

    

        this.state.integerList.forEach(
            function(i){
                if (getCanShow(i))
                beschriftungen.push(
                        <Beschriftung                         
                            pfeil={getPfeilrichtung(i)}
                            nummer={i}  
                            mappingNummer={_this.state.mappingIntegerList[i]}
                            left={getBeschriftungPositon(i).x}
                            top={getBeschriftungPositon(i).y}
                            canDrag={false}
                            canShow={getCanShow(i)} 
                        /> 
                )

                const index = i           

                const placeholder = `Beschriftung ${i+1}`

                if (getCanShow(i)) {

                    let tr = (

                            <tr>
                                <td width={15}>{_this.state.mappingIntegerList[i]}=</td>
                                <td><input className="form-control" type="text"  onChange={(e)=>setBeschriftung(e,index)} value={getBeschriftung(index)} /></td>
                            </tr>
                    )

                    if (_this.state.karteikarte.beschriftung_antwort_nummern===1){
                        tr = (
                            <tr>
                                <td width="100"><input className="form-control" type="number" min={1} max={10} onChange={(e)=>setBeschriftungNummer(e,index)} value={getBeschriftungNummer(index)} /></td>
                                <td width="20" className="text-center">=</td>
                                <td><b>{getKartenBeschriftung(index)}</b></td>
                            </tr>
                        )
                    }


                        trBeschriftungsInputs.push(
                            tr
                        )
                }

            }
        )

    
        


        let beschriftungenTable=(
            <table className="table" style={{zIndex:'3000', cursor:'pointer', backgroundColor:'white'}}>
                <tbody>                        
                        {trBeschriftungsInputs}
                </tbody>
            </table>
                
        )

        let image=(
            <img draggable={false}  src={this.state.karteikarte.frage_videobild_url} width={700} />
        )
  

        if (this.state.karteikarte.frage_videobild_url===""){
            beschriftungenTable = null
            image = null
            beschriftungen = null
        }   


       
        

        return(
            
            <>
            <div  style={{position:'relative', width:'700px',}}>
                {image}            
                {beschriftungen}
            </div>  
                {beschriftungenTable}
             </>   

        )

    }

    konjugationPruefen(karteikarte){

        let antwortrichtig = true
        let selectedmodulteil = this.state.selectedmodulteil

        if (selectedmodulteil.konjug_ich !== karteikarte.konjug_ich) {antwortrichtig = false}
        if (selectedmodulteil.konjug_du !== karteikarte.konjug_du){antwortrichtig = false}
        if (selectedmodulteil.konjug_ersiees !== karteikarte.konjug_ersiees){antwortrichtig = false}
        if (selectedmodulteil.konjug_wir !== karteikarte.konjug_wir){antwortrichtig = false}
        if (selectedmodulteil.konjug_ihr !== karteikarte.konjug_ihr){antwortrichtig = false}
        if (selectedmodulteil.konjug_sie !== karteikarte.konjug_sie){antwortrichtig = false}

        
        if (antwortrichtig){                     
            this.saveAntwort(this.state.karteikartenIndex)   
        }

        this.setWettbewerbState(antwortrichtig)   

    }

    

    changeKonjug(e, pron){

        let selectedmodulteil = this.state.selectedmodulteil
        if (pron==="ich") selectedmodulteil.konjug_ich = e.target.value
        if (pron==="du") selectedmodulteil.konjug_du = e.target.value
        if (pron==="ersiees") selectedmodulteil.konjug_ersiees = e.target.value
        if (pron==="wir") selectedmodulteil.konjug_wir = e.target.value
        if (pron==="ihr") selectedmodulteil.konjug_ihr = e.target.value
        if (pron==="sie") selectedmodulteil.konjug_sie = e.target.value
        this.setState({selectedmodulteil})

      }


    getKonjugationstabelle(){
        let ich_form=getPronom('ich', this.state.karteikarte.konjug_sprache)
            let du_form=getPronom('du', this.state.karteikarte.konjug_sprache)
            let ersiees_form=getPronom('ersiees', this.state.karteikarte.konjug_sprache)
            let wir_form=getPronom('wir', this.state.karteikarte.konjug_sprache)
            let ihr_form=getPronom('ihr', this.state.karteikarte.konjug_sprache)
            let sie_form=getPronom('sie', this.state.karteikarte.konjug_sprache)

            return(
                <>
                   
                  
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <tbody>
                                <tr>
                                    <td style={{backgroundColor:'#e7e7e7'}}>{ich_form}</td><td></td><td><input type="text" autoCapitalize="off" className="form-control" value={this.state.selectedmodulteil.konjug_ich} onChange={(e)=>this.changeKonjug(e,"ich")} /> </td>
                                </tr>
                                <tr>
                                    <td style={{backgroundColor:'#e7e7e7'}}>{du_form}</td><td></td><td><input type="text" autoCapitalize="off" className="form-control" value={this.state.selectedmodulteil.konjug_du} onChange={(e)=>this.changeKonjug(e,"du")} /></td>
                                </tr>
                                <tr>
                                    <td style={{backgroundColor:'#e7e7e7'}}>{ersiees_form}</td><td></td><td><input type="text" autoCapitalize="off" className="form-control" value={this.state.selectedmodulteil.konjug_ersiees} onChange={(e)=>this.changeKonjug(e,"ersiees")} /></td>
                                </tr>
                                <tr>
                                    <td style={{backgroundColor:'#e7e7e7'}}>{wir_form}</td><td></td><td><input type="text" autoCapitalize="off" className="form-control" value={this.state.selectedmodulteil.konjug_wir} onChange={(e)=>this.changeKonjug(e,"wir")} /></td>
                                </tr>
                                <tr>
                                    <td style={{backgroundColor:'#e7e7e7'}}>{ihr_form}</td><td></td><td><input type="text" autoCapitalize="off" className="form-control" value={this.state.selectedmodulteil.konjug_ihr} onChange={(e)=>this.changeKonjug(e,"ihr")} /></td>
                                </tr>
                                <tr>
                                    <td style={{backgroundColor:'#e7e7e7'}}>{sie_form}</td><td></td><td><input type="text" autoCapitalize="off" className="form-control" value={this.state.selectedmodulteil.konjug_sie} onChange={(e)=>this.changeKonjug(e, "sie")} /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </>
            )
    }

    richtigeAntwortenPruefen(karteikarte){

        let richtig = true

        if (Number(this.state.antwort1_richtig) !==karteikarte.antwort1_richtig ) richtig=false

        if (Number(this.state.antwort2_richtig) !==karteikarte.antwort2_richtig ) richtig=false
     
        if (karteikarte.anzahl_antworten>2){
            if (Number(this.state.antwort3_richtig) !==karteikarte.antwort3_richtig ) richtig=false
        }
        if (karteikarte.anzahl_antworten>3){
            if (Number(this.state.antwort4_richtig) !==karteikarte.antwort4_richtig ) richtig=false
        }
        if (karteikarte.anzahl_antworten>4){
            if (Number(this.state.antwort5_richtig) !==karteikarte.antwort5_richtig ) richtig=false
        }

        if (richtig){                     
            this.saveAntwort(this.state.karteikartenIndex)   
        }

        this.setWettbewerbState(richtig)   

    }

    checkAntwort(e, num){

        if (num===1){
            this.setState({antwort1_richtig: e.target.checked})

        }
        if (num===2){
            this.setState({antwort2_richtig: e.target.checked})
        }
        if (num===3){
            this.setState({antwort3_richtig: e.target.checked})
        }

        if (num===4){
            this.setState({antwort4_richtig: e.target.checked})
        }
        if (num===5){
            this.setState({antwort5_richtig: e.target.checked})
        }
    }

    getKarteikarteAntwort() {
        const karteikarte = this.state.karteikarte
       
        let antwort = karteikarte.antwort1

        if (antwort ===null) return ""

        if (karteikarte.multiplechoice===1){   

            let lis = [];
            for (var i=1;i<=karteikarte.anzahl_antworten;i++){   

                if(i===1 && karteikarte.antwort1_richtig===1 )lis.push(<li><b>{karteikarte.antwort1}</b></li>)
                if(i===2 && karteikarte.antwort2_richtig===1 )lis.push(<li><b>{karteikarte.antwort2}</b></li>)
                if(i===3 && karteikarte.antwort3_richtig===1 )lis.push(<li><b>{karteikarte.antwort3}</b></li>)
                if(i===4 && karteikarte.antwort4_richtig===1 )lis.push(<li><b>{karteikarte.antwort4}</b></li>)
                if(i===5 && karteikarte.antwort5_richtig===1 )lis.push(<li><b>{karteikarte.antwort5}</b></li>)

            }
            return <ul style={{marginTop:'25px', fontSize:'15px'}}>{lis}</ul>           

        }          

        try{             
            return antwort
        }  
        catch(err){
            return antwort + "FEHLER IM HTML-CODE!!!"
        }
    }


   clickAnsweroption(antwort, karteikarte){    

    let richtig = false
    

    if (antwort.number === 1 && karteikarte.antwort1_richtig===1){
            richtig = true          
    } 
    if (antwort.number === 2 && karteikarte.antwort2_richtig===1){
        richtig = true          
    } 
    if (antwort.number === 3 && karteikarte.antwort3_richtig===1){
        richtig = true          
           
    } 
    if (antwort.number === 4 && karteikarte.antwort4_richtig===1){
        richtig = true          
    } 
    if (antwort.number === 5 && karteikarte.antwort5_richtig===1){
        richtig = true          
    } 

    if (richtig){
                     
        this.saveAntwort(this.state.karteikartenIndex)   
    }

    this.setWettbewerbState(richtig)
     

}


    getButtonsOrCheckGroup(){    
      
        let antwortArray = this.state.antwortArray        
        if (antwortArray===undefined) antwortArray = this.getAntwortArray(this.state.karteikarte)

        return (
            <RadioButtons
              karteikarte={this.state.karteikarte}
              antwortArray={antwortArray}
              clickAnsweroption={this.clickAnsweroption}
              checkAntwort={this.checkAntwort}
            />
        ) 
    }

    getAntwortArray(karteikarte){

        if (karteikarte===null) return null

        if (karteikarte.multiplechoice===0) return []

        //Zufallsreihenfolgen

        let antwortArray = []

        for (var i=1; i<=karteikarte.anzahl_antworten;i++){
            let antwort = karteikarte.antwort1
            if (i===2) antwort = karteikarte.antwort2
            if (i===3) antwort = karteikarte.antwort3
            if (i===4) antwort = karteikarte.antwort4
            if (i===5) antwort = karteikarte.antwort5
            antwortArray.push(
                {
                    name:`antwort${i}`,
                    antwort,
                    number:i
                }
            )
        }
       
        shuffleArray(antwortArray)

        this.setState({antwortArray})

        return antwortArray
    }



    alertMessageDialog() {
        return (
            <Modal
                modalid="alertMessageDialog"
                buttonid="alertMessageDialogButton"
                title="Schoolflashcards"
                body={this.state.alertMessage}
                okButtonTitle="OK"
                okButtonenabled={true} />
        )
    }


    alertMessage(message) {

        this.setState({ alertMessage: message }, function () { $('#alertMessageDialogButton').click() })

    }

    saveAntwort(index){
        this.setState({anzahlrichtige:this.state.anzahlrichtige + 1})
        const sql = `UPDATE sfc_schueler_in_wettbewerben SET position=${index+1} WHERE wettbewerb_id=${this.state.wettbewerbMitSchuelern.wettbewerb.id} AND benutzer_id=${this.props.activeUser.id}`  
        const array={sql}
        axios.post('/api/execcommonquery', array)
        .then(
            result=>{             

            }
        )

    }

    clickOK(e){
        const karteikarte = this.state.karteikarte

        let richtig = false

        if (karteikarte.mussfrageschriftlichbeantworten===0 || karteikarte.mussfrageschriftlichbeantworten==1){  
            
            if (this.state.antwort === karteikarte.antwort1){
                richtig = true                
                this.saveAntwort(this.state.karteikartenIndex)   
            }
        }

        if (karteikarte.wahroderfalsch > -1){
            if (Number(e.target.value)===karteikarte.wahroderfalsch){
                richtig = true                
                this.saveAntwort(this.state.karteikartenIndex)   
            }
        }
        if (karteikarte.multiplechoice===1){
            // Ist an anderer Stelle gelöst
        }


        this.setWettbewerbState(richtig)


    }

    setWettbewerbState(richtig){
        if (!richtig) this.alertMessage('Falsch!')

        let karteikartenIndex = this.state.karteikartenIndex
        if (richtig)karteikartenIndex = this.state.karteikartenIndex +1   

        let erfuellt = this.state.karteikartenIndex === this.state.karteikarten.length-1    
        if (this.state.wettbewerb.modus>0){
            erfuellt = this.state.anzahlrichtige=== this.state.wettbewerb.anzahl_fragen_beantwortet-1
        }

        if (richtig && erfuellt    ){
            karteikartenIndex=this.state.karteikarten.length-1          
            const sql=`UPDATE sfc_wettbewerbe SET sieger_benutzer_id=${this.props.activeUser.id} WHERE id=${this.state.wettbewerb.id}`
            const array={sql}
            axios.post('/api/execcommonquery', array)
            let wettbewerb = this.state.wettbewerb
            wettbewerb.sieger_benutzer_id = this.props.activeUser.id
            wettbewerb.sieger_benutzername = this.props.activeUser.benutzername
            this.setState({wettbewerb, })        
        }    
        
        const karteikarte = this.state.karteikarten[karteikartenIndex]
       
        this.setState({
            karteikarte, 
            karteikartenIndex, 
            antwortArray:undefined, 
            antwort:'',
            radioChecked:true,
            antwort1_richtig:false,
            antwort2_richtig:false, 
            antwort3_richtig:false,
            antwort4_richtig:false,
            antwort5_richtig:false,
            selectedmodulteil:{
                id:0,
                unterrichtsmodule_id:this.state.wettbewerb.unterrichtsmodul_id,
                autor_id:0,
                frage:'',
                antwort1:'',
                antwort2:'',
                antwort3:'',
                antwort4:'',
                mussfrageschriftlichbeantworten:0,
                aktiv:1,
                notizen:'',
                zusatzinfos:'',
                frage_videobild_url:'',
                wahroderfalsch:0,
                antwort1_richtig:1,
                antwort2_richtig:0,
                antwort3_richtig:0,
                antwort4_richtig:0,
                antwort5_richtig:0,
                anzahl_antworten:4,  
                konjug_sprache:'',
                konjug_zeitform:'',
                konjug_ich:'',
                konjug_du:'',
                konjug_ersiees:'',
                konjug_wir:'',
                konjug_ihr:'',
                konjug_sie:'',
                ist_konjugation:0,
                konjug_verb:'' ,    
                ist_youtube_video:0,
                nur_audio_abspielen:0,
                youtube_video_id:'',
                youtube_video_startzeit:0,
                youtube_video_laufzeit:0,
                antwort_videobild_url:'',
                ist_vokabelaudio:0,
                ist_bildmitbeschriftung:0,
                beschriftung_1:'',
                beschriftung_2:'',
                beschriftung_3:'',
                beschriftung_4:'',
                beschriftung_5:'',
                beschriftung_6:'',
                beschriftung_7:'',
                beschriftung_8:'',
                beschriftung_9:'',
                beschriftung_10:'',
                beschriftung_1_pfeil:1,
                beschriftung_2_pfeil:1,
                beschriftung_3_pfeil:1,
                beschriftung_4_pfeil:1,
                beschriftung_5_pfeil:1,
                beschriftung_6_pfeil:1,
                beschriftung_7_pfeil:1,
                beschriftung_8_pfeil:1,
                beschriftung_9_pfeil:1,
                beschriftung_10_pfeil:1,
                beschriftung_1_x:0.0,
                beschriftung_1_y:0.0,
                beschriftung_2_x:60.0,
                beschriftung_2_y:0.0,
                beschriftung_3_x:120.0,
                beschriftung_3_y:0.0,
                beschriftung_4_x:160.0,
                beschriftung_4_y:0.0,
                beschriftung_5_x:200.0,
                beschriftung_5_y:0.0,
                beschriftung_6_x:240.0,
                beschriftung_6_y:0.0,
                beschriftung_7_x:280.0,
                beschriftung_7_y:0.0,
                beschriftung_8_x:320.0,
                beschriftung_8_y:0.0,
                beschriftung_9_x:360.0,
                beschriftung_9_y:0.0,
                beschriftung_10_x:400.0,
                beschriftung_10_y:0.0,
                beschriftung_antwort_nummern:0

            
            },
        
        
        
        })  
    }

    modalKarteikarte() {

        if (this.state.wettbewerb === null) return null

        if (this.state.wettbewerb.sieger_benutzer_id > 0 ) return null

        if(this.state.wettkampfGestartet === false) return null

        const body=this.getKarteikarte()
        const title = `Frage ${this.state.karteikartenIndex + 1} / ${this.state.karteikarten.length}`

        const imageSource = "/assets/img/karteikarte2.png"
        const url = `url(${imageSource})`

        let leftButton = <button className="btn btn-danger" onClick={()=>this.setState({karteikartenIndex:this.state.karteikartenIndex + 1, karteikarte:this.state.karteikarten[this.state.karteikartenIndex + 1]})}  >Nächste Frage</button>
       
        if (this.state.karteikartenIndex === this.state.karteikarten.length - 1) leftButton = null

        let okButtonTitle = "Prüfen"
        let okButton = <button className="btn btn-danger" onClick={this.clickOK}  >Prüfen</button>

        const karteikarte = this.state.karteikarte
        if (karteikarte.wahroderfalsch > -1)okButton = null
        if (karteikarte.multiplechoice===1 && getAnzahlRichtigeAntworten(karteikarte)==1) okButton = null
        if (karteikarte.multiplechoice===1 && getAnzahlRichtigeAntworten(karteikarte)>1)okButton = <button className="btn btn-danger" onClick={()=>this.richtigeAntwortenPruefen(karteikarte)}  >Prüfen</button>
        if (karteikarte.ist_konjugation===1)okButton = <button className="btn btn-danger" onClick={()=>this.konjugationPruefen(karteikarte)}  >Prüfen</button>
        if (karteikarte.ist_bildmitbeschriftung!==null && karteikarte.ist_bildmitbeschriftung===1)okButton =<button className="btn btn-danger" onClick={this.checkBeschriftungen}>Prüfen</button>
    
        if (this.state.wettbewerb.modus===0)leftButton = null

        return (
            <div style={{textAlign:'center', width:'800px', backgroundImage:url, backgroundSize:'100%'}}>
                {body}
                <hr/>
                {leftButton} {okButton}

            </div>
        )
   }

   

   changeAntwort2(e){
    let antwort = clearString(e.target.value)
    this.setState({antwort: e.target.value})
    if (e.target.value===this.state.karteikarte.antwort1){
      //  this.setState({showSweetalert:true}) Kinder wollten das nicht
    }
}

   getKarteikarte(){

    const karteikarte = this.state.karteikarte
    
    let youTubePlayer = null 
    if (karteikarte.ist_youtube_video===1){
        youTubePlayer = (
            <YoutubeVideo
              karteikarte={karteikarte}
            />
        )  
     }    

     let frage = parse(karteikarte.frage)
     if (!is_Html(karteikarte.frage)){
        frage = <h2>{karteikarte.frage}</h2>
     }

    let content   
    let subcontent = null
    
  
    let imgContent = null
    if (karteikarte.frage_videobild_url !==""){
        imgContent=<div><img width="50%" className="img-fluid" src={karteikarte.frage_videobild_url} alt="" /></div>
    }

    if (karteikarte.mussfrageschriftlichbeantworten===0 || karteikarte.mussfrageschriftlichbeantworten==1){       
        subcontent = 
            <div className="table-responsive" style={{paddingLeft:"50px", paddingRight:"50px", backgroundColor:"white"}}>
                <table className="table">
                    <tbody>
                        <tr>
                            <td>
                                <div className="form-group" style={{textAlign:"center"}}>
                                    <label htmlFor="inputantwort2">Gib die Antwort hier ganz genau ein. Achte auf Gross- und Kleinschreibung.</label>
                                    <input type="text"  autoCapitalize="off" autoComplete="off" onChange={(e)=>this.setState({antwort: e.target.value})} className="form-control" id="inputantwort2" placeholder="Antwort" value={this.state.antwort} />
                                    
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>                    
    }

      if (karteikarte.multiplechoice===1){
        subcontent =this.getButtonsOrCheckGroup()                    
    }
    
    if (karteikarte.wahroderfalsch > -1){
        frage = <h2 className="card-title">Wahr oder falsch? <br /> <b>{frage}</b></h2> 
        subcontent = (
            <div>
                <button value={1} onClick={this.clickOK} className="btn btn-default btn round">Wahr</button> <button value={0} onClick={this.clickOK} className="btn btn-default btn round">Falsch</button>
            </div>
        )
    }

  
    let divKommentarMehrereAntworten = null
    if (getAnzahlRichtigeAntworten(karteikarte)> 1){
            divKommentarMehrereAntworten = <h5>(Eine Antwort oder mehrere Antworten möglich)</h5>
    }            

    let zusatzKonjugation=null
    if (karteikarte.ist_konjugation===1){
        frage = null     
        subcontent = null          
        zusatzKonjugation=(
            <>
            <h3 className="card-title">{getSpracheLang(karteikarte.konjug_sprache)} - {karteikarte.konjug_zeitform}</h3>
            <h4>Konjugiere das Verb <big><b>{karteikarte.konjug_verb}</b></big></h4>
            <div>
            {this.getKonjugationstabelle()}
            </div>
            </>
        )
    }

    if (karteikarte.ist_vokabelaudio===1){
        subcontent = null
       
        frage = (
            <div>
                <h3>Hör gut zu und schreibe richtig</h3>
                <iframe src={karteikarte.frage_videobild_url}></iframe>
                <hr/>
                <div className="form-group" style={{textAlign:"center"}}>
                                    <label htmlFor="inputantwort2">Gib die Antwort sorgfältig ein. Achte auf Gross- und Kleinschreibung.</label>
                                    <input type="text"  autoCapitalize="off" autoComplete="off" onChange={this.changeAntwort2} className="form-control" id="inputantwort2" placeholder="Antwort" value={this.state.antwort} />
                                                     
                </div>
            </div>
        )
    }



    if (karteikarte.ist_bildmitbeschriftung===1){
        subcontent = null
        zusatzKonjugation = null
        divKommentarMehrereAntworten = null
        imgContent = null
        youTubePlayer = null        
        let title = "Bild/Karte beschriften"
        if (frage !=="") title = frage
        frage = (
            <div>
                <h3>{title}</h3>
                {this.getSkizzenBeschriftungsTabelle()}
            </div>
        )
    }
   
  

    content= (
                <div style={{textAlign:"center"}}>                
                        {frage}
                        {zusatzKonjugation}
                        {divKommentarMehrereAntworten}
                        {imgContent}
                        {subcontent}  
                    <div >{youTubePlayer} </div>        
                </div>
    )
   

    return content

}

    getWettbewerb(){
        if ( this.state.wettbewerbMitSchuelern===null  ) return
       
        axios.get('/api/wettbewerbe/' +  this.state.wettbewerbMitSchuelern.wettbewerb.id)
        .then(
            result=>{
                if (result.data !== null){
                    this.setState({gestartet: result.data.wettbewerb.ist_gestartet, wettbewerb: result.data.wettbewerb, abschlussliste:result.data.abschlussliste, schueler_in_wettbewerben:result.data.schuelerImWettbewerbs})
                   console.log(result.data.abschlussliste)

                }
            }
        )
    }

    componentDidMount(){
        axios.get('/api/wettbewerbe/' + this.props.params.wettbewerbid)
        .then(
            result=>{
                if (result.data !== null){
                    console.log(result.data)
                    let karteikarten = result.data.unterrichtsmodulteile 
                    shuffleArray(karteikarten)
                    this.setState({unterrichtsModul: result.data.unterrichtsModul,   wettbewerbMitSchuelern: result.data, karteikarten, schueler_in_wettbewerben:result.data.schuelerImWettbewerbs, wettbewerb: result.data.wettbewerb, abschlussliste:result.data.abschlussliste})
                    console.log(karteikarten)
                }
            }
        )
    }


    starteWettbewerb(){
        const sql=`UPDATE sfc_wettbewerbe SET ist_gestartet=1 WHERE id=${this.state.wettbewerbMitSchuelern.wettbewerb.id}`
        const array = {sql}
        axios.post('/api/execcommonquery', array)
        .then(
            result=>{
                this.setState({gestartet: 1, canCheckWettbewerb:true})
            }
        )
    }

    losGehts(){
        let karteikartenIndex = 0
        const karteikarte = this.state.karteikarten[0]
        this.setState({karteikartenIndex, karteikarte, wettkampfGestartet:true, canCheckWettbewerb:false})
    }

    
    rennTabelle(){

        const _this = this

        function row(siw){

            var index = _this.state.abschlussliste.indexOf(siw)
            let imgsrc= `/assets/img/jockey_${siw.farbe}.png` 
            return(
                <tr>
                    <td className="text-center">{index + 1}</td>
                    <td className="text-center"><img src={imgsrc} width="60" /></td>
                    <td>{siw.benutzername}</td>
                    <td className="text-center">{siw.position}</td>
                </tr>
            )
        }

        const div = (
            <div className="table-responsive" style ={{backgroundColor:'white'}}>
                <table className="table-striped">
                    <thead>
                        <tr>
                            <th>Rang</th>
                            <th></th>
                            <th>Jockey</th>
                            <th>Punkte</th>
                        </tr>
                    </thead>
                    <tbody>
                        {_this.state.abschlussliste.map(row)}
                    </tbody>
                </table>
            </div>
        )

        return(
            
                <div  style={{ backgroundColor:'white'}}>                   
                    <h3 style={{color:'white'}} className="card-title"> Abschlusstabelle </h3>
                    {div}
                </div>
        )

    }


    render(){

        if (this.state.wettbewerbMitSchuelern === null) return <div></div>

        function convertToPx(value){
                return `${value}px`
        }   

        const faktor = 0.5
        const laengePferd = 360 * faktor
        const pferdeLaenge=convertToPx(laengePferd)
        const rennbahnLaenge = 2000 * faktor
        const rennbahnHoehe = 700 * faktor
        const abstandLinien = (rennbahnLaenge - laengePferd)/ (this.state.unterrichtsModul.anzahl_modulteile )
        const senkrechteLinienWidth = 2 * faktor
        const senkrechteLinienHeight = 560 * faktor
        const senkrechteLinienTop = 120 * faktor
        const nummernWidth = 42 * faktor
        const nummernHeight = 42 * faktor
        const nummernBorderRadius = 5 * faktor
        const nummernTop = 360 * faktor
        const imageWidth = 160 * faktor
        const imageFinishTop = 120 * faktor
        const zaunVorneTop = 600 * faktor
        
        let buttonStart = <button className="btn btn-primary" onClick={this.starteWettbewerb}>Starten</button>
        let info = <p style={{color:'white'}}>Du hast die Teilnehmer eingeladen und musst das Spiel nun freigeben.<br/>Klicke auf "Starten", damit alle das Spiel gleichzeitig beginnen können.<br/>Erkläre allen Teilnehmern, dass sie anschliessend einen LOS!!!-Button sehen werden, auf den alle, auch du, klicken müssen."</p>
        if (this.state.gestartet || this.props.activeUser.id !==this.state.wettbewerbMitSchuelern.wettbewerb.start_benutzer_id){
            buttonStart = null
            info = null
        }

        let buttonLOS = null
        if (this.state.gestartet === 1){     
            buttonLOS = <button className="btn btn-info" onClick={()=>this.losGehts()}>LOS!!!</button>
        }   

        let pferde=[]
        
        let senkrechteLinien = []
        let nummern = []         

        let imageFinish = null
       
        for (var i=0;i< this.state.unterrichtsModul.anzahl_modulteile + 1;i++){
            const left = laengePferd + i*abstandLinien 
            senkrechteLinien.push(
                <div style={{position:'absolute', width:convertToPx(senkrechteLinienWidth), height:convertToPx(senkrechteLinienHeight), backgroundColor:'white', left:left, top:convertToPx(senkrechteLinienTop)}} />
            )

            nummern.push(
                <div style={{position:'absolute', borderRadius:convertToPx(nummernBorderRadius),  textAlign:'center',  width:convertToPx(nummernWidth), height:convertToPx(nummernHeight), backgroundColor:'white', left:left-nummernWidth/2, top:convertToPx(nummernTop)}} >{i}</div>
            )

            
            
            const imgLeft = left // - abstandLinien/2 - imageWidth/2
            const imageWidthPx = `${imageWidth}px`
            const imgLeftPx = `${imgLeft}px`

            if (i===this.state.unterrichtsModul.anzahl_modulteile){
               
                imageFinish = <img src="/assets/img/finish.png" style={{position:'absolute', left:imgLeftPx,  width:imageWidthPx, top:convertToPx(imageFinishTop)}}/>

            }
        }

        i=0

        const _this = this

        this.state.schueler_in_wettbewerben.forEach(
            function(siw){
                //2 Spieler
                let topIndent = 130 * faktor
                let top = topIndent +  i*230 * faktor
                //3 Spieler
                if (_this.state.schueler_in_wettbewerben.length ===3){
                    topIndent = 90 * faktor
                    top = topIndent +  i*190 * faktor                    
                }
                //4 Spieler
                if (_this.state.schueler_in_wettbewerben.length ===4){
                    topIndent = 50 * faktor
                    top = topIndent +  i*140   * faktor                  
                }
                //5 Spieler
                if (_this.state.schueler_in_wettbewerben.length ===5){
                    topIndent = 40 * faktor
                    top = topIndent +  i*105   * faktor                  
                }
                //6 Spieler
                if (_this.state.schueler_in_wettbewerben.length ===6){
                    topIndent = 40 * faktor
                    top = topIndent +  i*85   * faktor                
                }


                const posTop=`${top}px`
                const imageSource = `/assets/img/jockey_${siw.farbe}.png`
                let left =   siw.position * abstandLinien
                if (siw.benutzer_id=== _this.state.wettbewerb.sieger_benutzer_id) {
                    left = rennbahnLaenge - laengePferd/2
                }
                const leftPos = `${left}px`
               
                pferde.push(
                    <img src={imageSource} style={{position:'absolute',width:pferdeLaenge, left:leftPos, top:posTop,}} />
                )
                i++

            }
        )

        const zaunVorne =  <img src="/assets/img/pferdekoppelvorne.png" style={{position:'absolute', left:'0px', width:convertToPx(rennbahnLaenge), top:convertToPx(zaunVorneTop)}}/>

        let closeLink =  <Link to="/user/wettbewerbe" className="btn btn-danger">Schliessen</Link>

        const imageSource = "/assets/img/rennbahn.png"
        const url = `url(${imageSource})`
        const urlKranz = `url("/assets/img/siegerkranz.png")`

        let contentRight = this.modalKarteikarte()
        if (this.state.wettbewerb.sieger_benutzer_id > 0){
            contentRight = <><center><h2 style={{color:'white'}} className="card-title">Wir gratulieren!</h2><h3 style={{color:'white'}} className="card-title">1. Platz</h3><div style={{textAlign:'center',fontSize:'30px',  verticalAlign:'middle',   color:'white', display:'table-cell',  width:'325px', height:'300px', backgroundImage:urlKranz, backgroundSize:'100%', backgroundRepeat:'no-repeat'}}>{this.state.wettbewerb.sieger_benutzername}</div></center></>
            buttonLOS = null
            buttonStart = null

        }

        let subTitle = <p>Alle {this.state.wettbewerbMitSchuelern.unterrichtsModul.anzahl_modulteile} Fragen müssen beantwortet werden</p>
        if (this.state.wettbewerb.modus > 0){
            subTitle = <p>Es müssen {this.state.wettbewerb.anzahl_fragen_beantwortet} von {this.state.wettbewerbMitSchuelern.unterrichtsModul.anzahl_modulteile} Fragen beantwortet werden</p>
        }
        
        return(
            <div  style={{padding:'30px', backgroundColor:'green', height:'100%'}}>
                <h3 style={{color:'white'}} className="card-title">Wettrennen {this.state.wettbewerbMitSchuelern.wettbewerb.modultitel} ({this.state.wettbewerbMitSchuelern.unterrichtsModul.anzahl_modulteile} Karten)</h3>
                <div style={{color:'white'}}>{subTitle}</div>
                <div>{closeLink} {buttonStart} {buttonLOS}</div>
                {info}
                <div>               
                    <div style={{position:'relative', width:convertToPx(rennbahnLaenge), height:convertToPx(rennbahnHoehe),backgroundSize:'100%',  backgroundImage:url,}}>
                        {senkrechteLinien}
                        {imageFinish}
                        {nummern}
                        {pferde}
                        {zaunVorne}
                    </div>                     
                </div>
                <table className="table">
                    <tbody>
                        <tr>
                            <td>{this.rennTabelle()}</td>
                            <td>{contentRight} </td>
                        </tr>
                    </tbody>
                </table>
                {this.alertMessageDialog()}
            </div>
            )
        }


       


}


function mapStateToProps ({ posts }) {
    return {
      activeUser: posts.userData,
    };
  };
  
  export default connect(mapStateToProps) (Wettbewerb);
  
import React, { Component } from 'react';
import { Link } from 'react-router';
import axios, { post }  from 'axios';
import browserHistory from 'react-router/lib/browserHistory';
import { connect } from 'react-redux';
import parse from 'html-react-parser';
import YoutubeVideo from '../components/youtubevideo.jsx';
import Modal from '../components/modal';
import $ from 'jquery';
import ReactToPrint from 'react-to-print';
import {getSpracheLang, getKonjugationsTable, getPronom, convertDateTime,timeConvert, convertDateShort} from '../components/generalfunctions.jsx'

class Pruefungen extends Component  {

    constructor(props)  {
        super(props);

        this.state=(
            {            
            
              pruefung:null,
              pruefungsteile:[]  ,
              karteikarte:null, 
              gesamtpunktezahl:0,
              pruefungsmodus:'vorbereiten',
              schuelerinpruefungen:[],
              activeSchuelerInPruefung:null,
              schueler_in_pruefungsteilen:[],
              startbuttongedrueckt:false,
              abgebenbuttongedrueckt: false,
              allesueberprueft: false,
              quizbeendet: false,
              printWindowButton:(
                  <div className="align-right noprint"><ReactToPrint
                      trigger={() =>  <button className="btn btn-primary align-right noprint"> < i className="material-icons"> print </i> Drucken</button>}
                      content={() => this.componentRef}
                      //pageStyle ="fake, damits schwarzweiss druckt,sonst pagestyle rausschmeißen"
                  /></div>),
            }
      
        ) 
        this.pruefungsteileRow = this.pruefungsteileRow.bind(this)
        this.getFrage = this.getFrage.bind(this)
        this.modalYoutubeDialog = this.modalYoutubeDialog.bind(this)
        this.getLehrpersonAntwort = this.getLehrpersonAntwort.bind(this)
        this.changePunkte = this.changePunkte.bind(this)
        this.schuelerinpruefungenRow = this.schuelerinpruefungenRow.bind(this)
        this.schuelerNavigator = this.schuelerNavigator.bind(this)
        this.getSchuelerAntwort = this.getSchuelerAntwort.bind(this)
        this.saveSchuelerInPruefungsteil = this.saveSchuelerInPruefungsteil.bind(this)
        this.resetSchuelerInPruefungsteilen = this.resetSchuelerInPruefungsteilen.bind(this)
        this.starteFormular = this.starteFormular.bind(this)
        this.beendeFormular = this.beendeFormular.bind(this)
        this.modalAbgebenDialog = this.modalAbgebenDialog.bind(this)
        this.setPunkteValue = this.setPunkteValue.bind(this)
        this.getGesamtpunkte = this.getGesamtpunkte.bind(this)
        this.contentSchuelerAntwortZumKorrigieren =this.contentSchuelerAntwortZumKorrigieren.bind(this)
        this.setKommentar = this.setKommentar.bind(this)
        this.saveSchuelerInPruefung = this.saveSchuelerInPruefung.bind(this)
        this.checkNews = this.checkNews.bind(this)

        setInterval(this.checkNews, 30 * 1000)
    }



    checkNews() {
        this.getSchuelerinpruefungen()
        
    }

    modalAbgebenDialog(){

        const okButtonenabled = this.state.allesueberprueft===true

        const body = (
            <div>
                <h3>Möchtest du das Quiz beenden und deine Arbeit jetzt abgeben?</h3>
                
                <div className="togglebutton">
                    <label>
                        <input type="checkbox"  onChange={()=>this.setState({allesueberprueft:true}) } / >
                        <span className="toggle"></span>
                        ich habe alles überprüft und möchte jetzt abgeben
                    </label>
                </div>

            </div>
        )
        return (
            <Modal
                modalid="modalAbgebenDialog"
                buttonid="modalAbgebenDialogButton"
                body={body}
                title="Quiz beenden"
                okButtonTitle="Quiz jetzt beenden"
                okButtonClick={this.beendeFormular}
                okButtonenabled = {okButtonenabled}
                cancelButton={true}
            />
        )
    }

    saveSchuelerInPruefung(e){
      
        let activeSchuelerInPruefung = this.state.activeSchuelerInPruefung
        let sql
        if (e.target.name==="note"){
            activeSchuelerInPruefung.note = e.target.value
            sql = `UPDATE sfc_schueler_in_pruefungen SET note='${e.target.value}' WHERE schueler_id=${activeSchuelerInPruefung.schueler_id} AND pruefung_id=${this.state.pruefung.id}`
        }
        if (e.target.name==="kommentar"){
            activeSchuelerInPruefung.kommentar_lehrperson = e.target.value
            sql = `UPDATE sfc_schueler_in_pruefungen SET kommentar_lehrperson='${e.target.value}' WHERE schueler_id=${activeSchuelerInPruefung.schueler_id} AND pruefung_id=${this.state.pruefung.id}`
        }
        if (e.target.name==="korrigiert"){

            activeSchuelerInPruefung.status="korrigiert"
            sql = `UPDATE sfc_schueler_in_pruefungen SET status='korrigiert', korrigiert_am=now() WHERE schueler_id=${activeSchuelerInPruefung.schueler_id} AND pruefung_id=${this.state.pruefung.id}`
        }

       
        this.setState({activeSchuelerInPruefung})
        let array = {sql}
        axios.post('/api/execcommonquery/',array)
    }

    beendeFormular(){
       
       const sql = `UPDATE sfc_schueler_in_pruefungen SET endezeit = now(), status='abgegeben' WHERE schueler_id=${this.props.activeUser.id} AND pruefung_id=${this.state.pruefung.id}`
       const array ={sql}
       axios.post('/api/execcommonquery', array)
       .then(
        result=>{
            axios.post('/api/pruefungen/schuelerinpruefungsteilen', this.state.schueler_in_pruefungsteilen)
            .then(
                result=>{
                    this.setState({endezeit: new Date(), quizbeendet:true})
                }
            )
        }
       )
       

    }

    starteFormular(){

       
        const sql = `UPDATE sfc_schueler_in_pruefungen SET startzeit = now(), status='in Bearbeitung' WHERE schueler_id=${this.props.activeUser.id} AND pruefung_id=${this.state.pruefung.id}`
        const array ={sql}
        axios.post('/api/execcommonquery', array)
        this.setState({startzeit: new Date(), startbuttongedrueckt:true})
    }

    resetSchuelerInPruefungsteilen(schuelerinpruefung){

      
        const _this = this
        axios.get('/api/pruefungen/schuelerinpruefungsteilen/' + this.state.pruefung.id + '/' + schuelerinpruefung.schueler_id)
        .then(
            result=>{
                this.setState({ activeSchuelerInPruefung:schuelerinpruefung, schueler_in_pruefungsteilen: result.data}, function (){_this.setState({punktedesschuelers:_this.getGesamtpunkte()})})
               
            }
        )

    }

    schuelerinpruefungenRow(schuelerinpruefung){

        let startzeit = null
        let endezeit = null
        let punkte =null
        let minuten = null
        let status = schuelerinpruefung.status

        if (schuelerinpruefung.status !==null){

            startzeit = schuelerinpruefung.startzeit
            endezeit = schuelerinpruefung.endezeit  
            minuten =  (schuelerinpruefung.endezeit -     schuelerinpruefung.startzeit) / 1000   / 60
            minuten = timeConvert(minuten)
           
            
            if (schuelerinpruefung.status === "korrigiert"){
                status = "korrigiert am " +  convertDateShort(schuelerinpruefung.korrigiert_am)
            
            }

        }

        const _this = this

        return(
            <tr>
                <td><a href="#" onClick={()=>this.setState({activeSchuelerInPruefung:schuelerinpruefung, pruefungsmodus:'korrigieren'}, function(){_this.resetSchuelerInPruefungsteilen(schuelerinpruefung)})} >{schuelerinpruefung.benutzername}<br/>{schuelerinpruefung.namevoll}</a></td>
                <td>{status}</td>
                <td>{convertDateTime(startzeit)}</td>
                <td>{convertDateTime(endezeit)}</td>
                <td>{minuten}</td>
                <td>{schuelerinpruefung.punkte}</td>
            </tr>
        )


    }


    schuelerNavigator(){
     
        const _this = this

        const index = this.state.schuelerinpruefungen.indexOf(this.state.activeSchuelerInPruefung)

        let buttonfirst=<button className="btn btn-sm" onClick={()=>this.resetSchuelerInPruefungsteilen(this.state.schuelerinpruefungen[0])}><i className="material-icons">first_page</i></button>
        let buttonlast=<button className="btn btn-sm" onClick={()=>this.resetSchuelerInPruefungsteilen(this.state.schuelerinpruefungen[this.state.schuelerinpruefungen.length -1])}><i className="material-icons">last_page</i></button>
        let buttonnext=<button className="btn btn-sm" onClick={()=>this.resetSchuelerInPruefungsteilen(this.state.schuelerinpruefungen[index + 1])}><i className="material-icons">navigate_next</i></button>
        let buttonprior=<button className="btn btn-sm" onClick={()=>this.resetSchuelerInPruefungsteilen(this.state.schuelerinpruefungen[index - 1])}><i className="material-icons">navigate_before</i></button>

         
        if (index<1){
            buttonfirst=<button className="btn btn-sm" disabled><i className="material-icons">first_page</i></button>
            buttonprior=<button className="btn btn-sm" disabled><i className="material-icons">navigate_before</i></button>
        }
        if (index===this.state.schuelerinpruefungen.length -1){
            buttonlast=<button className="btn btn-sm" disabled><i className="material-icons">last_page</i></button>
            buttonnext=<button className="btn btn-sm" disabled><i className="material-icons">navigate_next</i></button>
        }


        function mapSchueler(schueler){
              return(
                   <a className="dropdown-item" href="#" onClick={()=>_this.resetSchuelerInPruefungsteilen(schueler)}>{schueler.benutzername}</a>
              )
          }
    

        let dropdown =(
            <div className="dropdown" >
                <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {this.state.activeSchuelerInPruefung.benutzername}
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    {this.state.schuelerinpruefungen.map(mapSchueler)}
                </div>
            </div>
        )

     

        return(
            <div className="noprint" style={{display:'flex'}}>
                {buttonfirst}  {buttonprior} {dropdown} {buttonnext}  {buttonlast}
            </div>
            
        )

    }

    getSchuelerinpruefungen(){
        if (this.state.pruefungsmodus !=="ueberblick") return

        axios.get('/api/pruefungen/schuelerinpruefungen/' +  this.props.params.pruefungid)
        .then(
            result=>{
                if (result !==null){  this.setState({schuelerinpruefungen:result.data})
                }
            }
        )
        
    }

    componentWillMount(){

        const _this = this

        axios.get('/api/pruefungen/id/' +  this.props.params.pruefungid)
        .then(
            result=>{
                if (result !==null){
                    axios.get('/api/pruefungen/pruefungsteile/' +  this.props.params.pruefungid)
                    .then(

                        result2=>{
                            if (result.data !==0){                               
                                axios.get('/api/pruefungen/schuelerinpruefungen/' +  this.props.params.pruefungid)
                                        .then(
                                            result3=>{
                                                if (result3.data!==null){  

                                                    let activeSchuelerInPruefung =result3.data[0]                                                   

                                                        result3.data.forEach(
                                                            function(sip){
                                                                if (_this.props.activeUser !== undefined && sip.schueler_id===_this.props.activeUser.id){
                                                                    activeSchuelerInPruefung = sip                                                                   
                                                                }
                                                            }
                                                        )    
                                                        let formularausfuellen = true
                                                        if (this.props.activeUser!==undefined && this.props.activeUser.ist_schueler===1){
                                                            if ( activeSchuelerInPruefung.status ==="abgegeben"  || activeSchuelerInPruefung.status==="korrigiert"){
                                                                formularausfuellen = false
                                                        }
                                                    

                                                    }  


                                                

                                                    this.setState({pruefung: result.data,gesamtpunktezahl:result.data.gesamtpunktezahl, pruefungsteile: result2.data,schuelerinpruefungen: result3.data,formularausfuellen,  },
                                                        function(){_this.resetSchuelerInPruefungsteilen(activeSchuelerInPruefung)})
                                                   
                                                }
                                            }
                                        )                               
                            }
                        }
                    )                        
                }
            }
        )
        
    }

    

    modalYoutubeDialog() {

        let karteikarte = null
        let title = null

        if (this.state.karteikarte !==null){
            karteikarte=this.state.karteikarte
            title=karteikarte.frage
        }


        let body=(
            <YoutubeVideo
            karteikarte={karteikarte}
          />
        )

        return (
            <Modal
                modalid="modalYoutubeDialog"
                buttonid="modalYoutubeDialogButton"
                body={body}
                title={title}
                okButtonTitle="OK"
                okButtonClick={()=>this.setState({karteikarte:null})}
                cancelButton={true}
                cancelButtonClick={()=>this.setState({karteikarte:null})}
            />
        )
    }

   getAnzahlRichtigeAntworten(karteikarte){
            let anzahlrichtigeAntworten=0
            if (karteikarte.antwort1_richtig===1)anzahlrichtigeAntworten++
            if (karteikarte.antwort2_richtig===1)anzahlrichtigeAntworten++
            if (karteikarte.antwort3_richtig===1)anzahlrichtigeAntworten++
            if (karteikarte.antwort4_richtig===1)anzahlrichtigeAntworten++
            if (karteikarte.antwort5_richtig===1)anzahlrichtigeAntworten++
            return anzahlrichtigeAntworten
        }

    

    getFrage(karteikarte){

        if (karteikarte === null) return null
       
          
        function getButtonsOrCheckGroup(karteikarte)   {
            return null
        }  

        let frage = <b>{parse(karteikarte.frage)}</b>
        if (karteikarte.wahroderfalsch > -1){
            frage = <>Ist folgende Behauptung wahr oder falsch? <br/>{frage}</>
        }
        let zusatzKonjugation=null
        if (karteikarte.ist_konjugation===1){
            frage = null               
            zusatzKonjugation=(
                <>
                {getSpracheLang(karteikarte.konjug_sprache)} - {karteikarte.konjug_zeitform}
                <p>Konjugiere das Verb <b>{karteikarte.konjug_verb}</b></p>
                </>
            )
        }

        if (karteikarte.ist_vokabelaudio===1){
            frage=(
                <>
                  Hören und schreiben <br/>
                  <iframe src={karteikarte.frage_videobild_url}></iframe>
                </>
            )
        }

        if (karteikarte.ist_bildmitbeschriftung!==null && karteikarte.ist_bildmitbeschriftung===1){
            frage=(
                <>
                  Skizze beschriften <br/>
                 
                </>
            )
        }


        let divKommentarMehrereAntworten = null
        if (this.getAnzahlRichtigeAntworten(karteikarte)> 1){
            divKommentarMehrereAntworten = <p>(Eine Antwort oder mehrere Antworten möglich)</p>
        }    
        let imgContent = null
        let imgClass="img-fluid"
        if (this.props.activeUser.ist_schueler===0)imgClass=""

        if (karteikarte.frage_videobild_url !==""){
            imgContent=<div><img height={120} className={imgClass} src={karteikarte.frage_videobild_url} alt="" /></div>
        }
        let youTubePlayerButton = null 
        if (karteikarte.ist_youtube_video===1){
            youTubePlayerButton = <button className="btn btn-primary btn.fab btn-fab-mini btn-round" onClick={()=>this.setState({karteikarte}, function(){$('#modalYoutubeDialogButton').click()} )}> play_circle</button>                            
        } 
        

        return(
            <div>
                <i>Frage</i><p><b>{frage}</b></p>     
                    {zusatzKonjugation}
                    {divKommentarMehrereAntworten}
                    {imgContent}
                    <div>{youTubePlayerButton}</div>
            </div>
        )
         
    }

    saveSchuelerInPruefungsteil(e, pruefungsteil, value, value1, value2){

        const _this = this
        let schueler_in_pruefungsteil = null
        this.state.schueler_in_pruefungsteilen.forEach(
            function(sipt){
                if (sipt.pruefungsteile_id===pruefungsteil.id)
                schueler_in_pruefungsteil = sipt
            }
        )

        if (schueler_in_pruefungsteil ===null ) return
      
       
        if (e.target.name==="wahroderfalsch"){
            schueler_in_pruefungsteil.wahroderfalsch = e.target.value
        }
        if (e.target.name==="antwort"){
            schueler_in_pruefungsteil.antwort1 = e.target.value
        }
        if (value ==="check"){

            if (value2 ===1){

                schueler_in_pruefungsteil.antwort1=''
                schueler_in_pruefungsteil.antwort2=''
                schueler_in_pruefungsteil.antwort3=''
                schueler_in_pruefungsteil.antwort4=''
                schueler_in_pruefungsteil.antwort5=''
                if (value1==="antwort1")schueler_in_pruefungsteil.antwort1=e.target.value
                if (value1==="antwort2")schueler_in_pruefungsteil.antwort2=e.target.value
                if (value1==="antwort3")schueler_in_pruefungsteil.antwort3=e.target.value
                if (value1==="antwort4")schueler_in_pruefungsteil.antwort4=e.target.value
                if (value1==="antwort5")schueler_in_pruefungsteil.antwort5=e.target.value

            }

            if (value2 >1){
                if (value1==="antwort1"){
                    schueler_in_pruefungsteil.antwort1=''
                    if (e.target.checked){
                        schueler_in_pruefungsteil.antwort1 =e.target.value
                    }
                }
                if (value1==="antwort2"){
                    schueler_in_pruefungsteil.antwort2=''
                    if (e.target.checked){
                        schueler_in_pruefungsteil.antwort2 =e.target.value
                    }
                }
                if (value1==="antwort3"){
                    schueler_in_pruefungsteil.antwort3=''
                    if (e.target.checked){
                        schueler_in_pruefungsteil.antwort3 =e.target.value
                    }
                }
                if (value1==="antwort4"){
                    schueler_in_pruefungsteil.antwort4=''
                    if (e.target.checked){
                        schueler_in_pruefungsteil.antwort4 =e.target.value
                    }
                }
                if (value1==="antwort5"){
                    schueler_in_pruefungsteil.antwort5=''
                    if (e.target.checked){
                        schueler_in_pruefungsteil.antwort5 =e.target.value
                    }
                }
            }

        }




        if (e.target.name==="konjug_ich"){schueler_in_pruefungsteil.konjug_ich=e.target.value}
        if (e.target.name==="konjug_du"){schueler_in_pruefungsteil.konjug_du=e.target.value}
        if (e.target.name==="konjug_ersiees"){schueler_in_pruefungsteil.konjug_ersiees=e.target.value}
        if (e.target.name==="konjug_wir"){schueler_in_pruefungsteil.konjug_wir=e.target.value}
        if (e.target.name==="konjug_ihr"){schueler_in_pruefungsteil.konjug_ihr=e.target.value}
        if (e.target.name==="konjug_sie"){schueler_in_pruefungsteil.konjug_sie=e.target.value}


        
        this.setState({schueler_in_pruefungsteilen:this.state.schueler_in_pruefungsteilen})

     


    }

    contentSchuelerAntwortZumKorrigieren(pruefungsteil){

        

        let karteikarte = pruefungsteil.unterrichtsModulTeil
        if (karteikarte===null) return null
        const _this = this
        let schueler_in_pruefungsteil = null
        this.state.schueler_in_pruefungsteilen.forEach(
            function(sipt){
                if (sipt.pruefungsteile_id===pruefungsteil.id)
                schueler_in_pruefungsteil = sipt
            }
        )

        if (schueler_in_pruefungsteil ===null ) return null

        let antwort = <b>{schueler_in_pruefungsteil.antwort1}</b>    
        
        
        let antwortrichtig = true
        if (schueler_in_pruefungsteil.antwort1 !== karteikarte.antwort1)antwortrichtig=false


        if (karteikarte.wahroderfalsch > -1){

            antwortrichtig = true
            if (schueler_in_pruefungsteil.wahroderfalsch !==karteikarte.wahroderfalsch) antwortrichtig = false

            let wahrchecked = false
            if (schueler_in_pruefungsteil.wahroderfalsch===1)wahrchecked = true
            let falschchecked = false
            if (schueler_in_pruefungsteil.wahroderfalsch===0)falschchecked = true

            antwort=(
                <>
                Die Behauptung ist
                    <div className="form-check form-check-radio" style={{marginTop:'15px'}}>
                        <label className="form-check-label">
                            <input className="form-check-input" type="radio" disabled checked={wahrchecked}/>
                            wahr
                            <span className="circle">
                                <span className="check"></span>
                            </span>
                        </label>
                    </div>
                    <div className="form-check form-check-radio">
                        <label className="form-check-label">
                            <input className="form-check-input" type="radio" disabled checked={falschchecked}/>
                            falsch
                            <span className="circle">
                                <span className="check"></span>
                            </span>
                        </label>
                    </div>
                </>
            )
        }

        if (karteikarte.multiplechoice===1){ 
            let radios = []

            antwortrichtig = true

            for (var i=1;i<=karteikarte.anzahl_antworten;i++){  

                let type= "radio"
                let checkName =`exampleradio${karteikarte.id}`

                const anzahlRichtige = this.getAnzahlRichtigeAntworten(karteikarte)            

                if (anzahlRichtige> 1){
                    type= "checkbox"
                    checkName = `exampleradio${karteikarte.id}${i}`
                }
            
                let antwort
                let checked=false
                if (i===1){
                    antwort = karteikarte.antwort1
                    checked=schueler_in_pruefungsteil.antwort1===karteikarte.antwort1

                    if (karteikarte.antwort1_richtig===1 && schueler_in_pruefungsteil.antwort1 !== karteikarte.antwort1)antwortrichtig=false

                }
                if (i===2){
                    antwort = karteikarte.antwort2     
                    checked=schueler_in_pruefungsteil.antwort2===karteikarte.antwort2     
                    if (karteikarte.antwort2_richtig===1 && schueler_in_pruefungsteil.antwort2 !== karteikarte.antwort2)antwortrichtig=false         
                }
                if (i===3){
                    antwort = karteikarte.antwort3
                    checked=schueler_in_pruefungsteil.antwort3===karteikarte.antwort3
                    if (karteikarte.antwort3_richtig===1 && schueler_in_pruefungsteil.antwort3 !== karteikarte.antwort3)antwortrichtig=false
                }
                if (i===4){
                    antwort = karteikarte.antwort4
                    checked=schueler_in_pruefungsteil.antwort4===karteikarte.antwort4
                    if (karteikarte.antwort4_richtig===1 && schueler_in_pruefungsteil.antwort4 !== karteikarte.antwort4)antwortrichtig=false
                }
                if (i===5){
                    antwort = karteikarte.antwort5
                    checked=schueler_in_pruefungsteil.antwort5===karteikarte.antwort5
                    if (karteikarte.antwort5_richtig===1 && schueler_in_pruefungsteil.antwort5 !== karteikarte.antwort5)antwortrichtig=false
                }

                let value = `antwort${i}`
            

            
                radios.push(
                    <div className="form-check form-check-radio">
                        <label className="form-check-label">
                            <input className="form-check-input" type={type} disabled checked={checked}/>
                            {antwort}
                            <span className="circle">
                                <span className="check"></span>
                            </span>
                        </label>
                    </div>
                )
            }
            antwort = <div style={{marginTop:'10px'}}>{radios}  </div>
        }

        if (karteikarte.ist_konjugation===1){ 

            antwortrichtig = true
            
            let ich_form=getPronom('ich', karteikarte.konjug_sprache)
            let du_form=getPronom('du', karteikarte.konjug_sprache)
            let ersiees_form=getPronom('ersiees', karteikarte.konjug_sprache)
            let wir_form=getPronom('wir', karteikarte.konjug_sprache)
            let ihr_form=getPronom('ihr', karteikarte.konjug_sprache)
            let sie_form=getPronom('sie', karteikarte.konjug_sprache)   


            let color_ich = 'black'
            let color_du = 'black'
            let color_ersiees = 'black'
            let color_wir = 'black'
            let color_ihr = 'black'
            let color_sie = 'black'
            
            if (schueler_in_pruefungsteil.konjug_ich !== karteikarte.konjug_ich) {antwortrichtig = false;color_ich='red'}
            if (schueler_in_pruefungsteil.konjug_du !== karteikarte.konjug_du){antwortrichtig = false;color_du='red'}
            if (schueler_in_pruefungsteil.konjug_ersiees !== karteikarte.konjug_ersiees){antwortrichtig = false;color_ersiees='red'}
            if (schueler_in_pruefungsteil.konjug_wir !== karteikarte.konjug_wir){antwortrichtig = false;color_wir='red'}
            if (schueler_in_pruefungsteil.konjug_ihr !== karteikarte.konjug_ihr){antwortrichtig = false;color_ihr='red'}
            if (schueler_in_pruefungsteil.konjug_sie !== karteikarte.konjug_sie){antwortrichtig = false;color_sie='red'}

            antwort =   <div className="table-responsive">
                    <table className="table table-striped">
                        <tbody>
                            <tr>
                                <td style={{backgroundColor:'#e7e7e7', width:'33%'}}>{ich_form}</td><td style={{backgroundColor:'white', color:color_ich}}>{schueler_in_pruefungsteil.konjug_ich}</td>
                            </tr>
                            <tr>
                                <td style={{backgroundColor:'#e7e7e7'}}>{du_form}</td><td style={{backgroundColor:'white', color:color_du}}>{schueler_in_pruefungsteil.konjug_du}</td>
                            </tr>
                            <tr>
                                <td style={{backgroundColor:'#e7e7e7'}}>{ersiees_form}</td><td style={{backgroundColor:'white', color:color_ersiees}}>{schueler_in_pruefungsteil.konjug_ersiees}</td>
                            </tr>
                            <tr>
                                <td style={{backgroundColor:'#e7e7e7'}}>{wir_form}</td><td style={{backgroundColor:'white', color:color_wir}}>{schueler_in_pruefungsteil.konjug_wir}</td>
                            </tr>
                            <tr>
                                <td style={{backgroundColor:'#e7e7e7'}}>{ihr_form}</td><td style={{backgroundColor:'white', color:color_ihr}}>{schueler_in_pruefungsteil.konjug_ihr}</td>
                            </tr>
                            <tr>
                                <td style={{backgroundColor:'#e7e7e7'}}>{sie_form}</td><td style={{backgroundColor:'white', color:color_sie}}>{schueler_in_pruefungsteil.konjug_sie}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
        }

        let color = '#63e83a'
        if (antwortrichtig===false){
            color = '#f23a77'
        }

        

        const inputKommentar =  <><br/><input type="text" key={schueler_in_pruefungsteil.id}  className="form-control" value={schueler_in_pruefungsteil.kommentar_lehrperson} onChange={(e)=>this.setKommentar(pruefungsteil, e)} /></>

        return(
            <>
            <div style={{backgroundColor: color}}><i>Antwort</i></div>
            <div>{antwort} {inputKommentar}</div>            
            </>
        )
    }

    getSchuelerAntwort(pruefungsteil){

        let karteikarte = pruefungsteil.unterrichtsModulTeil
        if (karteikarte===null || karteikarte === undefined ) return null

        let antwort = (
                <div className="form-group" style={{textAlign:"center"}}>                   
                    <textarea rows={3} autoCapitalize="off" autoComplete="off" name="antwort" onChange={(e)=>this.saveSchuelerInPruefungsteil(e, pruefungsteil)} className="form-control" id="inputantwort" placeholder="Antwort" value={this.state.antwort} />  
                </div>
                )

        let wahrOderFalsch=(
            <>
            Die Behauptung ist
                <div className="form-check form-check-radio" style={{marginTop:'15px'}}>
                    <label className="form-check-label">
                        <input className="form-check-input" type="radio" name="wahroderfalsch" value={1} onChange={(e)=>this.saveSchuelerInPruefungsteil(e, pruefungsteil)}/>
                        wahr
                        <span className="circle">
                            <span className="check"></span>
                        </span>
                    </label>
                </div>
                <div className="form-check form-check-radio">
                    <label className="form-check-label">
                        <input className="form-check-input" type="radio" name="wahroderfalsch" value={0} onChange={(e)=>this.saveSchuelerInPruefungsteil(e, pruefungsteil)}/>
                        falsch
                        <span className="circle">
                            <span className="check"></span>
                        </span>
                    </label>
                </div>
            </>
        )

      
       
             
     

        if (karteikarte.wahroderfalsch > -1){
            antwort = wahrOderFalsch
        }

        if (karteikarte.multiplechoice===1){ 
            let radios = []
            for (var i=1;i<=karteikarte.anzahl_antworten;i++){  

                let type= "radio"
                let checkName =`exampleradio${karteikarte.id}`

                const anzahlRichtige = this.getAnzahlRichtigeAntworten(karteikarte)
               

                if (anzahlRichtige> 1){
                    type= "checkbox"
                    checkName = `exampleradio${karteikarte.id}${i}`
                }
               
                let antwort
                if (i===1){
                    antwort = karteikarte.antwort1
                }
                if (i===2){
                    antwort = karteikarte.antwort2                   
                }
                if (i===3){
                    antwort = karteikarte.antwort3
                }
                if (i===4){
                    antwort = karteikarte.antwort4
                }
                if (i===5){
                    antwort = karteikarte.antwort5
                }

                let value = `antwort${i}`
              

               
                radios.push(
                    <div className="form-check form-check-radio">
                        <label className="form-check-label">
                            <input className="form-check-input" type={type} name={checkName} value={antwort} onChange={(e)=>this.saveSchuelerInPruefungsteil(e, pruefungsteil,"check", value,anzahlRichtige)}/>
                            {antwort}
                            <span className="circle">
                                <span className="check"></span>
                            </span>
                        </label>
                    </div>
                )
            }
            antwort = <div style={{marginTop:'10px'}}>{radios}  </div>
        }

        if (karteikarte.ist_konjugation===1){ 
            
            let ich_form=getPronom('ich', karteikarte.konjug_sprache)
            let du_form=getPronom('du', karteikarte.konjug_sprache)
            let ersiees_form=getPronom('ersiees', karteikarte.konjug_sprache)
            let wir_form=getPronom('wir', karteikarte.konjug_sprache)
            let ihr_form=getPronom('ihr', karteikarte.konjug_sprache)
            let sie_form=getPronom('sie', karteikarte.konjug_sprache)

            let konjug_ich = <input type="text" className="form-control" name="konjug_ich" onChange={(e)=>this.saveSchuelerInPruefungsteil(e, pruefungsteil)} />
            let konjug_du = <input type="text" className="form-control" name="konjug_du" onChange={(e)=>this.saveSchuelerInPruefungsteil(e, pruefungsteil)} />
            let konjug_ersiees = <input type="text" className="form-control" name="konjug_ersiees" onChange={(e)=>this.saveSchuelerInPruefungsteil(e, pruefungsteil)} />
            let konjug_wir = <input type="text" className="form-control" name="konjug_wir" onChange={(e)=>this.saveSchuelerInPruefungsteil(e, pruefungsteil)} />
            let konjug_ihr = <input type="text" className="form-control" name="konjug_ihr" onChange={(e)=>this.saveSchuelerInPruefungsteil(e, pruefungsteil)} />
            let konjug_sie = <input type="text" className="form-control" name="konjug_sie" onChange={(e)=>this.saveSchuelerInPruefungsteil(e, pruefungsteil)} />

            antwort =   <div className="table-responsive">
                    <table className="table table-striped">
                        <tbody>
                            <tr>
                                <td style={{backgroundColor:'#e7e7e7', width:'33%'}}>{ich_form}</td><td style={{backgroundColor:'white'}}>{konjug_ich}</td>
                            </tr>
                            <tr>
                                <td style={{backgroundColor:'#e7e7e7'}}>{du_form}</td><td style={{backgroundColor:'white'}}>{konjug_du}</td>
                            </tr>
                            <tr>
                                <td style={{backgroundColor:'#e7e7e7'}}>{ersiees_form}</td><td style={{backgroundColor:'white'}}>{konjug_ersiees}</td>
                            </tr>
                            <tr>
                                <td style={{backgroundColor:'#e7e7e7'}}>{wir_form}</td><td style={{backgroundColor:'white'}}>{konjug_wir}</td>
                            </tr>
                            <tr>
                                <td style={{backgroundColor:'#e7e7e7'}}>{ihr_form}</td><td style={{backgroundColor:'white'}}>{konjug_ihr}</td>
                            </tr>
                            <tr>
                                <td style={{backgroundColor:'#e7e7e7'}}>{sie_form}</td><td style={{backgroundColor:'white'}}>{konjug_sie}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

        }

        return(
            <>
            <div><i>Antwort</i></div>
            <div>{antwort}</div>            
            </>
        )
    }

    getLehrpersonAntwort(karteikarte){

        if (karteikarte === null) return null

        let antwort = <p><b>{karteikarte.antwort1}</b></p>        

        let wahrOderFalsch=(
            <>
            Die Behauptung ist
                <div className="form-check form-check-radio" style={{marginTop:'15px'}}>
                    <label className="form-check-label">
                        <input className="form-check-input" type="radio" name="exampleRadios" disabled checked={karteikarte.wahroderfalsch===1}/>
                        wahr
                        <span className="circle">
                            <span className="check"></span>
                        </span>
                    </label>
                </div>
                <div className="form-check form-check-radio">
                    <label className="form-check-label">
                        <input className="form-check-input" type="radio" name="exampleRadios" disabled checked={karteikarte.wahroderfalsch===0}/>
                        falsch
                        <span className="circle">
                            <span className="check"></span>
                        </span>
                    </label>
                </div>
            </>
        )

      
        if (karteikarte.mussfrageschriftlichbeantworten===1){            
                antwort = <p><b>{karteikarte.antwort1}</b></p>           
        }
        if (karteikarte.wahroderfalsch > -1){
            antwort = wahrOderFalsch
        }

        if (karteikarte.multiplechoice===1){ 
         
            let radios = []
            for (var i=1;i<=karteikarte.anzahl_antworten;i++){  

                let checked =false
                let antwort
                let radiosname = `exampleradio${karteikarte.id}${i}`

                if (i===1){
                    antwort = karteikarte.antwort1
                    checked =karteikarte.antwort1_richtig===1
                }
                if (i===2){
                    antwort = karteikarte.antwort2  
                    checked =karteikarte.antwort2_richtig===1                      
                }
                if (i===3){
                    antwort = karteikarte.antwort3
                    checked =karteikarte.antwort3_richtig===1
                }
                if (i===4){
                    antwort = karteikarte.antwort4
                    checked =karteikarte.antwort4_richtig===1
                }
                if (i===5){
                    antwort = karteikarte.antwort5
                    checked =karteikarte.antwort5_richtig===1
                }
                radios.push(
                    <div className="form-check form-check-radio">
                        <label className="form-check-label">
                            <input className="form-check-input" type="radio" name={radiosname} disabled checked={checked}/>
                            {antwort}
                            <span className="circle">
                                <span className="check"></span>
                            </span>
                        </label>
                    </div>
                )
            }
            antwort = radios   
        }

        if (karteikarte.ist_konjugation===1){ 
            antwort = getKonjugationsTable(karteikarte)           
        }

        return(
            <>
            <div><i>Antwort</i></div>
            <div>{antwort}</div>            
            </>
        )
    }

    pruefungsteileRow(pruefungsteil){    
        
        let punkteDiv=(
            <div className="form-group">
                <label>Punkte</label>
                <input type="number" min={0} value={pruefungsteil.punkte} onChange={(e)=>this.changePunkte(pruefungsteil, e)} className="form-control"  />
               
            </div>
        )

        if (this.props.activeUser.ist_schueler===1){
            punkteDiv=(
                <div>
                    Punkte<br/>
                    <b>{pruefungsteil.punkte}</b>
                </div>
            )
        }

        let widthprozent="45%"
        if (this.state.pruefungsmodus==="korrigieren") widthprozent="25%"
   

        let td_Antwort = <td className="left-border">{this.getLehrpersonAntwort(pruefungsteil.unterrichtsModulTeil)}</td>
        if (this.props.activeUser.ist_schueler===1) {
            td_Antwort = <td className="left-border">{this.getSchuelerAntwort(pruefungsteil)}</td>
        }

        let lastCellWidth = 60

        let kommentar = ''

        if (this.props.activeUser.ist_schueler===0 && this.state.pruefungsmodus==="korrigieren"){
            let punkte = 0
            
            const _this = this
            this.state.schueler_in_pruefungsteilen.forEach(
                function (sipt){
                    if (sipt.schueler_id===_this.state.activeSchuelerInPruefung.schueler_id && pruefungsteil.id === sipt.pruefungsteile_id){
                        punkte =  sipt.punkte
                        kommentar = sipt.kommentar_lehrperson
                    }
                }
            )

            lastCellWidth = 80
            punkteDiv=(
                
                    <div className="form-group">
                        <label>Punkte / {pruefungsteil.punkte}</label>
                        <input type="number" min={0} value={punkte} onChange={(e)=>this.setPunkteValue(pruefungsteil, e)} className="form-control"  />                    
                    </div>
            )
        }

        let td_punkte = <td width={lastCellWidth} className="text-center left-border" >{punkteDiv}</td>      

       let tdKorrekturAntwortSchueler=null
       if (this.state.pruefungsmodus==="korrigieren"){
        
        tdKorrekturAntwortSchueler= (       
            <td style={{backgroundColor:'#fae5a7'}} width="30%">
                {this.contentSchuelerAntwortZumKorrigieren(pruefungsteil)}               
            </td>)

        td_punkte = <td style={{backgroundColor:'#fae5a7'}} width={lastCellWidth} className="text-center left-border" >{punkteDiv}</td>   
        
        
       }


       if (this.state.pruefungsmodus==="korrigieren"){
            if (this.state.activeSchuelerInPruefung.status === null){
                tdKorrekturAntwortSchueler = <td style={{backgroundColor:'gray', width:'30%'}}>Noch nicht angefangen</td>
            }
            if (this.state.activeSchuelerInPruefung.status === "in Bearbeitung"){
                tdKorrekturAntwortSchueler = <td style={{backgroundColor:'gray', width:'30%'}}>in Bearbeitung <img width={70} src="/assets/img/giphy2.gif" alt="Laden..." />/</td>
            }
        }

   
        return(
            <tr>
                <td width={40} className="text-center">{this.state.pruefungsteile.indexOf(pruefungsteil)+1}.</td>
                <td width={widthprozent}  className="left-border">{this.getFrage(pruefungsteil.unterrichtsModulTeil)}</td>
                {td_Antwort}  
                {tdKorrekturAntwortSchueler}              
                {td_punkte}
            </tr>
        )
    }

    getGesamtpunkte(){

        var gespunkte = 0

        let schueler_in_pruefungsteilen = this.state.schueler_in_pruefungsteilen   
        schueler_in_pruefungsteilen.forEach(
            function (sipt){  
                if (sipt.punkte>0)
                gespunkte = gespunkte + sipt.punkte
            }
        )    
        
        this.setState({schueler_in_pruefungsteilen})
        return gespunkte


    }

    setKommentar(pruefungsteil, e){

            const _this = this

            let schueler_in_pruefungsteilen = this.state.schueler_in_pruefungsteilen

            schueler_in_pruefungsteilen.forEach(
                function (sipt){
                    if (sipt.schueler_id===_this.state.activeSchuelerInPruefung.schueler_id && pruefungsteil.id === sipt.pruefungsteile_id){
                        sipt.kommentar_lehrperson = e.target.value
                        let sql = `UPDATE sfc_schueler_in_pruefungsteilen SET kommentar_lehrperson='${e.target.value}' WHERE id=${sipt.id}`
                        let array={sql}
                        axios.post('/api/execcommonquery', array)
                    }                    
                }
            ) 
            this.setState({schueler_in_pruefungsteilen})

    }


    setPunkteValue(pruefungsteil, e){

            const _this = this

            let schueler_in_pruefungsteilen = this.state.schueler_in_pruefungsteilen

            schueler_in_pruefungsteilen.forEach(
                function (sipt){
                    if (sipt.schueler_id===_this.state.activeSchuelerInPruefung.schueler_id && pruefungsteil.id === sipt.pruefungsteile_id){
                        sipt.punkte = e.target.value
                        let sql = `UPDATE sfc_schueler_in_pruefungsteilen SET punkte=${e.target.value} WHERE id=${sipt.id}`
                        let array={sql}
                        axios.post('/api/execcommonquery', array)
                        .then(
                            result=>{
                                sql = `SELECT SUM(punkte) FROM sfc_schueler_in_pruefungsteilen  WHERE schueler_id=${sipt.schueler_id} AND pruefungsteile_id in (SELECT id from sfc_pruefungsteile WHERE pruefung_id=${pruefungsteil.pruefung_id})`
                                array={sql}
                                axios.post('/api/execcommonquery/float', array)
                                .then(
                                    result=>{
                                         _this.setState({punktedesschuelers:result.data})      
                                    }
                                )
                                
                            }
                        )
                    }
                    
                }
            ) 

            this.setState({schueler_in_pruefungsteilen})
           
    }


    changePunkte(pruefungsteil, e){


        pruefungsteil.punkte = e.target.value
        this.setState({pruefungsteile:this.state.pruefungsteile})
        let sql = `UPDATE sfc_pruefungsteile SET punkte = ${e.target.value} WHERE id= ${pruefungsteil.id}`
        let array={
            sql
        }
        axios.post('/api/execcommonquery', array)
        .then(
            result=>{
                sql = `SELECT sum(punkte) FROM sfc_pruefungsteile WHERE pruefung_id=${this.props.params.pruefungid}`
                array={sql}
                axios.post('/api/execcommonquery/integer', array)
                .then(
                    result=>{
                        this.setState({gesamtpunktezahl:result.data})
                    }
                )
            }
        )

    }

    render(){
        let closeLink = <Link className="btn btn-danger" to="/user/pruefungen">Schliessen</Link>

        if (this.state.pruefung ===null || this.state. pruefungsteile.length===0) return <div style={{marginTop:'100px', marginLeft:'100px'}}>Kein Modul gefunden <br/><hr/>{closeLink} </div>

        let subtitle
        let textVorPruefung = null
        if (this.props.activeUser.ist_schueler==1) {
            subtitle = <h3>Quiz</h3>
            textVorPruefung = <p>{this.state.pruefung.anweisung_vor_pruefung}</p>
        }

        if (this.props.activeUser.ist_schueler===0){

            let nav1link = "nav-link active"
            let nav2link = "nav-link"
            let nav3link = "nav-link"
            if (this.state.pruefungsmodus==='korrigieren'){
                nav1link = "nav-link "
                nav2link = "nav-link active"
                nav3link = "nav-link"
            }
            if (this.state.pruefungsmodus==='ueberblick'){
                nav1link = "nav-link "
                nav2link = "nav-link"
                nav3link = "nav-link active"
            }

            subtitle=(
                <ul className="nav nav-pills nav-pills-primary noprint" role="tablist">
                    <li className="nav-item">
                        <a className={nav1link} href="#" role="tablist" onClick={()=>this.setState({pruefungsmodus:'vorbereiten'})}>
                            Quiz vorbereiten
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className={nav2link}  href="#" role="tablist" onClick={()=>this.setState({pruefungsmodus:'korrigieren'})}>
                            Korrigieren
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className={nav3link}  href="#" role="tablist" onClick={()=>this.setState({pruefungsmodus:'ueberblick'})}>
                            Überblick
                        </a>
                    </li>
                </ul>
            )

        }

        let mapping = this.state.pruefungsteile.map(this.pruefungsteileRow)

        let thead = null

        if (this.state.pruefungsmodus==='ueberblick'){
            mapping = this.state.schuelerinpruefungen.map(this.schuelerinpruefungenRow)
            thead = (
                <thead>
                    <tr>
                        <td>Name</td>
                        <td>Status</td>
                        <td>Beginn</td>
                        <td>Beendet</td>
                        <td>Zeit</td>
                        <td>Punkte</td>
                    </tr>
                </thead>
            )
        }
        let schuelernavigator = null
        if (this.state.pruefungsmodus==="korrigieren"){
            schuelernavigator = this.schuelerNavigator()
        }

        let body=(
            <>
             {schuelernavigator}
            <div className="table-responsive">              
                <table className="table table-striped">
                    {thead}
                    <tbody>
                        {mapping}
                    </tbody>
                </table>
            </div>
            {schuelernavigator}
            </>
        )

        let divGesamtpunkte =  <div className="rightalign">Gesamtpunkte: <b>{this.state.gesamtpunktezahl}</b></div>

        if (this.props.activeUser.ist_schueler===1 && this.state.startbuttongedrueckt===false){
            body = <button className="btn btn-primary" onClick={this.starteFormular}>Start</button>
            divGesamtpunkte = null
        }

        let schuelernameZumDrucken = null

        if (this.state.pruefungsmodus==="korrigieren"){

            let note = this.state.punktedesschuelers * 5 / this.state.gesamtpunktezahl + 1
            let formel = `${this.state.punktedesschuelers} * 5 / ${this.state.gesamtpunktezahl} + 1`

            if (this.props.activeSchule.land==="DE"){
                note = 6 - 5 * this.state.punktedesschuelers / this.state.gesamtpunktezahl
                formel = `6 - 5 * ${this.state.punktedesschuelers} / ${this.state.gesamtpunktezahl}`
            }
            if (this.props.activeSchule.land==="A"){
                note = 5 - 4 * this.state.punktedesschuelers / this.state.gesamtpunktezahl
                formel = `5 - 4 * ${this.state.punktedesschuelers} / ${this.state.gesamtpunktezahl}`
            }
            
            let key=`kommentar_lehrperson${this.state.activeSchuelerInPruefung.id}`

            schuelernameZumDrucken = <div className="onlyprint"><h3>{this.state.activeSchuelerInPruefung.benutzername}</h3></div>

            let korrigiertButtonTr = null
              if (this.state.activeSchuelerInPruefung.status ==="abgegeben" || this.state.activeSchuelerInPruefung.status ==="korrigiert"){
                    korrigiertButtonTr =  (
                        <tr>
                            <td colspan={3}><button name="korrigiert" className="btn btn-primary noprint" onClick={this.saveSchuelerInPruefung}>Korrigiert!</button></td>
                        </tr>
                    ) 
              }
        
            

            divGesamtpunkte =  (
               
               
                <div style={{width:'400px', backgroundColor:'white', float:'right'}}>                   
                        <table className="table">
                            <tbody>
                                <tr>
                                    <td style={{backgroundColor:'#f7f7f7', fontSize:'12px'}}  className="text-center" rowSpan={2}>Note linear ({this.props.activeSchule.land})<br/>{formel}<br/>= <b>{note.toFixed(2)}</b></td><td style={{fontSize:'12px'}}>Gesamtpunkte:</td><td><b>{this.state.punktedesschuelers}</b> / {this.state.gesamtpunktezahl}</td>
                                </tr>
                                <tr>
                                    <td style={{fontSize:'12px'}}>Note:</td><td><input type="number" name="note" value={this.state.activeSchuelerInPruefung.note} onChange={this.saveSchuelerInPruefung} className="form-control" /></td>
                                </tr>
                                <tr>
                                    <td style={{fontSize:'12px'}}>Kommentar:</td><td colSpan={2}><textarea rows={2} key={key} name="kommentar" value={this.state.activeSchuelerInPruefung.kommentar_lehrperson} onChange={(e)=>this.saveSchuelerInPruefung(e)} className="form-control" ></textarea></td>
                                </tr>
                                {korrigiertButtonTr}
                            </tbody>
                        </table>
                </div>
            )
        }



        let abgebenbutton = null   
        if (this.props.activeUser.ist_schueler===1 ){
            abgebenbutton = <div className="rightalign noprint"><button className="btn btn-primary" onClick={()=>$('#modalAbgebenDialogButton').click()}>Abgeben und beenden</button></div>
            if (this.state.startbuttongedrueckt===false)abgebenbutton = null


            if (this.state.quizbeendet || this.state.formularausfuellen===false){
                abgebenbutton = null
                divGesamtpunkte = null
                subtitle = null
                textVorPruefung = <b>Du hast das Quiz beendet und kannst die Seite hier schliessen.</b>
                body = null
            }

          
        }

        if (this.state.pruefungsmodus==="ueberblick")divGesamtpunkte=null

        let printWindowButton = this.state.printWindowButton
        if (this.props.activeUser.ist_schueler===1) printWindowButton = null


        return(
      
                <div style={{backgroundColor:'white', padding:'50px'}} ref={el => (this.componentRef=el)}  >
                    <div className="rightalign noprint">{this.props.activeUser.benutzername}</div>
                    {abgebenbutton}
                    <div className="noprint">{closeLink} </div>
                    <h2> {this.state.pruefung.titel}</h2>
                   
                    {subtitle}
                    {textVorPruefung}      
                    <div className="rightalign noprint">{printWindowButton} </div>                
                    <hr/>       
                       {schuelernameZumDrucken}
                        {divGesamtpunkte}        
                        {body}
                        <hr/>
                        {abgebenbutton}
                    <div className="noprint">{closeLink} </div>
            

                {this.modalYoutubeDialog()} {this.modalAbgebenDialog()}
                </div>
        )
    }


}


function mapStateToProps ({ posts }) {
    return {
      activeUser: posts.userData,
      activeSchueler: posts.pupilsData,
      activeSchule:posts.schule
    };
  };
  export default connect(mapStateToProps) (Pruefungen);
  
  
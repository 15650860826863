import React from 'react';
import browserHistory from 'react-router/lib/browserHistory';
import axios from 'axios';
import { logoutUser, setActiveSchueler, setActiveSchule } from '../actions';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import Modal from '../components/modal';
import $ from 'jquery';
import CardWithText from '../components/cardwithtext.jsx'
import QRCode from "react-qr-code";
import SchulePanel from '../components/schulepanel'
import BenutzerPanel from '../components/benutzerpanel'
import { sort_by, convertDateTime, convertDate, convertDateShort, convertDateTimeSec, setCookies, shuffleArray } from '../components/generalfunctions'
import SchuelerPanel from '../components/schuelerpanel'
import SchuelerGruppenPanel from '../components/schuelergruppenpanel'
import { Circle, Heart, Hourglass } from 'react-spinners-css';
import { jsonToCSV, CSVReader } from 'react-papaparse';
import parse from 'html-react-parser';
import KorrekturStatus from '../components/korrekturstatus.jsx'
import Hilfe from '../components/hilfe.jsx'
import 'material-icons/iconfont/material-icons.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import de from 'date-fns/locale/de';
registerLocale('de', de)




const buttonRef = React.createRef();




class User extends React.Component {

   

    constructor(props) {
        super(props);

        let benutzer_id = 0
        if (props.activeUser !== undefined){
            benutzer_id = props.activeUser.id
        }
        this.state = {
            activePage: 'dashboard',
            alertMessage: '',
            email: '',
            activeEmail: '',
            activeSchule: null,
            activeBenutzer: null,
            activeSchueler: null,
            benutzername: '',
            namevoll: '',
            benutzer: [],
            orderState: 'asc',
            canCloseBenutzerDialog: false,
            schueler: [],
            bearbeitenstatus: false,
            schuelergruppen: [],
            schueleringruppen: [],
            activeSchuelerGruppe: null,
            activeSchuelerGruppenBezeichnung: '',
            benutzereinstellungen: [],
            selectedObjectIDs: [],
            activeSchuelerGruppebezeichnung: '',
            suchtext: '',
            changedstate: true,
            alleModuleFuerDenAutor: [],
            alleModuleFuerDenEmpfaenger: [],
            unterrichtsmodule: [],
            flashcardsThemaUKategorien: [],
            activeModule: null,
            activeWettbewerb: null,
            benutzertoshare: [],
            allegeteilteModuleFuerBenutzer: [],
            flashcardsthemen: [],
            neueKategorie: '',
            activeFlashcardsThema: null,
            teilen_array: { teilen_aufgabe: '', teilen_ist_aufgabe: false, teilen_aufgabe_abgabe_bis: new Date() },
            gruppenueberblick: false,
            schuelerinallenGruppen: [],
            benutzerordner: [],
            ordnername: '',
            activeBenutzerOrdner: null,
            parent_id: 0,
            hourGlassWaiting: false,
            flashcardSortField: 'Titel',
            public_autor_pseudonym: '',
            public_bemerkungen: '',
            lehrperson: null,
            zukorrigierendeunterrichtsmodule: [], 
            zurkorrekturabgeben:false,
            wettbewerbmachen:false,
            gemeinsamenstapelmachen:false,
            monitoringUnterrichtsmodule:[],
            lizenzbedingungengelesen: false,
            zukorrigierende_modulteile_insgesamt: 0,
            gemeinsameStapel:[],
            selectedBenutzerOrdner:{id:-99, benutzer_id, parent_id:0, anzahl_dateien:0, name:'Ordner (optional)'},
            neuerStapelBenutzerOrdner:[],
            pruefungen:[],
            activePruefung: {id:0, titel:'', datum: new Date()},
            pruefung_titel:'',
            pruefung_erstellt_am:'',
            aktivitaeten:[],
            wettbewerbe:[],
            wettkampfmodus:0

        }

        this.abmelden = this.abmelden.bind(this)
        this.dashBoardContent = this.dashBoardContent.bind(this)
        this.wettbewerbeContent = this.wettbewerbeContent.bind(this)
        this.starteWettbewerb = this.starteWettbewerb.bind(this)
        this.wettbewerbEinladungAnnehmen = this.wettbewerbEinladungAnnehmen.bind(this)
        this.getWettbewerbe = this.getWettbewerbe.bind(this)
        this.getUserRole = this.getUserRole.bind(this)
        this.korrigieren = this.korrigieren.bind(this)
        this.saveEmail = this.saveEmail.bind(this)
        this.getBenutzer = this.getBenutzer.bind(this)
        this.lehrkraefteContent = this.lehrkraefteContent.bind(this)
        this.userRow = this.userRow.bind(this);
        this.modalBenutzerDialog = this.modalBenutzerDialog.bind(this)
        this.modalSchuledialog = this.modalSchuledialog.bind(this)
        this.lizenzBestellen = this.lizenzBestellen.bind(this)
        this.speichereSchule = this.speichereSchule.bind(this)
        this.speichereBenutzer = this.speichereBenutzer.bind(this)
        this.aktualisiereSchule = this.aktualisiereSchule.bind(this)
        this.changeOrder = this.changeOrder.bind(this);
        this.changeOrderUModulKorrigierende = this.changeOrderUModulKorrigierende.bind(this)
        this.aktualisiereBenutzer = this.aktualisiereBenutzer.bind(this)
        this.neuerBenutzer = this.neuerBenutzer.bind(this)
        this.posteBenutzer = this.posteBenutzer.bind(this)
        this.modalDeleteBenutzerDialog = this.modalDeleteBenutzerDialog.bind(this)
        this.loescheBenutzer = this.loescheBenutzer.bind(this)
        this.schuelerLoeschenDialog = this.schuelerLoeschenDialog.bind(this)
        this.getSchueler = this.getSchueler.bind(this)
        this.getSchuelergruppen = this.getSchuelergruppen.bind(this)
        this.setBearbeitenstatus = this.setBearbeitenstatus.bind(this)
        this.schuelerRow = this.schuelerRow.bind(this)
        this.setzeAnmeldecodeInitial = this.setzeAnmeldecodeInitial.bind(this)
        this.setzeSchuelerGesperrt = this.setzeSchuelerGesperrt.bind(this)
        this.aktualisiereSchueler = this.aktualisiereSchueler.bind(this)
        this.modalSchuelerDialog = this.modalSchuelerDialog.bind(this)
        this.neuerSchueler = this.neuerSchueler.bind(this)
        this.posteSchueler = this.posteSchueler.bind(this)
        this.speichereSchueler = this.speichereSchueler.bind(this)
        this.loescheAnmeldcookies = this.loescheAnmeldcookies.bind(this)
        this.sendMail = this.sendMail.bind(this)
        this.removeSchueler = this.removeSchueler.bind(this)
        this.klassenContent = this.klassenContent.bind(this)
        this.fachgruppenContent = this.fachgruppenContent.bind(this)
        this.aktivitaetenContent = this.aktivitaetenContent.bind(this)
        this.changeOrderSchueler = this.changeOrderSchueler.bind(this)
        this.schuelergruppenRow = this.schuelergruppenRow.bind(this)
        this.schuelergruppenRow2 = this.schuelergruppenRow2.bind(this)
        this.schuelergruppenRow3 = this.schuelergruppenRow3.bind(this)
        this.changeNeuerBenutzerOrdner = this.changeNeuerBenutzerOrdner.bind(this)
        this.getSchuelerInGruppe = this.getSchuelerInGruppe.bind(this)
        this.schueleringruppenRow = this.schueleringruppenRow.bind(this)
        this.schueleringruppenUeberblickRow = this.schueleringruppenUeberblickRow.bind(this)
        this.changeOrderSchuelerInGruppe = this.changeOrderSchuelerInGruppe.bind(this)
        this.setSchuelergruppe = this.setSchuelergruppe.bind(this)
        this.setSchuelergruppe3 = this.setSchuelergruppe3.bind(this)
        this.speichereGruppe = this.speichereGruppe.bind(this)
        this.modalGruppeDialog = this.modalGruppeDialog.bind(this)
        this.schuelerDialogRow = this.schuelerDialogRow.bind(this)
        this.schuelerDialogRow2 = this.schuelerDialogRow2.bind(this)
        this.aktualisiereGruppenBezeichnung = this.aktualisiereGruppenBezeichnung.bind(this)
        this.clickDataset = this.clickDataset.bind(this)
        this.markieren = this.markieren.bind(this)
        this.neueGruppeanlegen = this.neueGruppeanlegen.bind(this)
        this.gruppeBearbeiten = this.gruppeBearbeiten.bind(this)
        this.loescheGruppe = this.loescheGruppe.bind(this)
        this.modalBenutzerKontoLoeschenDialog = this.modalBenutzerKontoLoeschenDialog.bind(this)
        this.loescheKonto = this.loescheKonto.bind(this)
        this.sucheSchueler = this.sucheSchueler.bind(this)
        this.setActivePage = this.setActivePage.bind(this)
        this.getBenutzereinstellung = this.getBenutzereinstellung.bind(this)
        this.clickModule = this.clickModule.bind(this)
        this.getBenutzereinstellungen = this.getBenutzereinstellungen.bind(this)
        this.aktualisiereBenutzername = this.aktualisiereBenutzername.bind(this)
        this.flashcardsContent = this.flashcardsContent.bind(this)
        this.flashCard = this.flashCard.bind(this)
        this.flashCard2 = this.flashCard2.bind(this)
        this.flashCardsMenuButton = this.flashCardsMenuButton.bind(this)
        this.kategorienRow = this.kategorienRow.bind(this)
        this.saveModul = this.saveModul.bind(this)
        this.modalModulEditDialog = this.modalModulEditDialog.bind(this)
        this.aktualisiereModul = this.aktualisiereModul.bind(this)
        this.neuesModul = this.neuesModul.bind(this)
        this.setKategorie = this.setKategorie.bind(this)
        this.modaldeleteModulDialog = this.modaldeleteModulDialog.bind(this)
        this.deleteModul = this.deleteModul.bind(this)
        this.modalShareBenutzerdialog = this.modalShareBenutzerdialog.bind(this)
        this.modulTeilen = this.modulTeilen.bind(this)
        this.modalModuleTeilenDialog = this.modalModuleTeilenDialog.bind(this)
        this.deleteFromMail = this.deleteFromMail.bind(this)
        this.modulDialogRow = this.modulDialogRow.bind(this)
        this.markierenModul = this.markierenModul.bind(this)
        this.markierenBenutzerToShare = this.markierenBenutzerToShare.bind(this)
        this.editUnterrichtsmodul = this.editUnterrichtsmodul.bind(this)
        this.neueKategorie = this.neueKategorie.bind(this)
        this.modalNeueKategorieDialog = this.modalNeueKategorieDialog.bind(this)
        this.flashcardsthemenRow = this.flashcardsthemenRow.bind(this)
        this.aktualisiere_teilen_array = this.aktualisiere_teilen_array.bind(this)
        this.getBenutzerToShare = this.getBenutzerToShare.bind(this)
        this.getSchuelerInGruppe2 = this.getSchuelerInGruppe2.bind(this)
        this.klassengruppenContent = this.klassengruppenContent.bind(this)
        this.changeOrderAufgaben = this.changeOrderAufgaben.bind(this)
        this.getSchuelerInAllenGruppen = this.getSchuelerInAllenGruppen.bind(this)
        this.copyUnterrichtsmodul = this.copyUnterrichtsmodul.bind(this)
        this.checkNews = this.checkNews.bind(this)
        this.copymodules = this.copymodules.bind(this)
        this.getBenutzerOrdner = this.getBenutzerOrdner.bind(this)
        this.modalBenutzerOrdnerDialog = this.modalBenutzerOrdnerDialog.bind(this)
        this.modalPruefungDialog = this.modalPruefungDialog.bind(this)
        this.pruefungLoeschenDialog = this.pruefungLoeschenDialog.bind(this)
        this.savePruefung = this.savePruefung.bind(this)
        this.aktualisiereOrdner = this.aktualisiereOrdner.bind(this)
        this.neuerBenutzerOrdner = this.neuerBenutzerOrdner.bind(this)
        this.benutzerordnerRow = this.benutzerordnerRow.bind(this)
        this.benutzerordnerRow2 = this.benutzerordnerRow2.bind(this)
        this.benutzerordnerRow3 = this.benutzerordnerRow3.bind(this)
        this.existsBenutzerOrdner = this.existsBenutzerOrdner.bind(this)
        this.postBenutzerOrdner = this.postBenutzerOrdner.bind(this)
        this.modalOrdnerLoeschenDialog = this.modalOrdnerLoeschenDialog.bind(this)
        this.ordnerLoeschen = this.ordnerLoeschen.bind(this)
        this.modalStapelHinzuFuegenDialog = this.modalStapelHinzuFuegenDialog.bind(this)
        this.stapelHinzufuegen = this.stapelHinzufuegen.bind(this)
        this.modulDialogRow4 = this.modulDialogRow4.bind(this)
        this.markierenModul4 = this.markierenModul4.bind(this)
        this.getUnterrichtsmodule = this.getUnterrichtsmodule.bind(this)
        this.ordnerAktualisieren = this.ordnerAktualisieren.bind(this)
        this.ausDemOrdnerEntfernen = this.ausDemOrdnerEntfernen.bind(this)
        this.schuelerRolle = this.schuelerRolle.bind(this)
        this.schuelerKontoEinstellungen = this.schuelerKontoEinstellungen.bind(this)
        this.gebeOrdnerInhaltFrei = this.gebeOrdnerInhaltFrei.bind(this)
        this.navBarItems = this.navBarItems.bind(this)
        this.flashcardsSortieren = this.flashcardsSortieren.bind(this)
        this.setUnterrichtsmoduleAndBenutzerordner = this.setUnterrichtsmoduleAndBenutzerordner.bind(this)
        this.modalModulVeroeffentlichenDialog = this.modalModulVeroeffentlichenDialog.bind(this)
        this.modulVeroeffentlichen = this.modulVeroeffentlichen.bind(this)
        this.benutzerOrdnerMenuButton = this.benutzerOrdnerMenuButton.bind(this)
        this.setBenutzereinstellung = this.setBenutzereinstellung.bind(this)
        this.checkSession = this.checkSession.bind(this)
        this.csvReaderRef = this.csvReaderRef.bind(this)
        this.handleOnFileLoad = this.handleOnFileLoad.bind(this)
        this.zurKorrekturAbgeben = this.zurKorrekturAbgeben.bind(this)
        this.getZuKorrigierendeUnterrichtsmodule = this.getZuKorrigierendeUnterrichtsmodule.bind(this)
        this.getPruefungen = this.getPruefungen.bind(this)
        this.zuKorrigierendeModuleRow = this.zuKorrigierendeModuleRow.bind(this)
        this.pruefungenRow = this.pruefungenRow.bind(this)
        this.removePruefung = this.removePruefung.bind(this)
        this.changeKorrektur = this.changeKorrektur.bind(this)
        this.alertMessage = this.alertMessage.bind(this)
        this.verbesserungMelden =this.verbesserungMelden.bind(this)
        this.korrigiertUndErledigt = this.korrigiertUndErledigt.bind(this)
        this.monitoringBody = this.monitoringBody.bind(this)
        this.schuelerPills = this.schuelerPills.bind(this)
        this.selectSchuelerMonitoring = this.selectSchuelerMonitoring.bind(this)
        this.monitoringModulRow = this.monitoringModulRow.bind(this)
        this.erledigen=this.erledigen.bind(this)
        this.modalLizenzBestellenDialog= this.modalLizenzBestellenDialog.bind(this)
        this.lizenzbestellen2 = this.lizenzbestellen2.bind(this)
        this.getAnzahlzukorrigierende_modulteile_insgesamt = this.getAnzahlzukorrigierende_modulteile_insgesamt.bind(this)
        this.gemeinsamenStapelMachen = this.gemeinsamenStapelMachen.bind(this)
        this.wettbewerbMachen = this.wettbewerbMachen.bind(this)
        this.getGemeinsameStapel = this.getGemeinsameStapel.bind(this)
        this.nichtMehrGemeinsamBearbeiten = this.nichtMehrGemeinsamBearbeiten.bind(this)
        this.getNavItemPill = this.getNavItemPill.bind(this)
        this.setEditModule = this.setEditModule.bind(this)
        this.neuePruefung = this.neuePruefung.bind(this)
        this.schuelerInPruefungenRow = this.schuelerInPruefungenRow.bind(this)
        this.getPruefung = this.getPruefung .bind(this)
        this.anzahlBevorstehenderPruefungen = this.anzahlBevorstehenderPruefungen.bind(this)
        this.openFile = this.openFile.bind(this)
        this.allowUpload = this.allowUpload.bind(this)
        this.onFocus = this.onFocus.bind(this)
        this.onBlur = this.onBlur.bind(this)
        this.onResize = this.onResize.bind(this)
        this.onVisibilitychange = this.onVisibilitychange.bind(this)
        this.getAktivitaeten = this.getAktivitaeten.bind(this)
        this.deleteWettbewerb = this.deleteWettbewerb.bind(this)
        this.checkWettbewerbe = this.checkWettbewerbe.bind(this)
        this.saveWettbewerbModus = this.saveWettbewerbModus.bind(this)
        
        setInterval(this.checkNews, 30 * 1000)
        setInterval(this.getAktivitaeten, 2 * 1000)
        setInterval(this.checkWettbewerbe, 2 * 1000)

    }


    checkWettbewerbe(){
        if (this.state.activePage==="wettbewerbe"){
            this.getWettbewerbe()
        }
    }

   

    getAktivitaeten(){

        if (this.props.activeUser === undefined || this.props.activeUser ===null) return

        if (this.props.activeUser.ist_schueler === 1) return


        if (this.state.activePage === "fachgruppen"){
            axios.get('/api/benutzer/benutzerablenkungen/' + this.props.activeUser.klassen_code)
            .then(
                result=>{
                    if (result.data !==null){
                        this.setState({aktivitaeten: result.data})
                    }

                }
            )
        }

    }

    allowUpload(schueler){

        axios.get('/api/execcommonquery/schuelerupload/' + schueler.id)
        this.setState({ alertMessage: 'Uploaderlaubnis wurde für 2 Stunden erteilt' }, function () { $('#alertMessageDialogButton').click() })
       
    }

    openFile(e){

      
       
        const fetchFile = async e => {
           
                const file = e.target.files[0]
           
            if (!file) return
            const text = await file.text()
          
            const lines = text.split(/\r?\n/gm)

            for (const line of lines) {

                let array = line.split(";")

                if (array.length < 2){
                    this.setState({alertMessage:'Es handelt sich um keine csv-Datei mit Semicolon als Spaltentrenner.'}, function(){ $('#alertMessageDialogButton').click()})
                    return;
                }
                const frage = array[0]
                const antwort = array[1]

                const unterrichtsmodul={
                    id:0,
                    unterrichtsmodule_id:this.state.activeModule.id,
                    frage,
                    antwort1:antwort,
                    antwort2:'',
                    antwort3:'',
                    antwort4:'',
                    antwort5:'',
                    mussfrageschriftlichbeantworten:0,
                    multiplechoice:0,
                    aktiv:1,
                    notizen:'',
                    zusatzinfos:'',
                    frage_videobild_url:'',
                    wahroderfalsch:-1,
                    antwort1_richtig:1,
                    antwort2_richtig:0,
                    antwort3_richtig:0,
                    antwort4_richtig:0,
                    antwort5_richtig:0,
                    anzahl_antworten:5, 
                    konjug_sprache:this.getBenutzereinstellung("lastKonjugationLanguage").strvalue,
                    konjug_zeitform:'',
                    konjug_ich:'',
                    konjug_du:'',
                    konjug_ersiees:'',
                    konjug_wir:'',
                    konjug_ihr:'',
                    konjug_sie:'',
                    ist_konjugation:0,
                    konjug_verb:'' ,      
                    ist_youtube_video:0,
                    nur_audio_abspielen:0,
                    youtube_video_id:'',
                    youtube_video_startzeit:0,
                    youtube_video_laufzeit:0,
                    antwort_videobild_url:''
                }
                axios.post('/api/unterrichtsmodulteile',unterrichtsmodul )

            } //end line loop
          }

          const _this = this

          fetchFile(e)
          .then(
            this.setState({alertMessage:'Die Datei wurde eingelesen!'}, function(){ $('#alertMessageDialogButton').click(); _this.getUnterrichtsmodule()})
            
          )

    }

   

    nichtMehrGemeinsamBearbeiten(modul){

        const sql=`DELETE FROM sfc_gemeinsame_stapel WHERE unterrichtsmodule_id=${modul.id} `

        const array={sql}

        axios.post('/api/execcommonquery', array)
        .then(
            result=>{
                this.getUnterrichtsmodule()
            }
        )

    }
    getWettbewerbe(){

        if (this.props.activeUser === undefined) return

        axios.get('/api/wettbewerbe/benutzer/' + this.props.activeUser.id)
        .then(
            result=>{

                if (result.data !== null){
                    this.setState({wettbewerbe: result.data})
                    console.log(result.data)
                }
            }
        )

    }

    wettbewerbMachen(){

        const _this = this

        let id = 0

        if (this.state.activeWettbewerb !==null) id = this.state.activeWettbewerb.id

        const wettbewerb = {
            id,
            unterrichtsmodul_id: this.state.activeModule.id,
            start_benutzer_id: this.props.activeUser.id,
            startzeit: null
        }
        axios.post('/api/wettbewerbe', wettbewerb)
        .then(
            result=>{
                //Jetzt selected Schueler posten
                let arrayIDs = []
                for (var i=0;i<this.state.selectedObjectIDs.length;i++){ 
                    arrayIDs.push(this.state.selectedObjectIDs[i])
                }
                arrayIDs.push(this.props.activeUser.id)
                let listSchuelerImWettbewerb=[]
                for (var i=0;i<arrayIDs.length;i++){  
                    let einladung_angenommen = 0
                    if (arrayIDs[i]===this.props.activeUser.id)einladung_angenommen=1
                    const schuelerimwettbewerb = {
                        id:0,
                        wettbewerb_id:result.data,
                        benutzer_id: arrayIDs[i],
                        start_nr: i+1,
                        einladung_angenommen,
                        farbe:'',
                    } 
                    listSchuelerImWettbewerb.push(schuelerimwettbewerb)
                }
                    
                    
                axios.post('/api/wettbewerbe/schuelerinwettbewerben',listSchuelerImWettbewerb )
                .then(
                    result2=>{
                        this.setState({activeWettbewerb:null, selectedObjectIDs:[], wettbewerbmachen:false, activePage:'wettbewerbe'}, ()=>this.getWettbewerbe())
                        
                    }
                )
               
            }
        )

    }

    gemeinsamenStapelMachen(){


        const jahr=new Date().getFullYear()
        if (this.props.activeSchule.lizenz_jahr < jahr){
            this.alertMessage(parse("<div>Keine Schul-Lizenz für das Jahr <b>" + jahr  + "</b> für diese Funktion vorhanden. Sie können unter 'Schuldaten bearbeiten' eine Lizenz bestellen. Bitte kontaktieren Sie Ihren Schuladministrator oder schreiben Sie eine Mail an <i>info@schoolflashcards.eu</i></div>" ))
            return
        }



        let selectedObjectIDs = this.state.selectedObjectIDs
         selectedObjectIDs.push(this.props.activeUser.id)     

        const gemeinsamerStapelPost = {
            unterrichtsmdule_id: this.state.activeModule.id,
            benutzerids: selectedObjectIDs
        }
        axios.post('/api/unterrichtsmodule/gemeinsamerstapel', gemeinsamerStapelPost)
        this.alertMessage('Der Stapel wurde zur Bearbeitung im Team mit den ausgewählten Teilnehmern freigegeben.')
        this.setState({gemeinsamenstapelmachen:false, selectedObjectIDs:[]})
    }

    checkSession() {
        axios.get('/api/session')
            .then(
                response => {
                    if (this.props.activeUser !== undefined && this.props.activeUser.id !== response.data) {
                        browserHistory.push('/login')
                    }
                }
            )
            .catch(error => {
                if (error.response.status === 401) {
                    browserHistory.push('/login')
                }
            })
    }

    ordnerLoeschen() {
        axios.post('/api/benutzerordner/delete', this.state.activeBenutzerOrdner)
            .then(
                result => {
                    this.getUnterrichtsmodule()
                }
            )
    }

    modalOrdnerLoeschenDialog() {

        if (this.state.activeBenutzerOrdner === null) return null

        let body = `Den Ordner "${this.state.activeBenutzerOrdner.name} " löschen? Der Vorgang kann nicht rückgängig gemacht werden!`

        if (this.state.activeBenutzerOrdner.anzahl_dateien > 0) body = `Den Ordner "${this.state.activeBenutzerOrdner.name} " löschen? Alle darin enthaltenen Stapel werden nicht gelöscht, sondern wieder ohne Ordner angezeigt. Der Vorgang kann nicht rückgängig gemacht werden!`



        return (
            <Modal
                modalid="modalOrdnerLoeschenDialog"
                buttonid="modalOrdnerLoeschenDialogButton"
                title="Ordner löschen"
                body={body}
                okButtonClick={this.ordnerLoeschen}
                okButtonTitle="Ordner löschen und Stapel behalten"
                cancelButton={true}
            />
        )
    }


    neuerBenutzerOrdner() {


        let parent_id = this.state.parent_id


        const activeBenutzerOrdner = {
            id: 0,
            parent_id,
            benutzer_id: this.props.activeUser.id,
            name: ''
        }
        this.setState({ activeBenutzerOrdner, ordnername: '' },
            function () {
                $('#modalBenutzerOrdnerDialogButton').click()
            })
    }

    postBenutzerOrdner() {

        if (this.state.activeBenutzerOrdner === null) return

        const _this = this

        this.state.activeBenutzerOrdner.name = this.state.ordnername


        axios.post('/api/benutzerordner', this.state.activeBenutzerOrdner)
            .then(
                result => {
                    if (result !== null) {
                        this.setState({ benutzerordner: result.data },
                            function () {
                                _this.forceUpdate()
                            })
                    }
                }
            )
    }

    getBenutzerOrdner() {
        if (this.props.activeUser === null || this.props.activeUser === undefined) return

        const _this = this

        axios.get('/api/benutzerordner/' + this.props.activeUser.id)
            .then(
                result => {
                    if (result.data !== null) {
                        let id = 0
                        let activeBenutzerOrdner = null
                        if (this.state.activeBenutzerOrdner !== null) id = this.state.activeBenutzerOrdner.id
                        result.data.forEach(
                            function (ordner) {
                                if (ordner.id === id) {
                                    activeBenutzerOrdner = ordner
                                }
                            }
                        )
                        this.setState({ benutzerordner: result.data, activeBenutzerOrdner },
                            function () {
                                _this.forceUpdate()
                            })
                    }
                }
            )

    }

    stapelHinzufuegen() {

        const _this = this

        this.setState({ hourGlassWaiting: true })
        let num = 0
        this.state.selectedObjectIDs.forEach(
            function (umodulid) {

                const sql = `UPDATE sfc_unterrichtsmodule SET benutzer_ordner_id=${_this.state.activeBenutzerOrdner.id} WHERE id=${umodulid} `

                const array = {
                    sql
                }
                axios.post('/api/execcommonquery', array)
                    .then(
                        result => {
                            _this.getUnterrichtsmodule()
                            num++
                            if (num === _this.state.selectedObjectIDs.length) {
                                _this.setState({ hourGlassWaiting: false })
                            }

                        }
                    )



            }
        )

    }

    modulDialogRow4(modul) {

        if (modul.benutzer_ordner_id !== this.state.parent_id) return null

        const checkbox = <div className="togglebutton"><label><input name="schuelerID" className="checkbox" onChange={this.clickDataset} checked={this.state.selectedObjectIDs.indexOf(modul.id) > -1} type="checkbox" value={modul.id} /><span className="toggle"></span></label></div>;
        let link = '/previewunterrichtsmodul/' + modul.id + '/briefkasten'
        return (

            <tr key={modul.id}>
                <td>{checkbox}</td>
                <td>{modul.bezeichnung}</td>
                <td>{modul.autor}</td>
            </tr>

        );

    }


    markierenModul4(e) {
        let selectedObjectIDs = []
        if (e.target.name === "allemarkieren") {
            this.state.unterrichtsmodule.forEach(function (modul) {
                if (modul.benutzer_ordner_id === 0)
                    selectedObjectIDs.push(modul.id);
            }
            );
        }

        this.setState({ selectedObjectIDs })
    }

    modalStapelHinzuFuegenDialog() {

        if (this.state.activeBenutzerOrdner === null) return null

        const body =
            <SchuelerGruppenPanel
                dataset={this.state.unterrichtsmodule}
                dialogRow={this.modulDialogRow4}
                markieren={this.markierenModul4}
            />

        const okButtonenabled = this.state.selectedObjectIDs.length > 0

        let title = `Stapel den Ordner "${this.state.activeBenutzerOrdner.name}" hinzuführen`

        return (
            <Modal
                modalid="modalStapelHinzuFuegenDialog"
                buttonid="modalStapelHinzuFuegenDialogButton"
                title="Stapel hinzufügen"
                body={body}
                okButtonClick={this.stapelHinzufuegen}
                okButtonTitle="OK"
                okButtonenabled={okButtonenabled}
                cancelButton={true}
            />
        )
    }

    gebeOrdnerInhaltFrei() {

        axios.post('/api/benutzerordner/freigeben', this.state.activeBenutzerOrdner)
            .then(
                result => {
                    this.getUnterrichtsmodule()
                }
            )

    }



    benutzerordnerRow2(ordner) {

        if (this.state.parent_id > 0) return null

        let anzahl_dateien = "1 Stapel"
        if (ordner.anzahl_dateien > 1) anzahl_dateien = `${ordner.anzahl_dateien} Stapel`
        if (ordner.anzahl_dateien === 0) anzahl_dateien = '(leer)'

        let link1 = <a href="#" onClick={() => { this.setState({ activeBenutzerOrdner: ordner, parent_id: ordner.id }, function () { this.forceUpdate() }) }}><img src="/assets/img/folder.png" width="60" /></a>
        let link2 = <a href="#" onClick={() => { this.setState({ activeBenutzerOrdner: ordner, parent_id: ordner.id }, function () { this.forceUpdate() }) }}><div><b>{ordner.name}</b> {anzahl_dateien}  </div></a>



        return (
            <div key={ordner.id} className="col-sm-12 col-md-12 col-lg-12">
                <table className="table">
                    <tbody>
                        <tr>
                            <td width="60">{link1}</td>
                            <td width="80">{this.benutzerOrdnerMenuButton(ordner)}</td>
                            <td >{link2}</td>
                        </tr>
                    </tbody>
                </table>


            </div>
        )

    }

    benutzerordnerRow3(ordner){

        const _this = this
      
        function setOrdner(ordner){
            _this.setState({selectedBenutzerOrdner: ordner})
        }

        let divider = null
        if (ordner.name==="Neuer Ordner")  divider=<div className="dropdown-divider" />
            
        return (
            <>
            <a key={ordner.id} className="dropdown-item" href="#" onClick={() => setOrdner(ordner)}>{ordner.name}</a>
            {divider}
            </>
        )
    }

    benutzerOrdnerMenuButton(ordner) {
        if (this.state.parent_id > 0) return null

        if (ordner.id=== 1234567890) return null // Team-Ordner


        return (
            <div className="dropdown">
                <button className="btn btn-default btn-sm btn-round dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className="material-icons"> menu </i>
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a className="dropdown-item" href="#" onClick={() => this.setState({ activeBenutzerOrdner: ordner, ordnername: ordner.name }, function () { $('#modalBenutzerOrdnerDialogButton').click() })}  >Umbenennen</a>
                    <a className="dropdown-item" href="#" onClick={() => this.setState({ activeBenutzerOrdner: ordner, ordnername: ordner.name }, function () { $('#modalStapelHinzuFuegenDialogButton').click() })} >Stapel hinzufügen...</a>
                    <div className="dropdown-divider" />
                    <a className="dropdown-item" href="#" onClick={() => this.setState({ activeBenutzerOrdner: ordner, ordnername: ordner.name }, function () { $('#modalOrdnerLoeschenDialogButton').click() })}>Ordner löschen</a>
                    <a className="dropdown-item" href="#" onClick={() => this.setState({ activeBenutzerOrdner: ordner, ordnername: ordner.name }, function () { this.gebeOrdnerInhaltFrei() })}>Ordnerinhalt freigeben</a>
                </div>
            </div>

        )

    }

    benutzerordnerRow(ordner) {

        const _this = this

        if (this.state.parent_id > 0) return null
        let anzahl_dateien = "1 Stapel"
        if (ordner.anzahl_dateien > 1) anzahl_dateien = `${ordner.anzahl_dateien} Stapel`
        if (ordner.anzahl_dateien === 0) anzahl_dateien = '(leer)'

        const top=430/210*298/2

        return (
            <div key={ordner.id} className="col">
                    <div className="folder">
                        <div className="rightalign">{this.benutzerOrdnerMenuButton(ordner)}</div> 
                       
                            <a href="#" onClick={() => { this.setState({ activeBenutzerOrdner: ordner, parent_id: ordner.id }, function () { this.forceUpdate() }) }}>                          
                                <div style={{textAlign:'center', marginTop:'100px', height:'100%'}}> 
                                    <h3   className="card-title text-primary">{ordner.name}</h3>
                                    {anzahl_dateien}
                                </div>
                            </a>
                    </div>
            </div>
        )
    }

    getZuKorrigierendeUnterrichtsmodule() {

        if (this.props.activeUser === undefined || this.props.activeUser ===null) return

        axios.get('/api/stapelablage/' + this.props.activeUser.id)
            .then(
                result => {
                    if (result.data !== null)
                        this.setState({ zukorrigierendeunterrichtsmodule: result.data })
                }
            )

    }

    getPruefungen(){

        if (this.props.activeUser === undefined || this.props.activeUser ===null) return

        axios.get('/api/pruefungen/' + this.props.activeUser.klassen_code)
            .then(
                result => {
                    if (result.data !== null)
                        this.setState({ pruefungen: result.data })
                }
            )

    }

    


    checkNews() {
        this.getAllegeteilteFuerBenutzer()
        this.getZuKorrigierendeUnterrichtsmodule()
        this.getUnterrichtsmodule()
        this.getGemeinsameStapel()
        this.getPruefungen()
        
    }


    getAllegeteilteFuerBenutzer() {
        if (this.props.activeUser === null || this.props.activeUser === undefined) return
        axios.get('/api/unterrichtsmodule/allegeteiltemodulefuerbenutzer/' + this.props.activeUser.id)
            .then(
                result => {
                    if (result.data !== null) {
                        this.setState({ allegeteilteModuleFuerBenutzer: result.data })
                    }
                }
            )
    }


    alleFuerdenAutorRow(umgt) {

        return (
            <tr key={umgt.id}>
                <td>{umgt.bezeichnung}</td>
                <td>{umgt.empfaenger}</td>
                <td>{umgt.status}</td>
                <td>{convertDateShort(umgt.datum_zuletzt_bearbeitet)}</td>
            </tr>
        )
    }

    getBenutzerToShare() {

        axios.post('/api/benutzer/benutzertoshare', this.props.activeUser)
            .then(
                result => {
                    if (result.data !== null)
                        this.setState({ benutzertoshare: result.data })
                }
            )

    }

    setBenutzereinstellung(tag, value) {

        let benutzereinstellung = this.getBenutzereinstellung(tag)

        if (benutzereinstellung === null) return

        if (tag === "showFlashCardsSymbole") {
            benutzereinstellung.boolvalue = value
        }
        if (tag === "lastUModuleID") {
            benutzereinstellung.intvalue = value
        }
        if (tag==="anweisungvorpruefung"){
            benutzereinstellung.textvalue = value
        }

        const _this = this
        axios.post('/api/benutzereinstellungen', benutzereinstellung)
            .then(
                result => {
                    if (result.data !== null) {
                        this.setState({ benutzereinstellungen: result.data }, function () { this.forceUpdate() })
                    }
                }
            )
    }

    getBenutzereinstellung(tag) {

        let benutzerEinstellung = null

        this.state.benutzereinstellungen.forEach(
            function (be) {

                if (be.tag === tag) {
                    benutzerEinstellung = be
                }
            }
        )

        return benutzerEinstellung
    }

    getBenutzereinstellungen() {

        axios.get('/api/benutzereinstellungen/' + this.props.activeUser.id)
            .then(
                result => {
                    if (result.data !== null) {
                        this.setState({ benutzereinstellungen: result.data })
                    }
                }
            )


    }

    getSchuelerInAllenGruppen() {
        axios.get('/api/schuelergruppen/schuelerinallengruppen/' + this.props.activeUser.klassen_code)
            .then(
                result => {
                    if (result.data !== null) {
                        this.setState({ schuelerinallenGruppen: result.data })
                    }
                }
            )
    }


    speichereGruppe() {

        const _this = this

        const gruppepost = {
            id: this.state.activeSchuelerGruppe.id,
            bezeichnung: this.state.activeSchuelerGruppebezeichnung,
            klassen_code: this.props.activeUser.klassen_code,
            schuelerids: this.state.selectedObjectIDs
        }
        axios.post('/api/schuelergruppen', gruppepost)
            .then(
                result => {
                    if (result.data !== null) {
                        this.setState({ schuelergruppen: result.data })
                        result.data.forEach(
                            function (gruppe) {
                                if (gruppe.bezeichnung === _this.state.activeSchuelerGruppebezeichnung) {
                                    _this.setState({ activeSchuelerGruppe: gruppe, activePage: 'fachgruppen', gruppenueberblick: false })
                                    _this.getSchuelerInGruppe(gruppe.id)
                                    _this.getSchuelerInAllenGruppen()
                                }
                            }
                        )
                    }
                }
            )

    }

    clickDataset(e) {

        let selectedObjectIDs = this.state.selectedObjectIDs;


        
        const schuelerid = Number(e.target.value);

        if (this.state.wettbewerbmachen && selectedObjectIDs.length>4 && selectedObjectIDs.indexOf(schuelerid)===-1) return


        if (selectedObjectIDs.indexOf(schuelerid) === -1) {
            selectedObjectIDs.push(schuelerid);
        }
        else {
            selectedObjectIDs.splice(selectedObjectIDs.indexOf(schuelerid), 1)
        }

        this.setState({ selectedObjectIDs })
    }


    aktualisiereGruppenBezeichnung(e) {
        let activeSchuelerGruppebezeichnung = e.target.value
        this.setState({ activeSchuelerGruppebezeichnung })
    }

    markierenModul(e) {
        let selectedObjectIDs = []
        if (e.target.name === "allemarkieren") {
            this.state.allegeteilteModuleFuerBenutzer.forEach(function (modul) {
                selectedObjectIDs.push(modul.id);
            }
            );
        }

        this.setState({ selectedObjectIDs })
    }

    markieren(e) {

        let selectedObjectIDs = []
        if (e.target.name === "allemarkieren") {
            this.state.schueler.forEach(function (schueler) {
                selectedObjectIDs.push(schueler.id);
            }
            );
        }

        this.setState({ selectedObjectIDs })
    }

    markierenBenutzerToShare(e) {

        let selectedObjectIDs = []
        if (e.target.name === "allemarkieren") {
            this.state.benutzertoshare.forEach(function (schueler) {
                selectedObjectIDs.push(schueler.id);
            }
            );
        }

        this.setState({ selectedObjectIDs })
    }


    schuelerDialogRow2(schueler) {


        if (this.props.activeUser === null) return null

        if (schueler.id === this.props.activeUser.id) return null




        let index = this.state.benutzertoshare.indexOf(schueler) + 1

        const checkbox = <div className="togglebutton"><label><input name="schuelerID" className="checkbox" onChange={this.clickDataset} checked={this.state.selectedObjectIDs.indexOf(schueler.id) > -1} type="checkbox" value={schueler.id} /><span className="toggle"></span></label></div>;

        return (

            <tr key={schueler.id}>
                <td width="100">{checkbox}</td>
                <td width="60">{index}</td>
                <td>{schueler.benutzername}</td>
            </tr>

        );
    }

    schuelerDialogRow(schueler) {

        if (this.props.activeUser.ist_schueler === 1) return this.schuelerDialogRow2(schueler)


        let index = this.state.benutzertoshare.indexOf(schueler) + 1

        const checkbox = <div className="togglebutton"><label><input name="schuelerID" className="checkbox" onChange={this.clickDataset} checked={this.state.selectedObjectIDs.indexOf(schueler.id) > -1} type="checkbox" value={schueler.id} /><span className="toggle"></span></label></div>;

        return (

            <tr key={schueler.id}>
                <td width="100">{checkbox}</td>
                <td width="60">{index}</td>
                <td>{schueler.benutzername}</td>
            </tr>

        );
    }

    schuelerInPruefungenRow(schueler) {    


        let index = this.state.schueler.indexOf(schueler) + 1

        const checkbox = <div className="togglebutton"><label><input name="schuelerID" className="checkbox" onChange={this.clickDataset} checked={this.state.selectedObjectIDs.indexOf(schueler.id) > -1} type="checkbox" value={schueler.id} /><span className="toggle"></span></label></div>;

        return (
            <tr key={schueler.id}>
                <td width="100">{checkbox}</td>
                <td width="60">{index}</td>
                <td>{schueler.benutzername}</td>
            </tr>
        );
    }


    modulDialogRow(modul) {



        let index = this.state.allegeteilteModuleFuerBenutzer.indexOf(modul) + 1

        const checkbox = <div className="togglebutton"><label><input name="schuelerID" className="checkbox" onChange={this.clickDataset} checked={this.state.selectedObjectIDs.indexOf(modul.id) > -1} type="checkbox" value={modul.id} /><span className="toggle"></span></label></div>;
        let link = '/editunterrichtsmodul/' + modul.id + '/briefkasten'
        return (

            <tr key={modul.id}>
                <td>{checkbox}</td>
                <td>{index}</td>
                <td>{modul.bezeichnung}</td>
                <td>{modul.autor}</td>

                <td className="text-center">{convertDate(modul.datum_zuletzt_bearbeitet)}</td>
                <td><button data-bs-dismiss="modal" className="btn btn-primary btn-sm btn-round" onClick={() => browserHistory.push(link)}><i className="material-icons"> pageview </i></button></td>
            </tr>

        );
    }

    modulTeilen() {



        const jahr=new Date().getFullYear()
        if (this.props.activeSchule.lizenz_jahr < jahr){
            this.alertMessage(parse("<div>Keine Schul-Lizenz für das Jahr <b>" + jahr  + "</b> für diese Funktion vorhanden. Sie können unter 'Schuldaten bearbeiten' eine Lizenz bestellen. Bitte kontaktieren Sie Ihren Schuladministrator oder schreiben Sie eine Mail an <i>info@schoolflashcards.eu</i></div>" ))
            return
        }


        const _this = this

        let ist_aufgabe = 0
        if (this.state.teilen_array.teilen_ist_aufgabe) ist_aufgabe = 1

        const aufgabe = this.state.teilen_array.teilen_aufgabe
        const aufgabe_abgabe_bis = this.state.teilen_array.teilen_aufgabe_abgabe_bis

        this.state.selectedObjectIDs.forEach(
            function (id) {

                const array = {
                    unterrichtsmodule_id: _this.state.activeModule.id,
                    geteilt_mit_benutzer_id: id,
                    ist_aufgabe,
                    aufgabe,
                    aufgabe_abgabe_bis
                }
                axios.post('/api/unterrichtsmodule/teilen', array)
                    .then(
                        result => {
                            _this.setUnterrichtsmoduleAndBenutzerordner(result)
                            axios.get('/api/unterrichtsmodule/allemodulefuerdenautor/' + _this.props.activeUser.id)
                                .then(
                                    result2 => {
                                        if (result.data !== null) {
                                            _this.setState({ alleModuleFuerDenAutor: result2.data })

                                        }
                                    }
                                )
                        }
                    )

            }
        )
        this.alertMessage("Der Stapel wurde mit den ausgewählten Benutzern geteilt und erscheint nun in deren Briefkästen")

    }

    aktualisiere_teilen_array(e) {
        let teilen_array = this.state.teilen_array;
        if (e.target.name === "teilen_ist_aufgabe") teilen_array.teilen_ist_aufgabe = e.target.checked
        if (e.target.name === "teilen_aufgabe") teilen_array.teilen_aufgabe = e.target.value
        if (e.target.name === "teilen_aufgabe_abgabe_bis") teilen_array.teilen_aufgabe_abgabe_bis = e.target.value
        this.setState({ teilen_array })
    }

    modalShareBenutzerdialog() {

        if (this.state.activeModule === null) return null
        let title = `Stapel "${this.state.activeModule.bezeichnung}" teilen`

        if (this.state.zurkorrekturabgeben){
            title= `Stapel "${this.state.activeModule.bezeichnung}" zur Überprüfung abgeben`
        }
        if (this.state.gemeinsamenstapelmachen){
            title= `Stapel "${this.state.activeModule.bezeichnung}" im Team bearbeiten`
        }
        if (this.state.wettbewerbmachen){
            title= `"${this.state.activeModule.bezeichnung}" - Wettrennen`
        }
        

        let trs_aufgabe = null
        if (this.state.teilen_array.teilen_ist_aufgabe) trs_aufgabe = (
            <>

                <tr>
                    <td width="150"> Aufgabenbeschreibung</td>
                    <td><input type="text" className="form-control" name="teilen_aufgabe" value={this.state.teilen_array.teilen_aufgabe} onChange={this.aktualisiere_teilen_array} /></td>
                </tr>
                <tr>
                    <td>Zu erledigen bis</td>
                    <td><input type="date" className="form-control" name="teilen_aufgabe_abgabe_bis" onChange={this.aktualisiere_teilen_array} value={this.state.teilen_array.teilen_aufgabe_abgabe_bis} />
                    </td>
                </tr>
            </>
        )

        let dropDownGruppen = null
        if (this.state.schuelergruppen.length > 0) {

            let titleDropDown = 'Senden an Gruppe (nicht gewählt)'
            if (this.state.activeSchuelerGruppe !== null) {
                titleDropDown = 'Senden an ' + this.state.activeSchuelerGruppe.bezeichnung
            }



            dropDownGruppen =
                <div className="dropdown">
                    <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {titleDropDown}
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        {this.state.schuelergruppen.map(this.schuelergruppenRow2)}
                    </div>
                </div>
        }




        let divAufgabe = (
            <table className="table">
                <tbody>
                    <tr>
                        <td colSpan="2"><div className="togglebutton">
                            <label>
                                <input type="checkbox" name="teilen_ist_aufgabe" checked={this.state.teilen_array.teilen_ist_aufgabe} onChange={this.aktualisiere_teilen_array} />
                                <span className="toggle"></span>
                                als Aufgabe senden
                            </label>
                        </div>
                        </td>
                    </tr>
                    {trs_aufgabe}

                </tbody>

            </table>
        )

        if (this.props.activeUser.ist_schueler === 1 || this.state.gemeinsamenstapelmachen) divAufgabe = null

        let showAlle = true

        let headertitle = "Senden an"
        if (this.state.wettbewerbmachen){
            headertitle =null
            divAufgabe = null
            dropDownGruppen = <div>Teilnehmer einladen <br/><small>Du kannst max. 5 Mitspieler einladen, und du bist als sechste/r Spieler/in schon mit dabei.</small></div>
            showAlle=false;
        }
        this.state.benutzertoshare.sort(sort_by("benutzername", false, function (a) { return a.toUpperCase() }));
       
       

        const body = (
            <>
                {dropDownGruppen}                
                <SchuelerGruppenPanel
                    dataset={this.state.benutzertoshare}
                    dialogRow={this.schuelerDialogRow}
                    markieren={this.markierenBenutzerToShare}
                    headertitle={headertitle}
                    showAlle={showAlle}
                />
                <hr />
                {divAufgabe}
            </>
        )

        let okButtonenabled = this.state.selectedObjectIDs.length > 0

        if (this.state.teilen_array.teilen_ist_aufgabe) okButtonenabled = (this.state.selectedObjectIDs.length > 0 && this.state.teilen_array.teilen_aufgabe !== "")

        let okButtonClick = this.modulTeilen
        if (this.state.zurkorrekturabgeben)okButtonClick=this.zurKorrekturAbgeben

        if (this.state.gemeinsamenstapelmachen)okButtonClick = this.gemeinsamenStapelMachen

        if (this.state.wettbewerbmachen)okButtonClick = this.wettbewerbMachen

        let okButtonTitle = "Teilen"
        if (this.state.gemeinsamenstapelmachen) okButtonTitle = "OK"
        if (this.state.zurkorrekturabgeben)okButtonTitle = "Zur Korrektur abgeben"
        if (this.state.wettbewerbmachen)okButtonTitle = "Wettrennen erzeugen"

        return (
            <Modal
                modalid="modalShareBenutzerdialog"
                buttonid="modalShareBenutzerdialogButton"
                title={title}
                body={body}
                okButtonClick={okButtonClick}
                okButtonenabled={okButtonenabled}
                errortext={this.state.errortext}
                okButtonTitle={okButtonTitle}
                cancelButton={true}
            />
        )

    }

    modalGruppeDialog() {

        if (this.state.activeSchuelerGruppe === null) return null

        let title = "Neue Gruppe anlegen"

        if (this.state.activeSchuelerGruppe.id > 0) {
            title = "Eigenschaften von " + this.state.activeSchuelerGruppebezeichnung
        }

        let okButtonenabled = this.state.activeSchuelerGruppebezeichnung !== ""

        const gruppenHeader = (
            <table className="table">
                <tbody>
                    <tr>
                        <td width="120">Bezeichnung der Gruppe *</td><td><input type="txt" className="form-control" id="exampleInput" value={this.state.activeSchuelerGruppebezeichnung} onChange={this.aktualisiereGruppenBezeichnung} placeholder="Bezeichnung" /></td>
                    </tr>
                </tbody>
            </table>
        )

        const body = (
            <SchuelerGruppenPanel
                dataset={this.state.schueler}
                dialogRow={this.schuelerDialogRow}
                markieren={this.markieren}
                gruppenHeader={gruppenHeader}
                headertitle="Mitglieder der Gruppe"
            />
        )

        return (
            <Modal
                modalid="modalGruppeDialog"
                buttonid="modalGruppeDialogButton"
                title={title}
                body={body}
                okButtonClick={this.speichereGruppe}
                okButtonenabled={okButtonenabled}
                errortext={this.state.errortext}
                okButtonTitle="Speichern"
                cancelButton={true}
            />
        )
    }




    aktualisiereSchueler(e) {
        let activeSchueler = this.state.activeSchueler;
        if (e.target.name === "benutzername") activeSchueler.benutzername = e.target.value;
        if (e.target.name === "email") activeSchueler.email = e.target.value;
        if (e.target.name === "kann_stapel_veroeffentlichen") {
            let value = 0
            if (e.target.checked) value = 1
            activeSchueler.kann_stapel_veroeffentlichen = value;
        }
        this.setState({ activeSchueler })
    }


    posteSchueler() {

        //schliesst den Dialog))
        $('#modalSchuelerDialogButton').click()

        axios.post("/api/benutzer/neuerschueler", this.state.activeSchueler)
            .then(
                result => {
                    if (result.data !== null) {
                        this.setState({ schueler: result.data, })
                        //Damit der neue Schüler auch in der Allgemein-Gruppe ist:
                        this.getSchuelergruppen()
                        this.getBenutzerToShare()
                    }
                }
            )
    }

    speichereSchueler() {

        let benutzer_id = 0
        if (this.state.activeSchueler > 0) benutzer_id = this.state.activeSchueler.id

        if (this.state.activeSchueler.email !== "") {
            axios.get('/api/benutzer/checkneueemail/' + this.state.activeSchueler.email + '/' + benutzer_id)
                .then(
                    result => {
                        if (result.data === 0) {
                            this.setState({ canCloseBenutzerDialog: false, errortext: 'Diese Emailadresse existiert bereits in der Datenbank' })
                        }
                        else{
                            
                            this.setState({ canCloseBenutzerDialog: true, errortext: '' }, function () { this.posteSchueler() })
                        }
                    }
                )
        }
        else {
            this.setState({ canCloseBenutzerDialog: true, errortext: '' }, function () { this.posteSchueler() })
        }


    }


    editSchueler(schueler) {



        this.setState({ activeSchueler: schueler, canCloseBenutzerDialog: false },
            function () { $('#modalSchuelerDialogButton').click() }
        )

    }


    modalSchuelerDialog() {
        if (this.state.activeSchueler === null || this.state.activeSchueler === undefined) return null



        const body = <SchuelerPanel activeSchueler={this.state.activeSchueler} aktualisiereSchueler={this.aktualisiereSchueler} />

        let title = this.state.activeSchueler.namevoll
        if (this.state.activeSchueler.id === 0) title = "Neue/n Schüler/in anlegen"

        const okButtonenabled = (this.state.activeSchueler.benutzername !== "");

        return (
            <Modal
                modalid="modalSchuelerDialog"
                buttonid="modalSchuelerDialogButton"
                title={title}
                body={body}
                okButtonClick={this.speichereSchueler}
                okButtonenabled={okButtonenabled}
                errortext={this.state.errortext}
                okButtonTitle="OK"
                canClose={this.state.canCloseBenutzerDialog}
                cancelButton={true}
            />
        )

    }

    neuerSchueler() {

        const activeSchueler = {
            id: 0,
            benutzername: '',
            namevoll: '',
            kuerzel: '',
            anmeldecode: '',
            email: '',
            schule_id: this.state.activeSchule.id,
            klassen_code: this.props.activeUser.klassen_code,
            kann_lehrpersonen_bearbeiten: 0,
            ist_schule_ansprechpartner: 0,
            email: '',
            ist_schueler: 1,
            kann_stapel_veroeffentlichen: 0
        }

        this.setState({ activeSchueler, canCloseBenutzerDialog: false },
            function () { $('#modalSchuelerDialogButton').click() }
        )

    }

    setzeSchuelerGesperrt(e, schueler){

        let ist_gesperrt = 0
        if (e.target.checked) ist_gesperrt = 1
        schueler.ist_gesperrt = ist_gesperrt

        axios.post('/api/benutzer/sperren', schueler)
            .then(
                result => {

                    let sch = this.state.schueler
                    sch.forEach(
                        function (s) {
                            if (s.id === schueler.id) {
                                s.ist_gesperrt = ist_gesperrt
                            }
                        }
                    )
                    this.setState({ schueler: sch })
                }
            )

        

    }

    setzeAnmeldecodeInitial(schueler) {


        axios.post('/api/benutzer/neueranmeldecode', schueler)
            .then(
                result => {

                    let sch = this.state.schueler
                    sch.forEach(
                        function (s) {
                            if (s.id === schueler.id) {
                                s.anmeldecode = result.data
                            }
                        }
                    )
                    this.setState({ schueler: sch })
                }
            )

    }

    sendMail(schueler) {
        const url = 'https://schoolflashcards.eu/_php/anmeldecodeschicken.php?anmeldecode=' + schueler.anmeldecode + '&email=' + schueler.email;

        fetch(url, {
            mode: 'no-cors',
            method: 'GET'
        })
            .then(
                (response) => {
                    this.alertMessage("Der Anmeldecode wurde an '" + schueler.email + "' verschickt")
                }
            )
    }


    schuelerRow(schueler) {

        const nr = this.state.schueler.indexOf(schueler) + 1

        let buttons = <><button className="btn btn-info btn-fab btn-fab-mini btn-round  noprint" onClick={() => this.editSchueler(schueler)}><i className="material-icons"> edit </i></button>  <button onClick={() => this.setState({ activeSchueler: schueler }, function () { $('#schuelerLoeschenDialogButton').click() })} className="btn btn-danger btn-fab btn-fab-mini btn-round  noprint"><i className="material-icons">close</i></button></>
        let sendEmailButton = null;

        if (schueler.email !== "") {
            sendEmailButton = <button className="btn btn-primary btn-round btn-sm noprint" onClick={() => this.sendMail(schueler)}><i className="material-icons"> mail</i>Anmeldecode</button>
        }
        if (this.state.bearbeitenstatus === false) {
            buttons = null
        }

        const anmeldecodedropDown = (
            <div className="dropdown noprint">
                <button className="dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {schueler.anmeldecode}
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a name={schueler.id} onClick={() => this.setzeAnmeldecodeInitial(schueler)} href="#" className="dropdown-item" >{schueler.benutzername}: Neuen Anmeldecode setzen </a>
                    <a name={schueler.id} onClick={() => this.copyToClipboard(schueler.anmeldecode)} href="#" className="dropdown-item" >{schueler.benutzername}: Anmeldecode in die Zwischenablage kopieren </a>
                </div>
            </div>
        )

        const qrlink = "https://schoolflashcards.eu/" + schueler.anmeldecode



        const divQRC = (

            <div className="onlyprint" style={{ width: '50', height: '50', fontSize: '10' }} >
                {schueler.anmeldecode}<br />
                <QRCode value={qrlink} /><br />
                schoolflashcards.eu
            </div>
        )

        let icon = null
        if (schueler.kann_stapel_veroeffentlichen === 1) {
            icon = <i className="material-icons"> done </i>
        }

        let checkbox = (
            <div className="togglebutton">
                <label>
                    <input type="checkbox" onChange={(e)=>this.setzeSchuelerGesperrt(e,schueler)} checked={schueler.ist_gesperrt===1} />
                    <span className="toggle"></span>
                   
                </label>
                </div>
        )

        let uploadAllowButton = <button className="btn btn-default btn-sm" onClick={()=>this.allowUpload(schueler)}>Upload temporär erlauben</button>

        uploadAllowButton = null

        return (
            <tr key={schueler.id}>
                <td style={{ width: '10px' }} className="text-center">{nr}</td>
                <td>{schueler.benutzername}</td>
                <td>{anmeldecodedropDown} {divQRC}</td>
                <td>{schueler.email} {sendEmailButton} </td>
                <td className="text-center">{icon}</td>
                <td>{checkbox}</td>
                <td>{buttons}</td>
                <td>{uploadAllowButton}</td>

            </tr>
        )

    }

    getSchuelerInGruppe(gruppeid) {


        axios.get('/api/benutzer/schuelergruppen/' + gruppeid)
            .then(
                result => {
                    if (result.data !== null) {
                        this.setState({ schueleringruppen: result.data, gruppenueberblick: false })
                    }


                }
            )


    }

    schuelerLoeschenDialog() {
        if (this.state.activeSchueler === null) return null
        const body = (
            <div>
                <h3>{this.state.activeSchueler.benutzername} löschen</h3>
                <b>Achtung! Der Vorgang nicht mehr rückgängig gemacht werden!</b> <br />
                <p><button className="btn btn-primary" onClick={this.removeSchueler}>Datensatz für immer löschen</button></p>
            </div>
        )

        return (
            <Modal
                modalid="schuelerLoeschenDialog"
                buttonid="schuelerLoeschenDialogButton"
                body={body}
                cancelButton={true}
                title="Schüler/in löschen"
                okButtonClick={this.removeSchueler}
            />
        )
    }


    removeSchueler() {


        axios.post('/api/benutzer/removeschueler', this.state.activeSchueler)
            .then(
                result => {
                    //modal schließen
                    $('#schuelerLoeschenDialogButton').click()
                    this.setState({ schueler: result.data, activeSchueler: null })
                }
            )
    }

    loescheBenutzer() {

        axios.post(`/api/benutzer/delete/${this.state.activeBenutzer.id}`, this.props.activeUser)
            .then(
                result => {
                    if (result.data !== null) {
                        this.setState({ benutzer: result.data })
                    }
                }
            )

    }

    modalDeleteBenutzerDialog() {

        if (this.state.activeBenutzer === null || this.state.activeBenutzer === undefined) return null

        const frage = `Möchten Sie die Lehrperson "${this.state.activeBenutzer.namevoll}" löschen?`


        return (
            <Modal
                modalid="modalDeleteBenutzerDialog"
                buttonid="modalDeleteBenutzerDialogButton"
                title="Lehrperson löschen"
                body={frage}
                okButtonenabled={true}
                okButtonTitle="Löschen"
                okButtonenabled={true}
                errortext={this.state.errortext}
                okButtonClick={this.loescheBenutzer}
                cancelButton={true}
            />
        )

    }


    editBenutzer(benutzer) {

        this.setState({ activeBenutzer: benutzer, canCloseBenutzerDialog: false },
            function () { $('#modalBenutzerDialogButton').click() }
        )
    }

    neuerBenutzer() {

        const activeBenutzer = {
            id: 0,
            benutzername: '',
            namevoll: '',
            kuerzel: '',
            anmeldecode: '',
            schule_id: this.state.activeSchule.id,
            kann_lehrpersonen_bearbeiten: 0,
            ist_schule_ansprechpartner: 0,
            klassen_code: '',
            email: '',
            ist_schueler: 0,
            create_user_id: this.props.activeUser.id,
            kann_stapel_veroeffentlichen: 1
        }

        this.setState({ activeBenutzer, canCloseBenutzerDialog: false },
            function () { $('#modalBenutzerDialogButton').click() }
        )

    }

    posteBenutzer() {


        $('#modalBenutzerDialog').hide();
        $('.modal-backdrop').hide();

        axios.post("/api/benutzer", this.state.activeBenutzer)
            .then(
                result => {
                    if (result.data !== null) {

                        this.setState({ benutzer: result.data, })

                    }
                }
            )
    }

    speichereBenutzer() {

        let benutzer_id = 0
        if (this.state.activeBenutzer.id > 0) benutzer_id = this.state.activeBenutzer.id


        if (this.state.activeBenutzer.id === 0) {
            axios.get('/api/benutzer/checkneueemail/' + this.state.activeBenutzer.email + '/' + benutzer_id)
                .then(
                    result => {
                        if (result.data === 0) {
                            this.setState({ errortext: 'Diese Emailadresse existiert bereits in der Datenbank' })
                        }
                        else {

                            this.setState({ canCloseBenutzerDialog: true, errortext: '' }, function () { this.posteBenutzer() })

                        }
                    }
                )
        }
        else {
            this.setState({ canCloseBenutzerDialog: true, errortext: '' }, function () { this.posteBenutzer() })
        }

    }

    aktualisiereBenutzer(e) {
        let activeBenutzer = this.state.activeBenutzer;
        if (e.target.name === "namevoll") activeBenutzer.namevoll = e.target.value;
        if (e.target.name === "benutzername") activeBenutzer.benutzername = e.target.value;
        if (e.target.name === "kuerzel") activeBenutzer.kuerzel = e.target.value;
        if (e.target.name === "email") activeBenutzer.email = e.target.value;
        if (e.target.name === "kann_lehrpersonen_bearbeiten") {
            let i = 0
            if (e.target.checked) i = 1
            activeBenutzer.kann_lehrpersonen_bearbeiten = i;
        }
        this.setState({ activeBenutzer })

    }


    modalBenutzerDialog() {
        if (this.props.activeUser === null || this.props.activeUser === undefined) return null
        if (this.state.activeBenutzer === null) return null

        const body = <BenutzerPanel activeBenutzer={this.state.activeBenutzer} aktualisiereBenutzer={this.aktualisiereBenutzer} kann_lehrpersonen_bearbeiten={this.props.activeUser.kann_lehrpersonen_bearbeiten} kann_schuldaten_bearbeiten={this.props.activeUser.kann_schuldaten_bearbeiten} />

        let title = this.state.activeBenutzer.namevoll
        if (this.state.activeBenutzer.id === 0) title = "Neue Lehrperson anlegen"


        const okButtonenabled = (this.state.activeBenutzer.namevoll !== "" && this.state.activeBenutzer.benutzername !== "" && this.state.activeBenutzer.email !== "");

        return (
            <Modal
                modalid="modalBenutzerDialog"
                buttonid="modalBenutzerDialogButton"
                title={title}
                body={body}
                okButtonClick={this.speichereBenutzer}
                okButtonenabled={okButtonenabled}
                errortext={this.state.errortext}
                okButtonTitle="OK"
                canClose={this.state.canCloseBenutzerDialog}
            />
        )

    }

    changeOrderAufgaben(e) {

        let alleModuleFuerDenAutor = this.state.alleModuleFuerDenAutor

        let order = 'asc';
        if (this.state.orderState === "asc") {
            order = 'desc';
        }

        if (e.target.name !== "datum_zuletzt_bearbeitet") {
            if (order === 'desc') {
                alleModuleFuerDenAutor.sort(sort_by(e.target.name, false, function (a) { return a.toUpperCase() }));
            }
            else {
                alleModuleFuerDenAutor.sort(sort_by(e.target.name, true, function (a) { return a.toUpperCase() }));
            }

        }
        else {  // wenn datum 
            if (order === 'desc') {
                alleModuleFuerDenAutor.sort(sort_by(e.target.name, false, function (a) { return new Date(a) }));
            }
            else {
                alleModuleFuerDenAutor.sort(sort_by(e.target.name, true, function (a) { return new Date(a) }));
            }


        }

        this.setState({ alleModuleFuerDenAutor, orderState: order })



    }


    changeOrderSchueler(e) {

        let schueler = this.state.schueler;

        let name = e.target.name
        let order = 'asc';
        if (this.state.orderState === "asc") {
            order = 'desc';
        }




        if (order === 'desc') {

            if (e.target.name === "kann_stapel_veroeffentlichen") {
                schueler.sort(sort_by(e.target.name, false, function (a) { return Number(a) }));
            }
            else {
                schueler.sort(sort_by(e.target.name, false, function (a) { return a.toUpperCase() }));
            }


        }
        else {
            if (e.target.name === "kann_stapel_veroeffentlichen") {
                schueler.sort(sort_by(e.target.name, true, function (a) { return Number(a) }));
            }
            else {
                schueler.sort(sort_by(e.target.name, true, function (a) { return a.toUpperCase() }));
            }
        }


        this.setState({ schueler, orderState: order, lastorder: e.target.name + "~" + order })

    }


    changeOrder(e) {

        let benutzer = this.state.benutzer;

        let name = e.target.name
        let order = 'asc';
        if (this.state.orderState === "asc") {
            order = 'desc';
        }
        let orderstr = `UPPER(${e.target.name})~${order}`
        if (e.target.name === "kann_lehrpersonen_bearbeiten" || e.target.name === "kann_schuldaten_bearbeiten") {
            orderstr = `${e.target.name}~${order}`
        }

        if (e.target.name !== "kann_lehrpersonen_bearbeiten" && e.target.name !== "kann_schuldaten_bearbeiten") {

            if (order === 'desc') {
                benutzer.sort(sort_by(e.target.name, false, function (a) { return a.toUpperCase() }));
            }
            else {
                benutzer.sort(sort_by(e.target.name, true, function (a) { return a.toUpperCase() }));
            }
        }
        else {

            if (order === 'desc') {
                benutzer.sort(sort_by(e.target.name, false, parseInt));
            }
            else {
                benutzer.sort(sort_by(e.target.name, true, parseInt));
            }
        }

        this.setState({ benutzer, orderState: order, lastorder: e.target.name + "~" + order })

    }

    speichereSchule() {

        const _this = this

        axios.post("/api/schulen", this.state.activeSchule)
            .then(
                result => {
                    if (result.data !== null) {

                        this.setState({ schulen: result.data })

                    }
                }
            )


    }


    getAdminActive(value) {
        let result = <i className="material-icons">block</i>;
        if (value === 1) {
            result = <i className="material-icons">done</i>;
        }
        return result;
    }


    userRow(user) {

        if (this.props.activeUser === null) return null


        const index = this.state.benutzer.indexOf(user) + 1

        const qrlink = "https://schoolflashcards.de/" + user.anmeldecode

        let className = "onlyprint"
        if (this.state.qrcodesanzeigen) className = ""

        const divQRC = (

            <td className="onlyprint" style={{ width: '50', height: '50', fontSize: '10' }} >
                {user.anmeldecode}<br />
                <QRCode value={qrlink} /><br />
                schoolflashcards.eu
            </td>

        )

        let tdZugrLehrer = <td className="text-center noprint">{this.getAdminActive(user.kann_lehrpersonen_bearbeiten)}</td>
        if (this.props.activeUser.kann_lehrpersonen_bearbeiten === 0) tdZugrLehrer = null
        let tdZugrSchule = <td className="text-center noprint">{this.getAdminActive(user.kann_schuldaten_bearbeiten)}</td>
        if (this.props.activeUser.kann_schuldaten_bearbeiten === 0) tdZugrSchule = null

        let editButtons = <><button className="btn btn-info btn-fab btn-fab-mini btn-round  noprint" onClick={() => this.editBenutzer(user)}><i className="material-icons">edit</i></button>  <button onClick={() => this.setState({ activeBenutzer: user }, function () { $('#modalDeleteBenutzerDialogButton').click() })} className="btn btn-danger btn-fab btn-fab-mini btn-round  noprint"><i className="material-icons">close</i></button></>

        if (user.create_user_id !== this.props.activeUser.id && this.props.activeUser.id !== user.id) editButtons = null

        let createUser = '(<= Registrierung der Schule)'
        if (user.create_user_id > 0) createUser = `(<= ${user.create_benutzer.benutzername}`
        let divCreateUser = <div className="noprint">{createUser}</div>

        return (

            <tr key={user.id}>
                <td className="text-center">{index}</td>
                <td>{user.benutzername} {divCreateUser}</td>
                <td>{user.namevoll}</td>
                <td>{user.email}</td>
                {divQRC}
                {tdZugrLehrer}
                {tdZugrSchule}
                <td>{editButtons}</td>
            </tr>

        );

    }

    getSchuelergruppen() {

        axios.get('/api/schuelergruppen/' + this.props.activeUser.klassen_code)
            .then(
                result => {
                    if (result.data !== null) {
                        this.setState({ schuelergruppen: result.data, activeSchuelerGruppe: result.data[0] })
                        this.getSchuelerInGruppe(result.data[0].id)
                    }
                }
            )
    }

    getSchueler() {
        axios.get('/api/benutzer/klasse/' + this.props.activeUser.klassen_code)
            .then(
                result => {
                    let schueler = result.data;
                    schueler.sort(sort_by('namevoll', false, function (a) { return a.toUpperCase() }));
                    this.setState({ schueler, activeSchueler: null })
                }
            )
    }


    getBenutzer() {

        if (this.props.activeUser === null) return

        axios.get('/api/benutzer/schule/' + this.props.activeUser.schule_id)
            .then(
                result => {
                    if (result.data !== null)
                        this.setState({ benutzer: result.data, selectedUsers: [] })

                })
    }


    componentWillMount() {

       



        //  
        if (this.props.activeUser === null || this.props.activeUser === undefined) return


       // this.checkSession()
        const _this = this

        this.setState({ activeEmail: this.props.activeUser.email, benutzername: this.props.activeUser.benutzername, namevoll: this.props.activeUser.namevoll })

        let postLink = '/api/benutzer/alledaten'
        if (this.props.activeUser.ist_schueler === 1) postLink = '/api/benutzer/schueleralledaten'

        axios.post(postLink, this.props.activeUser)
            .then(
                result => {

                    let activeSchule = null
                    if (result.data.schule !== null){
                        activeSchule = result.data.schule
                        this.props.setActiveSchule(result.data.schule)
                    } 

                    let benutzer = []
                    if (result.data.benutzer !== null) {
                        benutzer = result.data.benutzer
                    }
                    let schueler = []

                    if (result.data.schueler !== null) {
                        schueler = result.data.schueler
                    }
                    let schuelergruppen = []
                    let activeSchuelerGruppe = null
                    if (result.data.schuelergruppen !== null) {
                        schuelergruppen = result.data.schuelergruppen
                        if (result.data.schuelergruppen.length > 0) {
                            activeSchuelerGruppe = result.data.schuelergruppen[0]
                            this.getSchuelerInGruppe(result.data.schuelergruppen[0].id)
                        }

                    }
                    let benutzereinstellungen = []
                    if (result.data.benutzereinstellungen !== null) {
                        benutzereinstellungen = result.data.benutzereinstellungen
                    }
                    let unterrichtsmodule = []
                    if (result.data.unterrichtsmodule !== null) {
                        unterrichtsmodule = result.data.unterrichtsmodule

                    }
                    let flashcardsThemaUKategorien = []
                    if (result.data.flashcardsThemaUKategorien !== null) {
                        flashcardsThemaUKategorien = result.data.flashcardsThemaUKategorien
                    }
                    let benutzertoshare = []
                    if (result.data.benutzertoshare !== null) {
                        benutzertoshare = result.data.benutzertoshare
                    }
                    let allegeteilteModuleFuerBenutzer = []
                    if (result.data.allegeteilteFuerBenutzer !== null) {
                        allegeteilteModuleFuerBenutzer = result.data.allegeteilteFuerBenutzer
                    }
                    let flashcardsthemen = []
                    let activeFlashcardsThema = null
                    if (result.data.flashcardsthemen !== null && result.data.flashcardsthemen !== undefined) {
                        flashcardsthemen = result.data.flashcardsthemen
                        activeFlashcardsThema = result.data.flashcardsthemen[0]
                    }
                    let alleModuleFuerDenAutor = []
                    if (result.data.allemodulefuerdenautor !== null) {
                        alleModuleFuerDenAutor = result.data.allemodulefuerdenautor
                    }
                    let alleModuleFuerDenEmpfaenger = []
                    if (result.data.allemodulefuerdenempfaenger !== null) {
                        alleModuleFuerDenEmpfaenger = result.data.allemodulefuerdenempfaenger
                    }
                    let schuelerinallenGruppen = []
                    if (result.data.schuelerinallenGruppen !== null) {
                        schuelerinallenGruppen = result.data.schuelerinallenGruppen
                    }
                    let benutzerordner = []
                    if (result.data.benutzerOrdner !== null) {
                        benutzerordner = result.data.benutzerOrdner  
                    }

                    let lehrperson = null
                    if (result.data.lehrperson !== null) {
                        lehrperson = result.data.lehrperson
                    }
                    let zukorrigierendeunterrichtsmodule = []
                    if (result.data.zuKorrigierendemodule !== null) {
                        zukorrigierendeunterrichtsmodule = result.data.zuKorrigierendemodule
                    }   
                    let gemeinsameStapel = []
                    if (result.data.gemeinsameStapel !== null) {
                        gemeinsameStapel = result.data.gemeinsameStapel
                    }    
                    let pruefungen = []
                    if (result.data.pruefungen !== null) {
                        pruefungen = result.data.pruefungen
                    }  

                 
                    this.setState({
                        activeSchule,
                        benutzer,
                        schueler,
                        schuelergruppen,
                        activeSchuelerGruppe,
                        activeSchueler: null,
                        selectedUsers: [],
                        benutzereinstellungen,
                        unterrichtsmodule,
                        flashcardsThemaUKategorien,
                        benutzertoshare,
                        allegeteilteModuleFuerBenutzer,
                        flashcardsthemen,
                        activeFlashcardsThema,
                        alleModuleFuerDenAutor,
                        alleModuleFuerDenEmpfaenger,
                        schuelerinallenGruppen,
                        benutzerordner,
                        gemeinsameStapel,
                        lehrperson,
                        zukorrigierendeunterrichtsmodule,
                        pruefungen,
                        zukorrigierende_modulteile_insgesamt: this.getAnzahlzukorrigierende_modulteile_insgesamt(result.data.unterrichtsmodule)
                    }, function () {
                        _this.flashcardsSortieren('Titel')

                    })

                }
            )


        if (this.props.params.from !== undefined) {

            if (this.props.params.from==="briefkasten"){
                this.setState({activePage:'flashcards',  selectedObjectIDs: [] }, function () { $('#modalModuleTeilenDialogButton').click() })
            }

            else{

                this.setState({ activePage: this.props.params.from },
                    function () {
                        this.forceUpdate()
                    })
            }

        }

        if (this.props.params.action === "kopieren") {
            this.copyUnterrichtsmodul(this.props.params.unterrichtsmodulid)
        }

        

        if (this.props.activeSchueler !==undefined){
        
            this.selectSchuelerMonitoring(this.props.activeSchueler)
        }


        this.getWettbewerbe()

    }
    

    getGemeinsameStapel(){
        if (this.props.activeUser===null || this.props.activeUser===undefined) return

        axios.get('/api/unterrichtsmodule/gemeinsamestapel/' + this.props.activeUser.id)
        .then(
            result=>{   
                if (result.data !==null){                    
                    this.setState({gemeinsameStapel: result.data})
                }
            }
        )
    }

    copyUnterrichtsmodul(unterrichtsmodulid) {


        axios.get('/api/unterrichtsmodule/byid/' + unterrichtsmodulid)
            .then(
                result => {
                    if (result.data !== null) {

                        let unterrichtsmodul = result.data
                        unterrichtsmodul.eigentuemer_id = this.props.activeUser.id
                        unterrichtsmodul.ist_kopie_von_umodul_id = unterrichtsmodul.id
                        unterrichtsmodul.benutzer_ordner_id = 0

                        axios.post('/api/unterrichtsmodule/kopieren', unterrichtsmodul)
                            .then(
                                result2 => {
                                    this.setUnterrichtsmoduleAndBenutzerordner(result2)
                                }
                            )

                    }
                }
            )
    }

    saveEmail() {



        axios.get('/api/benutzer/checkneueemail/' + this.state.email + '/' + this.props.activeUser.id)
            .then(
                result => {
                    if (result.data === 0) {
                        this.alertMessage("Diese Emailadresse existiert bereits in der Datenbank")
                    }
                    else {
                        const sql = `UPDATE sfc_benutzer SET email='${this.state.email}' WHERE id=${this.props.activeUser.id}`
                        const array = {
                            sql
                        }
                        axios.post('/api/execcommonquery', array)
                            .then(
                                result => {
                                    this.setState({ email: '', activeEmail: this.state.email })
                                }
                            )

                    }
                }
            )
    }

    abmelden() {
        this.props.logoutUser().
            then(
                result => {
                    browserHistory.push('/login')
                }
            )

    }

    topNavigation() {

        return (
            <div>
                Topnavigation
            </div>
        )
    }

    getSchulname() {
        if (this.state.activeSchule === null) return null
        let schulname = this.state.activeSchule.name1
        if (this.state.activeSchule.name2 !== "") schulname = this.state.activeSchule.name1 + " " + this.state.activeSchule.name2


        return <p>{schulname} {this.state.activeSchule.plz} {this.state.activeSchule.ort}</p>

    }

    schuelerRolle() {
        let gruppen = ''
        this.state.schuelergruppen.forEach(
            function (gruppe) {
                gruppen = gruppen + ", " + gruppe.bezeichnung
            }
        )
        gruppen = gruppen.replace(", ", "")

        let strings = []

        strings.push(<li key={1}>Du bist in der Rolle "Schüler" eingeloggt.</li>)
        strings.push(<li key={2}>In der Rolle "Schüler" kannst du eigene Flashcards erstellen und sie mit anderen Benutzern teilen.</li>)
        strings.push(<li key={3}>Du kannst deinen Benutzernamen selbst wählen und eine Email-Adresse angeben, damit du dein Konto wiederherstellen kannst, falls du deinen Anmeldecode verloren hast.</li>)
        if (gruppen != "") strings.push(<li key={4}>Du bist folgenden Gruppen zugeordnet: <b>{gruppen}</b>.</li>)

        return <>Eingeloggt als: {this.props.activeUser.benutzername} <ul>{strings}</ul></>

    }

    getUserRole() {
        if (this.props.activeUser === null) return null

        if (this.props.activeUser.ist_schueler === 1) return this.schuelerRolle()


        let strings = []

        if (this.props.activeUser.ist_schule_ansprechpartner === 1) strings.push(<li key={1}>Sie sind als Ansprechpartner Ihrer Schule registriert.</li>)
        if (this.props.activeUser.kann_lehrpersonen_bearbeiten === 1) strings.push(<li key={2}>In der Rolle "Administrator" können Sie andere Lehrpersonen hinzufügen und deren Rollen setzen.</li>)
        if (this.props.activeUser.kann_schuldaten_bearbeiten === 1) strings.push(<li key={3}>In der Rolle "Administrator" können Sie die Schuldaten bearbeiten.</li>)
        strings.push(<li key={4}>In der Rolle "Lehrperson" können Sie ihre eigene Klasse und ihre Fachgruppen bearbeiten, sowie Aufgaben stellen und korrigieren.</li>)

      
        return <>Eingeloggt als: {this.props.activeUser.benutzername} <ul>{strings}</ul></>

    }

    alertMessage(message) {

        this.setState({ alertMessage: message }, function () { $('#alertMessageDialogButton').click() })

    }

    alertMessageDialog() {
        return (
            <Modal
                modalid="alertMessageDialog"
                buttonid="alertMessageDialogButton"
                title="Schoolflashcards"
                body={this.state.alertMessage}
                okButtonTitle="OK"
                okButtonenabled={true} />
        )
    }




    korrigieren() {



        if (this.props.activeUser.ist_schueler === 0 && this.state.schueler.length === 0) {
            this.alertMessage("Es müssen noch Schüler/innen erfasst werden.")
            return;
        }

        if (this.props.activeUser.ist_schueler === 1){
            this.setState({ activePage: 'pruefungen' })
        }
        else{

        this.setState({ activePage: 'korrigieren' })
        }



    }

    aktualisiereSchule(e) {

        let activeSchule = this.state.activeSchule;
        if (e.target.name === "name1") activeSchule.name1 = e.target.value;
        if (e.target.name === "name2") activeSchule.name2 = e.target.value;
        if (e.target.name === "anschrift1") activeSchule.anschrift1 = e.target.value;
        if (e.target.name === "anschrift2") activeSchule.anschrift2 = e.target.value;
        if (e.target.name === "plz") activeSchule.plz = e.target.value;
        if (e.target.name === "ort") activeSchule.ort = e.target.value;
        if (e.target.name === "land") activeSchule.land = e.target.value;
        if (e.target.name === "telefon1") activeSchule.telefon1 = e.target.value;
        if (e.target.name === "email1") activeSchule.email1 = e.target.value;
        if (e.target.name === "telefon2") activeSchule.telefon2 = e.target.value;
        if (e.target.name === "email2") activeSchule.email2 = e.target.value;
        if (e.target.name === "rechnungsanschrift1") activeSchule.rechnungsanschrift1 = e.target.value;
        if (e.target.name === "rechnungsanschrift2") activeSchule.rechnungsanschrift2 = e.target.value;
        if (e.target.name === "rechnungsplz") activeSchule.rechnungsplz = e.target.value;
        if (e.target.name === "rechnungsort") activeSchule.rechnungsort = e.target.value;
        if (e.target.name === "rechnungsland") activeSchule.rechnungsland = e.target.value;
        if (e.target.name === "hauspost_domainname") activeSchule.hauspost_domainname = e.target.value;

        this.setState({ activeSchule })

    }

    modalLizenzBestellenDialog(){

        let body=(
            <div>
                Beim Klick auf "Lizenz bestellen" bestellen Sie verbindlich eine <p><b>Schullizenz für das laufende Jahr {new Date().getFullYear()}</b>.</p> 
                <p>Hierfür werden der Schule <b>300.00 EUR</b> in Rechnung gestellt.</p><p>Schulen des Kantons Schaffhausen sind von der Lizenzgebühr befreit.</p>

                <div className="togglebutton">
                    <label>
                        <input type="checkbox" checked={this.state.lizenzbedingungengelesen===true} onChange={()=>this.setState({lizenzbedingungengelesen: !this.state.lizenzbedingungengelesen})}/>
                        <span className="toggle"></span>
                        Die Geschäftsbedingungen werden akzeptiert.
                       
                    </label>
                </div>
                <hr/>
                <a href="https://www.estrella-software.de/?page=agb" target="_blank">Geschäftsbedingungen...</a>
            </div>
        )

        let okButtonenabled=(this.state.lizenzbedingungengelesen===true)

        return (
            <Modal
                modalid="modalLizenzBestellenDialog"
                buttonid="modalLizenzBestellenDialogButton"
                title="Lizenz bestellen"
                body={body}
                okButtonClick={this.lizenzbestellen2}
                okButtonTitle="Lizenz bestellen"
                cancelButton={true}
                okButtonenabled={okButtonenabled}
            />
        )
      }

      lizenzbestellen2(){       


        const url = '/api/schulen/lizenzbestellen/' + this.state.activeSchule.id + '/' + new Date().getFullYear()
   
        let activeSchule = this.state.activeSchule
        activeSchule.lizenz_jahr =  new Date().getFullYear()
        this.setState({activeSchule})
        
        axios.get(url)        
        .then(
            (response)=>{               
                this.alertMessage(response.data)
            }
        )      
      }


    lizenzBestellen(){
        this.speichereSchule()
        $('#modalLizenzBestellenDialogButton').click()
    }

    modalSchuledialog() {

        if (this.state.activeSchule === null || this.state.activeSchule === undefined) return null

     
        let okButtonenabled = (this.state.activeSchule.name1 !== "");

        let buttonLizenz = null

        let ul= null
        let lis = []
        if (this.state.activeSchule.lizenz_jahr !== new Date().getFullYear()){

            let lis = []
            if (this.state.activeSchule.name1==="")lis.push(<li key={1}>Schulname fehlt</li>)
            if (this.state.activeSchule.telefon1==="")lis.push(<li key={2}>Telefon 1 fehlt</li>)
            if (this.state.activeSchule.rechnungsanschrift1==="")lis.push(<li key={3}>Rechnungsanschrift 1 fehlt</li>)
            if (this.state.activeSchule.rechnungsplz==="")lis.push(<li key={4}>Postleitzahl fehlt</li>)
            if (this.state.activeSchule.rechnungsort==="")lis.push(<li key={5}>Ort fehlt</li>)

            ul=(
                <div style={{color:'red'}}>Falls Sie eine Schullizenz bestellen möchten: <ul>{lis}</ul></div>
            )
            buttonLizenz = <button className="btn btn-primary" disabled>Lizenz für {new Date().getFullYear()} bestellen </button>
            if (lis.length===0){
                buttonLizenz = <button className="btn btn-primary" onClick={this.lizenzBestellen}>Lizenz für {new Date().getFullYear()} bestellen </button>
                ul = null
            }
        }

            

         const body = <><SchulePanel activeSchule={this.state.activeSchule} aktualisiereSchule={this.aktualisiereSchule} />{ul}</>
 


        return (
            <Modal
                modalid="modalSchuledialog"
                buttonid="modalSchuledialogButton"
                title={this.state.activeSchule.name1}
                body={body}
                okButtonClick={this.speichereSchule}
                okButtonenabled={okButtonenabled}
                errortext={this.state.errortext}
                leftButton1 = {buttonLizenz}
                okButtonTitle="OK"
            />
        )
    }

    anzahlBevorstehenderPruefungen(){
        let count = 0
        this.state.pruefungen.forEach(
            function(p){
                if (p.datum > new Date()){
                    count ++
                }
            }
        )
        return count
    }

    deleteWettbewerb(wb){

        const sql=`DELETE FROM sfc_wettbewerbe WHERE id=${wb.wettbewerb.id}; DELETE FROM sfc_schueler_in_wettbewerben WHERE wettbewerb_id=${wb.wettbewerb.id}`
        const array={sql}
        axios.post('/api/execcommonquery', array)
        .then(
            result=>{
                this.getWettbewerbe()
            }
        )
    }

    starteWettbewerb(wb){

        let sql=`UPDATE sfc_schueler_in_wettbewerben SET position=0 WHERE wettbewerb_id=${wb.wettbewerb.id}`
        let array={sql}
        axios.post('/api/execcommonquery', array)
        .then(
            result=>{
                sql=`UPDATE sfc_wettbewerbe SET sieger_benutzer_id=0, ist_gestartet=0 WHERE id=${wb.wettbewerb.id}`
                array={sql}
                axios.post('/api/execcommonquery', array)
                .then(
                    result=>{
                        this.setState({activePage:'flashcards'}, ()=>browserHistory.push('/wettbewerbe/' + wb.wettbewerb.id))
                    }
                )


                 
            }
        )


        
    }

    wettbewerbeContent(){

        const subtitle = `${this.state.wettbewerbe.length} Wettrennen anstehend`

        const _this = this;


        function del(siw){
            const sql = `DELETE FROM sfc_schueler_in_wettbewerben WHERE id=${siw.id}`
            const array={sql}
            axios.post('/api/execcommonquery', array)
            .then(
                result=>{
                    _this.getWettbewerbe()
                }
            )
                
        }

        function row(wb){

            let trs = []

            let kannstarten = true

            wb.schuelerImWettbewerbs.forEach(

                function(siw){

                    let angenommen = 'Teilnahme noch nicht bestätigt'

                    let image = null
                    if (siw.einladung_angenommen===1){
                        angenommen = 'Teilnahme bestätigt'
                        image = <i className="material-icons">done</i>
                    }
                    if (siw.benutzer_id===_this.props.activeUser.id && wb.wettbewerb.start_benutzer_id !== _this.props.activeUser.id && siw.einladung_angenommen===0){
                        angenommen = <button className="btn btn-primary" onClick={()=>_this.wettbewerbEinladungAnnehmen(siw)}>Einladung annehmen</button>
                    }
                    if (siw.einladung_angenommen===0) kannstarten = false

                    const imgname = `/assets/img/jockey_${siw.farbe}.png`

                    let delButton = <button className="btn btn-danger  btn-fab btn-fab-mini btn-round" onClick={()=>del(siw)}><i className="material-icons"> close </i></button>
                    if (siw.benutzer_id===_this.props.activeUser.id )delButton = null

                    trs.push(
                        <tr>
                            <td><img src={imgname} height={25}/></td><td><b>{siw.benutzername}</b></td><td> {angenommen} {image}</td><td>{delButton}</td>
                        </tr>         
                    )
                }
            )

            let buttonStart = null
            if (kannstarten){
                buttonStart = <div><button className="btn btn-primary" onClick={()=>_this.starteWettbewerb(wb)}  >Wettrennen starten </button></div>
            }
            if (wb.schuelerImWettbewerbs.length < 2)buttonStart=null
            let buttonDeleteWettbewerb = null;
            if (_this.props.activeUser.id===wb.wettbewerb.start_benutzer_id){
                buttonDeleteWettbewerb =<div><button onClick={()=>_this.deleteWettbewerb(wb)} className="btn btn-danger">Löschen <i className="material-icons"> close </i></button></div>
            }

            let buttonNeuerTeilnehmer = <button className="btn btn-primary" onClick={() => _this.setState({activeWettbewerb:wb.wettbewerb, activeModule: wb.unterrichtsModul,wettbewerbmachen: true, farbenaktualisieren: true }, function () { $('#modalShareBenutzerdialogButton').click() })}>Teilnehmer +</button>
            if (wb.schuelerImWettbewerbs.length>5)buttonNeuerTeilnehmer=null



            const titleHaelfte=`${Math.round(wb.unterrichtsModul.anzahl_modulteile / 2)} Fragen müssen beantwortet werden`
            const titlezweidrittel=`${Math.round(wb.unterrichtsModul.anzahl_modulteile / 3 * 2)} Fragen müssen beantwortet werden`
            let wettbewerbTitle=`Alle ${wb.unterrichtsModul.anzahl_modulteile} Fragen müssen beantwortet werden`
            if (wb.wettbewerb.modus===1)wettbewerbTitle=titleHaelfte
            if (wb.wettbewerb.modus===2)wettbewerbTitle=titlezweidrittel
            let menuItemzweiDrittel = <a className="dropdown-item" href="#" onClick={()=>_this.saveWettbewerbModus(wb,2)}>{titlezweidrittel}</a>
            if (Math.round(wb.unterrichtsModul.anzahl_modulteile / 2) === Math.round(wb.unterrichtsModul.anzahl_modulteile / 3 * 2))menuItemzweiDrittel = null
            let wettbewerbHeader=( 
                    <div className="dropdown">
                        <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {wettbewerbTitle}
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a className="dropdown-item" href="#" onClick={()=>_this.saveWettbewerbModus(wb,0)}>Alle {wb.unterrichtsModul.anzahl_modulteile} Fragen müssen beantwortet werden</a>
                            <a className="dropdown-item" href="#" onClick={()=>_this.saveWettbewerbModus(wb,1)}>{titleHaelfte}</a>
                            {menuItemzweiDrittel}
                        </div>
                    </div>
                )

            return (
                
                <tr>
                    <td >{wb.wettbewerb.modultitel} {wettbewerbHeader}   {buttonStart} {buttonDeleteWettbewerb}</td><td>{convertDateShort(wb.wettbewerb.startzeit)}</td><td>Einladung von <b>{wb.wettbewerb.start_benutzername}</b><br/>{buttonNeuerTeilnehmer}</td>
                    <td><table className="table table-striped"><tbody>{trs}</tbody></table></td>

                </tr>
            )

        }

        const body=(
            <div className="table-responsive">
                <table className="table table-striped">
                    <tbody>
                        {this.state.wettbewerbe.map(row)}
                    </tbody>
                </table>


            </div>
        )
     
            return (<CardWithText title="Wettrennen" subtitle={subtitle} body={body} stylename="card-header card-header-text card-header-info" />)
       
    }

    saveWettbewerbModus(wb, modus){
        
        wb.wettbewerb.modus = modus;
        let sql = `UPDATE sfc_wettbewerbe SET modus=${modus} WHERE id=${wb.wettbewerb.id}`
        if (modus > 0){
            let anzahl = Math.round(wb.unterrichtsModul.anzahl_modulteile / 2)
            if (modus===2) anzahl =  Math.round(wb.unterrichtsModul.anzahl_modulteile / 3 * 2)
            sql =`UPDATE sfc_wettbewerbe SET modus=${modus}, anzahl_fragen_beantwortet=${anzahl} WHERE id=${wb.wettbewerb.id}`
        }
        const array={sql}
        axios.post('/api/execcommonquery', array)
        .then(
            result=>{
                this.forceUpdate()
            }
        )

    }

    wettbewerbEinladungAnnehmen(siw){

        const sql = `UPDATE sfc_schueler_in_wettbewerben SET einladung_angenommen=1 WHERE id=${siw.id}`
        const array={sql}
        axios.post('/api/execcommonquery', array)
        .then(
            this.getWettbewerbe()
        )

    }

    dashBoardContent() {

        let aufgabenTitle = 'Aufgaben'
        let lastFlashCard = null
        const _this = this

        let benutzereinstellung = this.getBenutzereinstellung('lastUModuleID')

        if (benutzereinstellung !== null && benutzereinstellung.intvalue > 0) {

            this.state.unterrichtsmodule.forEach(
                function (modul) {
                    if (modul.id === benutzereinstellung.intvalue) {
                        lastFlashCard = (
                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3" style={{minWidth:'430px'}}>
                                <a onClick={() => _this.clickModule(modul, "quizstarten")} href="#">
                                    <CardWithText
                                            title="Letzter Stapel"
                                            body={_this.flashCardDashboard(modul)}
                                        stylename="card-header card-header-text card-header-info" 
                                        transparent={true} />                                        
                                </a>
                            </div>

                        )
                    }
                }
            )
        }

        
        if (this.state.zukorrigierendeunterrichtsmodule.length > 0){
           
            aufgabenTitle=<div>{aufgabenTitle} <span className="badge badge-danger">{this.state.zukorrigierendeunterrichtsmodule.length}</span></div>  
            
        }
        let anzahlBevorstehendePruefungen = this.anzahlBevorstehenderPruefungen()
        if (this.props.activeUser.ist_schueler===1 && anzahlBevorstehendePruefungen > 0){
            aufgabenTitle=<div>{aufgabenTitle} <span className="badge badge-danger">{anzahlBevorstehendePruefungen}</span></div>  

        }

        let aufgabenCard = (
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                <a onClick={this.korrigieren} href="#">
                    <CardWithText
                        title={aufgabenTitle}
                        body=<img className="img-fluid" src="/assets/img/korrigieren.png" border="0" />    
                        stylename="card-header card-header-text card-header-info"/>
                </a>
            </div>)
        let fachgruppenCard = (
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                <a onClick={() => this.setState({ activePage: 'fachgruppen', gruppenueberblick: false })} href="#">
                    <CardWithText
                        title="SuS / Gruppen"
                        body=<img className="img-fluid" src="/assets/img/fachgruppenbearbeiten.png" border="0" />
                    stylename="card-header card-header-text card-header-info"
                            />
                </a>
            </div>

        )
        if (this.state.schueler !== undefined && (this.state.schueler.length === 0 || this.state.schuelergruppen.length === 0)) {
            aufgabenCard = null
            fachgruppenCard = null
        }
        if (this.props.activeUser.ist_schueler === 1) {
            fachgruppenCard = null
        }
        let wettbewerbcard = null
        if(this.state.wettbewerbe.length > 0){
            wettbewerbcard = (
                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                    <a onClick={() => this.setState({ activePage: 'wettbewerbe'})} href="#">
                        <CardWithText
                            title="Quiz-Wettbewerbe"
                            body=<img className="img-fluid" src="/assets/img/students2.png" border="0" />
                        stylename="card-header card-header-text card-header-info"
                                />
                    </a>
                </div>

            )
        }

     

        return (
            <>
                {this.getSchulname()}

                <div className="noprint"><CardWithText title="Aktuelle Konfiguration" body={this.getUserRole()} stylename="card-header card-header-text card-header-primary" /></div>

                <div className="row">
                    {lastFlashCard}
                    {fachgruppenCard}
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                        <a onClick={() => this.setState({ activePage: 'flashcards' }, function () { this.forceUpdate() })} href="#">
                            <CardWithText
                                title="Meine Flashcards"
                                id="myFlashcards"
                                body=<img className="img-fluid" src="/assets/img/flashcards.png" border="0" />
                            stylename="card-header card-header-text card-header-info"
                            badgeCount={this.state.allegeteilteModuleFuerBenutzer.length + this.state.zukorrigierende_modulteile_insgesamt}
                            
                            />
                        </a>
                    </div>
                    {aufgabenCard}
                    {wettbewerbcard}
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                        <a onClick={() => this.setState({ activePage: 'einstellungen' })} href="#">
                            <CardWithText
                                title="Mein Konto"
                                body=<img className="img-fluid" src="/assets/img/einstellungen.png" border="0" />    
                            stylename="card-header card-header-text card-header-info"/>
                        </a>
                    </div>
                </div>


            </>
        )

    }

    loescheAnmeldcookies() {

        setCookies(false, null)
     
        this.alertMessage("Die Anmeldecookies für diesen Browser wurden gelöscht")

    }

    loescheKonto() {

        axios.post('/api/benutzer/removebenutzer', this.props.activeUser)
            .then(
                result => {
                    this.loescheAnmeldcookies()
                    //modal schliessen
                    $('#modalBenutzerKontoLoeschenDialog').click();
                    this.abmelden()
                }
            )

    }

    modalBenutzerKontoLoeschenDialog() {

        if (this.props.activeUser === null) return null

        let body = (
            <>
                Möchten Sie Ihr Konto unwiderruflich löschen?
                <ul>
                    <li>Alle mit Ihrer Person verbundenen Daten werden gelöscht.</li>
                    <li>Alle von Ihnen angelegten Lernmodule werden gelöscht.</li>
                    <li>Alle von Ihnen angelegten Schülerinnen und Schüler werden gelöscht.</li>
                    <li>Alle Browserdaten werden ebenfalls gelöscht.</li>
                    <li>Anschliessend werden Sie ausgeloggt.</li>
                </ul>
            </>
        )

        return (
            <Modal
                modalid="modalBenutzerKontoLoeschenDialog"
                buttonid="modalBenutzerKontoLoeschenDialogButton"
                title="Konto löschen"
                body={body}
                okButtonClick={this.loescheKonto}
                okButtonTitle="Alles klar! Mein Konto jetzt löschen!"
                cancelButton={true}
            />
        )

    }

    aktualisiereBenutzername(e) {

        let sql = ''
        let value = e.target.value

        if (e.target.name === "namevoll") {
            if (value === "") value = this.props.activeUser.namevoll
            this.setState({ namevoll: value })
            sql = `UPDATE sfc_benutzer SET namevoll='${e.target.value}' WHERE id = ${this.props.activeUser.id}`
        }

        if (e.target.name === "benutzername") {
            if (value === "") value = this.props.activeUser.benutzername
            this.setState({ benutzername: value })
            sql = `UPDATE sfc_benutzer SET benutzername='${e.target.value}' WHERE id = ${this.props.activeUser.id}`
        }
        const array = {
            sql
        }
        axios.post('/api/execcommonquery', array)


    }


    copyToClipboard(text) {
        let prompt = 'Copy to clipboard: Ctrl+C, Enter'
        if (navigator.platform.indexOf('Mac') > -1) prompt = 'Copy to clipboard: Cmd+C, Enter'
        window.prompt(prompt, text);
    }

    schuelerKontoEinstellungen() {

        let divBenutzername = (
            <>

                <div className="form-group">
                    <label htmlFor="Benutzername">Benutzername</label>
                    <input type="text" name="benutzername" value={this.state.benutzername} onChange={this.aktualisiereBenutzername} className="form-control" id="Benutzername" placeholder="Benutzername" />
                </div>
                <p>Dein Anmeldecode lautet <b>{this.props.activeUser.anmeldecode}</b> <a className="text-primary" href="#" onClick={() => this.copyToClipboard(this.props.activeUser.anmeldecode)}>Anmeldecode in die Zwischenablage kopieren</a> </p>
            </>
        )

        let saveButton = <button className="btn btn-primary" disabled>Neue Email speichern</button>

        let cookiesLoeschenButton = <button className="btn btn-primary" onClick={this.loescheAnmeldcookies}>Anmeldecookies von diesem Browser löschen</button>



        if (this.state.email.length > 0) {
            saveButton = <button className="btn btn-primary" onClick={this.saveEmail}>Neue Email speichern</button>
        }



        const einstellungTable = (

            <>
                {divBenutzername}
                Du bist mit folgender Emailadresse registriert: <b>{this.state.activeEmail}</b>
                <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Neue Emailadresse registrieren (die alte wird zurückgesetzt)</label>
                    <input type="email" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Neue Emailadresse" />
                </div>
                {saveButton}
                <hr />
                {cookiesLoeschenButton}
            </>
        )




        return (<CardWithText title="Kontoeinstellungen" subtitle={this.state.namevoll} body={einstellungTable} stylename="card-header card-header-text card-header-info" />)


    }

    einstellungenContent() {

        if (this.props.activeUser === null) return

        if (this.props.activeUser.ist_schueler === 1) return this.schuelerKontoEinstellungen()

        let divBenutzername = (
            <>
                <div className="form-group">
                    <label htmlFor="inputname">Vorname und Name</label>
                    <input type="text" name="namevoll" value={this.state.namevoll} onChange={this.aktualisiereBenutzername} className="form-control" id="inputname" placeholder="Vorname und Name" />
                </div>
                <div className="form-group">
                    <label htmlFor="Benutzername">Benutzername</label>
                    <input type="text" name="benutzername" value={this.state.benutzername} onChange={this.aktualisiereBenutzername} className="form-control" id="Benutzername" placeholder="Benutzername" />
                </div>
                <p>Ihr Anmeldecode lautet <b>{this.props.activeUser.anmeldecode}</b> <a className="text-primary" href="#" onClick={() => this.copyToClipboard(this.props.activeUser.anmeldecode)}>Anmeldecode in die Zwischenablage kopieren</a> </p>
            </>
        )

        let saveButton = <button className="btn btn-primary" disabled>Neue Email speichern</button>

        let cookiesLoeschenButton = <button className="btn btn-primary" onClick={this.loescheAnmeldcookies}>Anmeldecookies von diesem Browser löschen</button>

        let kontoLoeschenButton = <div style={{ marginTop: '50px' }}><button className="btn btn-primary" onClick={() => $('#modalBenutzerKontoLoeschenDialogButton').click()}>Mein Benutzerkonto löschen</button></div>


        if (this.state.email.length > 0) {
            saveButton = <button className="btn btn-primary" onClick={this.saveEmail}>Neue Email speichern</button>
        }



        const einstellungTable = (

            <>
                {divBenutzername}
                Sie sind mit folgender Emailadresse registriert: <b>{this.state.activeEmail}</b>
                <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Neue Emailadresse registrieren (die alte wird zurückgesetzt)</label>
                    <input type="email" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Neue Emailadresse" />
                </div>
                {saveButton}
                <hr />
                {cookiesLoeschenButton}
                {kontoLoeschenButton}
            </>
        )




        return (<CardWithText title="Kontoeinstellungen" subtitle={this.state.namevoll} body={einstellungTable} stylename="card-header card-header-text card-header-info" />)

    }

    setBearbeitenstatus(e) {
        const bearbeitenstatus = (e.target.value === "1")
        this.setState({ bearbeitenstatus })
    }





    changeOrderSchuelerInGruppe(e) {

        let schueleringruppen = this.state.schueleringruppen;

        let name = e.target.name
        let order = 'asc';
        if (this.state.orderState === "asc") {
            order = 'desc';
        }
        let orderstr = `UPPER(${e.target.name})~${order}`


        if (order === 'desc') {
            schueleringruppen.sort(sort_by(e.target.name, false, function (a) { return a.toUpperCase() }));
        }
        else {
            schueleringruppen.sort(sort_by(e.target.name, true, function (a) { return a.toUpperCase() }));
        }


        this.setState({ schueleringruppen, orderState: order, lastorder: e.target.name + "~" + order })


    }

    setSchuelergruppe(gruppe) {
        this.setState({ activeSchuelerGruppe: gruppe, activePage: 'fachgruppen', })
        this.getSchuelerInGruppe(gruppe.id)
        $('#toggler').click()

    }

    getSchuelerInGruppe2(gruppe) {
        const _this = this
        axios.get('/api/benutzer/schuelergruppen/' + gruppe.id)
            .then(
                result => {
                    if (result.data !== null) {
                        let selectedObjectIDs = []
                        result.data.forEach(
                            function (sig) {
                                selectedObjectIDs.push(sig.id)
                            }
                        )
                        _this.setState({ selectedObjectIDs, activeSchuelerGruppe: gruppe })
                    }


                }
            )
    }


    schuelergruppenRow2(gruppe) {

        return (
            <a key={gruppe.id} className="dropdown-item" href="#" onClick={() => this.getSchuelerInGruppe2(gruppe)} >{gruppe.bezeichnung}</a>
        )
    }


    schuelergruppenRow(gruppe) {

        return (
            <a key={gruppe.id} className="dropdown-item" href="#" onClick={() => this.setSchuelergruppe(gruppe)} >{gruppe.bezeichnung}</a>
        )
    }

    setSchuelergruppe3(gruppe) {
        this.setState({ activeSchuelerGruppe: gruppe, gruppenueberblick: false })
        this.getSchuelerInGruppe(gruppe.id)

    }

    schuelergruppenRow3(gruppe) {

        return (
            <a key={gruppe.id} className="dropdown-item" href="#" onClick={() => this.setSchuelergruppe3(gruppe)} >{gruppe.bezeichnung}</a>
        )
    }



    schueleringruppenRow(schueler) {

        return (

            <tr key={schueler.id}>
                <td>{schueler.benutzername}</td><td></td>
            </tr>

        )

    }

    schueleringruppenUeberblickRow(schueler) {

        const _this = this

        let tds = []
        this.state.schuelergruppen.forEach(
            function (gruppe) {
                let eintrag = ""
                _this.state.schuelerinallenGruppen.forEach(
                    function (siag) {
                        if (schueler.id === siag.benutzer_id && siag.schueler_gruppe_id === gruppe.id) {
                            eintrag = <i className="material-icons">done</i>
                        }
                    }
                )
                tds.push(<td className="text-center"> {eintrag} </td>)
            }
        )



        return (
            <tr key={schueler.id}>
                <td>{schueler.benutzername}</td>{tds}
            </tr>

        )

    }

    neueGruppeanlegen() {

        if (this.props.activeUser === null) return null

        const activeSchuelerGruppe = {
            id: 0,
            bezeichnung: '',
            klassen_code: this.props.activeUser.klassen_code
        }

        let selectedObjectIDs = []
        this.state.schueler.forEach(
            function (benutzer) {
                selectedObjectIDs.push(benutzer.id)
            }
        )

        this.setState({ selectedObjectIDs, activeSchuelerGruppe, activeSchuelerGruppebezeichnung: 'Neue Gruppe' },
            function () {
                $('#modalGruppeDialogButton').click()

            })

    }

    gruppeBearbeiten() {

        let selectedObjectIDs = []
        this.state.schueleringruppen.forEach(
            function (benutzer) {
                selectedObjectIDs.push(benutzer.id)
            }
        )
        this.setState({ selectedObjectIDs, activeSchuelerGruppebezeichnung: this.state.activeSchuelerGruppe.bezeichnung },
            function () {
                $('#modalGruppeDialogButton').click()
            })

    }

    klassengruppenContent() {

        let body = (
            <>
                <ul className="nav nav-pills nav-pills-primary noprint" role="tablist">
                    <li className="nav-item">
                        <a className="nav-link active" data-bs-toggle="tab" href="#link1" role="tablist" aria-expanded="true">
                            Klasse
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" data-bs-toggle="tab" href="#link2" role="tablist" aria-expanded="false">
                            Gruppen
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" data-bs-toggle="tab" href="#link3" role="tablist" aria-expanded="false">
                            Aktivitäten
                        </a>
                    </li>
                </ul>
                <div className="tab-content tab-space">
                    <div className="tab-pane active" id="link1" aria-expanded="true">
                        {this.klassenContent()}

                    </div>
                    <div className="tab-pane" id="link2" aria-expanded="false">
                        {this.fachgruppenContent()}
                    </div>
                    <div className="tab-pane" id="link3" aria-expanded="false">
                        {this.aktivitaetenContent()}
                    </div>
                </div>
            </>
        )

        return (<CardWithText title="Klasse / Gruppen" body={body} stylename="card-header card-header-text card-header-info" />)
    }


    aktivitaetenContent(){

        if (this.state.schueler.length === 0) {
            return (
                <div>Bitte fügen Sie zuerst Schülerinnen und Schüler hinzu.</div>
            )
        }

        const _this = this

       function aktivitaetenrow(akt){
          return(
            <tr>
                <td>{akt.benutzername}</td><td>{convertDateTimeSec(akt.zeit)}</td><td>{akt.bemerkung}</td>
            </tr>
         )

       }

       function loeschen(){
            const sql=`DELETE FROM sfc_benutzer_ablenkungen WHERE benutzer_id in(SELECT id FROM sfc_benutzer WHERE klassen_code='${_this.props.activeUser.klassen_code}')`
            const array={sql}
            axios.post('/api/execcommonquery', array)
            .then(
                result=>{
                    _this.getAktivitaeten()
                }
            )
       }


        return(
            <>
              <button className="btn btn-primary" onClick={loeschen}>Löschen</button>
                <div className="table-responsive">
                    <table className="table table-striped">
                        <tr>
                            <th>Name</th><th>Zeit</th><th>Bemerkung</th>
                        </tr>
                        <tbody>
                            {this.state.aktivitaeten.map(aktivitaetenrow)}
                        </tbody>
                    </table>
                </div>
            </>

        )



    }




    fachgruppenContent() {


        if (this.state.schueler.length === 0) {
            return (
                <div>Bitte fügen Sie zuerst Schülerinnen und Schüler hinzu.</div>
            )
        }
        let printbutton = (
            <button onClick={() => window.print()} className="btn btn-info noprint align-right noprint"> < i className="material-icons"> print </i> Drucken</button>
        )

        let linkgruppeBearbeiten = <a className="dropdown-item" href="#" onClick={this.gruppeBearbeiten}>Ausgewählte Gruppe bearbeiten</a>
        if (this.state.schuelergruppen.length === 0) linkgruppeBearbeiten = null

        let linkgruppeloeschen = <a className="dropdown-item" href="#" onClick={() => $('#modalGruppeLoeschenDialogButton').click()}>Ausgewählte Gruppe löschen</a>
        if (this.state.schuelergruppen.length === 0) linkgruppeloeschen = null

        let dropdowndivider = <div className="dropdown-divider" />
        if (this.state.schuelergruppen.length === 0) dropdowndivider = null

        let buttongruppenUeberblick = <a className="dropdown-item" href="#" onClick={() => this.setState({ gruppenueberblick: true })}>Überblick</a>

        let tableBody = this.state.schueleringruppen.map(this.schueleringruppenRow)
        let tableHead = null

        if (this.state.gruppenueberblick) {
            tableBody = this.state.schueler.map(this.schueleringruppenUeberblickRow)
            let theader = []
            theader.push(<th></th>)
            this.state.schuelergruppen.forEach(
                function (gruppe) {
                    theader.push(<th className="text-center">{gruppe.bezeichnung}</th>)
                }
            )

            tableHead = <tr>{theader}</tr>
        }


        const gruppeDropDown = (
            <div className="dropdown noprint">
                <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Gruppe(n)
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    {linkgruppeBearbeiten}
                    {dropdowndivider}
                    {linkgruppeloeschen}
                    {dropdowndivider}
                    {buttongruppenUeberblick}
                    {dropdowndivider}
                    <a className="dropdown-item" href="#" onClick={this.neueGruppeanlegen} > Neue Gruppe anlegen </a>
                    {this.state.schuelergruppen.map(this.schuelergruppenRow3)}
                </div>
            </div>
        )

        const body = (
            
            <div>
                <div className="rightalign noprint">{gruppeDropDown}{printbutton}</div>

                <div className="table-responsive">
                    <table className="table table-striped">
                        {tableHead}
                        <tbody>
                            {tableBody}
                        </tbody>
                    </table>
                </div>
            </div>
        )

        let title = ""
        let zusatz = ""
        if (this.state.activeSchuelerGruppe !== null) {
            title = `Gruppe "${this.state.activeSchuelerGruppe.bezeichnung}"`
            zusatz = <p> {this.state.schueleringruppen.length} Schüler/innen</p>

        }

        if (this.state.gruppenueberblick) {

            title = 'Alle SuS in den Gruppen'
            zusatz = ""
        }

        return (

            <div>
                <h4 className="card-title">{title}</h4>
                {zusatz}
                {body}
            </div>
        )

    }

    klassenContent() {

        let printbutton = (
            <button onClick={() => window.print()} className="btn btn-info noprint align-right noprint"> < i className="material-icons"> print </i> Drucken</button>
        )

        let buttonBearbeiten = <button value="1" className="btn btn-primary align-right noprint" onClick={this.setBearbeitenstatus}><i className="material-icons">edit</i>Bearbeiten</button>
        if (this.state.bearbeitenstatus) {
            buttonBearbeiten = <button value="0" className="btn btn-danger align-right noprint" onClick={this.setBearbeitenstatus}><i className="material-icons">cancel</i>Bearbeiten Stop</button>
        }

        if (this.state.schueler.length === 0) {
            buttonBearbeiten = null;
            printbutton = null
        }

        let neuerSchuelerButton = <button className="btn btn-primary  noprint" onClick={this.neuerSchueler}>Schüler/in +</button>
        const schuelerTable = (
            
            <div>
                <p> {this.state.schueler.length} Schüler/innen</p>

                <div className="rightalign noprint">{neuerSchuelerButton}{buttonBearbeiten}{printbutton}</div>

                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th className="text-center">#</th>
                                <th><button className="btn btn-default btn-sm btn-round" name="benutzername" onClick={this.changeOrderSchueler}>Benutzername</button></th>
                                <th>Anmeldecode</th>
                                <th><button className="btn btn-default btn-sm btn-round" name="email" onClick={this.changeOrderSchueler}>Email</button></th>
                                <th className="text-center"><button className="btn btn-default btn-sm btn-round" name="kann_stapel_veroeffentlichen" onClick={this.changeOrderSchueler}>Stapel veröffentlichen</button></th>
                                <th>Sperrung</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.schueler.map(this.schuelerRow)}
                        </tbody>
                    </table>
                </div>

            </div>
        )


        return (
            <div>

                {schuelerTable}
            </div>
        )


    }

    printWindowButton() {
        return (
            <button className="btn btn-info noprint" onClick={() => window.print()}> <i className="material-icons">print</i></button>
        )
    }

    lehrkraefteContent() {

        if (this.props.activeUser === null || this.props.activeUser === undefined) return null;

        const neuerBenutzerDropdown = <button className="btn btn-primary" onClick={this.neuerBenutzer}>Lehrperson +</button>

        let thZugrLehrer = <th className="text-center noprint"><button className="btn btn-default btn-sm btn-round" name="kann_lehrpersonen_bearbeiten" onClick={this.changeOrder}>Lehrpersonen</button></th>
        if (this.props.activeUser.kann_lehrpersonen_bearbeiten === 0) thZugrLehrer = null
        let thZugrSchule = <th className="text-center noprint"><button className="btn btn-default btn-sm btn-round" name="kann_schuldaten_bearbeiten" onClick={this.changeOrder}>Schuldaten</button></th>
        if (this.props.activeUser.kann_schuldaten_bearbeiten === 0) thZugrSchule = null

        let benutzerTable = (
            <>
                <div className="rightalign noprint">{neuerBenutzerDropdown} {this.printWindowButton()}</div>

                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th className="text-center">#</th>
                                <th><button className="btn btn-default btn-sm btn-round" name="benutzername" onClick={this.changeOrder}>Benutzername</button></th>
                                <th><button className="btn btn-default btn-sm btn-round" name="namevoll" onClick={this.changeOrder}>vollst. Name</button></th>
                                <th><button className="btn btn-default btn-sm btn-round" name="email" onClick={this.changeOrder}>Email</button></th>
                                <th className="onlyprint"></th>
                                {thZugrLehrer}
                                {thZugrSchule}
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.benutzer.map(this.userRow)}
                        </tbody>
                    </table>
                </div>


                <div className="rightalign noprint">{neuerBenutzerDropdown} {this.printWindowButton()}</div>
            </>
        )

        let contentLehrpersonen = <CardWithText title="Lehrpersonen" body={benutzerTable} stylename="card-header card-header-text card-header-info" />
        if (this.props.activeUser.kann_lehrpersonen_bearbeiten===0)contentSchule=contentLehrpersonen

        let buttonSchuldaten=<button className="btn btn-primary" onClick={()=>$('#modalSchuledialogButton').click()}>Schuldaten bearbeiten / Lizenz</button>   
        let contentSchule=(
            <CardWithText title="Schule" body={buttonSchuldaten} stylename="card-header card-header-text card-header-info" />
        )

        if (this.props.activeUser.kann_schuldaten_bearbeiten===0)contentSchule=null

        return (
            <div>
                {contentSchule}
                {contentLehrpersonen}
            </div>
        )



    }

    setKategorie(kategorie) {

        let activeModule = this.state.activeModule;
        activeModule.flashcards_kategorie_id = kategorie.id
        this.setState({ activeModule })
    }

    kategorienRow(kategorie) {


        return (
            <a key={kategorie.id} className="dropdown-item" href="#" onClick={() => this.setKategorie(kategorie)}>{kategorie.kategorie} ({kategorie.thema})</a>
        )
    }

    getAnzahlzukorrigierende_modulteile_insgesamt(unterrichtsmodule){

        let zukorrigierende_modulteile_insgesamt = 0
        unterrichtsmodule.forEach(
                function(modul){
                    zukorrigierende_modulteile_insgesamt = zukorrigierende_modulteile_insgesamt + modul.anzahl_zukorrigierende_modulteile
                }
            )
  
            return zukorrigierende_modulteile_insgesamt
    }

    setUnterrichtsmoduleAndBenutzerordner(result) {

        const _this = this
        
        if (result.data.unterrichtsmodule !== null) {   

            this.setState({zukorrigierende_modulteile_insgesamt: this.getAnzahlzukorrigierende_modulteile_insgesamt(result.data.unterrichtsmodule), unterrichtsmodule: result.data.unterrichtsmodule }, function () { _this.flashcardsSortieren(_this.state.flashcardSortField) })
        }
        if (result.data.benutzerordner !== null) this.setState({ benutzerordner: result.data.benutzerordner })
    }

    getUnterrichtsmodule() {
        if (this.props.activeUser===undefined || this.props.activeUser===null) return
        axios.get('/api/unterrichtsmodule/' + this.props.activeUser.id)
            .then(
                result => {
                    if (result.data !== null) {
                        this.setUnterrichtsmoduleAndBenutzerordner(result)
                    }
                }
            )
    }

    saveModul() {

        if (this.props.activeUser === null) return
          
        let neuesUnterrichtsmodulPost={
            benutzerOrdner: this.state.selectedBenutzerOrdner,
            unterrichtsModul: this.state.activeModule
        }


        axios.post('/api/unterrichtsmodule', neuesUnterrichtsmodulPost)
            .then(
                result => {
                    if (result.data.unterrichtsmodule !== null) this.setState({ unterrichtsmodule: result.data.unterrichtsmodule }, function () { this.flashcardsSortieren(this.state.flashcardSortField) })
                    if (result.data.benutzerordner !== null) this.setState({ benutzerordner: result.data.benutzerordner }, function () { this.forceUpdate() })
                }
            )
    }

    neueKategorie() {

        const _this = this

        const flashcardsThemaUKategorie = {
            id: 0,
            flashcards_themen_id: this.state.activeFlashcardsThema.id,
            kategorie: this.state.neueKategorie,
            thema: ''
        }




        axios.post('/api/flashcardsthemen', flashcardsThemaUKategorie).then(
            result => {
                if (result.data !== null) {
                    this.setState({ flashcardsThemaUKategorien: result.data })
                    result.data.forEach(
                        function (kategorie) {
                            if (kategorie.kategorie === _this.state.neueKategorie) {
                                let activeModule = _this.state.activeModule;
                                activeModule.flashcards_kategorie_id = kategorie.id
                                _this.setState({ activeModule })

                            }
                        }
                    )
                }
            }
        )

    }

    flashcardsthemenRow(thema) {

        return (
            <a key={thema.id} className="dropdown-item" href="#" onClick={() => this.setState({ activeFlashcardsThema: thema })}>{thema.bezeichnung}</a>
        )
    }

    modalNeueKategorieDialog() {

        if (this.state.activeFlashcardsThema === null) return null

        let title = this.state.activeFlashcardsThema.bezeichnung

        const body = (
            <table className="table">
                <tbody>
                    <tr>
                        <td width="100">Titel *</td><td><input type="text" className="form-control" value={this.state.neueKategorie} placeholder="Kategorie" onChange={(e) => this.setState({ neueKategorie: e.target.value })} /> </td>
                    </tr>
                    <tr>
                        <td>Thema</td>
                        <td>
                            <div className="dropdown">
                                <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {title}
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    {this.state.flashcardsthemen.map(this.flashcardsthemenRow)}
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        )

        const okButtonenabled = this.state.neueKategorie !== ""

        return (
            <Modal
                modalid="modalNeueKategorieDialog"
                buttonid="modalNeueKategorieDialogButton"
                title="Neue Kategorie erstellen"
                body={body}
                okButtonTitle="Erstellen"
                okButtonenabled={okButtonenabled}
                okButtonClick={this.neueKategorie}
                cancelButton={true}
            />
        )
    }

    changeNeuerBenutzerOrdner(e){

        const _this = this

        let name = e.target.value       
        let selectedBenutzerOrdner = this.state.selectedBenutzerOrdner
        selectedBenutzerOrdner.id = 0
        selectedBenutzerOrdner.name=name

        this.state.benutzerordner.forEach(
            function(ordner){
                if (ordner.name===name){
                    selectedBenutzerOrdner = ordner
                }

            }
        )



        this.setState({selectedBenutzerOrdner})
    }


    modalModulEditDialog() {

        const _this = this

        if (this.state.activeModule === null) return null

        let title = 'Kategorie (nicht gewählt)'

        if (this.state.activeModule !== null) {
            this.state.flashcardsThemaUKategorien.forEach(
                function (kategorie) {
                    if (kategorie.id === _this.state.activeModule.flashcards_kategorie_id) {
                        title = kategorie.kategorie
                    }
                }
            )
        }

        let menuItemNeueKategorie = null
        if (this.props.activeUser.ist_schueler === 0) {
            menuItemNeueKategorie = <a key={0} className="dropdown-item" href="#" onClick={() => $('#modalNeueKategorieDialogButton').click()}>Neue Kategorie +</a>
        }

      
        
        let ordnertitle = this.state.selectedBenutzerOrdner.name        
        if (ordnertitle==="kein Ordner") ordnertitle = 'Ordner (optional)'


        const div = (

            <>
                <table className="table">
                    <tbody>
                        <tr>
                            <td width="100">Titel *</td><td><input type="text" name="bezeichnung" className="form-control" value={this.state.activeModule.bezeichnung} placeholder="Titel" onChange={this.aktualisiereModul} /> </td>
                        </tr>
                        <tr>
                            <td>Kategorie *</td>
                            <td>
                                <div className="dropdown">
                                    <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        {title}
                                    </button>
                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        {menuItemNeueKategorie}
                                        {this.state.flashcardsThemaUKategorien.map(this.kategorienRow)}
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>Ordner</td>
                            <td>
                                <div className="dropdown">
                                  

                                    <input type="text"  onChange={this.changeNeuerBenutzerOrdner}   value={ordnertitle} placeholder="" className="form-control dropdown-toggle" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />

                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                                        {this.state.neuerStapelBenutzerOrdner.map(this.benutzerordnerRow3)}
                                    </div>
                                </div>
                            </td>
                        </tr>



                    </tbody>
                </table>
                <div id="accordion" role="tablist">
                    <h5 className="mb-0"><a data-bs-toggle="collapse" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">Details (optional)<i className="material-icons">keyboard_arrow_down</i></a></h5>
                    <div id="collapseOne" className="collapse" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordion">
                        <table className="table">
                            <tbody>
                                <tr>
                                    <td width="100">Jahrgangsstufen </td><td><input type="text" name="jahrgangsstufen" className="form-control" value={this.state.activeModule.jahrgangsstufen} placeholder="Jahrgangsstufen" onChange={this.aktualisiereModul} /></td>
                                </tr>
                                <tr>
                                    <td>Suchbegriffe</td><td><input type="text" name="faecher" className="form-control" value={this.state.activeModule.faecher} placeholder="Suchbegriffe" onChange={this.aktualisiereModul} /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>



            </>
        )

        let okButtonTitle = "Hinzufügen"
        title = "Stapel hinzufügen"
        let leftButton = null
        if (this.state.activeModule.id > 0) {
            okButtonTitle = "Eigenschaften speichern"
            title = `Eigenschaften von ${this.state.activeModule.bezeichnung}`
            leftButton = <button onClick={this.editUnterrichtsmodul} className="btn btn-primary">Karteikarten bearbeiten</button>
            
        }

        return (
            <Modal
                modalid="modalModulEditDialog"
                buttonid="modalModulEditDialogButton"
                body={div}
                title={title}
                okButtonTitle={okButtonTitle}
                okButtonClick={this.saveModul}
                okButtonenabled={this.state.activeModule.flashcards_kategorie_id > 0 && this.state.activeModule.bezeichnung !== ""}
                cancelButton={true}
                leftButton1={leftButton}
            />
        )
    }


    setEditModule(modul){

        let neuerStapelBenutzerOrdner=[]

        neuerStapelBenutzerOrdner.push(
            {
                id:0,
                benutzer_id:this.props.activeUser.id,
                parent_id:0,
                anzahl_dateien:0,
                name:'Neuer Ordner'
            }
        )    
        
        let keinOrdner={
                id:-99,
                benutzer_id:this.props.activeUser.id,
                parent_id:0,
                anzahl_dateien:0,
                name:'kein Ordner'
        }
        neuerStapelBenutzerOrdner.push(keinOrdner)

        let selectedBenutzerOrdner= keinOrdner



        this.state.benutzerordner.forEach(
            function(ordner){
                if (ordner.name !=="Team-Ordner")  neuerStapelBenutzerOrdner.push(ordner)  
                if (ordner.id===modul.benutzer_ordner_id) selectedBenutzerOrdner=ordner 
            }
        )

        this.setState({ activeModule: modul, selectedObjectIDs: [],selectedBenutzerOrdner, neuerStapelBenutzerOrdner}, function () { $('#modalModulEditDialogButton').click() })

     

    }

    editUnterrichtsmodul() {
        //modal schliessen
        $('#modalModulEditDialog').click();
        let link = "/editunterrichtsmodul/" + this.state.activeModule.id + '/user'
        browserHistory.push(link)
    }

    aktualisiereModul(e) {
        let activeModule = this.state.activeModule;
        if (e.target.name === "bezeichnung") activeModule.bezeichnung = e.target.value
        if (e.target.name === "jahrgangsstufen") activeModule.jahrgangsstufen = e.target.value
        if (e.target.name === "faecher") activeModule.faecher = e.target.faecher

        this.setState({ activeModule })
    }

    ausDemOrdnerEntfernen(modul) {

        const sql = `UPDATE sfc_unterrichtsmodule SET benutzer_ordner_id=0 WHERE id=${modul.id}`
        const array = {
            sql
        }
        axios.post('/api/execcommonquery', array)
            .then(
                result => {
                    this.getUnterrichtsmodule()
                }
            )
    }

    modalModulVeroeffentlichenDialog() {

        if (this.state.activeModule === null) return null

        let title = `Stapel "${this.state.activeModule.bezeichnung}" veröffentlichen`
        let okButtonTitle = "Veröffentlichen"

        let body = (

            <table className="table">
                <tbody>
                    <tr>
                        <td width={120}>Autor/in (Pseudonym)</td><td><input className="form-control" placeholder="Autor" type="text" value={this.state.public_autor_pseudonym} onChange={(e) => this.setState({ public_autor_pseudonym: e.target.value })} /></td>
                    </tr>
                    <tr>
                        <td>Bemerkungen</td><td><textarea className="form-control" rows={3} type="textarea" value={this.state.public_bemerkungen} onChange={(e) => this.setState({ public_bemerkungen: e.target.value })} /></td>
                    </tr>
                </tbody>
            </table>
        )

        let okButtonenabled = this.state.public_autor_pseudonym !== ""       


        if (this.state.activeModule.ist_veroeffentlicht===1){
            title= 'Veröffentlichung zurücknehmen'
            okButtonenabled = true
            okButtonTitle='Veröffentlichung zurücknehmen'
            body=(
                <p>Die Veröffentlichung des Stapels "{this.state.activeModule.bezeichnung}" wird zurückgenommen</p>
            )

        }


        return (
            <Modal
                modalid="modalModulVeroeffentlichenDialog"
                buttonid="modalModulVeroeffentlichenDialogButton"
                title={title}
                body={body}
                okButtonClick={this.modulVeroeffentlichen}
                okButtonTitle={okButtonTitle}
                okButtonenabled={okButtonenabled}
                cancelButton={true}
            />
        )
    }

    modulVeroeffentlichen() {


        if (this.state.activeModule.ist_veroeffentlicht===1){

            const sql = `UPDATE sfc_unterrichtsmodule SET ist_veroeffentlicht=0 WHERE id=${this.state.activeModule.id}`
            const array={sql}
            axios.post('/api/execcommonquery', array)
            .then(
                result=>{
                    this.getUnterrichtsmodule()
                    this.alertMessage('Die Veröffentlichung des Stapels wurde zurückgenommen.')
                }
            )

            return
        }


        const jahr=new Date().getFullYear()
        if (this.props.activeSchule.lizenz_jahr < jahr){
            this.alertMessage(parse("<div>Keine Schul-Lizenz für das Jahr <b>" + jahr  + "</b> für diese Funktion vorhanden. Sie können unter 'Schuldaten bearbeiten' eine Lizenz bestellen. Bitte kontaktieren Sie Ihren Schuladministrator oder schreiben Sie eine Mail an <i>info@schoolflashcards.eu</i></div>" ))
            return
        }

        const modul = {
            id: this.state.activeModule.id,
            public_autor_pseudonym: this.state.public_autor_pseudonym,
            public_bemerkungen: this.state.public_bemerkungen,
            ist_veroeffentlicht: 1
        }


        axios.post('/api/unterrichtsmodule/veroeffentlichen', modul)
            .then(
                result => {

                }
            )

    }

    clickModule(modul, mode) {
        this.setBenutzereinstellung("lastUModuleID", modul.id)
        if (mode===null){
            browserHistory.push('/previewunterrichtsmodul/' + modul.id)
        }
        if (mode==="quizstarten"){
            browserHistory.push('/previewunterrichtsmodul/' + modul.id + '/quiz')
        }
        if (mode==="quizwettbewerbstarten"){
            $('#modalShareBenutzerdialogButton').click()
        }
        
    }

    flashCard2(modul) {

        if (modul.benutzer_ordner_id !== this.state.parent_id) return null

        let countCards = "1 Karte"
        if (modul.anzahl_modulteile === 0) countCards = "0 Karten"
        if (modul.anzahl_modulteile > 1) countCards = modul.anzahl_modulteile + " Karten"

        let link1 = <a href="#" onClick={() => this.clickModule(modul, null)}><img src="/assets/img/karteikarte4.png" width="60" /></a>
        let link2 = <a href="#" onClick={() => this.clickModule(modul,"quizstarten")}><div title="Quiz starten"><b>{modul.bezeichnung}</b> {countCards}, {modul.kategorie} ({modul.thema}) </div></a>
        if (modul.anzahl_modulteile === 0) {
            link1 = <img src="/assets/img/karteikarte4.png" width="60" />
            link2 = <div title="Quiz starten"><b>{modul.bezeichnung}</b> {countCards}, {modul.kategorie} ({modul.thema}) </div>
        }
     
        let korrekturstatus=null
        if ( modul.ist_in_stapelablage>0){
           if (modul.anzahl_zukorrigierende_modulteile===0) {
                korrekturstatus= <KorrekturStatus status="ok" text="Korrigiert und ok!" />  
            }
            else{
                korrekturstatus= <KorrekturStatus status="false" text="Bitte zu den Aufgaben wechseln und diesen Stapel verbessern!" />  
            }               
        } 

        let wird_gemeinsam_bearbeitet = null
        if (modul.wird_gemeinsam_bearbeitet > 0){
            wird_gemeinsam_bearbeitet=<p><b>Wird im Team bearbeitet</b></p>
        }
        let ist_veroeffentlicht = null
        if (modul.ist_veroeffentlicht===1){
            ist_veroeffentlicht = <p><font color="0000ff"><b>Dieser Stapel wurde veröffentlicht</b></font></p>
        }

        return (
            <div key={modul.id} className="col-sm-12 col-md-12 col-lg-12">
                <table className="table">
                    <tbody>
                        <tr>
                            <td width="60">{link1}</td>
                            <td width="80">{this.flashCardsMenuButton(modul)}</td>
                            <td >{link2}</td>
                            <td width="150" className="text-center"> {this.getModulDetails(modul)} </td>
                            <td className="text-center">{korrekturstatus} {wird_gemeinsam_bearbeitet} {ist_veroeffentlicht}</td>
                        </tr>
                    </tbody>
                </table>


            </div>
        )
    }

    zurKorrekturAbgeben() {

        const _this = this
       
        let count = 0

        this.state.selectedObjectIDs.forEach(
            function (id){
                const stapelablage =   {
                    id:0,
                    unterrichtsmodule_id: _this.state.activeModule.id,
                    stapel_eigentuemer_id:_this.props.activeUser.id,
                    stapel_absender_id:id,
                    status:'Warten auf Überprüfung'
                }    

                

                axios.post('/api/stapelablage', stapelablage)
                .then(
                    result => {  
                        count ++
                        if (count ===_this.state.selectedObjectIDs.length)  _this.alertMessage('Der Status des Stapels wurde auf "Warten auf Überprüfung" gesetzt und an die gewählte/n Person/en geschickt.')
                        
                    }
                )
            }
        )
        this.setState({zurkorrekturabgeben:false, stapel_absender_id:false, selectedObjectIDs:[]})

    }

    flashCardsMenuButton(modul) {


        let titleVeroeffentlichen = 'Veröffentlichen'
        if (modul.ist_veroeffentlicht===1) titleVeroeffentlichen = 'Veröffentlichung zurücknehmen'

        

        let menuLinkVeroeffentlichen = <a key={modul.id + 305} className="dropdown-item" href="#" onClick={() => this.setState({ activeModule: modul }, function () { $('#modalModulVeroeffentlichenDialogButton').click() })}>{titleVeroeffentlichen}</a>
        if (this.props.activeUser.kann_stapel_veroeffentlichen === 0) menuLinkVeroeffentlichen = null

        let menuLinkGemeinsamenStapelMachen = <a key={modul.id + 309} className="dropdown-item" href="#" onClick={() => this.setState({ activeModule: modul,gemeinsamenstapelmachen:true}, function () { $('#modalShareBenutzerdialogButton').click() })}>Im Team bearbeiten mit...</a>

       
        let menuLinkZurKorrekturAbgeben = <a key={modul.id + 308} className="dropdown-item" href="#" onClick={() => this.setState({ activeModule: modul,zurkorrekturabgeben: true }, function () { $('#modalShareBenutzerdialogButton').click() })}>Zur Korrektur abgeben</a>
       // if (this.props.activeUser.ist_schueler === 0 ) menuLinkZurKorrekturAbgeben = null

        let ausdemOrdnerNehmen = <a key={modul.id + 300} className="dropdown-item" href="#" onClick={() => this.ausDemOrdnerEntfernen(modul)}>Aus dem Ordner entfernen</a>
        if (modul.benutzer_ordner_id === 0) ausdemOrdnerNehmen = null

        let divider = <div className="dropdown-divider" />
        let linkQuiz = <a key={modul.id + 301} className="dropdown-item" href="#" onClick={() => this.clickModule(modul,"quizstarten")}>Quiz starten</a>
        let linkQuizWettbewerb = <a key={modul.id + 311} className="dropdown-item" href="#" onClick={() => this.setState({ activeModule: modul,wettbewerbmachen: true }, function () { $('#modalShareBenutzerdialogButton').click() })}>Quiz-Wettrennen</a>
        if (modul.anzahl_modulteile === 0) {
            divider = null
            linkQuiz = null
            linkQuizWettbewerb = null;
        }

        let menuLinkStapelLoeschen = <a key={modul.id + 306} className="dropdown-item" href="#" onClick={() => this.setState({ activeModule: modul }, function () { $('#modaldeleteModulDialogButton').click() })} >Stapel löschen</a>

        let menuLinkEigenschaften = <a key={modul.id + 302} className="dropdown-item" href="#" onClick={() => this.setEditModule(modul)}>Eigenschaften</a>
        let menuLinkTeilen = <a key={modul.id + 304} className="dropdown-item" href="#" onClick={() => this.setState({ activeModule: modul, selectedObjectIDs: [], activeSchuelerGruppe: null, teilen_array: { teilen_aufgabe: '', teilen_ist_aufgabe: false, teilen_aufgabe_abgabe_bis: new Date() } }, function () { $('#modalShareBenutzerdialogButton').click() })}>Teilen</a>
        let menuLinkStapelNichtMehrImTeamBearbeiten = null

        let menuLinkDateiOeffnen =  <a key={modul.id + 310} className="dropdown-item" href="#" onClick={() => this.setState({ activeModule: modul}, function(){$('#fileInput').click()})   }>Aus *.txt, *.csv einlesen...</a>

        if (modul.wird_gemeinsam_bearbeitet > 0){
            menuLinkZurKorrekturAbgeben = null
            ausdemOrdnerNehmen = null
            if (this.props.activeUser.id !== modul.eigentuemer_id){
                menuLinkStapelLoeschen = null
                menuLinkGemeinsamenStapelMachen = null
                menuLinkEigenschaften = null
                menuLinkTeilen = null
                menuLinkVeroeffentlichen = null
                menuLinkDateiOeffnen = null
            }
            if (this.props.activeUser.id === modul.eigentuemer_id){
                 menuLinkStapelNichtMehrImTeamBearbeiten = <a key={modul.id + 310} className="dropdown-item" href="#" onClick={() => this.nichtMehrGemeinsamBearbeiten(modul) } > Diesen Stapel nicht mehr im Team bearbeiten</a>

            }

        }
        return (

            <div className="dropdown">
                <button className="btn btn-default btn-sm btn-round dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className="material-icons"> menu </i>
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{ backgroundColor: 'white' }}>
                    {linkQuiz}
                    {linkQuizWettbewerb}
                    {divider}
                    {menuLinkEigenschaften}
                    
                    <a key={modul.id + 303} className="dropdown-item" href="#" onClick={() => this.setState({ activeModule: modul, selectedObjectIDs: [] }, function () { this.editUnterrichtsmodul() })}>Karteikarten bearbeiten</a>
                    {menuLinkTeilen}
                    {menuLinkGemeinsamenStapelMachen}
                    {menuLinkStapelNichtMehrImTeamBearbeiten}
                    {menuLinkVeroeffentlichen}
                    {menuLinkZurKorrekturAbgeben}
                    {menuLinkDateiOeffnen}
                    
                    <div className="dropdown-divider" />
                    {ausdemOrdnerNehmen}
                    {menuLinkStapelLoeschen}
                    
                </div>
            </div>
        )

    }

    

    saveFile(fileName, urlFile) {
        let a = document.createElement("a");
        a.style = "display: none";
        document.body.appendChild(a);
        a.href = urlFile;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(urlFile);
        a.remove();
    }

    replaceKommas(value) {
        return value.replace(/,/g, '~')
    }

    replaceTildes(value) {
        return value.replace(/~/g, ',')
    }


    handleOpenDialog(e) {
        // Note that the ref is set async, so it might be null at some point
        if (buttonRef.current) {
            buttonRef.current.open(e);
        }
    }

    handleOnFileLoad(data) {


        const unterrichtsModul = {
            id: 0,
            eigentuemer_id: this.props.activeUser.id,
            bezeichnung: this.replaceTildes(data[1].data[0]),
            jahrgangsstufen: this.replaceTildes(data[1].data[1]),
            faecher: this.replaceTildes(data[1].data[2]),
            thema: this.replaceTildes(data[1].data[3]),
            kategorie: this.replaceTildes(data[1].data[4])
        }

        let unterrichtsModulTeile = []

        for (let i = 2; i < data.length; i++) {

            let unterrichtsModulTeil = {
                frage: this.replaceTildes(data[i].data[5]),
                antwort1: this.replaceTildes(data[i].data[6]),
                antwort2: this.replaceTildes(data[i].data[7]),
                antwort3: this.replaceTildes(data[i].data[8]),
                antwort4: this.replaceTildes(data[i].data[9]),
                antwort5: this.replaceTildes(data[i].data[10]),
                multiplechoice: data[i].data[10],
                richtigeantwortnummer: data[i].data[11],
                mussfrageschriftlichbeantworten: data[i].data[12],
                aktiv: data[i].data[13],
                notizen: this.replaceTildes(data[i].data[14]),
                zusatzinfo: this.replaceTildes(data[i].data[15]),
                frage_videobild_url: this.replaceTildes(data[i].data[16]),
                wahroderfalsch: data[i].data[17],
                korrekturmarkierung: data[i].data[18]


            }
            unterrichtsModulTeile.push(unterrichtsModulTeil)

        }

        const uModuleMitModulTeilen = {
            unterrichtsModul,
            unterrichtsModulTeile
        }

        axios.post('/api/unterrichtsmodule/importcsv', uModuleMitModulTeilen)
            .then(
                result => {
                    
                }
            )

    }


    flashCardDashboard(modul) {

        let countCards = "1 Karte"
        if (modul.anzahl_modulteile === 0) countCards = "0 Karten"
        if (modul.anzahl_modulteile > 1) countCards = modul.anzahl_modulteile + " Karten"


        return (
            <div className="text-center  flashcard2">
                <h3 className="card-title text-primary" >{modul.bezeichnung}</h3>
                <p className="category">{countCards}, {modul.kategorie} ({modul.thema})</p>
                Autor/in: <b>{modul.autor}</b><br />
                {this.getModulDetails(modul)}    
             </div>
        )
    }

    flashCard(modul) {

        if (modul.benutzer_ordner_id !== this.state.parent_id) return null

        const elementID = `flashcard${modul.id}`
        const elementh3ID = `flashcardh3${modul.id}`


        let geteiltebenutzer = null
        if (modul.geteiltebenutzer !== null) {
            const collapseId = `collapse${modul.id}`
            const collapseRef = `#collapse${modul.id}`
            let str = modul.geteiltebenutzer.substr(0, 30) + "..."
            let titleCollapse = "Geteilt mit " + str;
            if (modul.ist_aufgabe === 1) titleCollapse = "Aufgabe " + str;

            geteiltebenutzer = (
                <>
                    <a className="text-primary" data-bs-toggle="collapse" href={collapseRef} aria-expanded="false" aria-controls={collapseId}>
                        {titleCollapse}
                    </a>
                    <div className="collapse" id={collapseId}>
                        {modul.geteiltebenutzer}
                    </div>
                </>
            )

            if (str.length <= 30) {
                geteiltebenutzer = <i>Geteilt mit {modul.geteiltebenutzer.substr(0, 30)}</i>
            }
        }

        const key = modul.id + 200000

        let korrekturstatus=null
        if (modul.ist_in_stapelablage>0){

            if (modul.anzahl_zukorrigierende_modulteile===0) {
                korrekturstatus= <KorrekturStatus status="ok" text="Korrigiert und ok!" />  
            }
            else{
                korrekturstatus= <KorrekturStatus status="false" text="Bitte zu den Aufgaben wechseln und diesen Stapel verbessern!" />  
            }           

        }

        let wird_gemeinsam_bearbeitet = null
        if (modul.wird_gemeinsam_bearbeitet > 0){
            wird_gemeinsam_bearbeitet=<p><b>Wird im Team bearbeitet</b></p>
        }

        let ist_veroeffentlicht = null
        if (modul.ist_veroeffentlicht===1){
            ist_veroeffentlicht = <p><font color="0000ff"><b>Dieser Stapel wurde veröffentlicht</b></font></p>
        }

    
        return (

            <div key={key} className="col">
                <div>
                    <div className="flashcard" id={elementID} style={{ padding: '10px' }}>
                        <div className="rightalign">{this.flashCardsMenuButton(modul)}</div>
                        <div title="Quiz starten" onClick={() => this.clickModule(modul, "quizstarten")} className="text-center" style={{ cursor: 'pointer', marginTop: '15px' }}>
                            <h3 id={elementh3ID} className="card-title text-primary">{modul.bezeichnung}</h3>
                            <p className="category">{modul.anzahl_modulteile} Karten, {modul.kategorie} ({modul.thema})</p>
                        
                            Autor/in: <b>{modul.autor}</b><br />
                            {this.getModulDetails(modul)}       
                            {korrekturstatus}
                            {wird_gemeinsam_bearbeitet}
                            {ist_veroeffentlicht}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    getModulDetails(modul){
        return(
            <div>
                <small className="form-text text-muted">erstellt: <b>{convertDate(modul.datum)}</b><br/>geändert: <b>{convertDateTime(modul.datum_zuletztgeaendert)}</b><br/>letztes Quiz: <b>{convertDateTime(modul.datum_letztesquiz)}</b></small> 
            </div>
            
        )
    }

    neuesModul() {

        let benutzer_ordner_id = 0
        if (this.state.activeBenutzerOrdner !== null) benutzer_ordner_id = this.state.activeBenutzerOrdner.id

        const activeModule = {
            id: 0,
            bezeichnung: '',
            eigentuemer_id: this.props.activeUser.id,
            jahrgangsstufen: '',
            faecher: '',
            flashcards_kategorie_id: 0,
            benutzer_ordner_id
        }

        this.setState({ activeModule, },
            function () {
                this.setEditModule(activeModule)
                
            })
    }


    

    modalModuleTeilenDialog() {

        const body =
            <SchuelerGruppenPanel
                dataset={this.state.allegeteilteModuleFuerBenutzer}
                dialogRow={this.modulDialogRow}
                markieren={this.markierenModul}
                headertitle="Senden an"
            />

        const okButtonenabled = this.state.selectedObjectIDs.length > 0
       // if (this.state.allegeteilteModuleFuerBenutzer.length===0)okButtonenabled=false

        let leftButton1 = <button  className="btn btn-danger" onClick={this.deleteFromMail}><i className="material-icons"> close </i>Aus dem Briefkasten entfernen</button>
        if (okButtonenabled===false){
            leftButton1 = <button  className="btn btn-danger" disabled><i className="material-icons"> close </i>Aus dem Briefkasten entfernen</button>
        }

    

        return (
            <Modal
                modalid="modalModuleTeilenDialog"
                buttonid="modalModuleTeilenDialogButton"
                body={body}
                title="Briefkasten - eingegangene Stapel"
                okButtonTitle="Kopieren"
                okButtonClick={this.copymodules}
                cancelButton={true}
                okButtonenabled={okButtonenabled}
                leftButton1={leftButton1}
            />
        )
    }


    copymodules() {
        const _this = this
        this.state.selectedObjectIDs.forEach(
            function (umodulid) {
                _this.copyUnterrichtsmodul(umodulid)
            }
        )
    }

    deleteFromMail(){
        const _this = this
        let num=1
        this.state.selectedObjectIDs.forEach(
            function (umodulid) {
                const sql=`DELETE FROM sfc_unterrichtsmodule_geteilt WHERE unterrichtsmodule_id=${umodulid} AND geteilt_mit_benutzer_id=${_this.props.activeUser.id}`
                const array={sql}
                axios.post('/api/execcommonquery', array)
                if (num===_this.state.selectedObjectIDs.length){
                    _this.getAllegeteilteFuerBenutzer()
                    _this.setState({selectedObjectIDs:[]})
                }
                num++
            }
        )

    }


    ordnerAktualisieren() {

        const _this = this

        this.setState({ activeBenutzerOrdner: null, parent_id: 0 },
            function () {
                _this.forceUpdate()
            })

    }


    flashcardsSortieren(field) {


        let unterrichtsmodule = this.state.unterrichtsmodule

        if (field === "Titel") {
            unterrichtsmodule.sort(sort_by('bezeichnung', false, function (a) { return a.toUpperCase() }));
        }
        if (field === "Kategorie") {
            unterrichtsmodule.sort(sort_by('kategorie', false, function (a) { return a.toUpperCase() }));
        }
        if (field === "Thema") {
            unterrichtsmodule.sort(sort_by('thema', false, function (a) { return a.toUpperCase() }));
        }
        if (field === "Datum") {
            unterrichtsmodule.sort(sort_by('datum_zuletztgeaendert', true, function (a) { return new Date(a) }));
        }
        this.setState({ unterrichtsmodule, flashcardSortField: field })

    }



    csvReaderRef() {

        return (

            <CSVReader
                ref={buttonRef}
                onFileLoad={this.handleOnFileLoad}
                noClick
                noDrag
            >
                {({ file }) => (
                    <a
                        className="dropdown-item"
                        onClick={this.handleOpenDialog}
                        href="#"
                    >
                        Stapel aus *.csv einlesen...
                    </a>
                )}
            </CSVReader>
        )
    }


    flashcardsContent() {

        let checkTitel = null
        let checkKategorie = null
        let checkThema = null
        let checkDatum = null

        let linkNeueVorlage = null
        if (this.props.activeUser !== null && this.props.activeUser.ist_schueler===0){
            linkNeueVorlage = <><div className="dropdown-divider" /><a className="dropdown-item" target="_blank" href="/utils/vorlage.pdf">Flashcardsvorlage zum Ausdrucken...</a></>
        }



        let linkNeuerOrdner = <a className="dropdown-item" onClick={this.neuerBenutzerOrdner} href="#">Neuer Ordner...</a>
        if (this.state.parent_id > 0) {
            linkNeuerOrdner = null
        }

        let neuerStapelButton = (
            <div className="dropdown">
                <button className="btn btn-primary" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className="material-icons"> add </i>
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a className="dropdown-item" onClick={this.neuesModul} href="#">Neuer Stapel...</a>
                    {this.csvReaderRef()}
                    <a className="dropdown-item" href="#" onClick={() => browserHistory.push('/globalemodule')}>Globalen Kartenstapel kopieren...</a>
                    <div className="dropdown-divider" />
                    {linkNeuerOrdner}
                    {linkNeueVorlage}

                </div>
            </div>
        )

        let iTitle = <i className="material-icons"> table_rows </i>
        let checkListe = <i className="material-icons"> check </i>
        let checkSymbole = null
        if (this.getBenutzereinstellung('showFlashCardsSymbole') !== null && this.getBenutzereinstellung('showFlashCardsSymbole').boolvalue) {
            iTitle = <i className="material-icons"> grid_view </i>
            checkListe = null
            checkSymbole = <i className="material-icons"> check </i>

        }

        const check = <i className="material-icons"> check </i>
        if (this.state.flashcardSortField === "Titel") checkTitel = check
        if (this.state.flashcardSortField === "Kategorie") checkKategorie = check
        if (this.state.flashcardSortField === "Thema") checkThema = check
        if (this.state.flashcardSortField === "Datum") checkDatum = check

        let ansichtdropdown = (
            <div className="dropdown">
                <button className="btn btn-info dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {iTitle}
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a className="dropdown-item" style={{ zIndex: '9999' }} onClick={() => this.setBenutzereinstellung("showFlashCardsSymbole", true)} href="#">Karten {checkSymbole}</a>
                    <a className="dropdown-item" style={{ zIndex: '9999' }} onClick={() => this.setBenutzereinstellung("showFlashCardsSymbole", false)} href="#">Liste {checkListe}</a>
                    <div className="dropdown-divider" />
                    <a name="Titel" className="dropdown-item" onClick={(e) => this.flashcardsSortieren(e.target.name)} href="#">Titel {checkTitel}</a>
                    <a name="Kategorie" className="dropdown-item" onClick={(e) => this.flashcardsSortieren(e.target.name)} href="#">Kategorie {checkKategorie}</a>
                    <a name="Datum" className="dropdown-item" onClick={(e) => this.flashcardsSortieren(e.target.name)} href="#">Datum {checkDatum}</a>
                    <a name="Thema" className="dropdown-item" onClick={(e) => this.flashcardsSortieren(e.target.name)} href="#">Thema {checkThema}</a>
                </div>
            </div>
        )


        let moduleGeteiltButton = null
        if (this.state.allegeteilteModuleFuerBenutzer.length > 0) {
            moduleGeteiltButton = <button className="btn btn-default" onClick={() => this.setState({ selectedObjectIDs: [] }, function () { $('#modalModuleTeilenDialogButton').click() })}>Briefkasten <i className="material-icons">notifications</i><span className="badge badge-danger">{this.state.allegeteilteModuleFuerBenutzer.length}</span></button>
        }

        let topNav = null
        if (this.state.parent_id > 0) {
            topNav = (
                <>
                    <hr />
                    <div style={{ fontSize: '18px' }}><a className="text-primary" href="#" onClick={this.ordnerAktualisieren}>Flashcards - </a> <b>{this.state.activeBenutzerOrdner.name}</b> ({this.state.activeBenutzerOrdner.anzahl_dateien} Stapel) </div>
                    <hr />
                </>

            )
        }

        let benutzerordnerMapping = null
        let unterrichtsmoduleMapping = null

        if (this.getBenutzereinstellung('showFlashCardsSymbole') !== null && this.getBenutzereinstellung('showFlashCardsSymbole').boolvalue) {
            benutzerordnerMapping = this.state.benutzerordner.map(this.benutzerordnerRow)
            unterrichtsmoduleMapping = this.state.unterrichtsmodule.map(this.flashCard)
            if (this.state.parent_id===1234567890){
                unterrichtsmoduleMapping = this.state.gemeinsameStapel.map(this.flashCard)
            }   
        }
        else {
            benutzerordnerMapping = this.state.benutzerordner.map(this.benutzerordnerRow2)
            unterrichtsmoduleMapping = this.state.unterrichtsmodule.map(this.flashCard2)
            if (this.state.parent_id===1234567890){
                unterrichtsmoduleMapping = this.state.gemeinsameStapel.map(this.flashCard2)
            }
        }

        let body = (
            <div>
                <div className="rightalign noprint" >{ansichtdropdown}  {moduleGeteiltButton} {neuerStapelButton}</div>
                {topNav}
                <div className="row" style={{minHeight:'600px'}}>
                    {benutzerordnerMapping}
                    {unterrichtsmoduleMapping}
                </div>
            </div>
        )

        return <CardWithText title="Flashcards" body={body} stylename="card-header card-header-text card-header-info" />

    

    }

    aktualisiereOrdner(e) {
        let activeBenutzerOrdner = this.state.activeBenutzerOrdner
        if (e.target.name === "name") activeBenutzerOrdner.name = e.target.value
        if (e.target.name === "parent_id") activeBenutzerOrdner.parent_id = Number(e.target.value)

        this.setState({ activeBenutzerOrdner })
    }

    existsBenutzerOrdner(ordnername) {
        let result = false
        this.state.benutzerordner.forEach(
            function (ordner) {
                if (ordner.name.toLowerCase() === ordnername.toLowerCase()) result = true;
            }
        )

        return result
    }

    modalBenutzerOrdnerDialog() {

        if (this.state.activeBenutzerOrdner === null) return null

        let title = "Eigenschaften von " + this.state.activeBenutzerOrdner.name
        if (this.state.activeBenutzerOrdner.id === 0) title = "Neuen Ordner erstellen"

        const body = (
            <div className="form-group">
                <label htmlFor="exampleInputEmail1">Ordnername</label>
                <input type="text" className="form-control" value={this.state.ordnername} onChange={(e) => this.setState({ ordnername: e.target.value })} id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Ordnername" />
            </div>
        )
        let okButtonenabled = (this.existsBenutzerOrdner(this.state.ordnername) === false)

        return (
            <Modal
                modalid="modalBenutzerOrdnerDialog"
                buttonid="modalBenutzerOrdnerDialogButton"
                body={body}
                title={title}
                okButtonTitle="OK"
                okButtonenabled={okButtonenabled}
                okButtonClick={this.postBenutzerOrdner}
                cancelButton={true}
            />
        )

    }




    changeOrderUModulKorrigierende(e){

        let zukorrigierendeunterrichtsmodule = this.state.zukorrigierendeunterrichtsmodule;

        let order = 'asc';
        if (this.state.orderState === "asc") {
            order = 'desc';
        }


        if (e.target.name =="datum"){  

            if (order === 'desc') {                
                zukorrigierendeunterrichtsmodule.sort(sort_by(e.target.name, false, function (a) { return new Date(a)}));
            }
            else {
               zukorrigierendeunterrichtsmodule.sort(sort_by(e.target.name, true, function (a) { return new Date(a) }));                
            }

        }

        else{// wenn String

            if (order === 'desc') {                
                zukorrigierendeunterrichtsmodule.sort(sort_by(e.target.name, false, function (a) { return a.toUpperCase() }));
            }
            else {
               zukorrigierendeunterrichtsmodule.sort(sort_by(e.target.name, true, function (a) { return a.toUpperCase() }));                
            }
        }


        this.setState({ zukorrigierendeunterrichtsmodule, orderState: order, lastorder: e.target.name + "~" + order })

       

    }

    selectSchuelerMonitoring(schueler){      
        
        this.props.setActiveSchueler(schueler)

        axios.get('/api/unterrichtsmodule/' + schueler.id)
        .then(
            result=>{
                if (result.data.unterrichtsmodule !==null){
                    this.setState({monitoringUnterrichtsmodule: result.data.unterrichtsmodule})
                }
            }
        )
    }

    schuelerPills(schueler){

      
        let className="nav-link"

        if (this.props.activeSchueler!==undefined && this.props.activeSchueler!==null && schueler.id === this.props.activeSchueler.id)className="nav-link active"

        return(
            <li className="nav-item">
                <a className={className} href="#" onClick={()=>this.selectSchuelerMonitoring(schueler)}>{schueler.benutzername}</a>
            </li>
        )

    }

  
    pushMonitoringLink(modul){        
        let link = '/editunterrichtsmodul/' + modul.id + '/monitoring'  
        browserHistory.push(link) 
    }

    erledigen(modul){
        let sql = `UPDATE sfc_stapelablage SET ist_korrigiert_und_ok=1 WHERE unterrichtsmodule_id=${modul.id}; UPDATE sfc_unterrichtsmodulteile SET korrekturmarkierung=0 WHERE unterrichtsmodule_id=${modul.id};`
        const array={ sql }
        axios.post('/api/execcommonquery', array)
        .then(
            result=>{
                this.selectSchuelerMonitoring(this.props.activeSchueler)  
            }
        )
    }

    monitoringModulRow(modul){     

        if (modul.anzahl_modulteile===0) return null   

        const _this = this

        var erledigen = function() {     


            const jahr=new Date().getFullYear()
            if (_this.props.activeSchule.lizenz_jahr < jahr){
                _this.alertMessage(parse("<div>Keine Schul-Lizenz für das Jahr <b>" + jahr  + "</b> für die Korrigierfunktionen vorhanden. Sie können unter 'Schuldaten bearbeiten' eine Lizenz bestellen. Bitte kontaktieren Sie Ihren Schuladministrator oder schreiben Sie eine Mail an <i>info@schoolflashcards.eu</i></div>" ))
                return
            }

            const sql=`UPDATE sfc_unterrichtsmodulteile SET korrekturmarkierung=0 WHERE unterrichtsmodule_id=${modul.id}; DELETE FROM sfc_stapelablage WHERE unterrichtsmodule_id=${modul.id} AND stapel_eigentuemer_id=${modul.eigentuemer_id}`
            const array={sql}
            
            axios.post('/api/execcommonquery',array )
            .then(
                result=>{
                    _this.selectSchuelerMonitoring(_this.props.activeSchueler) ;                    
                }
                
            )
        }
     
        let korrekturstatus=null
        if (modul.ist_in_stapelablage > 0){

            if (modul.anzahl_zukorrigierende_modulteile > 0) {
                korrekturstatus= <KorrekturStatus status="false" text="Warten auf Verbesserung"/>  
            }
            else{
            korrekturstatus= <KorrekturStatus status="ok" text="Keine Fehler!" />  
            }            
        }

        let buttonKorrigiertUndOK = <button className="btn btn-primary btn-sm" onClick={erledigen}>Korrigiert und OK</button>    

        return(
            <tr  key={modul.id}>
                <td>{this.state.monitoringUnterrichtsmodule.indexOf(modul)+ 1}</td>
                <td>{modul.bezeichnung}</td>
                <td className="text-center">{modul.anzahl_modulteile} Karten</td>
                <td className="text-center">geändert: {convertDate(modul.datum_zuletztgeaendert)} <br/>letztes Quiz: <b>{convertDateTime(modul.datum_letztesquiz)}</b></td>
                <td className="text-center">{korrekturstatus}</td>
                <td><button className="btn btn-primary btn-sm" onClick={()=>this.pushMonitoringLink(modul)} ><i className="material-icons"> visibility </i></button></td>
                <td>{buttonKorrigiertUndOK}</td>
                
            </tr>
        )
    }

    monitoringBody(){

        return(
            <div className="row">
                <div className="col-sm-2 col-md-2 col-lg-2">
                    <ul className="nav nav-pills nav-pills-primary flex-column">
                        {this.state.schueler.map(this.schuelerPills)}
                    </ul>
                </div>
                <div className="col-sm-10 col-md-10 col-lg-10">
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <tbody>
                                {this.state.monitoringUnterrichtsmodule.map(this.monitoringModulRow)}
                            </tbody>

                        </table>                        
                    </div>
                   
                </div>
            </div>
        )

    }

    modalPruefungDialog() {


        const _this = this

        function change(e){
            let activePruefung = _this.state.activePruefung
            activePruefung.titel =e.target.value
             _this.setState({activePruefung})

        }
        function changeDate(date){
            let activePruefung = _this.state.activePruefung
            activePruefung.datum =date
             _this.setState({activePruefung})

        }
        function changeFreigegeben(e){
            let activePruefung = _this.state.activePruefung
            let freigegeben = 0
            if (e.target.checked) freigegeben = 1
            activePruefung.freigegeben =freigegeben
             _this.setState({activePruefung})

        }
        function changeKorrigiert(e){
            let activePruefung = _this.state.activePruefung
            let ist_korrigiert = 0
            if (e.target.checked) ist_korrigiert = 1
            activePruefung.ist_korrigiert =ist_korrigiert
             _this.setState({activePruefung})
        }


        function changeAnweisung(e){
            let activePruefung = _this.state.activePruefung           
            activePruefung.anweisung_vor_pruefung =e.target.value
             _this.setState({activePruefung})

        }

        let titel = 'Unbenannter Titel'
        let caption = 'Neues Quiz'

        if (this.state.activePruefung.id > 0){
            titel = this.state.activePruefung.titel
            caption = 'Quiz bearbeiten'
        }

        let titleDropdown = '(Karteikartenstapel nicht gewählt)'
        if (this.state.activeModule !==null){
            titleDropdown = this.state.activeModule.bezeichnung
        }

        

        function dropdownRow(modul){

            return(
                    <a key={modul.id} className="dropdown-item" href="#" onClick={()=>_this.setState({activeModule:modul})}>{modul.bezeichnung}</a>
            )
        }

        function getStandardText(){
            let activePruefung = _this.state.activePruefung   
            activePruefung.anweisung_vor_pruefung = _this.getBenutzereinstellung("anweisungvorpruefung").textvalue
            _this.setState({activePruefung})
        }

        function saveStandardtext(){
            _this.setBenutzereinstellung("anweisungvorpruefung", _this.state.activePruefung.anweisung_vor_pruefung)
        }
       

        const body=(
            <div>
                <div className="form-group">
                    <label >Titel</label>
                    <input type="text" name="titel" className="form-control" value={this.state.activePruefung.titel} onChange={change} placeholder="Titel"/>
                </div>
                <div className="form-group">
                    <label >Datum der Überprüfung</label>
                    <DatePicker locale="de" className="form-control"  dateFormat="P" selected={this.state.activePruefung.datum} onChange={(date)=>changeDate(date)} ></DatePicker>                    
                </div>
                <div className="togglebutton">
                    <label>
                        <input type="checkbox" checked={this.state.activePruefung.freigegeben===1} onChange={changeFreigegeben}/>
                        <span className="toggle"></span>
                        Quizaufgaben sind freigegeben ( = für SuS sichtbar)
                    </label>
                </div>
                <div className="togglebutton">
                    <label>
                        <input type="checkbox" checked={this.state.activePruefung.ist_korrigiert===1} onChange={changeKorrigiert}/>
                        <span className="toggle"></span>
                        als korrigiert kennzeichnen
                    </label>
                </div>
                <hr/>
                <div className="dropdown">
                    <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {titleDropdown}
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        {this.state.unterrichtsmodule.map(dropdownRow)}
                    </div>
                </div>
                <hr/>
                <div className="form-group">
                    <label >Anweisung,Text</label>
                    <textarea rows={3} className="form-control" value={this.state.activePruefung.anweisung_vor_pruefung} onChange={changeAnweisung} ></textarea> 
                    <span><button className="btn btn-sm" onClick={getStandardText}>Standardtext</button> <button className="btn btn-sm" onClick={saveStandardtext}>Als Standardanweisung speichern</button></span>                   
                </div>
            </div>
        )

        

        return (
            <Modal
                modalid="modalPruefungDialog"
                buttonid="modalPruefungDialogButton"
                body={body}
                title={caption}
                okButtonTitle="OK"
                okButtonClick={this.savePruefung}
                cancelButton={true}
            />
        )
    }

    savePruefung(){

        let unterrichtsmodul_id = 0
        if (this.state.activeModule !==null)unterrichtsmodul_id = this.state.activeModule.id

        let pruefung ={
            id: this.state.activePruefung.id,
            klassen_code: this.props.activeUser.klassen_code,
            titel: this.state.activePruefung.titel,
            datum: this.state.activePruefung.datum,
            freigegeben: this.state.activePruefung.freigegeben,
            unterrichtsmodul_id,
            anweisung_vor_pruefung:this.state.activePruefung.anweisung_vor_pruefung,
            ist_korrigiert:this.state.activePruefung.ist_korrigiert
        }

        axios.post('/api/pruefungen', pruefung)
        .then(
            result=>{
                this.setState({pruefungen: result.data})
            }
        )
    }


    neuePruefung(){         
      

        this.setState({activePruefung:{id:0, titel:'Unbenannter Titel', datum: new Date(), freigegeben:0,anweisung_vor_pruefung: '', ist_korrigiert:0 },activeModule:null },
        function(){$('#modalPruefungDialogButton').click()})        

    }


    korrigierenContent() {

        let lis = []
        if (this.props.activeUser !==null && this.props.activeUser.ist_schueler===0){
            if (this.state.schueler.length === 0) lis.push(<li key={1000}>Es wurden noch keine Schülerinnen und Schüler erfasst. Klicken Sie <a className="text-primary" href="#" onClick={() => this.setState({ activePage: 'klasse' })}>hier, um Ihre Klasse zu erfassen</a></li>)
            if (this.state.schueler.length > 0 && this.state.alleModuleFuerDenAutor.length === 0) lis.push(<li key={34527}>Es wurden noch keine Aufgaben gestellt. Klicken Sie <a className="text-primary" href="#" onClick={() => this.setState({ activePage: 'flashcards' })}>hier, um Karteikarten zu erfassen und die Stapel als Aufgaben zu teilen.</a></li>)

        }

        let bodyAufgaben = null


     /*     let bodyAufgaben = (
            <ul>{lis}</ul>
        )
        if (this.props.activeUser.ist_schueler === 1)bodyAufgaben=null*/
    
        let bodyKorrigieren = (
            <div className="table-responsive">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th className="text-center">#</th>
                            <th><button className="btn btn-default btn-sm btn-round" name="absender" onClick={this.changeOrderUModulKorrigierende}>Absender</button></th>
                            <th><button className="btn btn-default btn-sm btn-round" name="bezeichnung" onClick={this.changeOrderUModulKorrigierende}>Thema</button></th>
                            <th><button className="btn btn-default btn-sm btn-round" name="kategorie" onClick={this.changeOrderUModulKorrigierende}>Kategorie</button></th>
                            <th><button className="btn btn-default btn-sm btn-round" name="eigentuemer" onClick={this.changeOrderUModulKorrigierende}>Eigentümer/in</button></th>
                            <th className="text-center"><button className="btn btn-default btn-sm btn-round" name="datum" onClick={this.changeOrderUModulKorrigierende}>Zuletzt geändert</button></th>    
                            <th className="text-center"><button className="btn btn-default btn-sm btn-round" name="status" onClick={this.changeOrderUModulKorrigierende}>Status</button></th>
                            <th></th>   
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.zukorrigierendeunterrichtsmodule.map(this.zuKorrigierendeModuleRow)}
                    </tbody>
                </table>
            </div>

        )

        let neuePruefungButton =<button className="btn btn-primary" onClick={this.neuePruefung}>Neues Quiz +</button>        
        if (this.props.activeUser.ist_schueler === 1)neuePruefungButton=null
        let bodyPruefungen = (
            <>
            {neuePruefungButton}
            <div className="table-responsive">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th className="text-center">#</th>
                            <th>Thema</th>
                            <th>Datum</th>
                            <th>Freigegeben</th>
                            <th>Korrigiert</th>
                            <th></th>   
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.pruefungen.map(this.pruefungenRow)}
                    </tbody>
                </table>
            </div>
            </>
        )


        let korrigierenNavLinkClassName="nav-link "
        let korrigierenTabPaneClassName="tab-pane "

        let monitoringNavLinkClassName="nav-link"
        let monitoringTabPaneClassName="tab-pane"

        let pruefungenNavLinkClassName="nav-link"
        let pruefungenTabPaneClassName="tab-pane"


        let badge=null
        if (this.state.zukorrigierendeunterrichtsmodule.length>0)
            badge=(
                <span className="badge badge-danger">{this.state.zukorrigierendeunterrichtsmodule.length}</span>
            )


        if (this.state.activePage==="korrigieren"){
            korrigierenNavLinkClassName="nav-link active"
            korrigierenTabPaneClassName="tab-pane active"
        }
        if (this.state.activePage==="monitoring"){
            monitoringNavLinkClassName="nav-link active"
            monitoringTabPaneClassName="tab-pane active"
        }
        if (this.state.activePage==="pruefungen"){
            pruefungenNavLinkClassName="nav-link active"
            pruefungenTabPaneClassName="tab-pane active"
        }

        let navPillMonitoring = null
        let bodyMonitoring = null
        
        if (this.props.activeUser.ist_schueler===0){
            navPillMonitoring=(
                <li className="nav-item">
                    <a className={monitoringNavLinkClassName} data-bs-toggle="tab" href="#link3" role="tablist" aria-expanded="false">
                       Monitoring
                    </a>
                </li>

            )
            bodyMonitoring=(
                <div className={monitoringTabPaneClassName} id="link3" aria-expanded="false">
                    <hr/>
                    {this.monitoringBody()}
                </div>

            )
        }

   

        const body = (
            <>
                <ul className="nav nav-pills nav-pills-primary" role="tablist">  

                    <li className="nav-item">
                        <a className={pruefungenNavLinkClassName} data-bs-toggle="tab" href="#link1" role="tablist" aria-expanded="false">
                        Quiz
                        </a>
                    </li>

                    <li className="nav-item">
                        <a className={korrigierenNavLinkClassName} data-bs-toggle="tab" href="#link2" role="tablist" aria-expanded="false">
                        Verbessern {badge}
                        </a>
                    </li>
                    {navPillMonitoring}
                </ul>

                <div className="tab-content tab-space">
                    <div className={pruefungenTabPaneClassName} id="link1" aria-expanded="false">
                        
                        {bodyPruefungen}
                    </div>
                    <div className={korrigierenTabPaneClassName} id="link2" aria-expanded="false">
                        {bodyKorrigieren}
                    </div>

                    {bodyMonitoring}

                </div>
            </>

        )
         


        return (<CardWithText title="Aufgaben" body={body} stylename="card-header card-header-text card-header-info" />)

    }


    changeKorrektur(e, modulteil){
       
        
        if (this.props.activeUser!==null) {
              modulteil.korrekturmarkierung = 0;
              if(e.target.checked)  modulteil.korrekturmarkierung = 1;
        }

        axios.post('/api/unterrichtsmodulteile', modulteil)
        .then(
            result=>{                
                this.setState({unterrichtsmodulteile:result.data})
            }
        )
    }




    getSchueler(id) {

        let benutzername = ''
        this.state.schueler.forEach(
            function (schueler) {
                if (schueler.id === id) {
                    benutzername = schueler.benutzername;
                }
            }
        )
        return benutzername
    }

    verbesserungMelden(stapelablage) {

       
        stapelablage.status = 'Warten auf Überprüfung'
        axios.post('/api/stapelablage', stapelablage)
        .then(
            result=>{
                this.getZuKorrigierendeUnterrichtsmodule()
                this.alertMessage(`Der Status des Stapels wurde auf "Warten auf Überprüfung" gesetzt und an ${stapelablage.absender} geschickt.`)
            }
        )
    }

    korrigiertUndErledigt(stapelablage){        

        stapelablage.ist_korrigiert_und_ok = 1
        stapelablage.status='Fertig'

        axios.post('/api/stapelablage', stapelablage)
        .then(
            result=>{
                this.getZuKorrigierendeUnterrichtsmodule()
            }
        )
    }

    pruefungLoeschenDialog() {
        if (this.state.activePruefung === null) return null
        const body = (
            <div>
                <h3>{this.state.activePruefung.titel} löschen</h3>
                <b>Achtung! Der Vorgang nicht mehr rückgängig gemacht werden!</b> <br />
                
            </div>
        )

        return (
            <Modal
                modalid="pruefungLoeschenDialog"
                buttonid="pruefungLoeschenDialogButton"
                body={body}
                cancelButton={true}
                title="Quiz löschen"
                okButtonTitle="Quiz löschen"
                okButtonClick={this.removePruefung}
            />
        )
    }

    removePruefung(){

        axios.post('/api/pruefungen/delete', this.state.activePruefung)
        .then(
            result=>{
                let pruefungen = []
                if (result !==null){
                    pruefungen = result.data

                }
                this.setState({pruefungen})
            }
        )

    }

    getPruefung(pruefung){

        let activeModule = null
        this.state.unterrichtsmodule.forEach(
            function(modul){
                if (modul.id === pruefung.unterrichtsmodul_id){
                    activeModule = modul
                }
            }
        )

        let activePruefung=pruefung
        if (pruefung.anweisung_vor_pruefung===null)activePruefung.anweisung_vor_pruefung = ""
       // activePruefung.ist_korrigiert = pruefung.ist_korrigiert

       
       
        this.setState({activePruefung, activeModule}, function(){$('#modalPruefungDialogButton').click()})
    }

    pruefungenRow(pruefung){

        if (this.props.activeUser.ist_schueler===1 && pruefung.freigegeben===0){
            return null
        }


        let btnedit = <a className="btn btn-info btn-fab btn-fab-mini btn-round" href="#"onClick={()=>this.getPruefung(pruefung)} ><i className="material-icons">edit</i></a>
        let btndelete = <a className="btn btn-danger btn-fab btn-fab-mini btn-round" href="#"onClick={()=>this.setState({activePruefung:pruefung}, function(){$('#pruefungLoeschenDialogButton').click()})} ><i className="material-icons">close</i></a>
        if (this.props.activeUser.ist_schueler===1){
            btnedit = null;
            btndelete = null
        }
        
        
        let check = <i className="material-icons"> done </i>
        if (pruefung.freigegeben===0)check = <i className="material-icons"> block </i>
        let abgelaufen = null
        if (pruefung.datum < new Date()) abgelaufen = '(abgelaufen)'

        let link = "/pruefungen/" + pruefung.id


        let check2 = <i className="material-icons"> done </i>
        if (pruefung.ist_korrigiert===0)check2 = <i className="material-icons"> block </i>

      
         
        
        return (
            <tr>
                <td></td>
                <td><Link to={link}><b>{pruefung.titel}</b></Link></td>
                <td>{convertDate(pruefung.datum) }  {abgelaufen}</td>
                <td>{check}</td>
                <td>{check2}</td>
                <td>{btnedit} {btndelete}</td>
            </tr>
        )
    }
  


    zuKorrigierendeModuleRow(stapelablage) {

        if (this.props.activeUser===null) return null
        if (stapelablage.status==="Fertig") return null

        let link = '/editunterrichtsmodul/' + stapelablage.unterrichtsmodule_id + '/korrigieren'

        let zurueckAnAbsenderButton = <button className="btn btn-primary btn-sm" onClick={()=>this.verbesserungMelden(stapelablage)}> Verbesserung melden <i className="material-icons"> east </i></button>
      
        if (stapelablage.status==="Korrigieren")zurueckAnAbsenderButton=null

        let linkTitle = "Verbessern"
  

        if (this.props.activeUser.id ===stapelablage.stapel_absender_id){
            linkTitle='Überprüfen'
            zurueckAnAbsenderButton = null;            
        }
        let linkButton=<Link className="btn btn-primary btn-sm" to={link}>{linkTitle}</Link>

        if (this.props.activeUser.id !==stapelablage.stapel_absender_id){
            if (stapelablage.status==="Warten auf Überprüfung"){
                zurueckAnAbsenderButton = null;
                linkButton = null
            }
        }

        if (this.props.activeUser.id ===stapelablage.stapel_absender_id && stapelablage.status==="Warten auf Verbesserung"){
                linkButton = null
        }

        



       

       
    
        return (
            <tr key={stapelablage.id}>
                <td className="text-center">{this.state.zukorrigierendeunterrichtsmodule.indexOf(stapelablage) + 1}</td>
                <td>{stapelablage.absender}</td>
                <td>{stapelablage.bezeichnung}</td>
                <td>{stapelablage.kategorie}</td>
                <td>{stapelablage.eigentuemer}</td>
                <td className="text-center">{convertDateTime(stapelablage.datum)}</td>
                <td className="text-center">{stapelablage.status}</td>
                <td>{linkButton} {zurueckAnAbsenderButton}</td>
            </tr>
        )

    }

    sucheSchueler() {

    }

    setActivePage(activePage) {
        const _this = this
        this.setState({ activePage },
            function () {
                _this.forceUpdate()
            })

        let div =  document.getElementById('maincontainer')
        if (div!==null){              
                if (div.offsetLeft===20) $('#toggler').click()
        }

       
    }



    deleteModul() {

        axios.post('/api/unterrichtsmodule/delete', this.state.activeModule)
            .then(
                result => {
                    this.setUnterrichtsmoduleAndBenutzerordner(result)
                }
            )
    }


    modaldeleteModulDialog() {

        if (this.state.activeModule === null) return null
        let body = `Den Stapel "${this.state.activeModule.bezeichnung}" löschen?`

        if (this.state.activeModule.wird_gemeinsam_bearbeitet>0){
            body = `Der Stapel "${this.state.activeModule.bezeichnung}" wird im Team bearbeitet. Nach dem Löschen wird er für die Teilnehmer nicht mehr verfügbar sein. Trotzdem löschen?`
        }

        return (
            <Modal
                modalid="modaldeleteModulDialog"
                buttonid="modaldeleteModulDialogButton"
                body={body}
                title="Stapel löschen"
                okButtonTitle="Löschen"
                okButtonClick={this.deleteModul}
                cancelButton={true}
            />
        )
    }

    editSchuldaten() {
        $('#toggler').click()
        $('#modalSchuledialogButton').click()
    }


    navBarItems(){

        const dashboardMenu = this.getNavItemPill('dashboard', 'Dashboard', 'dashboard', 0)

        let lehrerSchuleMenu = null
        if (this.props.activeUser !== null && (this.props.activeUser.kann_lehrpersonen_bearbeiten === 1 || this.props.activeUser.kann_schuldaten_bearbeiten === 1)) {

            let title = "Schule"
            if (this.props.activeUser.kann_lehrpersonen_bearbeiten === 1 &&  this.props.activeUser.kann_schuldaten_bearbeiten === 1) title = "Verwaltung"
            if (this.props.activeUser.kann_lehrpersonen_bearbeiten === 1 &&  this.props.activeUser.kann_schuldaten_bearbeiten === 0) title = "Lehrpersonen"
            if (this.props.activeUser.kann_lehrpersonen_bearbeiten === 0 &&  this.props.activeUser.kann_schuldaten_bearbeiten === 1) title = "Schule"
            lehrerSchuleMenu = this.getNavItemPill('lehrkraefte', title, 'school', 0)
        }

        let fachgruppenMenu = this.getNavItemPill('fachgruppen', 'Klasse / Gruppen', 'widgets', 0)   
        if (this.props.activeUser.ist_schueler === 1) fachgruppenMenu = null

        const einstellungenMenu = this.getNavItemPill('einstellungen', 'Mein Konto', 'person', 0)  
      
        const flashCardsMenu = this.getNavItemPill('flashcards', 'Meine Flashcards', 'credit_card', this.state.allegeteilteModuleFuerBenutzer.length)  
       
        let aufgabenMenu =this.getNavItemPill('korrigieren', 'Aufgaben', 'edit', this.state.zukorrigierendeunterrichtsmodule.length)  

        if (this.props.activeUser.ist_schueler===1 && this.anzahlBevorstehenderPruefungen()>0){
            aufgabenMenu =this.getNavItemPill('pruefungen', 'Aufgaben', 'edit', this.anzahlBevorstehenderPruefungen())  
        }

        

        let hilfemenu =this.getNavItemPill('hilfe', 'Hilfe', 'help', 0)   
        hilfemenu = null // wird erst eingebaut, wenn Hilf auch da ist

        let abmeldenLink=(
            <li className="nav-item li-side-menu">                
                <a className="nav-link" href="#" onClick={() => this.abmelden()}>
                    <div style={{ color: '#d7d7d7' }}>Abmelden</div>
                </a>
            </li>

        )

        return (
            <>
             <ul className="nav nav-pills nav-pills-icons">
                {dashboardMenu}
                {lehrerSchuleMenu}
                {fachgruppenMenu}
                {flashCardsMenu}
                {aufgabenMenu}
                {einstellungenMenu}     
                {hilfemenu}           
             </ul>
             <div style={{marginTop:'10px',marginLeft:'25px', marginRight:'25px'}}  className="line"/>
             <ul className="nav nav-pills">
                 {abmeldenLink}
             </ul>
             </>
        )
    }

    getNavItemPill(page, title, icon, count ){

        let className="material-icons icon-white"
        let color ='#d7d7d7'
        if (this.state.activePage===page){
            className="material-icons icon-rose"
            color='#e91e63'
        }

        let badge=null
        if (count>0){
            badge = <span className="badge badge-default">{count}</span>
        }

        

        return(
            <li className="nav-item li-side-menu">
                <a className="nav-link" href="#" onClick={() => this.setActivePage(page)}>
                    <div>
                    <i className={className}> {icon} </i>
                    <div style={{ color: color }}>{title} {badge} </div>
                    </div>
                </a>
            </li>
        )
    }

    navBar(){


        return (
            <div id="navBar" className="navbar-dynamic">
                <div className="offcanvas offcanvas-start  nav-bar-shadow" id="demo" style={{ width: '220px', backgroundColor: 'black' }}>
                    <div className="offcanvas-header">
                        <div className="rightalign"></div><button className="btn" style={{ backgroundColor: 'transparent' }} data-bs-dismiss="offcanvas"><i style={{ fontSize: '24px' }} className="material-icons icon-rose">close</i></button>
                    </div>
                    <div className="offcanvas-body">

                        {this.navBarItems()}

                    </div>
                </div>

                <div className="fixed-top" style={{ marginLeft: '15px', marginTop: '15px' }}><button id="toggler" className="navbar-toggler btn btn-just-icon btn-white btn-fab btn-round noprint" type="button" data-bs-toggle="offcanvas" data-bs-target="#demo" aria-controls="demo" aria-expanded="false" aria-label="Toggle navigation">
                    <i className="material-icons text_align-center visible-on-sidebar-regular">more_vert</i>
                    <i className="material-icons design_bullet-list-67 visible-on-sidebar-mini">view_list</i>
                </button></div>


            </div>
        )
    }





    navBar1() {

        /*     if (this.props.activeUser===null || this.props.activeUser===undefined) return <div></div>
    
             let sidebarItemLehrkraefte = null;
    
             if (this.props.activeUser !==null && (this.props.activeUser.kann_lehrpersonen_bearbeiten===1 || this.props.activeUser.kann_schuldaten_bearbeiten===1)  ){
    
                 let title = "Schule/Lehrpersonen"
                 if (this.props.activeUser.kann_lehrpersonen_bearbeiten===1 && this.props.activeUser.kann_schuldaten_bearbeiten===0) title="Lehrpersonen"
                 if (this.props.activeUser.kann_lehrpersonen_bearbeiten===0 && this.props.activeUser.kann_schuldaten_bearbeiten===1) title="Schule"
    
                 sidebarItemLehrkraefte=(
    
                            <SidebarItem 
                                onClick={()=>this.setState({activePage:'lehrkraefte'})}
                                icon="people"
                                title={title}
                                selected={this.state.activePage==='lehrkraefte'}
                            />   
                 )
    
             }
    
          
            let titleKonto = `Kontoeinstellungen (${this.props.activeUser.benutzername})`
    
    
    
            return (
                <nav className="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top noprint" style={{height:'80px'}}>
                    <div className="container-fluid">        
                           
                        <button id="minimizeSidebar" className="navbar-toggler btn btn-just-icon btn-white btn-fab btn-round" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample"  aria-controls="offcanvasExample">
                            <i className="material-icons text_align-center visible-on-sidebar-regular">more_vert</i>
                            <i className="material-icons design_bullet-list-67 visible-on-sidebar-mini">view_list</i>
                        </button> 
                    
                        <div className="offcanvas offcanvas-start " tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel" >
                    
                            <div className="offcanvas-header">                       
                                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" style={{backgroundColor:'white', color:'#2d2e2e'}}></button>
                            </div>
    
    
                            <div className=" offcanvas-body off-canvas justify-content-end" style={{color:'black'}} >  
                            
                                <div style={{width:"200px"}} className="input-group no-border">
                                    <input type="text" value={this.state.suchtext}  className="form-control" placeholder="Schüler/in suchen..." onChange={(e)=>this.setState({suchtext:e.target.value})}/>
                                    <button onClick={this.sucheSchueler}  className="btn btn-just-icon btn-white btn-fab btn-round"> <i className="material-icons">search</i></button>
                                </div>      
    
                                <ul className="navbar-nav">
                                        <li className="nav-item">
                                            <a className="nav-link" href="#" onClick={  ()=>this.setActivePage('dashboard')}    > <div style={{display:'flex'}}> <i style={{float:'left'}} className="material-icons"> dashboard </i><p className="d-lg-none d-md-block"> Dashboard </p></div></a> 
                                        </li>
                                    <li className="nav-item active">
                                        <a className="nav-link" href="#">Home <span className="sr-only">(current)</span></a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#">Features</a>
                                    </li>
                                        <li className="nav-item">
                                    <a className="nav-link" href="#">Pricing</a>
                                        </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#">About</a>
                                    </li>
                                        <li className="nav-item">
                                    <a className="nav-link" href="#">About</a>
                                        </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#">About</a>
                                    </li>  
    
                                    <li className="active"><a data-bs-toggle="tab" href="#home">Home</a></li>
          <li><a data-bs-toggle="tab" href="#menu1">Menu 1</a></li>
          <li><a data-bs-toggle="tab" href="#menu2">Menu 2</a></li>                             
    
                                    <li className="nav-item dropdown">
                                        <a className="nav-link" href="#" id="navbarDropdownProfile" data-bs-toggle="dropdown"  aria-haspopup="true" aria-expanded="false"><div style={{display:'flex'}}><i style={{float:'left'}} className="material-icons"> person </i><p className="d-lg-none d-md-block"> Konto </p></div></a>  
                                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownProfile" style={{zIndex:'5000'}}>
                                        <a className="dropdown-item" onClick={()=>this.setActivePage('einstellungen')} href="#"> Mein Konto </a> 
                                        <div className="dropdown-divider"></div> 
                                        <a className="dropdown-item" href="#" onClick={()=>this.abmelden()}> Abmelden </a> 
                                        
                                        </div> 
                                    </li>                                
                                    
                                </ul>
    
                                <div className="tab-content">
          <div id="home" className="tab-pane fade in active">
            <h3>HOME</h3>
            <p>Some content.</p>
          </div>
          <div id="menu1" className="tab-pane fade">
            <h3>Menu 1</h3>
            <p>Some content in menu 1.</p>
          </div>
          <div id="menu2" className="tab-pane fade">
            <h3>Menu 2</h3>
            <p>Some content in menu 2.</p>
          </div>
        </div>
    
                            </div>
                        </div>
                    </div>
                </nav>
            )*/
    }

    loescheGruppe() {

        axios.post('/api/schuelergruppen/delete', this.state.activeSchuelerGruppe)
            .then(
                result => {
                    let schuelergruppen = []
                    if (result.data !== null) schuelergruppen = result.data
                    this.setState({ schuelergruppen })
                    if (result.data.length > 0) {
                        this.setState({ activeSchuelerGruppe: result.data[0] })
                    }
                }
            )

    }

    modalGruppeLoeschenDialog() {

        if (this.state.activeSchuelerGruppe === null) return null

        let body = `Möchten Sie die Gruppe "${this.state.activeSchuelerGruppe.bezeichnung}" löschen?`


        return (
            <Modal
                modalid="modalGruppeLoeschenDialog"
                buttonid="modalGruppeLoeschenDialogButton"
                title="Gruppe löschen"
                body={body}
                okButtonClick={this.loescheGruppe}
                okButtonTitle="Löschen"
                cancelButton={true}
            />
        )
    }



    hourglass() {

        let div = null
        if (this.state.hourGlassWaiting) {
            div = (
                <div className="text-center" style={{ zIndex: '4000' }}>
                    <Hourglass color="#be97e8" size={100} />
                </div>
            )
        }

        return div
    }

    onFocus(){
        console.log('focus')
        if (this.props.activeUser===undefined || this.props.activeUser ===null) return
        if (this.props.activeUser.ist_schueler===0) return

        const benutzerablenkung={
            id:0,
            benutzer_id: this.props.activeUser.id,
            zeit:null,
            bemerkung:'wieder zurück in schoolflashcards'
        }

        axios.post('/api/benutzer/benutzerablenkungen', benutzerablenkung)
    }

    onResize(){
        console.log('Resize')
        if (this.props.activeUser===undefined || this.props.activeUser ===null) return
        if (this.props.activeUser.ist_schueler===0) return

        const benutzerablenkung={
            id:0,
            benutzer_id: this.props.activeUser.id,
            zeit:null,
            bemerkung:'Fenstergrösse geändert' 
        }

        axios.post('/api/benutzer/benutzerablenkungen', benutzerablenkung)
    }

    onBlur(){
        console.log('Blur')
        if (this.props.activeUser===undefined || this.props.activeUser ===null) return
        if (this.props.activeUser.ist_schueler===0) return

        const benutzerablenkung={
            id:0,
            benutzer_id: this.props.activeUser.id,
            zeit:null,
            bemerkung:'(' + this.state.activePage + ') => anderes Fenster im Focus' 
        }

        axios.post('/api/benutzer/benutzerablenkungen', benutzerablenkung)
    }

    onVisibilitychange(){
        console.log('Visibilitychange')
        if (this.props.activeUser===undefined || this.props.activeUser ===null) return
       if (this.props.activeUser.ist_schueler===0) return

        const benutzerablenkung={
            id:0,
            benutzer_id: this.props.activeUser.id,
            zeit:null,
            bemerkung:'Fenster minimiert/maximiert' 
        }

        axios.post('/api/benutzer/benutzerablenkungen', benutzerablenkung)
    }

    componentDidMount(){

        window.addEventListener("focus", this.onFocus)
        window.addEventListener("blur", this.onBlur)
        window.addEventListener("resize", this.onResize)
        window.addEventListener("visibilitychange", this.onVisibilitychange)
     
    }






    render() {


        if (this.props.activeUser === null || this.props.activeUser === undefined) return null




        let content = null
        let topNavigation = null;

        if (this.state.activePage === "dashboard") {
            content = this.dashBoardContent()
        }
        if (this.state.activePage === "wettbewerbe") {
            content = this.wettbewerbeContent()
        }
        if (this.state.activePage === "einstellungen") {
            content = this.einstellungenContent()
        }
        if (this.state.activePage === "lehrkraefte") {
            content = this.lehrkraefteContent()
        }
        if (this.state.activePage === "korrigieren" || this.state.activePage === "monitoring" || this.state.activePage==="pruefungen") {
            content = this.korrigierenContent()
        }
        if (this.state.activePage === "flashcards") {
            content = this.flashcardsContent()
        }
        if (this.state.activePage === "fachgruppen") {
            content = this.klassengruppenContent()
        }
        if (this.state.activePage === "hilfe") {

            content = (
                <Hilfe               
                
                />
            )
            
        }





      /*  let minHeight = '300px'
        this.state.unterrichtsmodule.forEach(
            function (modul) {
                let elementID = `flashcard${modul.id}`
                let div = document.getElementById(elementID)
                if (div !== null) {
                    let height = div.offsetWidth / 500 * 357
                    div.style.height = `${height}px`
                    div.style.minHeight = minHeight

                }
                let elementh3ID = `flashcardh3${modul.id}`
                div = document.getElementById(elementh3ID)
                if (div !== null) {
                    let height = div.offsetWidth / 500 * 357 / 10
                    div.style.fontSize = `${height}px`
                    div.style.lineHeight = `${height}px`

                }
            }
        )
        this.state.benutzerordner.forEach(
            function (ordner) {
                let height
                let div = document.getElementById(`folder${ordner.id}`)
                if (div !== null) {
                    height = div.offsetWidth / 500 * 357
                    div.style.height = `${height}px`
                    div.style.minHeight = minHeight
                }
                div = document.getElementById(`div${ordner.id}`)
                if (div !== null) {
                    height = div.offsetWidth / 500 * 357
                    div.style.height = `${height}px`
                }

            }
        )
        let minHeight = '300px'
        let div = document.getElementById('myFlashcards')
        if (div !== null) {
            let height = div.offsetHeight
            div = document.getElementById('lastFlashCard')
            if (div !== null) {
                div.style.height = `${div.offsetWidth / 500 * 357}px`
                div.style.minHeight = minHeight
            }
        }*/

        let leftNav =(
            <div className="navbar-left nav-bar-shadow noprint">
                <div style={{height:'100%',width:'100%',  overflow:'auto'}}>
                    {this.navBarItems()}
                </div>
            </div>
        )

        let fileInput  = <input type="file" id="fileInput"  accept=".txt, .csv"  style={{opacity:'0'}} onChange={this.openFile}/>

       

      

        return (

            <div>

                {this.navBar()}

                {leftNav}
               
                <div id="maincontainer" className="main-container ">
                            {topNavigation}
                            {this.hourglass()}
                            {content}
                    <div className="footer onlyprint small-font">{this.props.programm} © 2021 Alle Rechte vorbehalten</div>
                </div>

                {this.modalBenutzerDialog()} {this.modalSchuledialog()} {this.modalDeleteBenutzerDialog()} {this.schuelerLoeschenDialog()}{this.modalSchuelerDialog()}{this.modalGruppeDialog()}
                {this.modalGruppeLoeschenDialog()}{this.modalBenutzerKontoLoeschenDialog()}{this.modalModulEditDialog()}{this.modaldeleteModulDialog()} {this.modalShareBenutzerdialog()} {this.modalModuleTeilenDialog()}
                {this.modalNeueKategorieDialog()}{this.modalBenutzerOrdnerDialog()}{this.modalOrdnerLoeschenDialog()}{this.modalStapelHinzuFuegenDialog()}{this.modalModulVeroeffentlichenDialog()}
                {this.modalLizenzBestellenDialog()} {this.modalPruefungDialog()} {this.pruefungLoeschenDialog()}
                {this.alertMessageDialog()} {fileInput}


            </div>

        )
    }

}


function mapStateToProps({ posts }) {
    return {
        activeUser: posts.userData,
        programm: posts.programmName,
        activeSchueler:posts.schueler,
        activeSchule:posts.schule
    };
};

export default connect(mapStateToProps, {setActiveSchueler,setActiveSchule, logoutUser })(User);

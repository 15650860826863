

export function   getAnzahlRichtigeAntworten(karteikarte){

    if (karteikarte===null) return null
    let anzahlrichtigeAntworten=0
    if (karteikarte.antwort1_richtig===1)anzahlrichtigeAntworten++
    if (karteikarte.antwort2_richtig===1)anzahlrichtigeAntworten++
    if (karteikarte.antwort3_richtig===1)anzahlrichtigeAntworten++
    if (karteikarte.antwort4_richtig===1)anzahlrichtigeAntworten++
    if (karteikarte.antwort5_richtig===1)anzahlrichtigeAntworten++

    return anzahlrichtigeAntworten

  }
